import { makeStyles } from '@material-ui/core/styles';
import { caption } from '../../contexts/Theme/font';

export default makeStyles({
  player: {
    borderRadius: '15px',
    minHeight: '25px',
    height: '25px',
    ...caption,
  },
});
