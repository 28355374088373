import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  List,
  ListItem,
  Popover,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import { SearchIcon } from '../../../assets/icons';
import { deletedProjectOption } from '../../../data/settings';
import { isEmptyValue } from '../../../utils';
import useStyles from './styles';

const PopoverSearchableList = (props) => {
  const classes = useStyles();
  const {
    single,
    isCheckAll,
    data,
    options,
    onClickOption,
    children,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const debounceCallBack = _.debounce((e, callBack) => {
    callBack();
  }, 200);

  const handleSearch = (e) => {
    e.persist();
    debounceCallBack(e, () => {
      setSearch(e.target.value);
    });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setSearch('');
    setAnchorEl(null);
  };

  const handleSelect = (e, value, label) => {
    let newValues = data || (single ? '' : []);
    if (single) {
      if (newValues !== value) {
        newValues = value;
      }
    } else if (label === deletedProjectOption().label) {
      const deletedProjects = value.split(',') || [];
      if (!isEmptyValue(deletedProjects)) {
        const isChecked = !isEmptyValue(data) && deletedProjects.every((v) => data.includes(v));
        if (!isChecked) {
          newValues = newValues.concat(deletedProjects);
        } else {
          newValues = newValues.filter((val) => !deletedProjects.includes(val));
        }
      }
    } else {
      const index = newValues.indexOf(value);
      const isChecked = index > -1;
      if (!isChecked) {
        newValues.push(value);
      } else {
        newValues.splice(index, 1);
      }
    }
    onClickOption(e, newValues, value);
  };

  const renderListItem = (option) => {
    if (single) {
      return (
        <ListItem
          button
          disabled={option.disabled}
          key={option.value}
          className={classes.listItem}
          onClick={(e) => handleSelect(e, option.value)}
        >
          <Radio
            checked={data === option.value}
          />
          <Typography className={classes.label}>
            { option.label }
          </Typography>
        </ListItem>
      );
    }

    let isChecked = false;

    if (isCheckAll) {
      isChecked = isCheckAll;
    } else if (data && option.value) {
      if (option.label === deletedProjectOption().label) {
        const deletedProjects = option.value.split(',');
        isChecked = deletedProjects.every((v) => data.includes(v));
      } else {
        const index = data.indexOf(option.value);
        isChecked = index > -1;
      }
    }
    return (
      <ListItem
        button
        disabled={option.disabled}
        key={option.value}
        className={classes.listItem}
        onClick={(e) => handleSelect(e, option.value, option.label)}
      >
        <Checkbox
          checked={isChecked}
        />
        <Typography className={classes.label}>
          { option.label }
        </Typography>
      </ListItem>
    );
  };

  const renderList = () => {
    let newOptions = options || [];
    if (search) {
      newOptions = newOptions.filter((option) => (
        option.label.toLowerCase().includes(search.trim().toLowerCase())
      ));
    }
    return (
      <List
        disablePadding
        className={classes.list}
      >
        {
          newOptions.length > 0
            ? newOptions.map((option) => renderListItem(option))
            : (
              <ListItem disabled>
                <Typography>
                  No result found
                </Typography>
              </ListItem>
            )
        }
      </List>
    );
  };

  return (
    <>
      {typeof children === 'function' ? children({ isOpen: Boolean(anchorEl), open: handleOpen, close: handleClose }) : children}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <TextField
            fullWidth
            placeholder="Search"
            variant="outlined"
            InputProps={{
              endAdornment: <img alt="search" className={classes.icon} src={SearchIcon} />,
            }}
            onChange={handleSearch}
          />
          { renderList() }
        </div>
      </Popover>
    </>
  );
};

PopoverSearchableList.propTypes = {
  single: PropTypes.bool,
  isCheckAll: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onClickOption: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};

PopoverSearchableList.defaultProps = {
  single: false,
  isCheckAll: false,
  options: [],
  data: null,
  onClickOption: () => {},
  children: null,
};

export default PopoverSearchableList;
