import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// READ read lead GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAPIKey',
    args: [
      { name: 'key', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'key', value: 'key' },
    ],
    fields: '{...APIKeyFields}',
    fragments: [Fragments.apikey],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list leads GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listAPIKeys',
    args: [
      { name: 'type', type: 'String' },
    ],
    argsMap: [
      { name: 'type', value: 'type' },
    ],
    fields: '{...ListAPIKeysResponseFields}',
    fragments: [Fragments.listAPIKeysResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
