import { CONSTRUCTOR } from '..';

export const fieldOption = CONSTRUCTOR.FRAGMENT({
  name: 'FieldOption',
  fields: `{
    name
    label
    value
  }`,
});

export const fieldOptionInput = CONSTRUCTOR.FRAGMENT({
  name: 'FieldOptionInput',
  fields: `{
    name
    label
    value
  }`,
});

export const field = CONSTRUCTOR.FRAGMENT({
  name: 'Field',
  fields: `{
    id
    name
    label
    description
    placeholder
    input_type
    default_value
    options {...FieldOptionFields}
    required
  }`,
  fragments: [fieldOption],
});

export const fieldInput = CONSTRUCTOR.FRAGMENT({
  name: 'FieldInput',
  fields: `{
    id
    name
    label
    description
    placeholder
    input_type
    default_value
    options {...FieldOptionInputFields}
    required
  }`,
  fragments: [fieldOptionInput],
});

export const mappings = CONSTRUCTOR.FRAGMENT({
  name: 'Mappings',
  fields: `{
    fields
    projects
    users
    dates
  }`,
});

export const mappingsInput = CONSTRUCTOR.FRAGMENT({
  name: 'MappingsInput',
  fields: `{
    fields
    projects
    users
    dates
  }`,
});

export const form = CONSTRUCTOR.FRAGMENT({
  name: 'Form',
  fields: `{
    id
    fb_form_id
    campaign_id
    company_id
    name
    header
    description
    image_url
    adset_sync_status
    default_form_source
    fields {...FieldFields}
    terms_and_conditions
    custom_success_message
    external_success_url
    mappings {...MappingsFields}
    accept_sub
    origin
    url
    mirage
    srb
    view_count
    change_7d
    submission_count
    opportunity_count
    qualified_lead_count
    unqualified_lead_count
    deleted_lead_count
    duplicated_count
    booked_opportunity_count
    invalid_submission_count
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
  fragments: [field, mappings],
});

export const listFormResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListFormResponse',
  fields: `{
    forms {...FormFields}
    count
  }`,
  fragments: [form],
});

export const facebookForm = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookForm',
  fields: `{
    fb_page_id
    fb_form_id
    fb_form_name
    fb_form_use_status
    fields {...FieldFields}
    status
  }`,
  fragments: [field],
});

export const listFacebookFormsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListFacebookFormsResponse',
  fields: `{
    fb_forms {...FacebookFormFields}
  }`,
  fragments: [facebookForm],
});
