import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLazyQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  MailSmallIcon,
  PhoneIcon,
  SMSIcon,
  WhatsAppSmallIcon,
} from '../../assets/icons';
import {
  CallAttemptsAlertModal,
  CallNotAllowedModal,
  IconRoundButton,
} from '..';
import {
  AuthContext,
  PageContext,
  SnackbarContext,
} from '../../contexts';
import { useEnhancedMutation } from '../../hooks';
import { ACTIVITY, CALLS } from '../../graphql';
import { clientActivity, clientCalls } from '../..';
import {
  callMethodStatus,
  extractPhoneNumber,
  isEmptyValue,
  jsonParser,
} from '../../utils';
import { pendingSystem, pendingUserAction } from '../callLogs/constants/callLogData';
import {
  IS_CALLING_SELF,
  MOBILE_DIALER,
  NO_AVANSER_LOGIN,
  NO_CALL_ACCESS,
  NO_CALLER_MOBILE_NUMBER,
  NO_ANSWERPOINT_DIALER,
} from '../../data';
import useStyles from './styles';

const ProfileContactOptions = (props) => {
  const {
    isLoading,
    type,
    record,
    refetchCallLogs,
  } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const authContext = useContext(AuthContext);
  const { user, apps } = authContext.state;
  const pageContext = useContext(PageContext);
  const { avanserAccount } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const [callPermissionStatus, setCallPermissionStatus] = useState('');
  const [activeStates, setActiveStates] = useState({
    callAttemptsAlertModal: false,
    callNotAllowedModal: false,
  });

  const mainMobile = record.msisdn || record.mobile || record.phone;

  const [listAnswerpoints, {
    data: listAnswerpointsData,
    loading: listAnswerpointsLoading,
    error: listAnswerpointsError,
  }] = useLazyQuery(CALLS.LIST_ANSWERPOINTS(), {
    client: clientCalls,
    fetchPolicy: 'no-cache',
  });

  const [activityAPI] = useEnhancedMutation(ACTIVITY.CREATE(), { client: clientActivity });

  useEffect(() => {
    if (user && user.id && avanserAccount && !isEmpty(avanserAccount)) {
      listAnswerpoints({
        variables: {
          filters: [
            { field: 'assignee_id', operator: '==', value: user.id },
            { field: 'status', operator: '==', value: 'active' },
          ],
        },
      });
    }
  }, [
    user,
    avanserAccount,
    listAnswerpoints,
  ]);

  const handleLogActivity = (object) => {
    if (record.id !== '' && type !== '') {
      activityAPI({
        variables: {
          type: 'outbound',
          object: JSON.stringify(object),
          target_id: record.id,
          target_type: type,
        },
      });
    }
  };

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const handleAPIError = (label = '', error) => {
    const errorMsgFromRes = error
      && error.response
      && error.response.data
      && jsonParser(error.response.data.error_message);
    const errorStatus = error
      && error.response
      && error.response.status;
    setOpenSnackbar({
      variant: 'error',
      message: `Failed to ${label} with error status ${errorStatus}: ${errorMsgFromRes}`,
    });
  };

  const handleCreateCallLog = async (status, callLabel) => {
    if (status) {
      let body = {
        ...(type === 'lead' && record.id ? { lead_id: record.id } : {}),
        ...(type === 'customer' && record.id ? { lead_id: record.id, customer_id: record.id } : {}),
        call_at: moment().format(),
        call_by: user.id,
        type: 'manual_call',
        direction: 'call_out',
        status,
      };

      if (status === pendingSystem.name) {
        if (callLabel) {
          body = { ...body, label: callLabel };
        } else {
          setOpenSnackbar({ variant: 'error', message: 'Failed to create call log' });
          return;
        }
      }

      try {
        const res = await API.post(
          'LEAD',
          '/calls/log',
          {
            headers: {
              'x-company-id': user.company.id,
              'x-user-id': user.id,
            },
            body: { ...body },
          },
        );
        if (res) {
          refetchCallLogs();
        }
      } catch (error) {
        handleAPIError('create call log', error);
      }
    }
  };

  const handleTriggerCall = async () => {
    let dialerInfo = {
      mobile: '',
    };

    if (!listAnswerpointsError
      && !listAnswerpointsLoading
      && listAnswerpointsData
      && listAnswerpointsData.listAnswerpoints) {
      const { items } = listAnswerpointsData.listAnswerpoints;
      if (!isEmptyValue(items)) {
        dialerInfo = {
          mobile: items[0].dialer_tracking_number,
        };
      }
    }

    if (dialerInfo.mobile) {
      const extracted = await extractPhoneNumber(dialerInfo.mobile);
      if (extracted.country_code && extracted.national_number) {
        toggleState('callAttemptsAlertModal')();
        try {
          const res = await API.post(
            'LEAD',
            '/calls',
            {
              headers: {
                'x-company-id': user.company.id,
                'x-user-id': user.id,
              },
              body: {
                call_to: mainMobile,
                call_from: `+${extracted.country_code}-${extracted.national_number}`,
                record: '1',
              },
            },
          );
          if (res) {
            handleCreateCallLog(pendingSystem.name, res.label);
            handleLogActivity({ type: 'phone', phone: mainMobile });
          }
        } catch (error) {
          handleAPIError('trigger call via Avanser', error);
        }
      } else {
        setOpenSnackbar({
          variant: 'error',
          message: `There/'s an error when retrieving your dialer number info: ${dialerInfo.mobile}`,
        });
      }
    }
  };

  const handleCallButton = async () => {
    let dialerInfo = {
      name: '',
      mobile: '',
    };

    if (!listAnswerpointsError
      && !listAnswerpointsLoading
      && listAnswerpointsData
      && listAnswerpointsData.listAnswerpoints) {
      const { items } = listAnswerpointsData.listAnswerpoints;
      if (!isEmptyValue(items)) {
        dialerInfo = {
          name: items[0].dialer_name,
          mobile: items[0].dialer_tracking_number,
        };
      }
    }

    const status = await callMethodStatus({
      apps,
      avanserAccount,
      user: {
        ...user,
        dialer_name: dialerInfo.name,
        dialer_tracking_number: dialerInfo.mobile,
      },
      record: {
        mobile_country_code: record.mobile_country_code,
        msisdn: record.msisdn,
        mobile: record.mobile,
        phone: record.phone,
      },
    });

    if (status) {
      if ([
        NO_CALL_ACCESS,
        NO_AVANSER_LOGIN,
        NO_CALLER_MOBILE_NUMBER,
        NO_ANSWERPOINT_DIALER,
        IS_CALLING_SELF,
      ].includes(status)) {
        setCallPermissionStatus(status);
        toggleState('callNotAllowedModal')();
      } else if (status === MOBILE_DIALER) {
        // Use native dialer if is mobile
        await handleLogActivity({ type: 'phone', phone: mainMobile });
        await handleCreateCallLog(pendingUserAction.name);
        window.location = `tel:${record.mobile_country_code ? `+${mainMobile}` : mainMobile}`;
      } else {
        toggleState('callAttemptsAlertModal')();
      }
    }
  };

  const handleClickAction = (name) => {
    let clickAction = () => {};

    switch (name) {
      case 'call':
        clickAction = handleCallButton;
        break;
      case 'whatsapp':
        clickAction = () => {
          window.open(`https://wa.me/${mainMobile}`, '_blank');
        };
        break;
      case 'mail':
        clickAction = () => {
          window.location = `mailto:${record.email}`;
        };
        break;
      case 'sms':
        clickAction = () => {
          window.location = `sms:${mainMobile}&body=Hi%2520there`;
        };
        break;
      default:
        break;
    }

    return clickAction;
  };

  const renderButtons = () => {
    const items = [
      {
        name: 'call',
        icon: PhoneIcon,
        disabled: !mainMobile || isLoading,
      },
      {
        name: 'whatsapp',
        icon: WhatsAppSmallIcon,
        disabled: !mainMobile || isLoading,
      },
      {
        name: 'mail',
        icon: MailSmallIcon,
        disabled: !record.email || isLoading,
      },
      {
        name: 'sms',
        icon: SMSIcon,
        hide: !isMobile,
        disabled: !mainMobile || isLoading,
      },
    ];

    return (
      items
        .filter((item) => !item.hide)
        .map((item) => (
          <IconRoundButton
            key={item.name}
            variant="outlined"
            disabled={item.disabled}
            imgIconSrc={item.icon}
            onClick={handleClickAction(item.name)}
          />
        ))
    );
  };

  return (
    <>
      <div className={classes.buttonGroup}>
        { renderButtons() }
      </div>

      <CallNotAllowedModal
        type={type}
        status={callPermissionStatus}
        open={activeStates.callNotAllowedModal}
        onClose={toggleState('callNotAllowedModal')}
      />
      <CallAttemptsAlertModal
        open={activeStates.callAttemptsAlertModal}
        onClose={toggleState('callAttemptsAlertModal')}
        onConfirm={handleTriggerCall}
      />
    </>
  );
};

ProfileContactOptions.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  record: PropTypes.instanceOf(Object),
  refetchCallLogs: PropTypes.func,
};

ProfileContactOptions.defaultProps = {
  isLoading: false,
  type: '',
  record: {
    id: '',
    email: '',
    msisdn: '',
    mobile: '',
    mobile_country_code: '',
    phone: '',
  },
  refetchCallLogs: () => {},
};

export default ProfileContactOptions;
