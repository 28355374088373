export const primary = {
  main: '#49b3b1',
  dark: '#2c8483',
  light: '#32c8c5',
  contrastText: '#ffffff',
};

export const secondary = {
  main: '#0094b3',
  dark: '#016e8d',
  light: '#00bbe0',
  contrastText: '#ffffff',
};

export const tertiary = {
  grey: '#dbddde',
  dimGrey: '#99a7b0',
  lightGrey: '#f8f9f9',
  lighterGrey: '#586976',
  mediumGrey: '#f4f5f7',
  darkGrey: '#e7eaed',

  space: '#00233B',

  cyprus: '#06363c',

  dimGreen: '#e5faf2',
  green: '#5bc699',
  lightGreen: '#ecf7ee',
  darkGreen: '#50b793',
  jade: '#24a476',

  blue: '#3da0e4',
  lightBlue: '#edf7fd',
  skyBlue: '#f3faff',

  red: '#ee0000',
  alertRed: '#fc7a71',
  alertYellow: '#fdc74c',

  mustard: '#fff6d1',
  amber: '#f2b42a',

  lightPink: '#ffeded',
  peach: '#ff796e',
  orange: '#f79a3e',
  lightOrange: '#f39670',

  lavender: '#7282d7',
  pastelPurple: '#e5eaff',

  hoarfrost: '#d9edfa',
};

export const font = {
  // Space
  header: '#00233b',
  // Slate
  body: '#596975',
  // Gray 1
  subBody: '#969ca4',
};
