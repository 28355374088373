import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// LIST get buyer access list GraphQL schema
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listBuyerAccesses',
    args: [
      { name: 'customer_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'customer_id', value: 'customer_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{ items }',
    fragments: [],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST get buyer access list GraphQL schema
export const UNIT_LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listUnits',
    args: [
      { name: 'project_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'keyword', type: 'String' },
    ],
    argsMap: [
      { name: 'project_id', value: 'project_id' },
      { name: 'limit', value: 'limit' },
      { name: 'keyword', value: 'keyword' },
    ],
    fields: '{...ListUnitsResponseFields}',
    fragments: [Fragments.listUnitsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// get buyer access list GraphQL schema
export const GET_BUYER_ACCESS_LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getBuyerAccessList',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
      // { name: 'sort', type: 'KeyValue' },
      // { name: 'filter', type: '[KeyValue]' },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
      // { name: 'sort', value: 'sort' },
      // { name: 'filter', value: 'filter' },
    ],
    fields: `{ 
      items 
      count
    }`,
    fragments: [],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// get buyer access customer GraphQL schema
export const GET_CUSTOMER_BY_LIST_ID = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getCustomerByListID',
    args: [
      { name: 'buyer_access_list_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'buyer_access_list_id', value: 'buyer_access_list_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: `{ 
      items 
      count
      main_item
    }`,
    fragments: [],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
