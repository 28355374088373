import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import Searchable from '../Searchable';
import useStyles from './styles';

export default function SearchableFieldSettings({
  placeholder,
  onChange,
  options,
  value,
  disabled,
  disabledOptions,
}) {
  const classes = useStyles();
  const fsOptions = options
    ? Object.entries(options).map(([key, attribute]) => ({
      id: attribute.id,
      value: attribute.value || '',
      input_type: attribute.type ? attribute.type.toUpperCase() : '',
    }))
    : [];
  const handleOptionChange = (event, newValue, info) => {
    onChange(event, newValue, info);
  };

  const renderOption = (option) => (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs>
        <Typography variant="body2" className={classes.label}>{option.value}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" className={classes.type}>
          {option.input_type}
        </Typography>
      </Grid>
    </Grid>
  );

  const preparedOptions = fsOptions.map((option) => ({
    ...option,
    disabled: disabledOptions.includes(option.id),
  }));

  return (
    <Searchable
      value={fsOptions.find((option) => option.value === value)}
      onChange={handleOptionChange}
      options={preparedOptions}
      getOptionLabel={(option) => option.value}
      renderOption={(option) => (
        renderOption(option)
      )}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

SearchableFieldSettings.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    crm_id: PropTypes.string,
    sc_id: PropTypes.string,
    label: PropTypes.string,
  })),
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};

SearchableFieldSettings.defaultProps = {
  placeholder: 'Select',
  value: '',
  options: [],
  disabled: false,
  disabledOptions: [],
};
