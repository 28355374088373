import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField } from '@material-ui/core';
import Countries from '../../Dropdown/Countries';
import { countries } from '../../../data';
import useStyles from './styles';

export default function MobileInput(props) {
  const {
    variant,
    name,
    label,
    data,
    placeholder,
    error,
    disabled,
    readOnly,
    required,
    hideFlag,
    disabledPortal,
    anchorRef,
    onChange,
    onBlur,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(data.number);
  const [countryCodeValue, setCountryCodeValue] = useState(data.country_code);
  const [callingCodeValue, setCallingCodeValue] = useState('60');
  const fieldRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (countryCodeValue) {
      const countryIndex = countries.findIndex(
        (v) => v.value === countryCodeValue.toUpperCase(),
      );
      if (countryIndex > -1 && countries[countryIndex]) {
        setCallingCodeValue(countries[countryIndex].calling_code);
      }
    }
  }, [countryCodeValue]);

  const removePrefix = (countryCode, number) => {
    const regex = /^(\+60|60|0)/;
    if (number) {
      if (countryCode !== 'MY') {
        return number;
      }
      return number.replace(regex, '');
    }
    return '';
  };

  const handleGetOpen = (v) => {
    setOpen(v);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    onChange(e, {
      country_code: countryCodeValue,
      number: value,
      msisdn: `${callingCodeValue}${value}`,
    });
  };

  const handleOnChangeCode = (e, { countryCode, callingCode }) => {
    let newNumber = inputValue;
    setCountryCodeValue(countryCode);
    if (countryCode === 'MY') {
      newNumber = removePrefix(countryCode, inputValue);
      setInputValue(newNumber);
    }
    onChange(e, {
      country_code: countryCode,
      number: newNumber,
      msisdn: `${callingCode}${newNumber}`,
    });
  };

  const handleOnBlur = (e) => {
    const { value } = e.target;
    if (countryCodeValue === 'MY') {
      const newNumber = removePrefix(countryCodeValue, value);
      setInputValue(newNumber);
      onBlur(e, {
        country_code: countryCodeValue,
        number: newNumber,
        msisdn: `${callingCodeValue}${newNumber}`,
      });
    }
  };

  return (
    <div ref={anchorRef}>
      {
        label && (
          <InputLabel
            id={name}
            error={!!error}
            required={required}
            shrink={false}
            variant="standard"
            disableAnimation
          >
            { label }
          </InputLabel>
        )
      }
      <TextField
        fullWidth
        type="tel"
        variant={variant}
        ref={fieldRef}
        name={name}
        error={!!error}
        helperText={error}
        required={required}
        value={inputValue || ''}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => handleOnChange(e)}
        className={`${classes.text} ${open && classes.focus}`}
        onBlur={(e) => handleOnBlur(e)}
        InputProps={{
          readOnly,
          startAdornment: (
            <Countries
              variant={variant}
              hideFlag={hideFlag}
              disabledPortal={disabledPortal}
              type="calling_code"
              readOnly={readOnly}
              disabled={disabled}
              anchorRef={fieldRef}
              value={countryCodeValue}
              onChange={handleOnChangeCode}
              onOpen={handleGetOpen}
            />
          ),
        }}
        {...rest}
      />
    </div>
  );
}

MobileInput.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hideFlag: PropTypes.bool,
  disabledPortal: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

MobileInput.defaultProps = {
  variant: 'outlined',
  name: '',
  label: '',
  error: '',
  placeholder: '',
  readOnly: false,
  disabled: false,
  required: false,
  hideFlag: false,
  disabledPortal: true,
  data: {
    country_code: '',
    number: '',
    msisdn: '',
  },
  anchorRef: null,
  onChange: () => {},
  onBlur: () => {},
};
