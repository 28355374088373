import { createContext } from 'react';
import Immutable from 'seamless-immutable';

export const initialState = Immutable({
  apps: {
    commission_tracker: {},
    credit_check: {},
    lead_management_v2: {},
    loan_tracker: {},
    showroom: {},
  },
  error: null,
  hasAccess: false,
  hasHigherAccess: false,
  isAuthenticated: false,
  isError: false,
  isLoggedOut: false,
  isLoading: false,
  user: {
    id: '',
    name: '',
    email: '',
    mobile_prefix: '',
    mobile_suffix: '',
    phone_number: '',
    groups: [],
    role: '',
    company: {
      id: '',
      name: '',
      shortName: '',
      apps: '',
      code: '',
    },
    companyBranch: {
      id: '',
      name: '',
      shortName: '',
      isExternal: false,
    },
  },
  users: {}, // Always return all users
  userOptions: [], // Have company branch restrictions based on access roles
  activeUserOptions: [],
  listCompanyBranches: [],
});

export default createContext({
  state: initialState,
  actions: {
    authenticate: () => {},
    signOut: () => {},
  },
});
