import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// LeadField GraphQL Queries.

// READ read lead field GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getLeadFieldSettings',
    args: [],
    argsMap: [],
    fields: '{...LeadFieldSettingsResponseFields}',
    fragments: [Fragments.leadFieldSettingsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export default READ;
