import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  content: {
    flexGrow: 1,
  },
  controls: {
    padding: '1rem 0',
  },
});
