import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.tertiary.mustard,
    padding: '1rem',
    marginBottom: '1rem',
    '& ul': {
      marginTop: '0.5rem',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
  },
}));
