import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputBase, LinearProgress, MenuItem } from '@material-ui/core';
import { filters, fonts } from '../../contexts/Theme';

export function useStyles(props, wide) {
  return makeStyles((theme) => ({
    button: {
      marginTop: '1.5rem',
    },
    closeIcon: {
      width: '20px',
      height: '20px',
      filter: filters.font.body,
      '&.disabled': {
        filter: filters.tertiary.grey,
      },
    },
    checkIcon: {
      color: theme.palette.tertiary.green,
      marginLeft: theme.spacing(1),
      fontSize: '1.14rem',
    },
    content: {
      flexGrow: 1,
      '& > div:first-child p': {
        color: theme.palette.font.header,
      },
    },
    errorText: {
      color: theme.palette.tertiary.alertRed,
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    hide: { display: 'none' },
    tableHeader: {
      ...fonts.subtitle1,
    },
    titleWrapper: {
      marginBottom: '0.93rem',
    },
    textIconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    fieldLabel: {
      fontWeight: fonts.semiBold,
      color: theme.palette.font.header,
    },
    fieldSection: { margin: '1.43rem 0' },
    fileInput: {
      padding: '1rem',
      minHeight: '3.93em',
      '& > img': {
        marginRight: '0.7rem',
      },
      '& > button': {
        marginLeft: '0.5rem',
      },
    },
    fileInputWrapper: {
      marginTop: '1.5rem',
      border: `1px solid ${theme.palette.tertiary.grey}`,
      borderRadius: '5px',
    },
    sections: {
      [theme.breakpoints.up('sm')]: {
        width: wide ? '42rem' : '',
      },
    },
    controls: {
      padding: '1.5rem 0',
    },
    scrollable: {
      overflowY: 'scroll',
    },
    paper: {
      maxHeight: '10rem',
    },
    paddingLeft: { paddingLeft: theme.spacing(6) },
    marginRight: { marginRight: theme.spacing(2) },
    list: {
      fontSize: '12px',
      paddingLeft: '1.8em',
      '& li': {
        marginBottom: '0.5rem',
      },
    },
    information: {
      marginBottom: '1.65rem',
    },
    optionTitle: {
      ...fonts.subtitle1,
      padding: '0.43rem 1.07rem',
      color: theme.palette.font.header,
    },
    description: {
      marginBottom: '1.5rem',
      '& > p': {
        color: theme.palette.font.header,
      },
    },
    buContainer: {
      marginBottom: '1.5rem',
      '& > label': {
        marginBottom: 0,
      },
      '& > span': {
        marginBottom: '0.5rem',
        display: 'block',
      },
    },
    fileInfo: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }))(props);
}

export const SelectInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.tertiary.grey}`,
    padding: '0.6rem 1.6rem 0.6rem 1rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:placeholder': {
      color: `${theme.palette.font.subBody}`,
    },
    '&:hover:not($disabled)': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  disabled: {
    '& $input': {
      backgroundColor: `${theme.palette.tertiary.lightGrey}`,
    },
  },
  focused: {
    '& $input': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))(InputBase);

export const CustomMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
  },
  selected: {},
}))(MenuItem);

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 20,
    backgroundColor: theme.palette.tertiary.dimGreen,
  },
  bar: {
    borderRadius: 20,
  },
}))(LinearProgress);
