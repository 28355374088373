export const enabledStages = ['prod', 'staging'];

// Channel types
export const facebookForm = 'fb_form';
export const facebookAd = 'fb_ad';
export const blastEmail = 'email';
export const blastSMS = 'sms';
export const event = 'event';
export const website = 'website';
export const listingPortal = 'listing_portal';
export const printedMedia = 'printed_media';

// All GA event types
export const login = 'login';
export const leadContacted = 'add_to_wishlist';
export const leadQualified = 'add_to_cart';
export const leadUnqualified = 'remove_from_cart';
export const opportunityReservedBooked = 'begin_checkout';
export const viewProfile = 'view_item';
export const campaignCreate = 'add_campaign';
export const channelCreate = 'add_channel';
export const formCreate = 'add_mhub_form';
export const autoAssignmentCreate = 'add_auto_assignment';
export const creditCheckGenerated = 'check_ctos';
export const creditCheckViewSuccess = 'view_ctos';
