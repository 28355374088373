import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Chip,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmptyValue } from '../../../utils';
import useStyles from './styles';

// WARNING:
// Deprecated component for Buyer access section only, will be replace with new Searchable component
export default function Searchable(props) {
  const {
    anchorRef, error,
    label, placeholder,
    textBetweenTags,
    options, value,
    required, onChange,
    hideErrorText,
    dynamicOptions,
    customTags,
    ...autocompleteProps
  } = props;

  const classes = useStyles(props);

  // TODO: LMS2880 NEED TO REVISIT THIS COMPONENT
  const handleOnChange = (e, values, reason) => {
    // To fix backspace will still remove tag that rendered outside
    if (reason === 'remove-option'
    && autocompleteProps.multiple
    && customTags) {
      return;
    }

    if (_.isArray(values)) {
      let newValues = values;
      let arrObjects = values;

      // Supports disallow remove selected option with "locked" in multiselection field
      if (options && options.length > 0 && typeof options[0] === 'object') {
        const lockedOptions = options.filter((option) => option.locked); // Obj format
        const lockedOptionValues = lockedOptions.map((option) => option.value); // Str format
        // Pass in selected locked option only
        const selectedLockedOptions = lockedOptions
          .filter((option) => value.includes(option.value));
        const selectedLockedOptionValues = lockedOptionValues
          .filter((option) => value.includes(option));

        if (selectedLockedOptions.length > 0) {
          // Do logic only if selected value included locked options
          const firstValue = values[0] || value[0];
          if (firstValue) {
            // TODO: LMS2880 Make it support only one type of value format = string,
            // so can remove logic like this
            if (typeof firstValue === 'string') {
              arrObjects = [
                ...selectedLockedOptions,
                ...values.filter((v) => lockedOptionValues.indexOf(v) === -1),
              ];
              newValues = [
                ...selectedLockedOptionValues,
                ...values
                  .filter((v) => lockedOptionValues.indexOf(v) === -1)
                  .map((v) => v),
              ];
            } else if (typeof firstValue === 'object' && Object.prototype.hasOwnProperty.call(firstValue, 'value')) {
              arrObjects = [
                ...selectedLockedOptions,
                ...values.filter((v) => lockedOptionValues.indexOf(v.value) === -1),
              ];
              newValues = [
                ...selectedLockedOptionValues,
                ...values
                  .filter((v) => lockedOptionValues.indexOf(v.value) === -1)
                  .map((v) => v.value),
              ];
            }
          }
        } else {
          newValues = values.map((v) => v.value || v);
        }
      }
      // Support object values and string values
      onChange(e, newValues, arrObjects);
      return;
    }
    onChange(e, values);
  };

  const getValues = () => {
    if (!_.isEmpty(value) && !_.isEmpty(options)) {
      if (_.isArray(value) && !dynamicOptions) {
        return value.map((v) => {
          if (typeof options[0] === 'string' && typeof value[0] !== 'string') {
            return v.value;
          }
          if (typeof options[0] === 'object' && typeof value[0] !== 'object') {
            const valueObj = options.filter((obj) => (obj.value === v));
            return valueObj[0];
          }
          return v;
        });
      }
      return value;
    }
    if (autocompleteProps.multiple && _.isEmpty(value) && !dynamicOptions) {
      return [];
    }
    return autocompleteProps.multiple ? value : null;
  };

  const renderOptionLabel = (option) => {
    if (option) {
      // To support array of object options
      if (typeof option === 'object' && option.label) {
        return option.label;
      }
      // To support array of strings options
      if (typeof option === 'string') {
        return option;
      }
    }
    return '';
  };

  const renderPlaceholder = () => {
    if ((!_.isEmpty(value) && autocompleteProps.disabled)
      || (autocompleteProps.multiple
      && autocompleteProps.renderTags === null
      && !_.isEmpty(getValues()))) {
      return '';
    }
    return placeholder;
  };

  return (
    <Autocomplete
      ref={anchorRef}
      classes={{
        root: classes.root,
        paper: classes.paper,
        listbox: classes.listbox,
      }}
      noOptionsText="No results found"
      value={getValues()}
      options={options}
      getOptionLabel={renderOptionLabel}
      getOptionDisabled={(option) => option.locked || option.disabled}
      renderTags={(tagValue, getTagProps) => (
        Array.isArray(tagValue) && !isEmptyValue(tagValue) && tagValue.map((v, index) => {
          let valueInfo = {};
          if (typeof v === 'object') {
            valueInfo = v;
          } else {
            valueInfo = options.find((opt) => {
              if (opt.value) {
                return opt.value === v;
              }
              return opt === v;
            });
          }
          return (
            <Fragment key={(valueInfo && valueInfo.value) || v}>
              <Chip
                label={(valueInfo && valueInfo.label) || v}
                {...getTagProps({ index })}
                disabled={(valueInfo && valueInfo.locked)}
              />
              { tagValue.length - 1 > index && textBetweenTags ? textBetweenTags : '' }
            </Fragment>
          );
        })
      )}
      onChange={(e, v, r) => handleOnChange(e, v, r)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={!!error}
          required={required}
          variant="outlined"
          label={label}
          placeholder={renderPlaceholder()}
          className={classes.textField}
          helperText={!hideErrorText && !!error ? error : null}
          InputLabelProps={{
            shrink: false,
            variant: 'standard',
            disableAnimation: true,
          }}
        />
      )}
      {...autocompleteProps}
    />
  );
}

Searchable.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  textBetweenTags: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  ]),
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  dynamicOptions: PropTypes.bool,
  customTags: PropTypes.bool,
  onChange: PropTypes.func,
};

Searchable.defaultProps = {
  label: '',
  error: '',
  placeholder: 'Search',
  textBetweenTags: '',
  options: [],
  value: {},
  anchorRef: null,
  required: false,
  hideErrorText: false,
  dynamicOptions: false,
  customTags: false,
  onChange: () => {},
};
