import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { SimpleModal } from '..';
import useStyles from './styles';

const BlastPauseConfirmation = (props) => {
  const {
    type,
    title,
    open,
    onConfirm,
    onCancel,
    content,
  } = props;

  const classes = useStyles();

  const renderContent = () => {
    if (content) {
      return content;
    }
    if (type === 'sms') {
      return (
        <>
          <Typography>
            Are you sure you want to do this?
          </Typography>
          <Typography variant="caption">
            Your credit will be&nbsp;
            <span className={classes.red}>refunded</span>
            &nbsp;to your account and you may schedule this blast again
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography>
          Are you sure you want to do this?
        </Typography>
        <Typography variant="caption">
          You may schedule this blast again
        </Typography>
      </>
    );
  };

  return (
    <SimpleModal
      title={title}
      open={open}
      onCancel={onCancel}
      content={(
        <div className={classes.content}>
          { renderContent() }
        </div>
      )}
      buttons={(
        <>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            Pause
          </Button>
        </>
      )}
    />
  );
};

BlastPauseConfirmation.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  content: PropTypes.node,
};

BlastPauseConfirmation.defaultProps = {
  type: '',
  title: 'Pause confirmation',
  open: false,
  onConfirm: () => {},
  onCancel: () => {},
  content: null,
};

export default BlastPauseConfirmation;
