import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';
import * as NoteFragments from '../Note/fragments';

// READ read lead GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getLead',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...GetLeadResponseFields}',
    fragments: [Fragments.getLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list leads GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listLeads',
    args: [
      { name: 'fields', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'per_query', type: 'Int' },
      { name: 'pit_id', type: 'String' },
      { name: 'search_after', type: 'String' },
      { name: 'list_back', type: 'Boolean' },
      { name: 'page', type: 'Int' },
      { name: 'count_only', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'fields', value: 'fields' },
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'per_query', value: 'per_query' },
      { name: 'pit_id', value: 'pit_id' },
      { name: 'search_after', value: 'search_after' },
      { name: 'list_back', value: 'list_back' },
      { name: 'page', value: 'page' },
      { name: 'count_only', value: 'count_only' },
    ],
    fields: '{...ListLeadResponseFields}',
    fragments: [Fragments.listLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DOWNLOAD download selected leads GraphQL schema.
export const DOWNLOAD = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'downloadLeads',
    args: [
      { name: 'ids', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'exported_column', type: '[String]' },
      { name: 'all_leads', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'ids', value: 'ids' },
      { name: 'filters', value: 'filters' },
      { name: 'exported_column', value: 'exported_column' },
      { name: 'all_leads', value: 'all_leads' },
    ],
    fields: '{...DownloadLeadsResponseFields}',
    fragments: [Fragments.downloadLeadsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_ACTIVITIES list leads' activities GraphQL schema.
export const LIST_ACTIVITIES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listActivities',
    args: [
      { name: 'lead_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListActivitiesResponseFields}',
    fragments: [Fragments.listActivitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SEARCH search leads GraphQL schema.
export const SEARCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'searchLead',
    args: [
      { name: 'query', type: 'String' },
      { name: 'email', type: 'String' },
      { name: 'mobile', type: 'String' },
      { name: 'duplication', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'query', value: 'query' },
      { name: 'email', value: 'email' },
      { name: 'mobile', value: 'mobile' },
      { name: 'duplication', value: 'duplication ' },
    ],
    fields: '{...SearchLeadResponseFields}',
    fragments: [Fragments.searchLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_NOTE read note GraphQL schema.
export const READ_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getNote',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...NoteFields}',
    fragments: [NoteFragments.note],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_NOTE list notes GraphQL schema.
export const LIST_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listNotes',
    args: [
      { name: 'lead_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListNoteResponseFields}',
    fragments: [NoteFragments.listNoteResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list of lead csv exports GraphQL schema.
export const LIST_LEAD_EXPORTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listLeadExports',
    args: [
      { name: 'prefix', type: 'String' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'prefix', value: 'prefix' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListLeadExportsResponseFields}',
    fragments: [Fragments.listLeadExportsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
