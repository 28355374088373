import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Audience GraphQL Mutations.

// CREATE creates audience group GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createAudienceGroup',
    args: [
      { name: 'name', type: 'String', required: true },
      { name: 'conditions', type: '[Filter!]', required: true },
    ],
    argsMap: [
      { name: 'name', value: 'name' },
      { name: 'conditions', value: 'conditions' },
    ],
    fields: '{...AudienceGroupFields}',
    fragments: [Fragments.audienceGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE updates audience group GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateAudienceGroup',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'conditions', type: '[Filter!]', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'name', value: 'name' },
      { name: 'conditions', value: 'conditions' },
    ],
    fields: '{...AudienceGroupFields}',
    fragments: [Fragments.audienceGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
