import { makeStyles } from '@material-ui/core/styles';

export function popperStyles(props, width) {
  return makeStyles(({
    popper: {
      width,
      zIndex: 2,
      '& > .MuiPaper-root > div > ul': {
        maxHeight: '10rem',
        overflowY: 'scroll',
      },
    },
  }))(props);
}

export function useStyles(props) {
  return makeStyles((theme) => ({
    flag: {
      marginRight: '0.5rem',
    },
    selectButton: {
      minHeight: '2.86rem',
      padding: '0 16px',
      '&:not(.standard)': {
        borderRight: `1px solid ${theme.palette.tertiary.grey}`,
        borderRadius: 0,
      },
      '& > span > i': {
        width: '4rem',
      },
      '& > span > svg': {
        color: theme.palette.font.body,
      },
    },
    placeholder: {
      margin: '0 1rem',
      color: theme.palette.font.subBody,
    },
    optionContainer: {
      '& > span': {
        marginRight: '0.5rem',
      },
    },
    searchMenu: {
      padding: '1rem',
      borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
    },
    countryCodeList: {
      '& > li.default': {
        borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
      },
    },
  }))(props);
}
