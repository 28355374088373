import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  primaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: '1rem 0.5rem 1.5rem 0.5rem',
    borderLeft: `1px solid ${theme.palette.tertiary.darkGrey}`,
    '& button + button': {
      marginTop: '0.5rem',
    },
  },
  active: {
    '& img': {
      opacity: 1,
    },
  },
  secondaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 0,
    transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn} 0s`,
    '& > *': {
      display: 'none',
    },
    '&.expand': {
      borderLeft: `1px solid ${theme.palette.tertiary.darkGrey}`,
      [theme.breakpoints.down('md')]: {
        width: '280px',
      },
      width: '330px',
      '& > *': {
        display: 'block',
      },
      '& > section': {
        padding: '1.5rem',
      },
    },
  },
  icon: {
    width: '20px',
    height: '20px',
    opacity: 0.5,
    '&.big': {
      width: '25px',
      height: '25px',
    },
  },
}));
