import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core';
import useStyles from './styles';

const CircularProgress = (props) => {
  const {
    color,
    ...rest
  } = props;
  const classes = useStyles(props, color);

  return (
    <div className={classes.root}>
      <MuiCircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={5}
        {...rest}
        value={100}
      />
      <MuiCircularProgress
        variant="determinate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={5}
        {...rest}
      />
    </div>
  );
};

CircularProgress.propTypes = {
  color: PropTypes.string,
};

CircularProgress.defaultProps = {
  color: '',
};

export default CircularProgress;
