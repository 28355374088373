import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Button,
  Chip,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { facebookReauthorise } from '../../../../assets/images';
import { InfoBanner } from '../../../../components';
import { AuthContext, PageContext } from '../../../../contexts';
import { ROLE_HEADQUARTERS } from '../../../../data';

import useStyles from './styles';

export default function ReauthoriseFacebookModal(props) {
  const {
    open,
    onClick,
    onClose,
  } = props;

  const authContext = useContext(AuthContext);
  const { user, hasHigherAccess } = authContext.state;
  const pageContext = useContext(PageContext);
  const { facebookSubscription } = pageContext.state;

  const [helperText, setHelperText] = useState('');
  const [content, setContent] = useState('');

  const classes = useStyles();

  const isHQ = user.role === ROLE_HEADQUARTERS;

  useEffect(() => {
    if (hasHigherAccess) {
      if (user.role === ROLE_HEADQUARTERS) {
        setContent('Facebook data authorisation has been interrupted due to security changes. Kindly re-authorise to ensure no interruption to your form submissions.');
      } else {
        setContent('Kindly contact your Facebook page admin or person-in-charge to re-authorise to ensure no interruption to your Facebook form submissions.');
      }
    }
  }, [
    hasHigherAccess,
    user,
  ]);

  useEffect(() => {
    if (!isEmpty(facebookSubscription)) {
      const {
        data_access_expires_at: dataAccessExpiresAt,
        is_valid: isValid,
      } = facebookSubscription;
      const expiresAt = moment(dataAccessExpiresAt);
      const now = moment();
      const daysLeftToExpire = expiresAt.diff(now, 'days');
      const expiring7Days = expiresAt.isAfter(now) && daysLeftToExpire <= 7;

      if (expiring7Days && isValid) {
        setHelperText('Your access token will expire soon');
      } else {
        setHelperText('Your access token has expired');
      }
    }
  }, [facebookSubscription]);

  const handleClick = () => {
    onClick();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <div className={classes.container}>
        <IconButton className={classes.iconClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <img alt="Reauthorise" src={facebookReauthorise} />
        <section>
          { helperText && (
            <InfoBanner
              className={classes.infoBanner}
              type="warning"
              content={(
                <Typography variant="body2" display="inline">
                  { helperText }
                </Typography>
              )}
            />
          )}
          <Chip
            variant="outlined"
            className={classes.statusChip}
            label="Action required"
          />
          <Typography variant="h4">Re-authorise Facebook</Typography>
          <Typography variant="body2">
            { content }
          </Typography>
          {
            isHQ ? (
              <Button
                fullWidth
                variant="contained"
                onClick={handleClick}
              >
                Re-authorise now
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={onClose}
                className={classes.level2CTA}
              >
                Okay
              </Button>
            )
          }
        </section>
      </div>
    </Dialog>
  );
}

ReauthoriseFacebookModal.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

ReauthoriseFacebookModal.defaultProps = {
  open: false,
  onClick: () => {},
  onClose: () => {},
};
