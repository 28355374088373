import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography } from '@material-ui/core';
import { Search2Icon } from '../../assets/icons';
import useStyles from './styles';

const ListEmptyState = (props) => {
  const {
    name,
    title,
    subtitle,
    hideSubtitle,
  } = props;
  const classes = useStyles();

  const renderTitle = () => {
    if (title !== '') {
      return <Typography>{ title }</Typography>;
    }
    return (
      <Typography variant="subtitle1">
        {`No ${name} found`}
      </Typography>
    );
  };

  const renderSubtitle = () => {
    if (!hideSubtitle) {
      if (subtitle !== '') {
        return <Typography>{ subtitle }</Typography>;
      }
      return (
        <Typography>
          {`Create new ${name} and it will show up here`}
        </Typography>
      );
    }
    return '';
  };

  return (
    <div className={classes.empty}>
      <Icon>
        <img alt="empty" src={Search2Icon} />
      </Icon>
      { renderTitle() }
      { renderSubtitle() }
    </div>
  );
};

ListEmptyState.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hideSubtitle: PropTypes.bool,
};

ListEmptyState.defaultProps = {
  title: '',
  subtitle: '',
  hideSubtitle: false,
};

export default ListEmptyState;
