import * as CONSTRUCTOR from '../generate';

// Avanser account fields fragment.
export const account = CONSTRUCTOR.FRAGMENT({
  name: 'Account',
  fields: `{
    id
    company_id
    client_id
    client_name
    client_email
    access_token
    created_by
    created_at
    updated_by
    updated_at
  }`,
});

// Adsource fields fragment.
export const adsource = CONSTRUCTOR.FRAGMENT({
  name: 'Adsource',
  fields: `{
    id
    company_id
    name
    service_code
    tracking_number
    description
    linked_id
    linked_type
    created_at
    created_by
    updated_at
    updated_by
    deleted_by
    deleted_at
  }`,
});

// Answerpoint fields fragment.
export const answerPoint = CONSTRUCTOR.FRAGMENT({
  name: 'Answerpoint',
  fields: `{
   id
   company_id
   adsource_id
   assignee_id
   name
   mobile
   email
   status
   dialer_name
   dialer_tracking_number
   created_by
   created_at
   updated_by
   updated_at
   deleted_by
   deleted_at
  }`,
});

// ListAdsourcesResponse fields fragment.
export const listAdsourcesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListAdsourcesResponse',
  fields: `{
    items {...AdsourceFields}
    count
  }`,
  fragments: [adsource],
});

// ListAnswerpointsResponse fields fragment.
export const listAnswerpointsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListAnswerpointsResponse',
  fields: `{
    items {...AnswerpointFields}
    count
  }`,
  fragments: [answerPoint],
});
