import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: 'auto',
    minWidth: '31.25rem',
    padding: '1.786rem 2.143rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  solidContainer: {
    border: `1px solid ${theme.palette.tertiary.grey}`,
    borderRadius: '5px',
    padding: '1rem',
    margin: '1.75rem 0',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  hide: { display: 'none' },
  iconClose: {
    '& > svg': {
      width: '24px',
      height: '24px',
      '& > path': {
        fill: theme.palette.font.body,
      },
    },
  },
  marginRight: {
    marginRight: '15px',
  },
  topBar: {
    marginBottom: '0.72rem',
  },
}));
