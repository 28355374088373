import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import {
  Link,
  Typography,
} from '@material-ui/core';
import SimpleModal from '../SimpleModal';
import Button from '../Button';
import { AuthContext } from '../../contexts';
import {
  IS_CALLING_SELF,
  NO_AVANSER_LOGIN,
  NO_CALL_ACCESS,
  NO_CALLER_MOBILE_NUMBER,
  NO_ANSWERPOINT_DIALER,
} from '../../data';

const CallNotAllowedModal = (props) => {
  const {
    status,
    type,
    open,
    onClose,
  } = props;

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { user } = authContext.state;

  const [modal, setModal] = useState({
    title: '',
    content: null,
    cta: onClose,
    ctaText: 'Okay',
  });

  useEffect(() => {
    if (status) {
      const defaultCTA = {
        cta: onClose,
        ctaText: 'Okay',
      };
      switch (status) {
        case NO_CALL_ACCESS:
        case NO_ANSWERPOINT_DIALER:
          setModal({
            title: NO_ANSWERPOINT_DIALER ? 'You need Avanser dialer to use this feature.' : 'This feature is only available on the mobile website.',
            content: (
              <Typography>
                Please access with your mobile device instead, or contact&nbsp;
                <Link href="mailto:support@mhub.my">support@mhub.my</Link>
                &nbsp;for more info
              </Typography>
            ),
            ...defaultCTA,
          });
          break;
        case NO_AVANSER_LOGIN:
          setModal({
            title: 'You have not connected to your Avanser account.',
            content: (
              <Typography>
                Connect your Avanser account in the&nbsp;
                <Link href="/settings/call">settings page</Link>
                &nbsp;first. If you don’t have one, contact&nbsp;
                <Link href="mailto:support@mhub.my">support@mhub.my</Link>
                &nbsp;for more info
              </Typography>
            ),
            cta: () => history.push('/settings/call'),
            ctaText: 'Go to settings page',
          });
          break;
        case NO_CALLER_MOBILE_NUMBER:
          setModal({
            title: 'Your mobile number is required',
            content: (
              <Typography>
                We notice that your account is not tie to any mobile number.
                Kindly update and try again.
              </Typography>
            ),
            ...defaultCTA,
          });
          break;
        case IS_CALLING_SELF:
          setModal({
            title: 'Same mobile number detected',
            content: (
              <Typography>
                We notice that the&nbsp;
                {type}
                &nbsp;you are trying to call has the same mobile number as you.
              </Typography>
            ),
            ...defaultCTA,
          });
          break;
        default:
          break;
      }
    }
  }, [
    status,
    history,
    type,
    onClose,
    user,
  ]);

  return (
    <SimpleModal
      title={modal.title}
      open={!!status && open}
      content={modal.content}
      onCancel={onClose}
      buttons={(
        <Button
          variant="contained"
          onClick={modal.cta}
        >
          { modal.ctaText }
        </Button>
      )}
    />
  );
};

CallNotAllowedModal.propTypes = {
  status: PropTypes.string,
  type: PropTypes.oneOf(['lead', 'customer']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CallNotAllowedModal.defaultProps = {
  status: '',
  type: 'lead',
  open: false,
  onClose: () => {},
};

export default CallNotAllowedModal;
