import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addButton: {
    padding: 0,
    margin: 0,
    minHeight: 'unset',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
    },
  },
  disabledText: { color: theme.palette.font.subBody },
  description: {
    marginBottom: '1rem',
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
  chipContainer: {
    maxHeight: '150px',
    marginTop: '1rem',
    overflowX: 'hidden',
  },
  chip: {
    maxWidth: '100%',
    marginRight: '0.36rem',
    marginBottom: '0.36rem',
  },
  menuItem: {
    padding: '0',
  },
}));
