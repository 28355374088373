import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import {
  Button,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const ItemQualified = ({ history }) => {
  const classes = useStyles();

  const match = useRouteMatch();
  const customerId = match.params.id;

  const handleClick = () => {
    history.push('/');
  };

  window.document.title = 'Item Qualified';

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography variant="h1">Oops!</Typography>
      <div>
        <Typography variant="body1" display="inline">
          The record that you are trying to view has already been qualified. Click&nbsp;
        </Typography>
        <Typography
          variant="body1"
          display="inline"
          component={Link}
          onClick={() => history.push(`/customers/${customerId}`)}
        >
          here
        </Typography>
        <Typography variant="body1" display="inline">
          &nbsp;to view the qualified lead instead
        </Typography>
      </div>
      <div>
        <Typography variant="body1" display="inline">
        Kindly contact&nbsp;
          <Link href="mailto:support@mhub.my">support@mhub.my</Link>
          &nbsp;for further assistance&nbsp;
        </Typography>
        {/* Zendesk chat widget support has been removed since release/v1.113.0  */}
        {/* <Typography variant="body1" display="inline">
        </Typography>
        For further assistance, kindly contact our customer success team via
        <Typography
          variant="body1"
          display="inline"
          component={Link}
          onClick={() => {
            openSupportWidget();
          }}
        >
            &nbsp;chat widget&nbsp;
      </Typography> */}
        <Typography variant="body1" display="inline">
          or learn more on&nbsp;
        </Typography>
        <Typography
          variant="body1"
          display="inline"
          component={Link}
          target="_blank"
          href="https://support.mhub.my/hc/en-us/categories/360001490114-Lead"
        >
          MHub Support
        </Typography>
        <Typography variant="body1" display="inline">
          &nbsp;knowledge base.
        </Typography>
      </div>
      <Grid item>
        <Button
          variant="contained"
          className={classes.buttons}
          onClick={handleClick}
        >
          Go back
        </Button>
      </Grid>
    </Grid>
  );
};

ItemQualified.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default ItemQualified;
