import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomTooltip from './styles';

const Tooltip = (props) => {
  const {
    title,
    children,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <CustomTooltip
      title={title}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...rest}
    >
      { typeof children === 'function' ? children({ toggle }) : children }
    </CustomTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};

Tooltip.defaultProps = {
  title: '',
  children: null,
};

export default Tooltip;
