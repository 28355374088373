import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {
  validate,
  required,
} from '@mhub/web-validations';
import {
  InputLabel,
  TextField,
} from '@material-ui/core';
import {
  DatePicker,
  Drawer,
  SelectInput,
  TimePicker,
} from '../../../index';
import { SearchableAssignee } from '../../../Dropdown';
import { AuthContext } from '../../../../contexts';
import { callOutcomes  } from '../../constants/callLogData';
import useStyles from './styles';

export default function AddCallLog(props) {
  const {
    type,
    open,
    lead,
    customer,
    onSubmit,
    onClose,
  } = props;
  const authContext = useContext(AuthContext);
  const { user } = authContext.state;
  const classes = useStyles();

  const [log, setLog] = useState({});
  const [errors, setErrors] = useState({});

  const fields = [
    {
      name: 'lead_id',
      label: 'Lead contacted',
      type: 'text',
      disabled: true,
      hide: type !== 'lead',
    },
    {
      name: 'customer_id',
      label: 'Customer contacted',
      type: 'text',
      disabled: true,
      hide: type !== 'customer',
    },
    {
      name: 'call_by',
      label: 'Caller',
      type: 'dropdown',
      disabled: true,
    },
    {
      name: 'call_created_date',
      label: 'Call date',
      type: 'date',
      required: true,
    },
    {
      name: 'call_created_time',
      label: 'Call time',
      type: 'time',
      required: true,
    },
    {
      name: 'duration',
      label: 'Call duration (minutes)',
      type: 'number',
    },
    {
      name: 'status',
      label: 'Call outcome',
      type: 'dropdown',
      options: callOutcomes,
      required: true,
    },
    {
      name: 'remark',
      label: 'Remarks',
      type: 'longtext',
    },
  ];

  useEffect(() => {
    if (open
      && ((type === 'lead' && lead.id) || (type === 'customer' && customer.id))) {
      setLog((prev) => ({
        ...prev,
        ...(type === 'lead' && lead && lead.id ? { lead_id: lead.id } : {}),
        ...(type === 'customer' && customer && customer.id ? { customer_id: customer.id } : {}),
        call_by: user.id,
      }));
    }
  }, [
    type,
    open,
    lead,
    customer,
    user.id,
  ]);

  const getValidations = (isRequired = false) => {
    const validations = [];
    if (isRequired) {
      validations.push(required);
    }
    return validations;
  };

  const getRequiredValidation = (name, value) => {
    const validations = getValidations(true);
    let newLabel = _.startCase(name);
    switch (name) {
      case 'call_created_date':
        newLabel = 'Call date';
        break;
      case 'call_created_time':
        newLabel = 'Call time';
        break;
      case 'status':
        newLabel = 'Call outcome';
        break;
      default:
        break;
    }
    validate({
      name,
      value: !value ? '' : value,
      label: newLabel,
      validations,
    }).then((result) => {
      setErrors((err) => {
        const newErrors = { ...err };
        if (!result.message) {
          delete newErrors[result.key];
          return newErrors;
        }
        return ({
          ...newErrors,
          [result.key]: result.message,
        });
      });
    });
  };

  const handleClose = () => {
    setLog({});
    setErrors({});
    onClose();
  };

  const handleChange = (e, isRequired) => {
    const { name, value } = e.target;
    if (isRequired) {
      getRequiredValidation(name, value);
    }
    setLog((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeDateTime = (field, v) => {
    let newValue = v;
    if (field.name === 'call_created_time') {
      newValue = moment(v).format();
    }
    if (field.required) {
      getRequiredValidation(field.name, newValue);
    }
    setLog((prev) => ({
      ...prev,
      [field.name]: newValue,
    }));
  };

  const handleOnSubmit = () => {
    const emptyRequiredFields = [];
    ['call_created_date', 'call_created_time', 'status'].forEach((name) => {
      getRequiredValidation(name, log[name]);
      if (!log[name]) {
        emptyRequiredFields.push(name);
      }
    });

    if (_.isEmpty(errors) && emptyRequiredFields.length <= 0) {
      const newLog = JSON.parse(JSON.stringify(log));
      const formatDate = moment(log.call_created_date).format('YYYY-MM-DD');
      const formatTime = moment(log.call_created_time).format('HH:mm');
      const combineDateTime = moment(`${formatDate} ${formatTime}`).format();

      delete newLog.call_created_date;
      delete newLog.call_created_time;

      // format duration from minutes to seconds
      if (log.duration) {
        const formatDuration = log.duration * 60;
        newLog.duration = formatDuration.toString();
      }

      onSubmit({
        ...newLog,
        call_at: combineDateTime,
        type: 'manual',
        direction: 'call_out',
      });
      handleClose();
    }
  };

  const renderField = (f) => {
    if (f.hide) return null;

    let funcComponent;

    if (f.name === 'call_by') {
      funcComponent = (
        <>
          <InputLabel>
            {f.label}
          </InputLabel>
          <SearchableAssignee
            restrictOptions
            disabled={f.disabled}
            value={log[f.name] || ''}
          />
        </>
      );
    } else if (['lead_id', 'customer_id'].includes(f.name)) {
      let nameValue = '';
      if (type === 'lead' && lead.id) {
        nameValue = lead.name;
      } else if (type === 'customer' && customer.id) {
        nameValue = customer.name;
      }
      funcComponent = (
        <>
          <InputLabel>
            {f.label}
          </InputLabel>
          <TextField
            fullWidth
            name={f.name}
            variant="outlined"
            type={f.type}
            value={nameValue}
            disabled={f.disabled}
          />
        </>
      );
    } else {
      switch (f.type) {
        case 'date':
          funcComponent = (
            <DatePicker
              simple
              required={f.required}
              disableClearable
              disabled={f.disbled}
              label={f.label}
              error={errors[f.name]}
              name={f.name}
              value={log[f.name] || ''}
              onChange={(v) => handleChangeDateTime(f, v)}
            />
          );
          break;
        case 'time':
          funcComponent = (
            <TimePicker
              fullWidth
              required={f.required}
              disableClearable
              disabled={f.disbled}
              label={f.label}
              error={errors[f.name]}
              value={log[f.name] || null}
              onChange={(v) => handleChangeDateTime(f, v)}
            />
          );
          break;
        case 'dropdown':
          funcComponent = (
            <SelectInput
              required={f.required}
              disabled={f.disbled}
              name={f.name}
              label={f.label}
              options={f.options}
              value={log[f.name] || ''}
              error={!!errors[f.name]}
              caption={errors[f.name]}
              onChange={(e) => handleChange(e, f.required)}
            />
          );
          break;
        case 'longtext':
          funcComponent = (
            <>
              <InputLabel>
                {f.label}
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                disabled={f.disbled}
                name={f.name}
                value={log[f.name] || ''}
                onChange={(e) => handleChange(e, f.required)}
                className={classes.textarea}
              />
            </>
          );
          break;
        default:
          funcComponent = (
            <>
              <InputLabel>
                {f.label}
              </InputLabel>
              <TextField
                fullWidth
                name={f.name}
                variant="outlined"
                type={f.type}
                defaultValue={log[f.name] || ''}
                disabled={f.disabled}
                onChange={(e) => handleChange(e, f.required)}
                InputProps={{
                  inputProps: f.type !== 'text' ? {
                    min: 1,
                  } : {},
                }}
              />
            </>
          );
      }
    }

    return (
      <div key={f.name}>
        { funcComponent }
      </div>
    );
  };

  return (
    <Drawer
      header="Log call"
      open={open}
      submitLabel="Create"
      onSubmit={handleOnSubmit}
      onClose={handleClose}
    >
      <div className={classes.content}>
        { fields.map((field) => renderField(field)) }
      </div>
    </Drawer>
  );
}

AddCallLog.propTypes = {
  type: PropTypes.oneOf(['lead', 'customer']),
  lead: PropTypes.instanceOf(Object),
  customer: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

AddCallLog.defaultProps = {
  type: 'lead',
  lead: {},
  customer: {},
  open: false,
  onSubmit: () => {},
  onClose: () => {},
};
