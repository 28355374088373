import { makeStyles } from '@material-ui/core';
import { filters } from '../../../contexts/Theme';

export default makeStyles(({
  controls: {
    marginTop: '0.5rem',
    '& > div > button': {
      width: '36px',
      padding: 0,
    },
  },
  icon: {
    height: '18px',
    width: '18px',
    filter: filters.tertiary.lighterGrey,
  },
}));
