import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Icon,
  Badge,
} from '@material-ui/core';
import Tooltip from '../Tooltip';
import useStyles from './styles';

const IconRoundButton = (props) => {
  const {
    showBadge,
    activeType,
    size,
    variant,
    tooltipLabel,
    icon,
    imgIconSrc,
    ...rest
  } = props;

  const classes = useStyles();

  const renderIcon = () => {
    if (imgIconSrc) {
      return <img alt="" src={imgIconSrc} />;
    }
    if (icon) {
      return icon;
    }
    return 'n/a';
  };

  const renderChildren = () => {
    if (tooltipLabel) {
      return (
        <Badge
          color="error"
          variant="dot"
          invisible={!showBadge}
          className={classes.badge}
        >
          <Tooltip title={tooltipLabel} placement="bottom">
            <Icon className={`${classes.icon} ${size}`}>
              { renderIcon() }
            </Icon>
          </Tooltip>
        </Badge>
      );
    }
    return (
      <Badge
        color="error"
        variant="dot"
        invisible={!showBadge}
        className={classes.badge}
      >
        <Icon className={`${classes.icon} ${size}`}>
          { renderIcon() }
        </Icon>
      </Badge>
    );
  };

  return (
    <IconButton
      {...rest}
      className={`${rest.className} ${classes.button} ${variant} ${size} ${activeType}`}
    >
      { renderChildren() }
    </IconButton>
  );
};

IconRoundButton.propTypes = {
  showBadge: PropTypes.bool,
  activeType: PropTypes.oneOf(['', 'active', 'inactive']),
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  tooltipLabel: PropTypes.string,
  imgIconSrc: PropTypes.string,
  icon: PropTypes.node,
};

IconRoundButton.defaultProps = {
  showBadge: false,
  activeType: '',
  size: 'small',
  variant: 'standard',
  tooltipLabel: '',
  imgIconSrc: '',
  icon: null,
};

export default IconRoundButton;
