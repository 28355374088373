import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  hide: { display: 'none' },
  marginRight: { marginRight: theme.spacing(2) },
  uploadContainer: {
    borderRadius: '5px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.palette.tertiary.grey,
    padding: '1rem',
    '&.disabled': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
      borderStyle: 'none',
      '& > div': {
        '& p': {
          color: '#C5C5C5',
        },
        '& > label > p': {
          color: '#C4C4C4',
        },
      },
    },
  },
}));
