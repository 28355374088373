import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './styles';


const CardButton = (props) => {
  const {
    active,
    className,
    children,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
      }}
      className={`${active ? classes.active : ''} ${className}`}
      {...rest}
    >
      { children }
    </Button>
  );
};

CardButton.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
};

CardButton.defaultProps = {
  className: '',
  active: false,
  children: null,
};

export default CardButton;
