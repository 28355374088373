/* eslint react/prop-types: 0 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../contexts/Auth';

import {
  decodeRedirect,
  encodeRedirect,
  getQuery,
  parseRedirect,
} from '../utils/url';

const RouteAuthenticated = ({
  path,
  component: C,
  props: cProps,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const { apps, user, hasHigherAccess } = authContext.state;

  const hasAccess = () => {
    switch (path) {
      // LMS-2843
      // case '/settings/chats':
      case '/settings':
      case '/settings/fields':
      case '/settings/auto_assignment':
      case '/settings/workflow':
      case '/settings/call':
      case '/settings/api_integrations':
      case '/settings/buyer_access':
      case '/settings/credit_check':
      case '/customers/credit_check':
        if (user.role && user.role !== 'Headquarters') {
          return false;
        }
        if (path === '/settings/call' && !(apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess)) {
          return false;
        }
        break;
      case '/campaigns/submissions':
      case '/audiences':
        if (!hasHigherAccess) {
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const hasProductAccess = () => {
    if (!apps.lead_management_v2
        || !apps.lead_management_v2.hasAccess
        || !apps.showroom
        || !apps.showroom.hasAccess) {
      return false;
    }
    return true;
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthContext.Consumer>
          {({ state }) => {
            const { redirect } = getQuery();

            if (state.isAuthenticated) {
              if (!hasAccess() || !hasProductAccess()) {
                return <Redirect to="/access_denied" />;
              }
              if (redirect) {
                let url = decodeRedirect(redirect);
                if (url.startsWith(window.location.origin)) {
                  url = url.replace(window.location.origin, '');
                }
                return <Redirect from="/" to={url} />;
              }
              return <C {...props} {...cProps} />;
            }
            // redirect to login screen;
            return <Redirect to={`/login${parseRedirect(redirect || encodeRedirect(window.location.href))}`} />;
          }}
        </AuthContext.Consumer>
      )}
    />
  );
};

RouteAuthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default RouteAuthenticated;
