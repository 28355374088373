import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// LINK_ACCOUNT links avanser account
export const LINK_ACCOUNT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'linkAccount',
    args: [
      { name: 'username', type: 'String', required: true },
      { name: 'password', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'username', value: 'username' },
      { name: 'password', value: 'password' },
    ],
    fields: '{ ...AccountFields }',
    fragments: [Fragments.account],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LINK_ACCOUNT unlinks avanser account
export const UNLINK_ACCOUNT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'unlinkAccount',
    args: [],
    argsMap: [],
    fields: '{ ...AccountFields }',
    fragments: [Fragments.account],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_ADSOURCE update adsource info GraphQL schema.
export const UPDATE_ADSOURCE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateAdsource',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'description', type: 'String' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'description', value: 'description' },
    ],
    fields: '{ ...AdsourceFields }',
    fragments: [Fragments.adsource],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE_ANSWERPOINT creates answer point with new/existing adsource GraphQL schema.
export const CREATE_ANSWERPOINT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createAnswerpoint',
    args: [
      { name: 'client_id', type: 'String', required: true },
      { name: 'client_name', type: 'String', required: true },
      { name: 'client_email', type: 'String', required: true },
      { name: 'assignee_id', type: 'String', required: true },
      { name: 'mobile', type: 'String', required: true },
      { name: 'adsource_id', type: 'String' },
      { name: 'adsource_name', type: 'String' },
      { name: 'adsource_tracking_number', type: 'String' },
      { name: 'adsource_service_code', type: 'String' },
      { name: 'adsource_description', type: 'String' },
      { name: 'integration_type', type: 'String' },
    ],
    argsMap: [
      { name: 'client_id', value: 'client_id' },
      { name: 'client_name', value: 'client_name' },
      { name: 'client_email', value: 'client_email' },
      { name: 'assignee_id', value: 'assignee_id' },
      { name: 'mobile', value: 'mobile' },
      { name: 'adsource_id', value: 'adsource_id' },
      { name: 'adsource_name', value: 'adsource_name' },
      { name: 'adsource_tracking_number', value: 'adsource_tracking_number' },
      { name: 'adsource_service_code', value: 'adsource_service_code' },
      { name: 'adsource_description', value: 'adsource_description' },
      { name: 'integration_type', value: 'integration_type' },
    ],
    fields: '{ ...AnswerpointFields }',
    fragments: [Fragments.answerPoint],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_ANSWERPOINT deletes answerpoint GraphQL schema.
export const DELETE_ANSWERPOINT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteAnswerpoint',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{ ...AnswerpointFields }',
    fragments: [Fragments.answerPoint],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_ADSOURCE deletes adsource and all the answer points under it GraphQL schema.
export const DELETE_ADSOURCE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteAdsource',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{ ...AdsourceFields }',
    fragments: [Fragments.adsource],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
