import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export default makeStyles(() => ({
  forbidden: {
    height: '70%',
    '& > .MuiIcon-root': {
      height: '5rem',
      width: '5rem',
      marginBottom: '2rem',
      '& img': {
        filter: filters.primary.main,
      },
    },
  },
  buttonGroup: {
    padding: '1.15rem 0',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  bodyText: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
}));
