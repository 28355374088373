import { CONSTRUCTOR } from '..';

// Lead fields fragment.
export const lead = CONSTRUCTOR.FRAGMENT({
  name: 'Lead',
  fields: `{
    full_name
    email
    mobile
    address
    city
    state
    postcode
    country_code
    nationality_country_code
    nric
    passport
  }`,
});

// ListLeadResponse fields fragment.
export const listLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListLeadResponse',
  fields: `{
    body
    count
    pit_id
    search_afters
  }`,
});

// CreateLeadResponse fields fragment.
export const createLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'CreateLeadResponse',
  fields: `{
    body
  }`,
});

// UpdateLeadResponse fields fragment.
export const updateLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UpdateLeadResponse',
  fields: `{
    body
  }`,
});

// GetLeadResponse fields fragment.
export const getLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetLeadResponse',
  fields: `{
    body
  }`,
});

// DeleteLeadResponse fields fragment.
export const deleteLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DeleteLeadResponse',
  fields: `{
    body
  }`,
});

// AssignLeadResponse fields fragment.
export const assignLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'AssignLeadResponse',
  fields: `{
    body
  }`,
});

// UnassignLeadResponse fields fragment.
export const unassignLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UnassignLeadResponse',
  fields: `{
    body
  }`,
});

// UploadLeadsResponse fields fragment.
export const uploadLeadsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UploadLeadsResponse',
  fields: `{
    url
    import_id
  }`,
});

// DownloadLeadsResponse fields fragment.
export const downloadLeadsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DownloadLeadsResponse',
  fields: `{
    body
  }`,
});

// listActivitiesResponse fields fragment.
export const listActivitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListActivitiesResponse',
  fields: `{
    items
    count
  }`,
});

// convertLeadRespinse fields fragment.
export const convertLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ConvertLeadResponse',
  fields: `{
    customer_id
  }`,
});

// searchLeadResponse fields fragment.
export const searchLeadResponse = CONSTRUCTOR.FRAGMENT({
  name: 'SearchLeadResponse',
  fields: `{
    body
    count
  }`,
});

// MergeLeadsResponse fields fragment.
export const mergeLeadsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'MergeLeadsResponse',
  fields: `{
    body
  }`,
});

export const leadExports = CONSTRUCTOR.FRAGMENT({
  name: 'LeadExports',
  fields: `{
    filename
    file_key
    file_url
    export_count
    export_type
    filetype
    export_id
    expired_at
    created_at
    created_by
  }`,
});

export const listLeadExportsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListLeadExportsResponse',
  fields: `{
    items {...LeadExportsFields}
    count
  }`,
  fragments: [leadExports],
});
