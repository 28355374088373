import { makeStyles } from '@material-ui/core/styles';

export default function useStyles(props, color) {
  return makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    bottom: {
      color: color || theme.palette.primary.main,
      opacity: '0.2',
    },
    top: {
      color: color || theme.palette.primary.main,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }))(props);
}
