import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// CREATE updates form Graphql schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createForm',
    args: [
      { name: 'campaign_id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'header', type: 'String' },
      { name: 'description', type: 'String' },
      { name: 'image_url', type: 'String' },
      { name: 'terms_and_conditions', type: 'String' },
      { name: 'custom_success_message', type: 'String' },
      { name: 'external_success_url', type: 'String' },
      { name: 'default_form_source', type: 'String' },
      { name: 'accept_sub', type: 'Boolean' },
      { name: 'mirage', type: 'Boolean' },
      { name: 'origin', type: 'String', required: true },
      { name: 'fields', type: '[FieldInput]' },
    ],
    argsMap: [
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'name', value: 'name' },
      { name: 'header', value: 'header' },
      { name: 'description', value: 'description' },
      { name: 'image_url', value: 'image_url' },
      { name: 'terms_and_conditions', value: 'terms_and_conditions' },
      { name: 'custom_success_message', value: 'custom_success_message' },
      { name: 'external_success_url', value: 'external_success_url' },
      { name: 'default_form_source', value: 'default_form_source' },
      { name: 'accept_sub', value: 'accept_sub' },
      { name: 'mirage', value: 'mirage' },
      { name: 'origin', value: 'origin' },
      { name: 'fields', value: 'fields' },
    ],
    fields: '{...FormFields}',
    fragments: [Fragments.form],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE updates form Graphql schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateForm',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'campaign_id', type: 'String', required: true },
      { name: 'name', type: 'String' },
      { name: 'header', type: 'String' },
      { name: 'description', type: 'String' },
      { name: 'image_url', type: 'String' },
      { name: 'terms_and_conditions', type: 'String' },
      { name: 'custom_success_message', type: 'String' },
      { name: 'external_success_url', type: 'String' },
      { name: 'default_form_source', type: 'String' },
      { name: 'accept_sub', type: 'Boolean' },
      { name: 'mirage', type: 'Boolean' },
      { name: 'fields', type: '[FieldInput]' },
      { name: 'mappings', type: 'MappingsInput' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'name', value: 'name' },
      { name: 'header', value: 'header' },
      { name: 'description', value: 'description' },
      { name: 'image_url', value: 'image_url' },
      { name: 'terms_and_conditions', value: 'terms_and_conditions' },
      { name: 'custom_success_message', value: 'custom_success_message' },
      { name: 'external_success_url', value: 'external_success_url' },
      { name: 'default_form_source', value: 'default_form_source' },
      { name: 'accept_sub', value: 'accept_sub' },
      { name: 'mirage', value: 'mirage' },
      { name: 'fields', value: 'fields' },
      { name: 'mappings', value: 'mappings' },
    ],
    fields: '{...FormFields}',
    fragments: [Fragments.form],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE deletes form GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteForm',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...FormFields}',
    fragments: [Fragments.form],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LINK_FACEBOOK_FORM link facebook form Graphql schema.
export const LINK_FACEBOOK_FORM = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'linkFacebookForm',
    args: [
      { name: 'fb_page_id', type: 'String', required: true },
      { name: 'fb_form_id', type: 'String', required: true },
      { name: 'fb_form_name', type: 'String', required: true },
      { name: 'status', type: 'String', required: true },
      { name: 'campaign_id', type: 'String', required: true },
      { name: 'ad_account_ids', type: '[String]' },
    ],
    argsMap: [
      { name: 'fb_page_id', value: 'fb_page_id' },
      { name: 'fb_form_id', value: 'fb_form_id' },
      { name: 'fb_form_name', value: 'fb_form_name' },
      { name: 'status', value: 'status' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'ad_account_ids', value: 'ad_account_ids' },
    ],
    fields: '{...FormFields}',
    fragments: [Fragments.form],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
