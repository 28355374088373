import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export const useStyles = makeStyles({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '18px',
    },
  },
});

export function toggleButtonStyles(props, elem) {
  switch (elem) {
    case 'wrapper':
      return makeStyles(({
        root: {
          position: 'relative',
        },
      }))(props);
    case 'button':
      return makeStyles((theme) => ({
        root: {
          position: 'absolute',
          top: '12px',
          left: '-16px',
          zIndex: 2,
          color: theme.palette.font.body,
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.tertiary.mediumGrey}`,
          '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '& img': {
              filter: 'invert(1)',
            },
          },
          '&:focus:not(:hover)': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.font.body,
          },
        },
      }))(props);
    default:
      return null;
  }
}

export const useAppNavStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    zIndex: 1,
  },
  drawerContainer: {
    height: '100%',
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      width: '50vw',
    },
  },
  drawerHeader: {
    display: 'flex',
    minHeight: '52px',
    padding: '0.8rem 1rem',
    '& img': {
      height: '26px',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& h6': {
      marginLeft: '0.2rem',
    },
  },
  editIcon: {
    height: '18px',
    width: '18px',
    filter: 'invert(42%)',
  },
}));

export const usePrimaryNavStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.tertiary.mediumGrey,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
    padding: 0,
  },
  badge: {
    '& .MuiBadge-colorError': {
      top: '15%',
      right: '3px',
    },
  },
  item: {
    padding: '1.1rem 1.35rem',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&.Mui-selected': {
      background: theme.palette.primary.dark,
      '& $icon': {
        filter: 'invert(1)',
      },
      [theme.breakpoints.down('sm')]: {
        background: theme.palette.tertiary.darkGrey,
        '& $icon': {
          filter: 'invert(0.4)',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
      marginBottom: '0.5rem',
      borderRadius: '4px',
      '& $icon': {
        filter: 'invert(0.4)',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        background: theme.palette.primary.dark,
      },
      '&.Mui-selected:hover': {
        background: theme.palette.primary.dark,
      },
    },
    '& > h6': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      marginLeft: '1rem',
    },
  },
  icon: {
    minWidth: 'unset',
    filter: 'invert(1)',
    '& .MuiIcon-root': {
      height: '24px',
      width: '24px',
    },
  },
  profile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
    background: theme.palette.tertiary.darkGrey,
  },
  profileIcon: {
    height: '1.3em',
    width: '1.3em',
    filter: filters.font.body,
    cursor: 'pointer',
  },
  profileName: {
    flex: 1,
    margin: '0 0.8rem',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      lineHeight: '12px',
    },
  },
}));

export const useSecondaryNavStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.tertiary.mediumGrey,
    height: '100%',
    width: '240px',
    padding: '1rem',
    border: '0 !important',
    borderRight: `1px solid ${theme.palette.tertiary.grey}`,

    transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn} 0s`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > .MuiTypography-caption': {
      color: theme.palette.font.subBody,
    },
    '& > div $header:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  collapsed: {
    width: '1.5rem',
    padding: 0,
    '& > *': {
      display: 'none',
    },
  },
  list: {
    padding: 0,
  },
  footer: {
    marginLeft: '0.5rem',
  },
  header: {
    paddingBottom: '1rem',
    paddingLeft: '0.5rem',
  },
  badge: {
    '& .MuiBadge-colorError': {
      top: '30%',
      right: '-5px',
    },
  },
  item: {
    borderRadius: '4px',
    padding: '0.5rem',
    '&.Mui-selected, &.Mui-selected:hover': {
      background: theme.palette.tertiary.darkGrey,
    },
    '&:not(:last-child)': {
      marginBottom: '2px',
    },
    '&:hover': {
      background: theme.palette.tertiary.darkGrey,
    },
  },
}));

export function useNavItemStyles(props) {
  return makeStyles((theme) => ({
    root: {
      minHeight: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.font.subBody,
      '&$selected': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&$selected:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    selected: {},
  }))(props);
}
