import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  Paper,
  Typography,
  Grid,
  Badge,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSecondaryNavStyles } from './styles';
import config from '../../config';

const SecondaryNav = (props) => {
  const classes = useSecondaryNavStyles();

  const {
    collapsed,
    data,
    selected,
    onSelect,
  } = props;

  function renderListItem(properties, sectionName) {
    const { item, idx } = properties;
    const { pathname, search } = window.location;
    const path = `${pathname}${search}`;
    const isSameNameAndIndex = selected.sectionName === sectionName && selected.index === idx;
    const selectedNav = isSameNameAndIndex && item.path && item.path.startsWith(path);
    const content = (
      <Badge
        color="error"
        variant="dot"
        className={classes.badge}
        invisible={!item.haveBadge}
      >
        <Typography>
          { item.text }
        </Typography>
      </Badge>
    );

    if (item.hide) {
      return null;
    }

    if (item.onClick) {
      return (
        <ListItem
          button
          key={item.text}
          className={classes.item}
          onClick={item.onClick}
        >
          { content }
        </ListItem>
      );
    }

    if (item.href) {
      return (
        <ListItem
          button
          key={item.text}
          className={classes.item}
          component="a"
          href={item.href}
          target="_blank"
        >
          { content }
        </ListItem>
      );
    }

    return (
      <ListItem
        button
        key={item.text}
        className={classes.item}
        component={RouterLink}
        to={item.path}
        selected={selectedNav}
        onClick={item.onSelect || onSelect}
      >
        { content }
      </ListItem>
    );
  }

  return (
    <Paper
      square
      component="nav"
      className={`${classes.root} ${classes.flexContainer} ${collapsed ? classes.collapsed : ''}`}
    >
      <div>
        {
          data.filter((item) => item && !item.hide).map((item) => (
            <Fragment key={item.header}>
              <Grid container alignItems="center" justify="space-between" className={classes.header}>
                <Grid item>
                  <Typography variant="h6">
                    { item.header }
                  </Typography>
                </Grid>
                <Grid item>
                  { item.sideHeaderNode }
                </Grid>
              </Grid>
              <List component="nav" className={classes.list}>
                {
                  item.items.map((l, idx) => (
                    renderListItem({ item: l, idx }, item.name)
                  ))
                }
              </List>
            </Fragment>
          ))
        }
      </div>
      <Typography variant="caption" className={classes.footer}>
        {`v${config.app.version}`}
      </Typography>
    </Paper>
  );
};

SecondaryNav.propTypes = {
  collapsed: PropTypes.bool,
  selected: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
  onSelect: PropTypes.func,
};

SecondaryNav.defaultProps = {
  collapsed: true,
  selected: {
    index: -1,
    sectionName: '',
  },
  data: {},
  onSelect: () => {},
};

export default SecondaryNav;
