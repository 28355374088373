import { makeStyles } from '@material-ui/core';
import { filters } from '../../contexts';

export default makeStyles((theme) => ({
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.font.header,
  },
  info: {
    background: theme.palette.tertiary.lightBlue,
    '& $message svg': {
      filter: filters.secondary.main,
    },
  },
  error: {
    background: theme.palette.tertiary.lightPink,
    '& $message svg': {
      filter: filters.tertiary.peach,
    },
  },
  success: {
    background: theme.palette.tertiary.dimGreen,
    '& a': {
      color: theme.palette.tertiary.jade,
    },
    '& $message svg': {
      filter: filters.tertiary.jade,
    },
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  close: {
    fontSize: 20,
    color: theme.palette.tertiary.dimGrey,
    '&:hover': {
      backgroundColor: 'unset',
      color: theme.palette.font.body,
    },
  },
  wrapper: {
    border: 'unset',
    flexWrap: 'nowrap',
  },
}));
