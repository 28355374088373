import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import useStyles from './styles';

const AudioPlayer = (props) => {
  const {
    url,
    currentPlayingURL,
    onClick,
    disabled,
    ...rest
  } = props;
  const classes = useStyles();

  const [isPlaying, setIsPlaying] = useState(false);
  const [haveError, setHaveError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const audioRef = useRef(!haveError ? new Audio(url) : null);

  useEffect(() => {
    // Make sure sound overlaps when there's multiple audio players
    if (!audioRef.current.paused
      && currentPlayingURL !== url
      && !haveError) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [
    currentPlayingURL,
    url,
    haveError,
  ]);

  useEffect(() => () => {
    // Stop the audio if no longer in screen
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  }, []);

  const handleToggleAudio = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!haveError) {
      onClick(url);
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        const playPromise = audioRef.current.play();
        if (playPromise || playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch(() => {
              setIsPlaying(false);
              setHaveError(true);
            });
        }
      }
    }
  }, [
    audioRef,
    url,
    onClick,
    haveError,
  ]);

  audioRef.current.oncanplay = () => {
    setIsLoading(false);
  };

  audioRef.current.onended = () => {
    setIsPlaying(false);
  };

  audioRef.current.onerror = () => {
    setHaveError(true);
  };

  const renderText = () => {
    if (isLoading) {
      return 'Loading...';
    }
    if (haveError) {
      return 'Error occured';
    }
    return 'Recording available';
  };

  return (
    <Button
      variant="outlined"
      startIcon={isPlaying ? <Pause /> : <PlayArrow />}
      className={`${classes.player} ${haveError ? 'error' : ''}`}
      onClick={handleToggleAudio}
      disabled={disabled || isLoading}
      {...rest}
    >
      { renderText() }
    </Button>
  );
};

AudioPlayer.propTypes = {
  disabled: PropTypes.bool,
  url: PropTypes.string.isRequired,
  currentPlayingURL: PropTypes.string,
  onClick: PropTypes.func,
};

AudioPlayer.defaultProps = {
  disabled: false,
  currentPlayingURL: '',
  onClick: () => {},
};

export default AudioPlayer;
