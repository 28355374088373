import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Button from '../Button';

import useStyles from './styles';

const DeleteConfirmation = (props) => {
  const {
    isLoading,
    disableConfirm,
    onConfirm,
    onCancel,
  } = props;
  const classes = useStyles();

  const handleOnConfirm = () => {
    if (!isLoading && !disableConfirm) {
      onConfirm();
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.heading}
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Delete</Typography>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Typography>
        Are you sure you want to do this?
      </Typography>
      <Typography variant="caption">
        Note: You cannot undo this
      </Typography>
      <div className={classes.controls}>
        <Button variant="outlined" onClick={onCancel} isLoading={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" disabled={disableConfirm} onClick={handleOnConfirm} isLoading={isLoading}>
          Delete
        </Button>
      </div>
    </div>
  );
};

DeleteConfirmation.propTypes = {
  isLoading: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

DeleteConfirmation.defaultProps = {
  isLoading: false,
  disableConfirm: false,
  onConfirm: () => {},
  onCancel: () => {},
};

export default DeleteConfirmation;
