import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    '& > div + div': {
      marginLeft: '8px',
    },
    '& > div:first-child': {
      overflow: 'hidden',
    },
  },
  tableContainer: {
    maxHeight: '300px',
    minWidth: '15rem',
    '& th': {
      backgroundColor: theme.palette.common.white,
    },
  },
  projectWrapper: {
    '&.secondary > span': {
      cursor: 'pointer',
      background: theme.palette.tertiary.mediumGrey,
      '&:hover': {
        backgroundColor: theme.palette.tertiary.grey,
      },
    },
  },
  project: {
    borderRadius: '4px',
    background: theme.palette.tertiary.dimGreen,
    padding: '4px 8px 4px 8px',
    fontWeight: fonts.semiBold,
  },
  ellipsis: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
