import { CONSTRUCTOR } from '..';

// OpportunityFieldSettingsResponse fields fragment.
export const opportunityFieldSettingsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'OpportunityFieldSettingsResponse',
  fields: `{
    body
    updated_at
  }`,
});
