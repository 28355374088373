import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  topMessage: {
    marginBottom: '1.5rem',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  th: {
    '& > tr > th:first-child': {
      width: '35%',
    },
  },
  tbody: {
    '& > tr > td:first-child > a': {
      maxWidth: '25ch',
    },
    '& > tr > td:first-child > a, p': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  value: {
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
  },
}));
