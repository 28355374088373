import { inRange, isEmpty } from 'lodash';
import moment from 'moment';

const dateFormat = 'MMM DD, YYYY';
export const getDate = (d) => moment(d).format(dateFormat);

export const jsonParser = (str) => {
  let rtn;
  try {
    const parsedErr = JSON.parse(str);
    rtn = !isEmpty(parsedErr.errorMessage) && parsedErr.errorMessage;
  } catch (e) {
    rtn = str;
  }
  return rtn;
};

export const NEW = 'new';
export const REQUEST_PENDING_APPROVAL = 'request_pending_approval';
export const REQUEST_MHUB_CTOS_RECEIVED = 'request_mhub_ctos_received';
export const REQUEST_MHUB_FAILURE = 'request_mhub_failure';
export const REQUEST_SUCCESS = 'request_success';
export const REQUEST_CTOS_FAILURE = 'request_ctos_failure';
export const REQUEST_REJECTED = 'request_rejected';
export const REQUEST_CTOS_BAD_REQUEST = 'request_ctos_bad_request';
export const REQUEST_CTOS_IDENTITY_VERIFICATION_FAILED = 'request_ctos_identity_verification_failed';
export const REQUEST_CTOS_MULTIPLE_ENTITIES = 'request_ctos_multiple_entities';
export const REQUEST_CTOS_REPORT_EMPTY = 'request_ctos_report_empty';
export const NOT_AVAILABLE = 'request_ctos_not_available';
export const EXPIRED = 'expired';

export const generateCategory = (score) => {
  if (score) {
    if (inRange(score, 300, 528)) {
      return 'Poor';
    } if (inRange(score, 529, 650)) {
      return 'Low';
    } if (inRange(score, 651, 696)) {
      return 'Fair';
    } if (inRange(score, 697, 717)) {
      return 'Good';
    } if (inRange(score, 718, 743)) {
      return 'Very Good';
    } if (inRange(score, 744, 850)) {
      return 'Excellent';
    }
  }
  return 'No score';
};

export const generateStatus = (status) => {
  if (status) {
    switch (status) {
      case NEW:
        return 'Draft';
      case REQUEST_PENDING_APPROVAL:
        return 'Pending Approval';
      case REQUEST_MHUB_CTOS_RECEIVED:
        return 'Pending Result';
      case REQUEST_MHUB_FAILURE:
        return 'Request Failed';
      case REQUEST_SUCCESS:
      case REQUEST_CTOS_REPORT_EMPTY:
        return 'Active';
      case REQUEST_CTOS_FAILURE:
        return 'Request Failed';
      case REQUEST_REJECTED:
        return 'Rejected';
      case REQUEST_CTOS_BAD_REQUEST:
        return 'Wrong Info';
      case REQUEST_CTOS_IDENTITY_VERIFICATION_FAILED:
        return 'Wrong IC';
      case REQUEST_CTOS_MULTIPLE_ENTITIES:
        return 'Multiple Entities';
      case NOT_AVAILABLE:
        return 'CTOS Service Unavailable';
      case EXPIRED:
        return 'Expired';
      default:
        break;
    }
  }
  return 'Draft';
};

export const generateDescription = (status) => {
  if (status) {
    switch (status) {
      case REQUEST_PENDING_APPROVAL:
        return 'Waiting for Developer HQ to approve the Credit Check Request.';
      case REQUEST_MHUB_CTOS_RECEIVED:
        return 'Waiting for the Credit Check results from CTOS.';
      case REQUEST_MHUB_FAILURE:
      case REQUEST_CTOS_FAILURE:
        return 'Credit Check request was failed due to an error, please try again.';
      case REQUEST_SUCCESS:
      case REQUEST_CTOS_REPORT_EMPTY:
        return 'Credit Check score is valid, it will expire after 14 days from the date requested.';
      case REQUEST_CTOS_BAD_REQUEST:
        return 'The NRIC or Name doesn’t match with the CTOS report. Developer HQ need to approve.';
      case REQUEST_CTOS_IDENTITY_VERIFICATION_FAILED:
        return 'The NRIC doesn’t match with the CTOS record.';
      case REQUEST_REJECTED:
        return 'CTOS report was rejected by the Developer HQ due to incorrect information.';
      case REQUEST_CTOS_MULTIPLE_ENTITIES:
        return 'Multiple record found, Developer  HQ need to choose the correct record. ';
      case NOT_AVAILABLE:
        return 'Potential Reason: Server downtime in CTOS or BNM. Please contact MHub Support for further info.';
      case EXPIRED:
        return 'Credit Check score is expired, run again for the latest report.';
      default:
        break;
    }
  }
  return '';
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (reportName, byte, customType) => {
  let fileType = 'application/pdf';
  if (customType) {
    fileType = customType;
  }
  const blob = new Blob([byte], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};
