import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../../contexts';
import { overline } from '../../../contexts/Theme/font';

export default makeStyles((theme) => ({
  name: {
    color: theme.palette.font.header,
  },
  role: {
    ...overline,
    display: 'block',
    textTransform: 'uppercase',
  },
  number: {
    color: theme.palette.font.body,
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
  },
  branchName: {
    display: 'block',
    color: theme.palette.font.subBody,
  },
  avatar: {
    '&.MuiChip-avatar': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      '&.suspended': {
        backgroundColor: theme.palette.tertiary.grey,
      },
    },
  },
  checkbox: {
    padding: '0.5rem',
  },
  alertIcon: {
    filter: filters.tertiary.peach,
  },
  lightChipLabel: {
    color: theme.palette.font.subBody,
  },
}));
