import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fileDetailContainer: {
    height: '70px',
    padding: '0.5rem',
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
  },
  fileTypeIconContainer: {
    [theme.breakpoints.down('xs')]: { display: 'none' },
  },
  redText: {
    color: theme.palette.tertiary.red,
  },
  paddingLeft: {
    paddingLeft: '1rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 'unset',
    },
  },
  button: {
    '& span > span > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));
