import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    minWidth: '420px',
    padding: '1.5rem 2rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  heading: {
    marginBottom: '1rem',
  },
}));
