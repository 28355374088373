import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  ClickAwayListener,
  Grid,
  Typography,
  TextField,
  Icon,
  MenuList,
  Popper,
  Paper,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { SearchIcon } from '../../../assets/icons';
import {
  useStyles,
  CustomMenuItem,
  popperStyles,
} from './styles';


export default function GlobalSearch(props) {
  const {
    loading,
    className,
    searchType,
    inputValue,
    searchTypeOptions,
    inputOptions,
    onSearchTypeChange,
    onInputChange,
    onChange,
    onClearInput,
    ...autocompleteProps
  } = props;

  const fieldRef = useRef();
  const searchFieldRef = useRef();

  const classes = useStyles();
  const popperClass = popperStyles(
    props,
    searchFieldRef && searchFieldRef.current
    && searchFieldRef.current.getBoundingClientRect().width,
  );

  const [openDropdown, setOpenDropdown] = useState({
    search: false,
    input: false,
  });
  const [searchValue, setSearchValue] = useState(false);

  const handleToggle = (name) => {
    setOpenDropdown((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleOpen = (name) => {
    setOpenDropdown((prev) => ({ ...prev, [name]: true }));
  };

  const handleClose = (name) => {
    setOpenDropdown((prev) => ({ ...prev, [name]: false }));
  };

  const handleOnSearchTypeChange = (type) => {
    handleClose('search');
    onSearchTypeChange(type, searchValue);
  };

  // To fix conflict with field onChange to close pop up when click clear
  useEffect(() => {
    if (!searchValue) {
      handleClose('input');
    }
  }, [searchValue]);

  const handleOnSearchInputChange = (e, v) => {
    setSearchValue(v);
    onInputChange(e, v);
    if (v) {
      handleOpen('input');
    } else {
      handleClose('input');
    }
  };

  const handleOnClearInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchValue('');
    onClearInput(e);
  };

  const renderSearchTypeOptions = () => (
    <MenuList disablePadding>
      {
        searchTypeOptions.length > 0 ? (
          searchTypeOptions.map((type) => (
            <CustomMenuItem
              key={type.value}
              value={type.value}
              selected={type.value === searchType}
              onClick={() => handleOnSearchTypeChange(type.value)}
            >
              <Icon className={classes.icon}>
                <img alt={type.value} src={type.icon} />
              </Icon>
              <Typography>{type.label}</Typography>
            </CustomMenuItem>
          ))
        ) : (
          <CustomMenuItem key="null" value="null" disabled>
            <Typography>No Options</Typography>
          </CustomMenuItem>
        )
      }
    </MenuList>
  );

  const getIcon = () => {
    if (searchType && searchTypeOptions.length > 0) {
      const type = searchTypeOptions.filter((opt) => (opt.value === searchType));
      if (type.length > 0) {
        return type[0].icon;
      }
    }
    return SearchIcon;
  };

  const renderSearchButton = () => (
    <>
      <Button
        size="small"
        disableRipple
        ref={fieldRef}
        className={classes.selectButton}
        onClick={() => handleToggle('search')}
      >
        <Icon className={classes.icon}>
          <img alt="search" src={getIcon()} />
        </Icon>
        <Icons.ArrowDropDown className={classes.arrow} />
      </Button>
      <Popper
        disablePortal
        open={openDropdown.search}
        anchorEl={fieldRef.current}
        placement="bottom-start"
        className={classes.popper}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => handleClose('search')}>
            {renderSearchTypeOptions()}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );

  const renderInputEndAdornment = (params) => {
    const { inputProps } = params;
    if (loading) {
      return (
        <InputAdornment position="end">
          <CircularProgress className={classes.loader} color="inherit" size={15} />
        </InputAdornment>
      );
    }
    if (inputProps.value) {
      return (
        <InputAdornment position="end">
          <IconButton
            size="small"
            className={classes.clear}
            onClick={handleOnClearInput}
          >
            <Icons.Close />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <Grid container alignItems="flex-end" className={className}>
      {renderSearchButton()}
      <Autocomplete
        freeSolo
        selectOnFocus
        open={openDropdown.input}
        classes={{
          root: classes.root,
          input: classes.input,
          listbox: classes.listbox,
        }}
        options={inputOptions}
        getOptionLabel={(option) => option.label || option || ''}
        onChange={(e, v) => {
          onChange(e, v);
          handleClose('input');
        }}
        value={searchValue}
        onInputChange={handleOnSearchInputChange}
        renderOption={(option) => (
          <>
            {
              autocompleteProps.renderOption
                ? autocompleteProps.renderOption
                : (
                  <Typography>
                    { option.label }
                  </Typography>
                )
            }
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={searchFieldRef}
            fullWidth
            placeholder="Search"
            onClick={() => params.inputProps.value && handleOpen('input')}
            onBlur={() => handleClose('input')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                renderInputEndAdornment(params)
              ),
            }}
          />
        )}
        {...autocompleteProps}
      />
      {
        searchFieldRef.current && inputOptions.length <= 0 && (
          <Popper
            disablePortal
            open={openDropdown.input}
            anchorEl={searchFieldRef.current}
            placement="bottom-start"
            className={`${classes.popper} ${popperClass.popper}`}
          >
            <Paper>
              <MenuList>
                <CustomMenuItem key="null" value="null" disabled>
                  <Typography>No result found</Typography>
                </CustomMenuItem>
              </MenuList>
            </Paper>
          </Popper>
        )
      }
    </Grid>
  );
}

GlobalSearch.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  searchType: PropTypes.string,
  inputValue: PropTypes.string,
  searchTypeOptions: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
  inputOptions: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
  onSearchTypeChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onClearInput: PropTypes.func,
};
GlobalSearch.defaultProps = {
  loading: false,
  className: '',
  searchType: '',
  inputValue: '',
  searchTypeOptions: [],
  inputOptions: [],
  onSearchTypeChange: () => {},
  onInputChange: () => {},
  onChange: () => {},
  onClearInput: () => {},
};
