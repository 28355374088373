import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../../contexts';

export default makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-input': {
      maxHeight: '2.5rem',
    },
  },
  adornment: {
    color: theme.palette.tertiary.grey,
  },
  searchIcon: {
    height: '1.5rem',
    width: '1.5rem',
    filter: filters.font.body,
  },
}));
