import { createContext } from 'react';

export const initialState = ({
  appLoading: false,
  avanserAccount: {},
  facebookSubscription: {},
  showWhatsNewModal: false,
  lastReleaseNoteVersionSeen: '',
  leadFieldSettings: {
    basic: { attributes: {} },
    sales: { attributes: {} },
    work: { attributes: {} },
    emergency_contact: { attributes: {} },
    custom: { attributes: {} },
  },
  contactFieldSettings: {
    basic: { attributes: {} },
    sales: { attributes: {} },
    work: { attributes: {} },
    emergency_contact: { attributes: {} },
    custom: { attributes: {} },
  },
  opportunityFieldSettings: {
    basic: { attributes: {} },
  },
  cancellationReasonOptions: [],
  campaigns: {},
  campaignOptions: [],
  opportunityChatGroups: {},
  projects: {},
  projectOptions: [],
  pageContextLoaders: {
    leadFieldSettings: false,
    contactFieldSettings: false,
  },
  swIsRegistered: false,
  navigations: [],
  indexedDB: null,
  indexedDBInfo: {
    name: '',
    storeName: '',
    version: 0,
  },
  isWindowActive: true,
  fBResyncStatus: '',
  savedFields: [],
});

export default createContext({
  state: initialState,
  actions: {
    setAppLoading: () => {},
    setWhatsNewModal: () => {},
    setAvanserAuthentication: () => {},
    setFacebookSubscription: () => {},
    setLeadFieldSettings: () => {},
    setContactFieldSettings: () => {},
    setOpportunityFieldSettings: () => {},
    setCancellationReasons: () => {},
    setListCampaigns: () => {},
    setListOpportunityChatGroups: () => {},
    setCustomNavSection: () => {},
    setIndexedDB: () => {},
    setIndexeDBInfo: () => {},
    setIsWindowActive: () => {},
    setFBResyncStatus: () => {},
    setSavedFields: () => {},
    getSavedFields: () => {},
  },
});
