import React, {
  forwardRef,
  memo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const TextFieldWithRegex = forwardRef((props, ref) => {
  const {
    value,
    type,
    regex,
    onChange,
    ...rest
  } = props;

  const [newValue, setNewValue] = useState(value);

  const handleOnChange = useCallback((e) => {
    if (regex || type === 'number') {
      // only allow certain characters based on type/regex on text field
      const reg = regex || /^[0-9\b]+$/;
      if (e.target.value !== '' && !reg.test(e.target.value)) {
        return;
      }
    }
    setNewValue(e.target.value);
    onChange(e);
  }, [regex, type, onChange]);

  return (
    <TextField
      {...rest}
      ref={ref}
      value={newValue}
      onChange={handleOnChange}
      InputLabelProps={{
        shrink: false,
        variant: 'standard',
        disableAnimation: true,
      }}
    />
  );
});

TextFieldWithRegex.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  regex: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func,
};

TextFieldWithRegex.defaultProps = {
  value: '',
  type: '',
  regex: null,
  onChange: () => {},
};


export default memo(TextFieldWithRegex);
