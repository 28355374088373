import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import VC from '@uvarov.frontend/vanilla-calendar';
import '@uvarov.frontend/vanilla-calendar/build/vanilla-calendar.min.css';
import '@uvarov.frontend/vanilla-calendar/build/themes/light.min.css';
import useStyles from './styles';

function MiniCalendar(props) {
  const { onChangeDate, config, ...attributes } = props;

  const ref = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (ref.current) {
      const calendar = new VC(ref.current, {
        settings: {
          iso8601: false,
          visibility: {
            weekend: false,
            theme: 'light',
          },
        },
        DOMTemplates: {
          default: `
            <div class=${classes.headerBar}>
              <div>
                <#Month /> <#Year />
              </div>
              <div>
                <#ArrowPrev />
                <#ArrowNext />
              </div>
            </div>
            <div class=${classes.contentWrapper}>
              <#Week />
              <#Days />
            </div>
          `,
        },
        actions: {
          clickDay(e, dates) {
            // Remember to pass in function as useCallback
            // to prevent continuosly triggering this useEffect
            onChangeDate(dates);
          },
        },
      });
      calendar.init();
    }
  }, [
    classes,
    ref,
    config,
    onChangeDate,
  ]);

  return (
    <div className={classes.container} {...attributes} ref={ref} />
  );
}

MiniCalendar.propTypes = {
  onChangeDate: PropTypes.func,
  config: PropTypes.shape({
    input: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'multiple', 'month', 'year']),
    months: PropTypes.number,
    jumpMonths: PropTypes.number,
    date: PropTypes.instanceOf(Object),
    settings: PropTypes.instanceOf(Object),
    locale: PropTypes.instanceOf(Object),
    actions: PropTypes.instanceOf(Object),
    popups: PropTypes.instanceOf(Object),
    CSSClasses: PropTypes.instanceOf(Object),
    DOMTemplates: PropTypes.instanceOf(Object),
  }),
};

MiniCalendar.defaultProps = {
  onChangeDate: () => {},
  config: {
    input: false,
    type: 'default',
    months: 0,
    jumpMonths: 0,
    date: {},
    settings: {},
    locale: {},
    actions: {},
    popups: null,
    CSSClasses: {},
    DOMTemplates: {},
  },
};

export default MiniCalendar;
