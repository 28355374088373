import React from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import {
  ChevronLeft,
  ChevronRight,
  ArrowDropDown,
} from '@material-ui/icons';
import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import DropdownMenu from '../DropdownMenu';
import useStyles from './styles';

// TODO: Rename this to Pagination when all listing API behavior changed
const PaginationWithLoadMore = (props) => {
  const {
    showLoadMore,
    limit,
    page,
    total,
    pageCount,
    variant,
    limitOptions,
    onChangePage,
    onChangeCount,
    onLoadMore,
    anchorOrigin,
    transformOrigin,
  } = props;

  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getFromTo = (v) => ({ from: (v - 1) * limit + 1, to: v * limit });

  const handleOnCountChange = (amount) => {
    onChangeCount({
      limit: amount,
      page: 1,
    });
  };

  const handleOnPageChange = ({ selected }) => {
    const selectedPage = selected + 1;
    onChangePage({
      limit,
      page: selectedPage,
    });
  };

  const handleOnLoadMore = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onLoadMore();
  };

  const renderMenuItem = (amount) => (
    <Grid container>
      <Grid item>
        {`Show ${amount}`}
      </Grid>
    </Grid>
  );

  const renderCounts = () => {
    const { from, to } = getFromTo(page);
    const labelCounts = `${from} - ${to > total ? total : to} of ${total}`;
    if (variant === 'simple') {
      return (
        <Typography variant="body2">
          {labelCounts}
        </Typography>
      );
    }
    return (
      <DropdownMenu
        data={limitOptions.map((v) => ({
          children: renderMenuItem(v),
          onClick: () => handleOnCountChange(v),
        }))}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {({ open }) => (
          <Button
            onClick={open}
            className={classes.pageAmountButton}
            endIcon={<ArrowDropDown />}
          >
            <Typography variant="body2">
              {labelCounts}
            </Typography>
          </Button>
        )}
      </DropdownMenu>
    );
  };

  return (
    <>
      {
        total ? (
          <Grid
            container
            alignItems="center"
            justify={variant === 'simple' ? 'space-between' : 'flex-start'}
          >
            <Grid
              item
              container
              xs={variant === 'simple' ? 4 : 12}
              sm={2}
              justify={!isMobile || variant === 'simple' ? 'flex-start' : 'center'}
            >
              { renderCounts() }
            </Grid>
            <Grid item className={classes.pages} xs={variant === 'simple' ? 8 : 12} sm={10}>
              <ReactPaginate
                forcePage={page - 1}
                pageRangeDisplayed={!isMobile ? 2 : 1}
                marginPagesDisplayed={!isMobile ? 3 : 2}
                pageCount={pageCount}
                onPageChange={handleOnPageChange}
                containerClassName={`
                  ${classes.container}
                  ${variant === 'simple' ? classes.hideNumbers : ''}
                `}
                pageLinkClassName={classes.link}
                previousLinkClassName={classes.link}
                nextLinkClassName={classes.link}
                breakLinkClassName={classes.breakLink}
                disabledClassName={`${classes.disabledLink} ${variant === 'simple' ? classes.hideAll : ''}`}
                activeLinkClassName={classes.activeLink}
                previousLabel={<ChevronLeft />}
                nextLabel={(
                  <>
                    {
                      showLoadMore && (
                        <Typography
                          variant="body2"
                          className={classes.loadMore}
                          onClick={handleOnLoadMore}
                        >
                          Load more
                        </Typography>
                      )
                    }
                    <ChevronRight />
                  </>
                )}
              />
            </Grid>
          </Grid>
        ) : null
      }
    </>
  );
};

PaginationWithLoadMore.propTypes = {
  showLoadMore: PropTypes.bool,
  total: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  limitOptions: PropTypes.arrayOf(PropTypes.number),
  anchorOrigin: PropTypes.instanceOf(Object),
  transformOrigin: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['simple', 'detailed']),
  onChangePage: PropTypes.func,
  onChangeCount: PropTypes.func,
  onLoadMore: PropTypes.func,
};

PaginationWithLoadMore.defaultProps = {
  showLoadMore: false,
  pageCount: 0,
  limit: 10,
  page: 0,
  limitOptions: [10, 30, 50],
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  variant: 'detailed',
  onChangePage: () => {},
  onChangeCount: () => {},
  onLoadMore: () => {},
};

export default PaginationWithLoadMore;
