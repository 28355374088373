import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  loadingOverlay: {
    zIndex: 3,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#afadad59',
    backdropFilter: 'blur(2px)',
  },
  content: {
    display: 'flex',
    '&:not(.noNav)': {
      width: '100vw',
      height: 'calc(100vh - 50px)',
      marginTop: '50px',
      overflow: 'hidden',
    },
  },
}));
