export const companies = {
  '23fd0401-1de5-4768-a5af-dc2000e8e3cf': 'https://gamuda.stage.mhub.my',
  '93c7ac23-7d10-49b1-bc19-92433a53957f': 'https://srbdemo.mhub.my',
  'a8d074f6-3039-4627-937e-f0429eb5eb19': 'https://aspen.mhub.my',
  'aea24add-b3ae-43fe-a3cd-71a3698bde78': 'https://lbsbina.mhub.my',
  'f6512314-d4b8-4a7e-a206-ed502333051a': 'https://asiagreen.mhub.my',
  'ea0ba31e-3d47-4c83-b533-c9e07ff008bf': 'https://azmisabah.mhub.my',
  '73830b5e-3458-4ea4-b702-bb3938eed124': 'https://gamuda.mhub.my',
  '9284f7ee-6e22-4c9c-a747-d09d255197ca': 'https://ijmland.mhub.my',
  '80db0ea6-60ed-4969-9d50-bfd074d3630a': 'https://farcapital.mhub.my',
  '9d1787d9-8d77-4684-805d-804cb73c7202': 'https://setia.mhub.my',
  '130435a9-38b3-403c-9308-68e75ae1cc22': 'https://hectarworld.mhub.my',
  'db48be3e-63d6-491b-90a4-dd1365661e46': 'https://sunway.mhub.my',
  'f4c07f3a-e0f9-4829-88f7-91bbad3ae32a': 'https://fabulous.mhub.my',
  '8d3b3590-1370-4481-b574-e776ba2da490': 'https://lagenda.mhub.my',
  '6416a41c-c6bc-4ee4-919e-5f8f2aa75427': 'https://ghmedia.mhub.my',
  'bdda0560-7837-4db1-8ab8-574c5922ef42': 'https://gsdland.mhub.my',
  'c6241b6b-5421-42ff-8194-d8ef1ba4a8df': 'https://royalhlh.mhub.my',
  'f71f1f11-c041-4e72-8484-ac1c2163506a': 'https://klkland.mhub.my',
  'c42e17ce-cee0-4737-bb28-9e784d55ff1d': 'https://uemsunrise.mhub.my',
};

export const companyIDs = {
  BHP: '911f215b-1ea1-4a1d-ad76-58a8eb683df6',
};
