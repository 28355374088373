import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import DropdownMenu from '../DropdownMenu';
import useStyles from './styles';

const amounts = [10, 30, 50];

const Pagination = (props) => {
  const {
    total,
    variant,
    onChangePage,
    onChangeCount,
    anchorOrigin,
    transformOrigin,
  } = props;

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getFromTo = (v) => ({ from: (v - 1) * limit + 1, to: v * limit });

  useEffect(() => {
    setPage(1);
  }, [total]);

  const handleOnCountChange = (amount) => {
    setLimit(amount);
    const selectedPage = page;
    const newPageCount = Math.ceil(total / amount);
    let offset = Math.ceil((selectedPage - 1) * amount);
    if (selectedPage > newPageCount) {
      setPage(newPageCount);
      offset = Math.ceil((newPageCount - 1) * amount);
    }
    onChangeCount({
      limit: amount,
      offset,
    });
  };

  const handleOnPageChange = ({ selected }) => {
    const v = selected + 1;
    setPage(v);
    onChangePage({
      limit,
      offset: Math.ceil(selected * limit),
    });
  };

  const renderMenuItem = (amount) => (
    <Grid container>
      <Grid item>
        {`Show ${amount}`}
      </Grid>
    </Grid>
  );

  const renderCounts = () => {
    const { from, to } = getFromTo(page);
    const labelCounts = `${from} - ${to > total ? total : to} of ${total}`;
    if (variant === 'simple') {
      return (
        <Typography variant="body2">
          {labelCounts}
        </Typography>
      );
    }
    return (
      <DropdownMenu
        data={amounts.map((v) => ({
          children: renderMenuItem(v),
          onClick: () => handleOnCountChange(v),
        }))}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {({ open }) => (
          <Button onClick={open} className={classes.pageAmountButton}>
            <Typography variant="body2">
              {labelCounts}
            </Typography>
          </Button>
        )}
      </DropdownMenu>
    );
  };

  return (
    <>
      {
        total ? (
          <Grid
            container
            alignItems="center"
            justify={variant === 'simple' ? 'space-between' : 'flex-start'}
          >
            <Grid
              item
              container
              xs={variant === 'simple' ? 4 : 12}
              sm={2}
              justify={!isMobile || variant === 'simple' ? 'flex-start' : 'center'}
            >
              { renderCounts() }
            </Grid>
            <Grid item className={classes.pages} xs={variant === 'simple' ? 8 : 12} sm={10}>
              <ReactPaginate
                forcePage={page - 1}
                pageRangeDisplayed={!isMobile ? 2 : 1}
                marginPagesDisplayed={!isMobile ? 3 : 2}
                pageCount={Math.ceil(total / limit)}
                onPageChange={handleOnPageChange}
                containerClassName={`
                  ${classes.container}
                  ${variant === 'simple' ? classes.hideNumbers : ''}
                `}
                pageLinkClassName={classes.link}
                previousLinkClassName={classes.link}
                nextLinkClassName={classes.link}
                breakLinkClassName={classes.breakLink}
                disabledClassName={`${classes.disabledLink} ${variant === 'simple' ? classes.hideAll : ''}`}
                activeLinkClassName={classes.activeLink}
                previousLabel={<ChevronLeftIcon />}
                nextLabel={<ChevronRightIcon />}
              />
            </Grid>
          </Grid>
        ) : null
      }
    </>
  );
};

Pagination.propTypes = {
  anchorOrigin: PropTypes.instanceOf(Object),
  transformOrigin: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['simple', 'detailed']),
  onChangePage: PropTypes.func,
  onChangeCount: PropTypes.func,
  total: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  variant: 'detailed',
  onChangePage: () => {},
  onChangeCount: () => {},
};

export default Pagination;
