import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';


export function useStyles(props) {
  return makeStyles((theme) => ({
    root: {
      flex: 1,
      '& .MuiAutocomplete-inputRoot': {
        padding: '0 2rem 0 1rem',
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderWidth: '1px',
      },
    },
    loader: {
      position: 'absolute',
    },
    clear: {
      position: 'absolute',
      '& svg': {
        width: '1rem',
        height: '1rem',
      },
    },
    icon: {
      display: 'flex',
      width: '1.2rem',
      filter: 'invert(0.4)',
    },
    arrow: {
      filter: 'invert(0.4)',
    },
    listbox: {
      padding: 0,
    },
    selectButton: {
      minWidth: 'unset',
      padding: '0 0 0 5px',
      '&:focus': {
        backgroundColor: theme.palette.tertiary.mediumGrey,
      },
    },
    popper: {
      paddingTop: '5px',
      maxHeight: '20rem',
    },
  }))(props);
}

export const CustomMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '& > span': {
      marginRight: '1rem',
    },
  },
  selected: {},
}))(MenuItem);

export function popperStyles(props, width) {
  return makeStyles(({
    popper: {
      width,
    },
  }))(props);
}
