import React, { useEffect, useState, useContext } from 'react';

import { Dialog, Button, Typography } from '@material-ui/core';

import config from '../../config';
import { AuthContext } from '../../contexts/Auth';
import { LoadingPage } from '../../components';

import useStyles from './styles';
import './styles.css';

const redirectData = () => {
  const url = window.location.href;
  const data = url.split('?');
  if (data.length > 1) {
    return data[1];
  }
  return '';
};

const Login = () => {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);

  const classes = useStyles();

  const getDestination = () => {
    const { state } = authContext;
    const { isAuthenticated, hasAccess, isLoggedOut } = state;
    let destination = `${config.app.secureUrl}/login`;
    if (!isLoggedOut && (!isAuthenticated || hasAccess)) {
      destination += `?${redirectData()}`;
    }
    return destination;
  };

  const redirect = () => {
    const destination = getDestination();
    window.location.replace(destination);
  };

  const checkRedirect = () => {
    const { state } = authContext;
    const {
      hasAccess,
      isAuthenticated,
      isLoading,
      isLoggedOut,
    } = state;
    if (!isLoading) {
      if (!isLoggedOut && isAuthenticated && !hasAccess) {
        setOpen(true);
      } else {
        redirect();
      }
    }
  };

  useEffect(() => {
    checkRedirect();
  });

  const { state } = authContext;
  const { isLoading, isAuthenticated, hasAccess } = state;
  if ((isAuthenticated && hasAccess) || isLoading) {
    return <LoadingPage isLoading />;
  }
  const destination = getDestination();
  return (
    <div className={classes.Login}>
      <Dialog open={open}>
        <div className={classes.root}>
          <Typography variant="h5" align="center">
            Forbidden access.
            <Typography align="center">You have no access to this website.</Typography>
          </Typography>
          <Button
            onClick={redirect}
            variant="contained"
            fullWidth
            className={classes.buttons}
          >
           Back to Secure
          </Button>
        </div>
      </Dialog>
      <Typography variant="h6">
        Please login
      </Typography>
      <Button component="a" variant="contained" href={destination}>
        Login
      </Button>
    </div>
  );
};

export default Login;
