import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as UploadDisabledIcon } from '../../assets/icons/upload_disabled.svg';
import useStyles from './styles';

const UploadFileContainer = (props) => {
  const {
    accept,
    description,
    disabled,
    onChange,
  } = props;

  const classes = useStyles();

  const handleOnChange = (e) => {
    if (!disabled) {
      onChange(e);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="row"
      classes={{
        container: `${classes.uploadContainer} ${disabled ? 'disabled' : ''}`,
      }}
    >
      {
        disabled
          ? <UploadDisabledIcon className={classes.marginRight} />
          : <UploadIcon className={classes.marginRight} />
      }
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <label htmlFor="icon-button-file">
          <input
            accept={accept}
            className={classes.hide}
            id="icon-button-file"
            type="file"
            disabled={disabled}
            onChange={handleOnChange}
          />
          <Typography variant="subtitle1" component={disabled ? Typography : Link}>
            Browse to upload your file
          </Typography>
        </label>
        <Typography variant="body2">
          { description }
        </Typography>
      </Box>
    </Grid>
  );
};

UploadFileContainer.propTypes = {
  description: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

UploadFileContainer.defaultProps = {
  description: '',
  accept: '',
  disabled: false,
  onChange: () => {},
};

export default UploadFileContainer;
