import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Typography,
  Dialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const SimpleModal = (props) => {
  const {
    buttonsClassName,
    open,
    isWide,
    title,
    content,
    buttons,
    onCancel,
  } = props;

  const classes = useStyles();

  return (
    <Dialog onClose={onCancel} open={open}>
      <div className={`${classes.container} ${isWide ? 'wider' : ''}`}>
        <Grid
          container
          className={classes.heading}
          justify="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{ title }</Typography>
          <IconButton size="small" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Grid>
        { content }
        {
          buttons && (
            <div className={`${buttonsClassName} ${classes.controls}`}>
              { buttons }
            </div>
          )
        }
      </div>
    </Dialog>
  );
};

SimpleModal.propTypes = {
  open: PropTypes.bool,
  isWide: PropTypes.bool,
  buttonsClassName: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  buttons: PropTypes.node,
  onCancel: PropTypes.func,
};

SimpleModal.defaultProps = {
  open: false,
  isWide: false,
  buttonsClassName: '',
  title: '',
  content: null,
  buttons: null,
  onCancel: () => {},
};

export default SimpleModal;
