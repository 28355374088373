import { CONSTRUCTOR } from '..';

// User fields fragment.
export const user = CONSTRUCTOR.FRAGMENT({
  name: 'User',
  fields: `{
    id
    company_id
    company_branch_id
    name
    preferred_name
    email
    phone_number
    email_verified
    phone_number_verified
    created_at
    last_modified
    user_status
    sms_mfa_status
    groups
  }`,
});

// Condition input fields fragment.
export const conditionInput = CONSTRUCTOR.FRAGMENT({
  name: 'ConditionInput',
  fields: `{
    field
    operator
    value
  }`,
});

// User group fields fragment.
export const userGroup = CONSTRUCTOR.FRAGMENT({
  name: 'UserGroup',
  fields: `{
    id
    company_id
    assignment_name
    name
    description
    user_ids
    enabled
    reassignment
    type
    assignment_rules
    reassignment_rules
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

// Company branch fields fragment.
export const companyBranch = CONSTRUCTOR.FRAGMENT({
  name: 'CompanyBranch',
  fields: `{
    id
    name
    is_external
  }`,
});

// ListUserGroups fields fragment.
export const listUserGroupsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListUserGroupsResponse',
  fields: `{
    user_groups {...UserGroupFields}
    count
  }`,
  fragments: [userGroup],
});

// ListUsers fields fragment.
export const listUserResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListUserResponse',
  fields: `{
    users {...UserFields}
    count
  }`,
  fragments: [user],
});

// ListCompanyBranches fields fragment.
export const listCompanyBranchesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCompanyBranchesResponse',
  fields: `{
    company_branches {...CompanyBranchFields}
    count
  }`,
  fragments: [companyBranch],
});

// listActivitiesResponse fields fragment.
export const listActivitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListActivitiesResponse',
  fields: `{
    items
    count
  }`,
});
