import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import SimpleModal from '../SimpleModal';
import Button from '../Button';

const CallAttemptsAlertModsl = (props) => {
  const {
    open,
    onClose,
    onConfirm,
  } = props;

  return (
    <SimpleModal
      title="Confirm call"
      open={open}
      content={(
        <Typography>
          Our calling system will track the duration, recording and outcome of this call.
          The system will also attempt to call the assignee first and once the assignee picks up,
          the call will be triggered to the lead or customer.
          If the assignee does not pick up, the system will attempt up to 5 times.
        </Typography>
      )}
      onCancel={onClose}
      buttons={(
        <>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
          >
            Proceed
          </Button>
        </>
      )}
    />
  );
};

CallAttemptsAlertModsl.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

CallAttemptsAlertModsl.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default CallAttemptsAlertModsl;
