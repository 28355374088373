import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import {
  MenuItem,
  Button,
  ClickAwayListener,
  MenuList,
  Paper,
  Popper,
  Typography,
  Grid,
  TextField,
  Box,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { debounce, toLower } from 'lodash';
import { countries } from '../../../data';
import Searchable from '../Searchable';
import { isEmptyValue } from '../../../utils';
import {
  useStyles,
  popperStyles,
} from './styles';

export default function Countries(props) {
  const {
    type,
    variant,
    name,
    label,
    error,
    readOnly,
    disabled,
    addNotApplicable,
    hideFlag,
    disabledPortal,
    value,
    anchorRef,
    onOpen,
    onChange,
    ...searchableProps
  } = props;

  const [open, setOpen] = useState(false);
  const [localCountryValue, setLocalCountryValue] = useState('');
  // Countries option that will change based on search value
  const [searchCallingCodeOptions, setSearchCallingCodeOptions] = useState(countries);

  const classes = useStyles();
  const popperClass = popperStyles(
    props,
    anchorRef && anchorRef.current && anchorRef.current.getBoundingClientRect() ? anchorRef.current.getBoundingClientRect().width : '85%',
  );

  useEffect(() => {
    if (type === 'calling_code') {
      // Shift local calling code to the first option
      const localCountryIndex = countries.findIndex(
        (v) => v.value === 'MY',
      );
      const clonedCountryOptions = JSON.parse(JSON.stringify(countries));
      if (localCountryIndex > -1) {
        clonedCountryOptions.unshift(clonedCountryOptions.splice(localCountryIndex, 1)[0]);
        setSearchCallingCodeOptions(clonedCountryOptions);
        setLocalCountryValue(countries[localCountryIndex].value);
      }
    }
  }, [value, type]);

  const debounceCallBack = debounce((e, callBack) => {
    callBack();
  }, 200);

  const handleSearchCallingCode = (e) => {
    let clonedOptions = JSON.parse(JSON.stringify(countries));
    const formatted = e.target.value.toLowerCase();
    if (formatted) {
      clonedOptions = clonedOptions.filter((opt) => (
        (opt.label && opt.label.toLowerCase().startsWith(formatted))
        || (opt.calling_code && opt.calling_code.toLowerCase().startsWith(formatted))
      ));
    } else {
      clonedOptions = JSON.parse(JSON.stringify(countries));
    }
    debounceCallBack(e, () => {
      setSearchCallingCodeOptions(clonedOptions);
    });
  };

  const handleOnChange = (e, options, country) => {
    if (readOnly) {
      setOpen(false);
      return;
    }
    if (type === 'calling_code') {
      const countryIndex = options.findIndex((opt) => opt.value === country.value);
      if (countryIndex > -1) {
        onChange(e, {
          countryCode: country.value,
          callingCode: country.calling_code,
        });
      } else {
        Bugsnag.notify(`Invalid country:${country.value} in countries list`);
        return;
      }
      setOpen(false);
      onOpen(false);
    } else {
      onChange(e, e.target.value);
    }
  };

  const handleOnSearchableChange = (e, selected) => {
    onChange(e, { name, value: selected });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    onOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    onOpen(false);
  };

  const getValue = () => {
    if (value) {
      if (value === 'n/a') {
        return value;
      }
      return value.toUpperCase();
    }
    return null;
  };

  const getFilterOptions = (opts, state) => {
    // Show options that are already filtered by the component itself
    // Such as when pass in filterSelectedOptions
    let filteredOptions = opts;
    if (state && state.inputValue) {
      const { inputValue } = state;
      const formatted = inputValue.toLowerCase();
      filteredOptions = opts.filter((opt) => (
        opt.label && opt.label.toLowerCase().includes(formatted)
      ));
    }
    return filteredOptions;
  };

  const renderDefaultType = () => {
    const clonedOptions = JSON.parse(JSON.stringify(countries));
    if (addNotApplicable && !clonedOptions.some((option) => option.value === 'n/a')) {
      clonedOptions.push({
        label: 'Not applicable',
        value: 'n/a',
      });
    }

    return (
      <Searchable
        anchorRef={anchorRef}
        label={label}
        value={getValue()}
        error={error}
        disabled={disabled}
        filterOptions={getFilterOptions}
        onChange={handleOnSearchableChange}
        options={clonedOptions}
        renderOption={(opt) => (
          <Grid container className={classes.optionContainer}>
            {
              !hideFlag && opt.value !== 'n/a' && (
                <span className={`flag-icon flag-icon-${opt.value.toLowerCase()}`} />
              )
            }
            { opt.label }
          </Grid>
        )}
        {...searchableProps}
      />
    );
  };

  const renderCallingCodeType = () => {
    let selectedCallingCode = {};
    if (value && countries.find((country) => country.value === value)) {
      selectedCallingCode = countries.find((country) => country.value === value);
    }
    return (
      <>
        <Button
          className={`${classes.selectButton} ${variant === 'standard' ? 'standard' : ''}`}
          onClick={handleToggle}
          disabled={disabled}
        >
          {
            !value && isEmptyValue(selectedCallingCode)
              ? <Typography className={classes.placeholder}>Select</Typography>
              : (
                <>
                  {
                    !hideFlag && (
                      <i className={`flag-icon flag-icon-${toLower(selectedCallingCode.value)} ${classes.flag}`} />
                    )
                  }
                  <Typography>
                    {`+${selectedCallingCode.calling_code}`}
                  </Typography>
                </>
              )
            }
          <Icons.ArrowDropDown />
        </Button>
        <Popper
          open={open}
          disablePortal={disabledPortal}
          className={popperClass.popper}
          anchorEl={anchorRef.current}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  placeholder="Search"
                  className={classes.searchMenu}
                  onChange={handleSearchCallingCode}
                />
                <MenuList disablePadding className={classes.countryCodeList}>
                  {
                    searchCallingCodeOptions.length > 0
                      ? searchCallingCodeOptions.map((country) => (
                        <MenuItem
                          selected={
                            !isEmptyValue(selectedCallingCode)
                            && selectedCallingCode.value === country.value
                          }
                          key={`${country.value}_${country.calling_code}`}
                          value={country.value}
                          onClick={(e) => handleOnChange(e, searchCallingCodeOptions, country)}
                          className={localCountryValue === country.value ? 'default' : ''}
                        >
                          {
                            !hideFlag && (
                              <i className={`flag-icon flag-icon-${toLower(country.value)} ${classes.flag}`} />
                            )
                          }
                          {`${country.label} (+${country.calling_code})`}
                        </MenuItem>
                      ))
                      : <MenuItem key="null" value="null" disabled>No result</MenuItem>
                  }
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </>
    );
  };

  return (
    <>
      {
        type === 'calling_code'
          ? renderCallingCodeType()
          : renderDefaultType()
      }
    </>
  );
}
Countries.propTypes = {
  type: PropTypes.oneOf(['default', 'calling_code']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  addNotApplicable: PropTypes.bool,
  hideFlag: PropTypes.bool,
  disabledPortal: PropTypes.bool,
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
};
Countries.defaultProps = {
  type: 'default',
  variant: 'standard',
  name: '',
  label: '',
  value: '',
  error: '',
  readOnly: false,
  disabled: false,
  addNotApplicable: false,
  hideFlag: false,
  disabledPortal: true,
  anchorRef: null,
  onOpen: () => {},
  onChange: () => {},
};
