export function getRenderProps(render, ...value) {
  if (render) {
    if (value && typeof render === 'function') {
      return render(...value);
    }
    return render;
  }
  return null;
}

export function getSelectorValue(data, selector) {
  if (selector) {
    if (typeof selector === 'function') {
      return selector(data);
    }
    if (Object.prototype.hasOwnProperty.call(data, selector)) {
      return data[selector];
    }
  }
  return data;
}
