import { CONSTRUCTOR } from '..';

export const listSubmissionResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListSubmissionResponse',
  fields: `{
    body
    count
    pit_id
    search_afters
  }`,
});

export const searchSubmissionsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'SearchSubmissionsResponse',
  fields: `{
    body
    count
  }`,
});

export const deleteSubmissionResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DeleteSubmissionResponse',
  fields: `{
    body
  }`,
});

export const updateSubmissionResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UpdateSubmissionResponse',
  fields: `{
    body
  }`,
});

// DownloadSubmissionsResponse fields fragment.
export const downloadSubmissionsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DownloadSubmissionsResponse',
  fields: `{
    body
  }`,
});

export const submissionExports = CONSTRUCTOR.FRAGMENT({
  name: 'SubmissionExports',
  fields: `{
    filename
    file_key
    file_url
    export_count
    export_type
    filetype
    export_id
    expired_at
    created_at
    created_by
  }`,
});

export const listSubmissionExportsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListSubmissionExportsResponse',
  fields: `{
    items {...SubmissionExportsFields}
    count
  }`,
  fragments: [submissionExports],
});
