import * as CONSTRUCTOR from '../generate';

export const customer = CONSTRUCTOR.FRAGMENT({
  name: 'Customer',
  fields: `{
    id
    company_id
    type
    name
    contact_ids
    imported_at
    imported_by
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
    qualified_at
  }`,
});

export const searchCustomersResponse = CONSTRUCTOR.FRAGMENT({
  name: 'SearchCustomersResponse',
  fields: `{
    body
    count
  }`,
});

export const customerInfo = CONSTRUCTOR.FRAGMENT({
  name: 'CustomerInfo',
  fields: `{
    id
    company_id
    type
    name
    contact_ids
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
    qualified_at
  }`,
});

// ListCustomersResponse fields fragment.
export const listCustomersResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCustomersResponse',
  fields: `{
    customers
    count
    pit_id
    search_afters
  }`,
});

// DownloadCustomersResponse fields fragment.
export const downloadCustomersResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DownloadCustomersResponse',
  fields: `{
    body
  }`,
});

// listActivitiesResponse fields fragment.
export const listActivitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListActivitiesResponse',
  fields: `{
    items
    count
    info_mappings {
      customer_infos
    }
  }`,
});

// uploadCustomersResponse fields fragment.
export const uploadCustomersResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UploadCustomersResponse',
  fields: `{
    url
    import_id
  }`,
});

// Customer uploads fields fragment.
export const customerUpload = CONSTRUCTOR.FRAGMENT({
  name: 'CustomerUploads',
  fields: `{
    filename
    created_at
    created_by
    imported_count
    error_count
    new_import_count
    updated_import_count
    error_file_key
    error_file_url
    import_id
  }`,
});

// ListCustomerUploadsResponse fields fragment.
export const listCustomerUploadsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCustomerUploadsResponse',
  fields: `{
    items {...CustomerUploadsFields}
    count
  }`,
  fragments: [customerUpload],
});

export const customerExports = CONSTRUCTOR.FRAGMENT({
  name: 'CustomerExports',
  fields: `{
    filename
    file_key
    file_url
    export_count
    export_type
    filetype
    export_id
    expired_at
    created_at
    created_by
  }`,
});

export const listCustomerExportsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCustomerExportsResponse',
  fields: `{
    items {...CustomerExportsFields}
    count
  }`,
  fragments: [customerExports],
});
