import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    overflow: 'auto',
    padding: '2rem 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
  expansionPanelSummaryRoot: {
    backgroundColor: theme.palette.tertiary.mediumGrey,
    borderStyle: 'none',
    minHeight: '1rem',
    marginBottom: '1rem',
  },
  expansionPanelRoot: {
    borderStyle: 'none',
    '&::before': {
      top: 0,
    },
  },
  expansionPanelDetailsRoot: {
    padding: '8px 16px 8px',
  },
  marginTop: {
    marginTop: '2rem',
  },
  marginBottom: {
    marginBottom: '2rem',
  },
}));
