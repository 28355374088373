import React, { useEffect } from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';

const LINK_MHUB_SUPPORT = 'https://support.mhub.my/hc/en-us/categories/360001490114-Lead';
const LINK_READ_MORE = 'https://mhubhelp.zendesk.com/hc/en-us/articles/360043019994-Who-can-view-my-leads-and-customers';
const ANSWER_1 = 'MHub CRM is a Customer Relationship Management (CRM) software '
+ 'that stores information about potential buyers or buyers, such as '
+ 'their names, as well as other customisable information. It is a sales'
+ 'tool that keeps track of customer activity, all in one place.';
const ANSWER_2_1 = ' are like raw data. Leads require the attention '
+ 'of a salesperson or perhaps a call centre personnel, '
+ 'for cold-calling or other forms of verification before being '
+ 'qualified as customers.';
const ANSWER_2_2 = 'Example: You receive 50 signups from a campaign you just ran.'
+ 'These are leads that are new and yet to be contacted. '
+ 'They could be junk leads whose phone numbers '
+ 'and not contactable.';
const ANSWER_2_3 = 'on the other hand, are qualified leads. They could be '
+ 'return buyers or potential buyers that are one step closer '
+ 'to buying a property. Example: You received Jason as a lead and made '
+ 'a call to the contact number provided. He expressed that he would like to buy '
+ 'a property within this year before he gets married '
+ 'and very interested in a specific project and unit, which you then link as an '
+ 'opportunity to the customer.';
const ANSWER_2_4 = 'Leads will progress to being a customer and will not appear as a '
+ 'lead once they are qualified.';
const ANSWER_3 = 'Think of ‘Contacts’ like the address book in MHub CRM. '
+ 'Once a lead gets qualified and converted to a customer, '
+ 'a contact profile will be created along with a customer profile.'
+ ' Every customer has a contact.';
const ANSWER_4 = 'An opportunity represents a potential or pending sale. '
+ 'This includes the project name, unit number and other details about the sale.';
const ANSWER_5 = 'Sharing settings determine who can view and access records in your database. '
+ 'MHub CRM uses business units and user role hierarchies to grant access. '
+ 'Read more ';

const FAQ = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'FAQ';
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderAnswerPanel2 = () => (
    <Typography variant="body1">
      <strong>Leads </strong>
      {ANSWER_2_1}
      <br />
      {ANSWER_2_2}
      <br />
      <br />
      <strong>Customers</strong>
        &nbsp;
      {ANSWER_2_3}
      <br />
      <br />
      {ANSWER_2_4}
    </Typography>
  );

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h1">
          FAQ
        </Typography>
        <Typography className={classes.marginBottom}>
          Need answers? Find them here!
        </Typography>
      </div>
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
            }}
          >
            <Typography variant="h6">What is MHub CRM?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.expansionPanelDetailsRoot,
            }}
          >
            <Typography>
              {ANSWER_1}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
            }}
          >
            <Typography variant="h6">
              What is the difference between leads and customers?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.expansionPanelDetailsRoot,
            }}
          >
            {renderAnswerPanel2()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
            }}
          >
            <Typography variant="h6">What is a contact?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.expansionPanelDetailsRoot,
            }}
          >
            <Typography>
              {ANSWER_3}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
            }}
          >
            <Typography variant="h6">What is an opportunity?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.expansionPanelDetailsRoot,
            }}
          >
            <Typography>
              {ANSWER_4}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
            }}
          >
            <Typography variant="h6">Who can view my leads and customers?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.expansionPanelDetailsRoot,
            }}
          >
            <Typography>
              {ANSWER_5}
              <Typography
                component={Link}
                onClick={() => window.open(LINK_READ_MORE, '_blank')}
              >
                here
              </Typography>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <div>
        <Typography className={classes.marginTop}>
          Still can’t find what you’re looking for? Head to&nbsp;
          <Typography
            component={Link}
            onClick={() => window.open(LINK_MHUB_SUPPORT, '_blank')}
          >
             MHub Support
          </Typography>
          &nbsp;
          for further guidance.
        </Typography>
      </div>

    </div>
  );
};

export default FAQ;
