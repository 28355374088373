import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';

const LoadingOverlay = (props) => {
  const { isLoading } = props;

  const classes = useStyles();

  const renderLoader = () => {
    if (!isLoading) {
      return null;
    }
    return (
      <div className={classes.loadingOverlay}>
        <CircularProgress />
      </div>
    );
  };

  return renderLoader();
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  isLoading: false,
};

export default LoadingOverlay;
