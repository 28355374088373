import { CONSTRUCTOR } from '..';

export const emergencyContact = CONSTRUCTOR.FRAGMENT({
  name: 'EmergencyContact',
  fields: `{
    relation
    name
    nric
    email
    mobile
    mobile_country_code
    mobile_prefix
  }`,
});

// Contact field fragment.
export const contact = CONSTRUCTOR.FRAGMENT({
  name: 'Contact',
  fields: `{
    id
    company_id
    name
    preferred_name
    email
    mobile
    mobile_country_code
    phone
    msisdn
    bumi
    gender
    buyer_type
    monthly_income
    address
    address_1
    address_2
    city
    state
    postcode
    nationality_country_code
    country_code
    nric
    passport
    secondary_email
    secondary_mobile
    source
    title
    date_of_birth
    race
    marital_status
    occupation
    emergency_contacts {...EmergencyContactFields}
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
  fragments: [emergencyContact],
});

// GetContactResponse field fragment.
export const getContactResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetContactResponse',
  fields: `{
    contact
  }`,
});

// ListContactsResponse field fragment
export const listContactsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListContactsResponse',
  fields: `{
    body
    count
  }`,
});

// searchContactResponse fields fragment.
export const searchContactResponse = CONSTRUCTOR.FRAGMENT({
  name: 'SearchContactResponse',
  fields: `{
    contacts {...ContactFields}
    count
  }`,
  fragments: [contact],
});

// CreateContactResponse field fragment.
export const createContactResponse = CONSTRUCTOR.FRAGMENT({
  name: 'CreateContactResponse',
  fields: `{
    contact
  }`,
});

// updateContactResponse fields fragment.
export const updateContactResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UpdateContactResponse',
  fields: `{
    contact
  }`,
});
