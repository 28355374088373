import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import Searchable from '../Searchable';
import useStyles from './styles';

export default function SearchableStatuses({
  placeholder,
  onChange,
  options,
  disabled,
  disabledOptions,
  ...searchableProps
}) {
  const classes = useStyles();
  const statusOptions = options
    ? Object.entries(options).map(([key, attribute]) => {
      if (!searchableProps.multiple) {
        return {
          id: attribute.id,
          value: attribute.value,
        };
      }
      return {
        value: attribute.id,
        label: attribute.value,
      };
    })
    : [];

  const handleOptionChange = (event, newValue, info) => {
    if (!searchableProps.multiple) {
      const index = statusOptions.findIndex((option) => option.id === info.id);
      if (index > -1) {
        onChange(event, newValue, { ...statusOptions[index] });
      }
    } else {
      onChange(event, newValue, info);
    }
  };

  const renderOption = (optionLabel) => (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs>
        <Typography variant="body2" className={classes.label}>{optionLabel || ''}</Typography>
      </Grid>
    </Grid>
  );

  const preparedOptions = statusOptions.map((option) => (
    {
      ...option,
      disabled: disabledOptions.includes(option.id || option.value),
    }));

  return (
    <Searchable
      onChange={handleOptionChange}
      options={preparedOptions}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.value || option.label;
      }}
      renderOption={(option) => {
        if (typeof option === 'string') {
          return renderOption(option);
        }
        return renderOption(option.label ? option.label : option.value);
      }}
      placeholder={placeholder}
      disabled={disabled}
      {...searchableProps}
    />
  );
}

SearchableStatuses.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    crm_id: PropTypes.string,
    sc_id: PropTypes.string,
    label: PropTypes.string,
  })),
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};

SearchableStatuses.defaultProps = {
  placeholder: 'Select',
  options: [],
  disabled: false,
  disabledOptions: [],
};
