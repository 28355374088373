import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 4.43rem 0 0',
      '& .MuiAutocomplete-input:first-child': {
        padding: '0.7rem 1rem',
      },
    },
    '& .MuiAutocomplete-tag': {
      color: theme.palette.font.header,
      backgroundColor: theme.palette.tertiary.mediumGrey,
      '& > svg': {
        width: '1.07rem',
      },
    },
  },
  paper: {
    margin: 0,
  },
  listbox: {
    padding: 0,
    '& .MuiTypography-body1': {
      color: theme.palette.font.header,
    },
  },
  textField: {
    backgroundColor: theme.palette.common.white,
  },
}));
