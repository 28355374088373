import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { formatValue } from '../../../utils';
import useStyles from './styles';

const CallTypeTag = (props) => {
  const { type, direction, shortenLabel } = props;

  const classes = useStyles();

  const renderLabel = () => {
    let label = 'n/a';
    const callText = !shortenLabel ? ' call' : '';

    if (direction === 'call_in') {
      label = `Inbound${callText}`;
    } else {
      switch (type) {
        case 'workflow':
          label = `Automated${callText}`;
          break;
        case 'manual_call':
          label = `Manual${callText}`;
          break;
        case 'manual':
          label = `Manual log${callText}`;
          break;
        default:
          label = formatValue(type);
          break;
      }
    }

    return label;
  };

  return (
    <Chip
      className={`${classes.chip} ${direction === 'call_in' ? 'inbound' : type}`}
      label={renderLabel()}
    />
  );
};

CallTypeTag.propTypes = {
  type: PropTypes.string,
  direction: PropTypes.string,
  shortenLabel: PropTypes.bool,
};

CallTypeTag.defaultProps = {
  type: '',
  direction: '',
  shortenLabel: false,
};

export default CallTypeTag;
