import _ from 'lodash';
import {
  ImageIcon,
  TermsAndConditionIcon,
  CheckIcon,
  ArrowDownIcon,
  CalendarIcon,
  MailSmallIcon,
  NumbersIcon,
  RadioIcon,
  PhoneIcon,
  TextIcon,
  ParagraphIcon,
  TimeIcon,
} from '../assets/icons';
import { formatValue, isEmptyValue } from '../utils';

export const allOption = { label: 'Select all', name: 'all', value: 'all' };
export const deletedProjectOption = (value) => (
  { label: '[Deleted projects]', statusID: 'deleted', value: value || '' }
);

export const leadStage = {
  name: 'stage',
  label: 'Lead stage',
  input_type: 'dropdown',
  options: [
    { label: 'New lead', name: 'new', value: 'new' },
    { label: 'Contacted (no answer)', name: 'contacted_no_answer', value: 'contacted_no_answer' },
    { label: 'Contacted (to call back)', name: 'contacted_to_call_back', value: 'contacted_to_call_back' },
    { label: 'Qualified', name: 'qualified', value: 'qualified' },
    { label: 'Unqualified', name: 'unqualified', value: 'unqualified' },
  ],
};

export const unqualifiedReason = {
  name: 'unqualified_reason',
  label: 'Unqualified reason',
  input_type: 'dropdown',
  options: [
    { label: 'Invalid number', name: 'invalid_number', value: 'invalid_number' },
    { label: 'Unreachable', name: 'unreachable', value: 'unreachable' },
    { label: 'Wrong number', name: 'wrong_number', value: 'wrong_number' },
    { label: 'No longer interested', name: 'no_longer_interested', value: 'no_longer_interested' },
  ],
};

// Unselectable source options that generated by system
export const systemSource = [
  { label: 'Facebook', name: 'facebook', value: 'facebook' },
  { label: 'iProperty', name: 'iproperty', value: 'iproperty' },
  { label: 'PropertyGuru', name: 'propertyguru', value: 'propertyguru' },
  { label: 'Showroom', name: 'showroom', value: 'showroom' },
  { label: 'Showroom for buyer', name: 'srb', value: 'srb' },
  { label: 'SalesCandy', name: 'salescandy', value: 'sales_candy' },
];

// default source options that cannot be remove from field settings
export const source = {
  name: 'source',
  label: 'Source',
  input_type: 'dropdown',
  options: [
    { label: 'Billboard/poster', name: 'billboard_poster', value: 'billboard_poster' },
    { label: 'Event', name: 'event', value: 'event' },
    { label: 'Social media', name: 'social_media', value: 'social_media' },
    { label: 'Listing portals', name: 'listing_portals', value: 'listing_portals' },
    { label: 'Instagram', name: 'instagram', value: 'instagram' },
    { label: 'Referral', name: 'referral', value: 'referral' },
    { label: 'Walk-in', name: 'walk_in', value: 'walk_in' },
    { label: 'Website', name: 'website', value: 'website' },
    ...systemSource,
  ],
};

// default consideration_stage options
export const considerationStage = {
  name: 'consideration_stage',
  label: 'Consideration stage',
  input_type: 'dropdown',
  options: [
    {
      name: 'pending_appointment',
      label: 'Pending appointment',
      value: 'pending_appointment',
    },
    {
      name: 'pending_booking_fee',
      label: 'Pending booking fee',
      value: 'pending_booking_fee',
    },
    {
      name: 'scheduled_appointment',
      label: 'Schedule appointment',
      value: 'scheduled_appointment',
    },
    {
      name: 'just_browsing',
      label: 'Just browsing',
      value: 'just_browsing',
    },
  ],
};

// Unselectable lost reason options that generated by system
export const systemLostReason = [
  {
    label: 'Cancelled by developer',
    name: 'cancelled_by_developer',
    value: 'cancelled_by_developer',
  },
  {
    label: 'Expired',
    name: 'expired',
    value: 'expired',
  },
  {
    label: 'Removed',
    name: 'removed',
    value: 'removed',
  },
];

// default lost reasons
export const lostReason = {
  name: 'lost_reason',
  label: 'Lost reason',
  input_type: 'dropdown',
  options: [
    {
      label: 'Not interested (Pricing)',
      name: 'not_interested_pricing',
      value: 'not_interested_pricing',
    },
    {
      label: 'Not interested (Property type)',
      name: 'not_interested_property_type',
      value: 'not_interested_property_type',
    },
    {
      label: 'Not interested (Location)',
      name: 'not_interested_location',
      value: 'not_interested_location',
    },
    {
      label: 'Opted for another project',
      name: 'opted_for_another_project',
      value: 'opted_for_another_project',
    },
    ...systemLostReason,
  ],
};

// default appointment type
export const appointmentType = {
  name: 'appointment_type',
  label: 'Appointment type',
  input_type: 'dropdown',
  options: [
    { label: 'First visit to sales gallery', name: 'first_visit_to_sales_gallery', value: 'first_visit_to_sales_gallery' },
    { label: 'Unit reservation', name: 'unit_reservation', value: 'unit_reservation' },
    { label: 'SPA signing', name: 'spa_signing', value: 'spa_signing' },
    { label: 'LA signing', name: 'la_signing', value: 'la_signing' },
    { label: 'Delivery of VP', name: 'delivery_of_vp', value: 'delivery_of_vp' },
    { label: 'Others', name: 'others', value: 'others' },
  ],
};

export const getLostReasonWithDisabledOptions = ({
  disabledNames = systemLostReason.map((reason) => reason.name),
  opportunityFieldSettings,
}) => {
  const lostReasonOptions = (opportunityFieldSettings.basic
    && opportunityFieldSettings.basic.attributes
    && opportunityFieldSettings.basic.attributes.lost_reason
    && opportunityFieldSettings.basic.attributes.lost_reason.options) || [];
  const newOptions = JSON.parse(JSON.stringify(lostReasonOptions));
  if (disabledNames.length > 0) {
    newOptions.forEach((option, index) => {
      if (disabledNames.includes(option.value)) {
        newOptions[index].locked = true;
      }
    });
  }
  return newOptions;
};

export const getSourceWithDisabledOptions = (
  fields,
  disabledNames = systemSource.map((opt) => opt.value),
) => {
  if (fields.sales
    && fields.sales.attributes
    && fields.sales.attributes.source
    && fields.sales.attributes.source.options
    && Array.isArray(disabledNames)
  ) {
    const { options } = fields.sales.attributes.source;
    const newOptions = JSON.parse(JSON.stringify(options));
    disabledNames.forEach((name) => {
      const optionIdx = options.findIndex((opt) => opt.value === name);
      if (optionIdx > -1) {
        newOptions[optionIdx].disabled = true;
      }
    });
    return ({
      source: {
        ...fields.sales.attributes.source,
        options: [
          ...newOptions,
        ],
      },
    });
  }
  return {};
};

export const getProjectsWithDeletedOption = (projectOptions) => {
  let options = [];
  const categorized = _.groupBy(projectOptions, (opt) => (opt.statusID === 'deleted' ? 'deleted' : 'others'));

  if (categorized.others && !isEmptyValue(categorized.others)) {
    options = categorized.others;
  }

  if (categorized.deleted && !isEmptyValue(categorized.deleted)) {
    const deletedProjects = categorized.deleted.map((opt) => opt.value).join(',');
    options.push(deletedProjectOption(deletedProjects));
  }

  return options;
};

export const getFormFieldTypes = (type) => {
  let color;
  let icon;
  let label = formatValue(type);

  switch (type) {
    case 'header_bg':
      color = 'grey';
      icon = ImageIcon;
      break;
    case 'terms_and_conditions':
      color = 'grey';
      icon = TermsAndConditionIcon;
      label = 'Terms and condition';
      break;
    case 'checkbox':
      color = 'yellow';
      icon = CheckIcon;
      break;
    case 'select':
      color = 'green';
      icon = ArrowDownIcon;
      break;
    case 'date':
      color = 'purple';
      icon = CalendarIcon;
      break;
    case 'email':
      color = 'blue';
      icon = MailSmallIcon;
      break;
    case 'number':
      color = 'turquoise';
      icon = NumbersIcon;
      break;
    case 'radio':
      color = 'red';
      icon = RadioIcon;
      label = 'Multiple choice';
      break;
    case 'tel':
      color = 'blue';
      icon = PhoneIcon;
      break;
    case 'text':
      color = 'turquoise';
      icon = TextIcon;
      label = 'Short answer';
      break;
    case 'textarea':
      color = 'turquoise';
      icon = ParagraphIcon;
      label = 'Paragraph';
      break;
    case 'time':
      color = 'purple';
      icon = TimeIcon;
      break;
    default:
      break;
  }

  return { color, icon, label };
};

export const reservedFields = [
  'all',
  'action',
  'assignee',
  'assigned_to',
  'assigned_by',
  'assigned_at',
  'assigned_on',
  'tin',
  'tax_identification_number',
  'added_at',
  'added_on',
  'added_by',
  'appointment_date',
  'appointment_starting_at',
  'appointment_ending_at',
  'appointment_starting_on',
  'appointment_ending_on',
  'aging_days',
  'aging_days_created_at',
  'aging_days_imported_at',
  'aging_days_created_on',
  'aging_days_imported_on',
  'address',
  'api_key',
  'bumiputera',
  'buyer_access_project_ids',
  'booking_id',
  'booking_name',
  'caller',
  'call',
  'call_at',
  'call_on',
  'call_by',
  'call_from',
  'call_duration',
  'cancellation_reason_id',
  'cancellation_reason',
  'channel_id',
  'channel_name',
  'channel_type',
  'country',
  'country_code',
  'complete_mobile_info',
  'company_id',
  'company_branch_id',
  'company_name',
  'company_bussiness_unit',
  'created_at',
  'created_on',
  'created_by',
  'campaign_id',
  'campaign_ids',
  'campaign_name',
  'campaign',
  'contact_id',
  'customer',
  'customer_name',
  'customer_id',
  'customer_ids',
  'remark',
  'remarks',
  'columns',
  'duration',
  'durations',
  'deleted',
  'deleted_at',
  'deleted_on',
  'deleted_by',
  'default_form_source',
  'developer_id',
  'emergency_contacts',
  'emergency_contact_name',
  'emergency_contact_full_name',
  'emergency_contact_email',
  'emergency_contact_nric',
  'emergency_contact_mobile',
  'emergency_contact_msisdn',
  'emergency_contact_mobile_country_code',
  'emergency_contact_relation',
  'error',
  'error_message',
  'email_is_reachable',
  'form_id',
  'form_name',
  'form_url',
  'full_name',
  'fields',
  'fb_ad_id',
  'fb_ad_name',
  'fb_adset_id',
  'fb_adset_name',
  'fb_campaign_id',
  'fb_campaign_name',
  'id',
  'imported_at',
  'imported_on',
  'imported_by',
  'key',
  'lead_id',
  'lead',
  'lead_name',
  'listing_id',
  'invalid',
  'nationality',
  'nationality_country_code',
  'msisdn',
  'mobile',
  'mobile_country_code',
  'opportunity',
  'opportunities',
  'opportunity_id',
  'page',
  'patched_on',
  'patched_at',
  '_patched_at',
  'project_id',
  'project_ids',
  'project_name',
  'project_names',
  'project',
  'primary_customer_id',
  'primary_customer_name',
  'preferred_salesperson_agent',
  'preferred_assignee',
  'preferred_assign_to',
  'propertyguru_api_key',
  'source',
  'submission_source',
  'submission_name',
  'submission_id',
  'submitted_at',
  'submitted_on',
  'stage',
  'srb_id',
  'srb_username',
  'srb_company_app_client_id',
  'srb_created_at',
  'srb_created_on',
  'sc_lead_origin',
  'sc_lead_source',
  'sort',
  'status',
  'scheduled_at',
  'scheduled_on',
  'type',
  'unqualified_reason',
  'unit_name',
  'unit_id',
  'unit_number',
  'unit_size',
  'unit_spa_value',
  'unit_spa_currency',
  'unit_status_id',
  'unit_status_name',
  'unit_status_description',
  'unit_layout_type',
  'unit_created_at',
  'unit_updated_at',
  'unit_created_on',
  'unit_updated_on',
  'updated_at',
  'updated_on',
  'updated_by',
  'auto_assignment_id',
  'auto_assignment_name',
  'reassignment_id',
  'manual_reassignment_id',
  'last_updated_at',
  'last_updated_on',
  'last_updated_by',
  'user_group_id',
  'user_group_assignment_name',
  'user_group_name',
  'qualified_at',
  'qualified_on',
  'qualified_by',
  'merged_id',
  'merged_ids',
  'merged_at',
  'merged_on',
  'merged_by',
  'inbound_call',
  'inbound_call_at',
  'inbound_call_on',
  'iproperty_api_key',
];

export const reservedOptions = [
  'all',
];

export const defaultDateFormat = 'YYYY-MM-DD';

export const utmFields = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

export const utmFieldOptions = () => (
  utmFields.map((key) => ({
    label: formatValue(key),
    name: key,
  })));

export const getSubmissionLabel = (key, label) => {
  let newLabel = label || key;

  switch (key) {
    case 'created_at':
      newLabel = 'Submitted on';
      break;
    case 'campaign_id':
      newLabel = 'Campaign name';
      break;
    case 'assigned_to':
      newLabel = 'Preferred salesperson/agent';
      break;
    case 'error_message':
      newLabel = 'Error message';
      break;
    case 'project_ids':
      newLabel = 'Project name(s)';
      break;
    default:
      break;
  }

  return newLabel;
};

export const appointmentStatus = [
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Pending', value: 'pending', disabled: true },
];
