import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker as MuiTimePicker } from '@material-ui/pickers';
import {
  IconButton,
  Icon,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { TimeIcon } from '../../assets/icons';
import { previewTime } from '../../utils';
import useStyles from './styles';

export default function TimePicker(props) {
  const {
    value,
    name, label,
    disableClearable,
    required, error,
    onChange, onClear,
    ...rest
  } = props;

  const classes = useStyles();

  const handleOnChangeTime = (v) => {
    onChange(v);
  };

  const handleOnClear = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClear(e);
  };

  const renderLabel = () => {
    if (value) {
      return previewTime(value);
    }
    return 'Select';
  };


  return (
    <div key={name}>
      <MuiTimePicker
        fullWidth
        variant="inline"
        inputVariant="outlined"
        label={label}
        labelFunc={() => renderLabel()}
        required={required}
        error={!!error}
        helperText={error}
        value={value}
        onChange={handleOnChangeTime}
        InputProps={{
          className: `${classes.input} ${value ? null : classes.placeholder}`,
          endAdornment: (
            <>
              {
                !disableClearable && (
                  <IconButton
                    className={`${classes.clearButton} ${value ? classes.visibleButton : null}`}
                    onClick={handleOnClear}
                  >
                    <Icons.CloseOutlined fontSize="small" />
                  </IconButton>
                )
              }
              <Icon className={classes.icon}>
                <img alt="time" src={TimeIcon} />
              </Icon>
            </>
          ),
        }}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
        PopoverProps={{
          PaperProps: {
            className: classes.timePickerPopover,
          },
        }}
        {...rest}
      />
    </div>
  );
}

TimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disableClearable: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

TimePicker.defaultProps = {
  name: '',
  label: '',
  error: '',
  required: false,
  disableClearable: false,
  onChange: () => {},
  onClear: () => {},
  value: null,
};
