import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export default makeStyles((theme) => ({
  icon: {
    '&.small': {
      width: '1.7rem',
      height: '1.7rem',
    },
  },
  button: {
    width: '2.86rem',
    height: '2.86rem',
    '& img': {
      filter: filters.font.body,
    },
    '& svg': {
      fill: theme.palette.font.body,
    },
    '&.outlined': {
      border: `1px solid ${theme.palette.tertiary.grey}`,
      '&:disabled': {
        backgroundColor: theme.palette.tertiary.mediumGrey,
        borderColor: theme.palette.tertiary.mediumGrey,
        '& img': {
          filter: filters.tertiary.grey,
        },
        '& svg': {
          fill: theme.palette.tertiary.grey,
        },
      },
    },
    '&.standard': {
      '&:disabled': {
        '& img': {
          filter: filters.tertiary.grey,
        },
        '& svg': {
          fill: theme.palette.tertiary.grey,
        },
      },
      '&:not(:disabled).active img, &:not(:disabled):hover.inactive img': {
        filter: filters.tertiary.space,
      },
      '&:not(:disabled).active svg, &:not(:disabled):hover.inactive svg': {
        fill: theme.palette.tertiary.space,
      },
      '&:not(:disabled).inactive img': {
        filter: filters.font.subBody,
      },
      '&:not(:disabled).inactive svg': {
        fill: theme.palette.font.subBody,
      },
    },
  },
  badge: {
    '& .MuiBadge-colorError': {
      top: '15%',
      right: '3px',
    },
  },
}));
