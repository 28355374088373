import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import {
  withClientContext,
  AuthContext,
  PageContext,
} from '../../contexts';
import LoadingContent from '../loader/LoadingContent';
import {
  formatValue,
  getAppointmentFieldLabel,
  getCampaignName,
  getCountryLabel,
  getFieldLabel,
  getLabelFromList,
  isEmptyValue,
  isJSON,
  previewDate,
  previewDateTime,
  previewTime,
  toCapitalize,
  toTitleCase,
} from '../../utils';
import { leadStage } from '../../data/settings';
import { omitQualifiedItems, omittedLogItems, omittedLogItemsWithoutMerged } from '../../data/activityLogs';
import useStyles from './styles';

const ActivityLog = (props) => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const { users, listCompanyBranches } = authContext.state;
  const pageContext = useContext(PageContext);
  const {
    campaigns,
    projects,
    opportunityFieldSettings,
    cancellationReasonOptions,
  } = pageContext.state;

  const [fieldsWithOptions, setFieldsWithOptions] = useState([]);

  const {
    itemName,
    isLoading,
    formLoading,
    activities,
    fields,
    userGroupList,
    formList,
    infoMappings,
  } = props;

  useEffect(() => {
    if (fields) {
      const dropdowns = [];
      Object.keys(fields).forEach((name) => {
        if (['dropdown', 'multiselections'].includes(fields[name].input_type)) {
          dropdowns.push({
            name,
            options: fields[name].options || [],
          });
        }
      });
      setFieldsWithOptions(dropdowns);
    }
  }, [fields]);

  const getUserName = (id, isActor) => {
    const user = users[id];
    if (user) {
      if (user.name) {
        return user.name;
      }
      if (user.preferred_name) {
        return user.preferred_name;
      }
    }
    if (id && isActor) {
      return 'SRB User';
    }
    return id;
  };

  const renderFieldLabel = (k, type) => {
    if (type === 'appointment') {
      return getAppointmentFieldLabel(k);
    }
    if (!(fields && fields[k] && fields[k].label)) {
      return getFieldLabel(k);
    }
    return fields[k].label;
  };

  const getBusinessUnitName = (id) => {
    let name = id || '';
    if (id && listCompanyBranches[id] && listCompanyBranches[id].name) {
      name = listCompanyBranches[id].name;
    }
    return name;
  };


  const getProjectLabel = (ids) => {
    if (Array.isArray(ids) && ids.length > 0) {
      const projectNames = ids.map((id) => (
        (projects[id] && projects[id].name) || id
      ));
      return projectNames.join(' , ');
    }
    if (projects[ids] && projects[ids].name) {
      return projects[ids].name;
    }
    return ids;
  };

  const formatStageLabel = (value) => {
    const currentStageInfo = leadStage.options.find((opt) => (opt.value === value));
    if (value === 'new') {
      return 'New';
    }
    return (currentStageInfo && currentStageInfo.label) || formatValue(value);
  };

  const formatFieldValue = (value, name) => {
    // To support list value format coming from submission
    // Convert into string with seperated comma
    if (isJSON(value)) {
      let formatted = JSON.parse(value);
      if (Array.isArray(formatted) && formatted.length) {
        formatted = formatted.map((f) => (f.name));
        return formatValue(formatted.toString());
      }
    }
    // Get dropdown fields options label from settings
    const fieldIndex = fieldsWithOptions.findIndex((field) => field.name === name);
    if (fieldIndex && fieldIndex > -1) {
      return getLabelFromList(value, fieldsWithOptions[fieldIndex].options);
    }
    return value || '';
  };

  const renderAppointmentTime = (values) => {
    if ((values.starting_at && values.ending_at)) {
      return `${previewTime(values.starting_at)} - ${previewTime(values.ending_at)}`;
    }
    return '';
  };

  const renderAppointmentValue = (name, value) => {
    switch (name) {
      case 'type':
        return getLabelFromList(
          value,
          (opportunityFieldSettings
            && opportunityFieldSettings.basic
            && opportunityFieldSettings.basic.attributes
            && opportunityFieldSettings.basic.attributes.appointment_type
            && opportunityFieldSettings.basic.attributes.appointment_type.options) || [],
        );
      case 'date':
        return previewDate(value);
      default:
        return '';
    }
  };

  const renderCreateDeleteAppointmentContent = (values) => {
    if (values && !isEmptyValue(values)) {
      return `${renderAppointmentValue('type', values.type)}, ${renderAppointmentValue('date', values.starting_at)}, ${renderAppointmentTime(values)}`;
    }
    return 'No values';
  };

  const renderFieldValue = (name, value, values) => {
    switch (name) {
      case 'name':
        return toCapitalize(value);
      case 'country_code':
      case 'nationality_country_code':
        return getCountryLabel(value);
      case 'stage':
        return formatStageLabel(value);
      case 'lost_reason':
        return getLabelFromList(
          value,
          (opportunityFieldSettings.basic
          && opportunityFieldSettings.basic.attributes
          && opportunityFieldSettings.basic.attributes.lost_reason
          && opportunityFieldSettings.basic.attributes.lost_reason.options) || [],
        );
      case 'cancellation_reason_id':
        return getLabelFromList(
          value,
          cancellationReasonOptions || [],
        );
      case 'campaign_id':
        return getCampaignName({ campaign_id: value }, campaigns);
      case 'project_ids':
        return getProjectLabel(value);
      case 'assigned_to':
      case 'imported_by':
      case 'qualified_by':
        return getUserName(value);
      case 'customer_ids':
        return (infoMappings && infoMappings[value] && infoMappings[value].name) || value;
      case 'channel_id':
        return values.channel_name || value;
      case 'company_branch_id':
        return getBusinessUnitName(values[name]);
      case 'qualified_at':
      case 'starting_at':
      case 'ending_at':
        return previewDateTime(values[name]);
      default:
        return formatFieldValue(value, name);
    }
  };

  const formatGroupedDate = (ts) => moment(ts).calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'ddd, MMM DD YYYY',
  });

  const formatDate = (ts) => moment(ts).format('h:mm a');

  const renderItemFormat = (value, idx) => {
    if (typeof value === 'object' && value !== null) {
      const list = Object.keys(value);
      if (list.length > 0) {
        return (
          <Fragment key={idx}>
            {
              list
                .filter((name) => value[name] !== null)
                .map((name) => (
                  <li key={name}>
                    <Typography variant="subtitle2">
                      {`${formatValue(name)}: ${value[name]}`}
                    </Typography>
                  </li>
                ))
            }
          </Fragment>
        );
      }
    }
    return '';
  };

  // Supports string, array of string, array of object, number, and boolean
  const renderItem = (key, value, values) => {
    if (Array.isArray(value)) {
      if (_.every(value, _.isString)) {
        return (
          <Typography variant="subtitle2">
            { value.map((v) => renderFieldValue(key, v)).join(', ') }
          </Typography>
        );
      }

      if (_.every(value, _.isObject)) {
        return (
          <ul className={classes.list}>
            {
              value.map((v, idx) => (
                renderItemFormat(v, idx)
              ))
            }
          </ul>
        );
      }
    }
    if (['boolean', 'number'].includes(typeof value)) {
      return (
        <Typography variant="subtitle2">{ value }</Typography>
      );
    }
    if (typeof value === 'string') {
      return (
        <Typography variant="subtitle2">{renderFieldValue(key, value, values)}</Typography>
      );
    }
    return '';
  };

  const renderFieldDeletion = (key, oldValues, type) => (
    <>
      { renderFieldLabel(key, type) }
      &nbsp;
      { renderItem(key, oldValues[key], oldValues) }
      &nbsp;has been removed
    </>
  );

  const renderAssigneeContent = (key, oldValues, newValues) => (
    <>
      { !oldValues || !oldValues[key] ? renderItem(key, 'Unassigned') : renderItem(key, oldValues[key], oldValues) }
      &nbsp;updated to&nbsp;
      { renderItem(key, newValues[key], newValues) }
    </>
  );

  const renderCustomerList = (items) => {
    if (Array.isArray(items)) {
      return (
        <Typography variant="subtitle2">
          {items.length < 1 ? (items.map((v) => toCapitalize(v.name) || v.id))
            : (items.map((v) => toCapitalize(v.name) || v.id).join(', ')) }
        </Typography>
      );
    }
    const firstItem = items[0];
    return (
      <Typography variant="subtitle2">
        {toCapitalize((firstItem && firstItem.name) || items.name)
        || (firstItem && firstItem.id) || items.id}
      </Typography>
    );
  };

  const renderCustomerContent = (key, oldValues, newValues) => {
    if (oldValues && oldValues[key]) {
      if (!newValues || isEmptyValue(newValues[key])) {
        return (
          <>
            { renderFieldLabel(key) }
            &nbsp;
            { renderCustomerList(oldValues[key]) }
            &nbsp;has been removed
          </>
        );
      }
      return (
        <>
          { renderCustomerList(oldValues[key]) }
          &nbsp;updated to&nbsp;
          { renderCustomerList(newValues[key]) }
        </>
      );
    }
    return (
      <>
        updated to&nbsp;
        { renderCustomerList(newValues[key]) }
      </>
    );
  };

  const renderUpdateOpportunityContent = (key, activity) => {
    if (key === 'primary_customer') {
      return renderCustomerContent(key, activity.old_values, activity.new_values);
    }
    if (key === 'assigned_to') {
      return renderAssigneeContent(key, activity.old_values, activity.new_values);
    }
    if (activity.old_values && activity.old_values[key]) {
      if (isEmptyValue(activity.new_values[key])) {
        return (
          <>
            &nbsp;
            { renderItem(key, activity.old_values[key], activity.old_values) }
            &nbsp;has been removed
          </>
        );
      }
      return (
        <>
          { renderItem(key, activity.old_values[key], activity.old_values) }
          &nbsp;updated to&nbsp;
          { renderItem(key, activity.new_values[key], activity.new_values) }
        </>
      );
    }
    return (
      <>
        updated to&nbsp;
        { renderItem(key, activity.new_values[key], activity.new_values)}
      </>
    );
  };

  const renderUpdateOpportunity = (activity) => {
    // Field deletion
    if (!activity.new_values) {
      const newKeys = Object.keys(activity.old_values)
        .filter((key) => !omittedLogItems.includes(key));
      return (
        <>
          <Typography variant="subtitle2" id="action">
            Opportunity edited
          </Typography>
          <div className={classes.changes}>
            {
              newKeys.map((key) => (
                <div key={key}>
                  {
                    (key === 'primary_customer')
                      ? renderCustomerContent(key, activity.old_values)
                      : renderFieldDeletion(key, activity.old_values)
                  }
                </div>
              ))
            }
          </div>
        </>
      );
    }

    // For comparasion between old and new to get deleted fields
    const combineValues = { ...activity.old_values, ...activity.new_values };
    const filterOmittedLog = Object
      .keys(combineValues)
      .filter((name) => !omittedLogItems.includes(name));
    if (filterOmittedLog.length > 0) {
      return (
        <>
          <Typography variant="subtitle2" id="action">
            Opportunity edited
          </Typography>
          <div className={classes.changes}>
            {
              filterOmittedLog.map((k) => (
                <div key={k}>
                  { renderFieldLabel(k) }
                  &nbsp;
                  { renderUpdateOpportunityContent(k, activity) }
                </div>
              ))
            }
          </div>
        </>
      );
    }
    return null;
  };

  const renderEditContent = (key, oldValues, newValues, type) => {
    const hasNewAppointmentStartEnd = (
      type === 'appointment'
      && newValues
      && oldValues
      && Object.prototype.hasOwnProperty.call(newValues, 'starting_at')
      && Object.prototype.hasOwnProperty.call(newValues, 'ending_at')
      && Object.prototype.hasOwnProperty.call(oldValues, 'starting_at')
      && Object.prototype.hasOwnProperty.call(oldValues, 'ending_at')
    );

    if (hasNewAppointmentStartEnd) {
      return (
        <>
          { renderFieldLabel('date_time', type) }
          &nbsp;
          <Typography variant="subtitle2">
            { `${renderAppointmentValue('date', oldValues.starting_at)}, ${renderAppointmentTime(oldValues)}` }
          </Typography>
          &nbsp;updated to&nbsp;
          <Typography variant="subtitle2">
            { `${renderAppointmentValue('date', newValues.starting_at)}, ${renderAppointmentTime(newValues)}` }
          </Typography>
        </>
      );
    }
    if (key === 'assigned_to') {
      return (
        <>
          { renderFieldLabel(key) }
          &nbsp;
          { renderAssigneeContent(key, oldValues, newValues) }
        </>
      );
    }
    if (oldValues && oldValues[key]) {
      // To detect field deletion when have other actions made together
      if (isEmptyValue(newValues[key])) {
        return renderFieldDeletion(key, oldValues, type);
      }
      return (
        <>
          { renderFieldLabel(key, type) }
          &nbsp;
          { renderItem(key, oldValues[key], oldValues) }
          &nbsp;updated to&nbsp;
          { renderItem(key, newValues[key], newValues) }
        </>
      );
    }
    return (
      <>
        { renderFieldLabel(key, type) }
        &nbsp;updated to&nbsp;
        { renderItem(key, newValues[key], newValues) }
      </>
    );
  };

  const renderNewCreationLog = (activity, isFromQualified) => {
    const { new_values: newValues, type, object } = activity;
    let title = 'New';
    let changes = renderFieldValue('name', (newValues && newValues.name) || itemName);

    switch (type) {
      case 'import':
        title = `New imported ${object.type}`;
        break;
      case 'add':
      case 'create':
        if (isFromQualified) {
          title = 'Qualified lead';
        } else if (newValues && newValues.inbound_call_at) {
          title = `New inbound ${object.type}`;
        } else {
          title = `New ${object.type}`;
        }
        changes = activity.new_values && !activity.new_values.inbound_call_at ? changes : '';
        break;
      default:
        break;
    }

    return (
      <>
        <Typography variant="subtitle2" id="action">
          { title }
        </Typography>
        <div className={classes.changes}>
          <Typography variant="body2">
            { changes }
          </Typography>
        </div>
      </>
    );
  };

  const renderActivity = (activity) => {
    const { new_values: newValues, old_values: oldValues } = activity;

    // Qualified lead into the customer
    const isQualifiedCustomer = (
      activity.object
      && activity.object.type === 'customer'
      && newValues
      && (
        Object.prototype.hasOwnProperty.call(newValues, 'qualified_at')
        || Object.prototype.hasOwnProperty.call(newValues, 'merged_at')
      )
    );

    switch (activity.type) {
      case 'import':
        switch (activity.object.type) {
          case 'customer':
          case 'lead':
            return renderNewCreationLog(activity);
          default:
        }
        break;
      case 'add':
      case 'create':
        switch (activity.object.type) {
          case 'customer':
          case 'lead':
            return renderNewCreationLog(activity, isQualifiedCustomer);
          case 'opportunity':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  Opportunity added
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    { activity.object.name || 'No project added' }
                  </Typography>
                </div>
              </>
            );
          case 'appointment':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  Appointment added
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    { renderCreateDeleteAppointmentContent(activity.new_values || {}) }
                  </Typography>
                </div>
              </>
            );
          case 'note':
            return (
              <>
                <Typography variant="subtitle2">
                  New note
                </Typography>
              </>
            );

          case 'booking':
            return (
              <>
                <Typography variant="subtitle2">
                  New SRB booking
                </Typography>
              </>
            );
          default:
        }
        break;
      case 'edit':
      case 'update':
        switch (activity.object.type) {
          case 'note':
            return (
              <Typography variant="subtitle2">
                {`${toTitleCase(activity.object.type)} edited`}
              </Typography>
            );
          case 'opportunity':
            return renderUpdateOpportunity(activity);
          case 'customer':
          case 'lead':
          case 'appointment':
            switch (true) {
              // To detect only field deletion action (dont have new value)
              case !newValues: {
                const newKeys = Object.keys(oldValues)
                  .filter((key) => !omittedLogItems.includes(key));
                return (
                  <>
                    <Typography variant="subtitle2" id="action">
                      {`${toTitleCase(activity.object.type)} edited`}
                    </Typography>
                    <div className={classes.changes}>
                      {
                        newKeys.map((key) => (
                          <div key={key}>
                            { renderFieldDeletion(key, oldValues, activity.object.type) }
                          </div>
                        ))
                      }
                    </div>
                  </>
                );
              }
              case isQualifiedCustomer:
                return (
                  <Typography variant="subtitle2">
                    Merged lead to existing customer
                  </Typography>
                );
              case Object.prototype.hasOwnProperty.call(newValues, 'srb_id'):
                return (
                  <Typography variant="subtitle2">
                    {`New SRB registration - ${toCapitalize(itemName)}`}
                  </Typography>
                );
              default: {
                const filterOutNullValues = (values, oppositeValues) => (
                  values
                  && Object.keys(values).reduce((accumulator, key) => {
                    if ((values[key]) || (!values[key] && oppositeValues && oppositeValues[key])) {
                      accumulator[key] = values[key];
                    }
                    return accumulator;
                  }, {})
                );
                  // Filter out new fields that added in with null values but without old values
                const filteredNullNewValues = filterOutNullValues(newValues, oldValues);
                // Filter out old fields that added in with null values but got remove afterwards
                const filteredNullOldValues = filterOutNullValues(oldValues, newValues);
                // For comparasion between old and new to get deleted fields
                const combineValues = { ...filteredNullOldValues, ...filteredNullNewValues };
                const isMerged = filteredNullNewValues.merged_at;
                let filterOmittedLog = Object
                  .keys(combineValues)
                  .filter((name) => !omittedLogItems.includes(name));

                filterOmittedLog = omitQualifiedItems(filteredNullNewValues, filterOmittedLog);

                const hasNewAppointmentStartEnd = (
                  activity.object.type === 'appointment'
                  && newValues
                  && oldValues
                  && Object.prototype.hasOwnProperty.call(newValues, 'starting_at')
                  && Object.prototype.hasOwnProperty.call(newValues, 'ending_at')
                  && Object.prototype.hasOwnProperty.call(oldValues, 'starting_at')
                  && Object.prototype.hasOwnProperty.call(oldValues, 'ending_at')
                );

                //
                if (hasNewAppointmentStartEnd) {
                  filterOmittedLog = filterOmittedLog.filter((name) => name !== 'ending_at');
                }

                if (filterOmittedLog.length > 0) {
                  return (
                    <>
                      <Typography variant="subtitle2" id="action">
                        {toTitleCase(activity.object.type)}
                        &nbsp;
                        {isMerged ? 'merged' : 'edited'}
                      </Typography>
                      {
                        !isMerged && (
                          <div className={classes.changes}>
                            {
                              filterOmittedLog.map((k) => (
                                <div key={k}>
                                  {
                                    renderEditContent(
                                      k,
                                      filteredNullOldValues,
                                      filteredNullNewValues,
                                      activity.object.type,
                                    )
                                  }
                                </div>
                              ))
                            }
                          </div>
                        )
                        }
                    </>
                  );
                }
                if (isMerged) {
                  return (
                    <Typography variant="subtitle2">
                      { activity.object.type === 'lead' ? 'Lead' : 'Customer' }
                      &nbsp;merged
                    </Typography>
                  );
                }
                return null;
              }
            }
          default:
        }
        break;
      case 'remove':
      case 'delete':
        switch (activity.object.type) {
          case 'note':
            return (
              <Typography variant="subtitle2">
                {`${toTitleCase(activity.object.type)} deleted`}
              </Typography>
            );
          case 'appointment':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  {`${toTitleCase(activity.object.type)} deleted`}
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    {
                      renderCreateDeleteAppointmentContent(
                        (activity.object && activity.object.appointment)
                        || {},
                      )
                    }
                  </Typography>
                </div>
              </>
            );
          default:
        }
        break;
      case 'outbound':
        switch (activity.object.type) {
          case 'phone':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  Call clicked
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    { activity.object.phone }
                  </Typography>
                </div>
              </>
            );
          case 'whatsapp':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  Whatsapp clicked
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    { activity.object.phone }
                  </Typography>
                </div>
              </>
            );
          case 'email':
            return (
              <>
                <Typography variant="subtitle2" id="action">
                  Email clicked
                </Typography>
                <div className={classes.changes}>
                  <Typography variant="body2">
                    { activity.object.email }
                  </Typography>
                </div>
              </>
            );
          default:
        }
        break;
      case 'remind':
        return (
          <>
            <Typography variant="subtitle2" id="action">
              Automated reminder
            </Typography>
            <div className={classes.changes}>
              <Typography variant="body2">
                Notified assignee and recipients via email due to inactivity
              </Typography>
            </div>
          </>
        );
      default:
        return (
          <Typography variant="subtitle2">{ toCapitalize(itemName) }</Typography>
        );
    }
    return null;
  };

  const renderContent = (activity) => (
    activity.object && activity.object.content
      ? (
        <Paper>
          <Typography variant="body2">
            { activity.object.content }
          </Typography>
        </Paper>
      )
      : null
  );

  const renderActor = (activity) => {
    if (activity) {
      if (activity.actor && activity.actor.id) {
        return <Typography variant="caption">{ getUserName(activity.actor.id) }</Typography>;
      }
      // From workflow reminder
      if (activity.type === 'remind') {
        return <Typography variant="caption">Workflow automation</Typography>;
      }
      // From user group or form assignee
      if (activity.new_values && (
        Object.prototype.hasOwnProperty.call(activity.new_values, 'user_group_id')
          || Object.prototype.hasOwnProperty.call(activity.new_values, 'form_id')
      )) {
        let label = 'System';

        if (activity.new_values.user_group_id || activity.new_values.form_id) {
          if (activity.new_values.user_group_id
            && Object.prototype.hasOwnProperty.call(activity.new_values, 'user_group_assignment_name')) {
            label = `System (${activity.new_values.user_group_assignment_name || 'n/a'})(Deleted)`;

            // Get updated user group name if have
            const { user_group_id: userGroupID } = activity.new_values;
            if (userGroupList && userGroupList.length > 0) {
              const userGroupIndex = userGroupList.findIndex((group) => group.id === userGroupID);
              if (userGroupIndex > -1 && userGroupList[userGroupIndex].assignment_name) {
                label = `System (${userGroupList[userGroupIndex].assignment_name})`;
              }
            }
          }

          if (activity.new_values.form_id
            && Object.prototype.hasOwnProperty.call(activity.new_values, 'form_name')) {
            if (formLoading) {
              label = 'Loading...';
            } else {
              label = `System (${activity.new_values.form_name || 'n/a'})(Deleted)`;
              // Get updated form name if have
              const { form_id: formID } = activity.new_values;
              if (formList && formList.length > 0) {
                const index = formList.findIndex((form) => form.id === formID);
                if (index > -1) {
                  const { name } = formList[index];
                  label = `System (${name || formID})`;
                }
              }
            }
          }
        } else {
          // Handles weird scenario where there's assignee fields from external factor
          // but no values
          label = 'System (n/a)';
        }

        return <Typography variant="caption">{label}</Typography>;
      }
    }

    return null;
  };

  // Get activity log that filtered out empty log after remove omitted field
  // Also remove old field that added in as null but remove afterwards
  // Also remove new field that added in as null
  const haveValues = (values, oppositeValues) => (
    values
    && Object.keys(values).filter((name) => (
      !omittedLogItemsWithoutMerged.includes(name)
      && !(!values[name] && !(oppositeValues && oppositeValues[name]))
    )).length > 0
  );
  const fieldWithChanges = [
    'edit',
    'update',
  ];
  const filterEmptyLog = activities && activities.filter((a) => (
    (fieldWithChanges.includes(a.type) && ((a.object && a.object.type && a.object.type === 'note') || haveValues(a.new_values, a.old_values) || haveValues(a.old_values, a.new_values)))
    || !fieldWithChanges.includes(a.type)
  ));
  const grouped = _.groupBy(filterEmptyLog, (a) => formatGroupedDate(a.created_at));

  return (
    <div>
      <LoadingContent isLoading={isLoading} />
      {
        Object.keys(grouped).map((key) => (
          <div key={key} className={classes.log}>
            <div className={classes.date}>
              <Chip label={key} />
            </div>
            <div className={classes.events}>
              {
                grouped[key].map((activity) => (
                  <div className={classes.event} key={activity.id}>
                    <div className={classes.summary}>
                      { renderActivity(activity) }
                    </div>
                    <div className={classes.content}>
                      { renderContent(activity) }
                    </div>
                    <div className={classes.info}>
                      <Typography variant="caption">{ formatDate(activity.created_at) }</Typography>
                      { renderActor(activity) }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

ActivityLog.propTypes = {
  itemName: PropTypes.string,
  isLoading: PropTypes.bool,
  formLoading: PropTypes.bool,
  activities: PropTypes.instanceOf(Array),
  infoMappings: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Object),
  userGroupList: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  formList: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

ActivityLog.defaultProps = {
  itemName: '',
  isLoading: false,
  formLoading: false,
  activities: [],
  fields: {},
  userGroupList: [],
  formList: [],
  infoMappings: {},
};

export default withClientContext('clientCustomer')(ActivityLog);
