import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getForm',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...FormFields}',
    fragments: [Fragments.form],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listForms',
    args: [
      { name: 'fields', type: '[String]' },
      { name: 'filters', type: '[Filter]' },
      { name: 'sorts', type: '[Sort]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
      { name: 'name_only', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'fields', value: 'fields' },
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
      { name: 'name_only', value: 'name_only' },
    ],
    fields: '{...ListFormResponseFields}',
    fragments: [Fragments.listFormResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST_FACEBOOK_FORMS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listFacebookForms',
    args: [
      { name: 'page_id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'page_id', value: 'page_id' },
    ],
    fields: '{...ListFacebookFormsResponseFields}',
    fragments: [Fragments.listFacebookFormsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
