import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    height: '60%',
  },
  buttons: {
    marginTop: '1rem',
  },
  bodyText: {
    textAlign: 'center',
  },
}));
