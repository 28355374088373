import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';
import * as NoteFragments from '../Note/fragments';

// Lead GraphQL Mutations.

// CREATE create lead GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createLead',
    args: [
      { name: 'content', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'content', value: 'content' },
    ],
    fields: '{...CreateLeadResponseFields}',
    fragments: [Fragments.createLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update lead GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateLead',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'fields', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'fields', value: 'fields' },
    ],
    fields: '{...UpdateLeadResponseFields}',
    fragments: [Fragments.updateLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE delete lead GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteLead',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...DeleteLeadResponseFields}',
    fragments: [Fragments.deleteLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// ASSIGN assign lead GraphQL schema.
export const ASSIGN = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'assignLead',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'assigned_to', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'assigned_to', value: 'assigned_to' },
    ],
    fields: '{...AssignLeadResponseFields}',
    fragments: [Fragments.assignLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UNASSIGN unassign lead GraphQL schema.
export const UNASSIGN = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'unassignLead',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'assigned_to', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'assigned_to', value: 'assigned_to' },
    ],
    fields: '{...UnassignLeadResponseFields}',
    fragments: [Fragments.unassignLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPLOAD upload lead GraphQL schema.
export const UPLOAD = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'uploadLeads',
    args: [
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...UploadLeadsResponseFields}',
    fragments: [Fragments.uploadLeadsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};


// CONVERT convert lead to contact GraphQL schema.
export const CONVERT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'convertLead',
    args: [
      { name: 'lead_id', type: 'String', required: true },
      { name: 'customer_id', type: 'String' },
      { name: 'consideration_stage', type: 'String' },
      { name: 'stage', type: 'String' },
      { name: 'lost_reason', type: 'String' },
      { name: 'project_id', type: 'String' },
      { name: 'project_name', type: 'String' },
      { name: 'unit_id', type: 'String' },
      { name: 'unit_number', type: 'String' },
      { name: 'unit_size', type: 'String' },
      { name: 'unit_spa_value', type: 'String' },
      { name: 'unit_spa_currency', type: 'String' },
      { name: 'unit_status_name', type: 'String' },
      { name: 'unit_status_description', type: 'String' },
      { name: 'unit_layout_type', type: 'String' },
      { name: 'unit_created_at', type: 'String' },
      { name: 'unit_updated_at', type: 'String' },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'customer_id', value: 'customer_id' },
      { name: 'consideration_stage', value: 'consideration_stage' },
      { name: 'stage', value: 'stage' },
      { name: 'lost_reason', value: 'lost_reason' },
      { name: 'project_id', value: 'project_id' },
      { name: 'project_name', value: 'project_name' },
      { name: 'unit_id', value: 'unit_id' },
      { name: 'unit_number', value: 'unit_number' },
      { name: 'unit_size', value: 'unit_size' },
      { name: 'unit_spa_value', value: 'unit_spa_value' },
      { name: 'unit_spa_currency', value: 'unit_spa_currency' },
      { name: 'unit_status_name', value: 'unit_status_name' },
      { name: 'unit_status_description', value: 'unit_status_description' },
      { name: 'unit_layout_type', value: 'unit_layout_type' },
      { name: 'unit_created_at', value: 'unit_created_at' },
      { name: 'unit_updated_at', value: 'unit_updated_at' },
    ],
    fields: '{...ConvertLeadResponseFields}',
    fragments: [Fragments.convertLeadResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE_NOTE create note GraphQL schema.
export const CREATE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createNote',
    args: [
      { name: 'lead_id', type: 'String', required: true },
      { name: 'message', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'message', value: 'message' },
    ],
    fields: '{...NoteFields, lead_id}',
    fragments: [NoteFragments.note],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_NOTE update note GraphQL schema.
export const UPDATE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateNote',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'message', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'message', value: 'message' },
    ],
    fields: '{...NoteFields, lead_id}',
    fragments: [NoteFragments.note],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_NOTE delete note GraphQL schema.
export const DELETE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteNote',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...NoteFields, lead_id}',
    fragments: [NoteFragments.note],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// MERGE merge duplicate leads GraphQL schema.
export const MERGE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'mergeLeads',
    args: [
      { name: 'lead_id', type: 'String', required: true },
      { name: 'duplicate_lead_ids', type: '[String]' },
      { name: 'fields', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'duplicate_lead_ids', value: 'duplicate_lead_ids' },
      { name: 'fields', value: 'fields' },
    ],
    fields: '{...MergeLeadsResponseFields}',
    fragments: [Fragments.mergeLeadsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
