import { makeStyles } from '@material-ui/core/styles';
import { filters, fonts } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  root: {
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.5rem',
      '& > div': {
        flexGrow: 1,
      },
      '&.expand': {
        padding: '0 0.5rem',
      },
    },
    position: 'fixed',
    padding: '0.5rem 2rem',
    border: 0,
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
  },
  controls: {
    marginBottom: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  date: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint,
  },
  documentTitle: {
    textAlign: 'center',
    fontFamily: fonts.titleFont,
  },
  ul: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    fontFamily: fonts.titleFont,
    '& > li:not(:first-child)': {
      fontSize: '1.1rem',
      marginLeft: '1.5rem',
      '& > a': {
        textDecoration: 'none',
        color: theme.palette.font.body,
        '&:hover, &.focus': {
          color: theme.palette.primary.main,
        },
      },
      '&:not($moreApps) > a:hover, & > a.underline': {
        paddingBottom: '4px',
        borderBottom: `1.3px solid ${theme.palette.primary.main}`,
      },
    },
  },
  trailTextContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  trialText: {
    fontSize: '0.72rem',
    fontFamily: fonts.bodyFont,
    fontStyle: 'italic',
    color: theme.palette.font.subBody,
    marginLeft: '0.57rem',
  },
  betaTextContainer: {
    ...fonts.overline,
    fontFamily: fonts.bodyFont,
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '0.5rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '2px 6px',
    borderRadius: '10px',
  },
  logo: {
    display: 'flex',
    borderRight: `1px solid ${theme.palette.tertiary.grey}`,
    paddingRight: '1rem',
  },
  smallIcon: {
    fontSize: '0.94rem',
  },
  subFontColor: {
    color: theme.palette.font.subBody,
  },
  header: {
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
  },
  menu: {
    width: '200px',
    padding: 0,
    '& li': {
      height: '50px',
    },
  },
  menuIcon: {
    minWidth: '2.2rem',
    lineHeight: '0',
    '& img': {
      filter: filters.font.body,
    },
  },
  navigationFont: {
    fontFamily: fonts.titleFont,
  },
  notifications: {
    width: '25rem',
    maxHeight: '35.7rem',
    '& li': {
      padding: '0.5rem 1rem',
    },
  },
  appsDropdownContainer: {
    '& .MuiInput-underline::before, & .MuiInput-underline::after': {
      borderBottom: 'none',
    },
  },
  appsDropdown: {
    '& > div': {
      paddingLeft: '1.7rem',
    },
    '& div > p': {
      color: theme.palette.primary.main,
      fontSize: '1.125em',
      fontWeight: fonts.semiBold,
      fontFamily: fonts.titleFont,
    },
    '& svg': {
      margin: '0 -1rem',
    },
  },
  moreApps: {
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      '&:not:hover > svg': {
        filter: 'invert(0.4)',
      },
    },
  },
  navigationsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:second-child)': {
      padding: '0 12px',
    },
    '& > button': {
      marginLeft: '0.5rem',
    },
  },
  searchListTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 1.2rem',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
    backgroundColor: theme.palette.common.white,
    '& > span': {
      lineHeight: '20px',
      fontWeight: fonts.bold,
      color: theme.palette.font.subBody,
    },
  },
  searchButton: {
    padding: '0.5rem',
  },
  icon: {
    width: '1.2rem',
    filter: filters.font.body,
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '1.5rem',
    },
  },
  searchListIcon: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  backIcon: {
    width: '1.5rem',
    filter: filters.font.body,
    marginRight: '0.5rem',
  },
  mobileGlobalSearch: {
    alignItems: 'center',
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  mobileNavs: {
    display: 'flex',
  },
  option: {
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  },
  backButton: {
    padding: '0 0.5rem 0 0',
  },
  rightIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  userInfo: {
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
  },
  value: {
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
  },
  contactLabel: {
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
  },
  oldChat: {
    height: '1rem',
    ...fonts.caption,
    fontWeight: fonts.semiBold,
    padding: '0.5rem 0',
    color: theme.palette.common.white,
    background: theme.palette.tertiary.red,
    alignSelf: 'center',
    marginLeft: '0.2rem',
    '& span': {
      padding: '0 10px',
    },
  },
}));
