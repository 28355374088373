function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgUpload = function SvgUpload(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Assets",
    width: 32,
    height: 32,
    viewBox: "0 0 32 32",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("g", null, React.createElement("path", {
    d: "M25.5,9.765A9.666,9.666,0,0,0,6.545,9.759a6.766,6.766,0,0,0,.779,13.488h4.833a.967.967,0,1,0,0-1.933H7.324a4.833,4.833,0,0,1,.057-9.666.967.967,0,0,0,.961-.86,7.733,7.733,0,0,1,15.363,0,1.025,1.025,0,0,0,1.018.86,4.833,4.833,0,0,1,0,9.666H19.89a.967.967,0,1,0,0,1.933h4.833A6.766,6.766,0,0,0,25.5,9.765Z",
    style: {
      fill: "#49b3b1"
    }
  }), React.createElement("path", {
    d: "M19.441,19.332a.966.966,0,0,0,1.39-1.342l-.024-.024-4.1-4.1a.967.967,0,0,0-1.367,0h0l-4.1,4.1a.966.966,0,1,0,1.343,1.39l.024-.024,2.45-2.451V29.047a.967.967,0,0,0,1.933,0V16.881Z",
    style: {
      fill: "#f2b42a"
    }
  })));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgUpload, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/upload.e24692f8.svg";
export { ForwardRef as ReactComponent };