import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useLazyQuery } from 'react-apollo';
import moment from 'moment';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  LeadsIcon,
  MailSmallIcon,
  OpportunityIcon,
  PhoneIcon,
  ViewIcon,
  WhatsAppSmallIcon,
} from '../../assets/icons';
import Button from '../Button';
import ProjectTags from '../ProjectTags';
import CallAttemptsAlertModal from '../CallAttemptsAlertModal';
import CallNotAllowedModal from '../CallNotAllowedModal';
import SimpleModal from '../SimpleModal';
import {
  pendingSystem,
  pendingUserAction,
} from '../callLogs/constants/callLogData';
import {
  callMethodStatus,
  extractPhoneNumber,
  getNumberWithCommas,
  isEmptyValue,
  jsonParser,
} from '../../utils';
import {
  IS_CALLING_SELF,
  MOBILE_DIALER,
  NO_AVANSER_LOGIN,
  NO_CALL_ACCESS,
  NO_CALLER_MOBILE_NUMBER,
  NO_ANSWERPOINT_DIALER,
} from '../../data';
import {
  AuthContext,
  PageContext,
  SnackbarContext,
} from '../../contexts';
import { useEnhancedMutation } from '../../hooks';
import { ACTIVITY, CALLS } from '../../graphql';
import { clientActivity, clientCalls } from '../..';
import { LoadingContent } from '../loader';
import useStyles from './styles';

const NotificationNewAssignedModal = (props) => {
  const {
    record,
    mobile,
    type,
    errorText,
    open,
    isLoading,
    onClose,
    refetchCallLogs,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const authContext = useContext(AuthContext);
  const { user, apps } = authContext.state;
  const pageContext = useContext(PageContext);
  const { projects, campaigns, avanserAccount } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const [loader, setLoader] = useState(false);
  const [activeStates, setActiveStates] = useState({
    callAttemptsAlertModal: false,
    callNotAllowedModal: false,
  });
  const [callPermissionStatus, setCallPermissionStatus] = useState('');

  const [listAnswerpoints, {
    data: listAnswerpointsData,
    loading: listAnswerpointsLoading,
    error: listAnswerpointsError,
  }] = useLazyQuery(CALLS.LIST_ANSWERPOINTS(), {
    client: clientCalls,
    fetchPolicy: 'no-cache',
  });

  const [activityAPI] = useEnhancedMutation(ACTIVITY.CREATE(), { client: clientActivity });

  useEffect(() => {
    if (user && user.id && avanserAccount && !isEmpty(avanserAccount)) {
      listAnswerpoints({
        variables: {
          filters: [
            { field: 'assignee_id', operator: '==', value: user.id },
            { field: 'status', operator: '==', value: 'active' },
          ],
        },
      });
    }
  }, [
    user,
    avanserAccount,
    listAnswerpoints,
  ]);

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const handleAPIError = (label = '', error) => {
    const errorMsgFromRes = error
      && error.response
      && error.response.data
      && jsonParser(error.response.data.error_message);
    const errorStatus = error
      && error.response
      && error.response.status;
    setOpenSnackbar({
      variant: 'error',
      message: `Failed to ${label} with error status ${errorStatus}: ${errorMsgFromRes}`,
    });
  };

  const handleLogActivity = (object) => {
    if (record.id !== '' && type !== '') {
      activityAPI({
        variables: {
          type: 'outbound',
          object: JSON.stringify(object),
          target_id: record.id,
          target_type: type,
        },
      });
    }
  };

  const handleCreateCallLog = async (status, callLabel) => {
    if (status) {
      let body = {
        ...(type === 'lead' && record.id ? { lead_id: record.id } : {}),
        ...(type === 'customer' && record.id ? { lead_id: record.id, customer_id: record.id } : {}),
        call_at: moment().format(),
        call_by: user.id,
        type: 'manual_call',
        direction: 'call_out',
        status,
      };

      if (status === pendingSystem.name) {
        if (callLabel) {
          body = { ...body, label: callLabel };
        } else {
          setOpenSnackbar({ variant: 'error', message: 'Failed to create call log' });
          return;
        }
      }

      try {
        const res = await API.post(
          'LEAD',
          '/calls/log',
          {
            headers: {
              'x-company-id': user.company.id,
              'x-user-id': user.id,
            },
            body: { ...body },
          },
        );
        // Only need to refetch in some places
        if (res && refetchCallLogs.enabled) {
          await refetchCallLogs.callback();
          setLoader(false);
          onClose();
        }
      } catch (error) {
        handleAPIError('create call log', error);
        setLoader(false);
      }
    }
  };

  const handleTriggerCall = async () => {
    let dialerInfo = {
      mobile: '',
    };

    if (!listAnswerpointsError
      && !listAnswerpointsLoading
      && listAnswerpointsData
      && listAnswerpointsData.listAnswerpoints) {
      const { items } = listAnswerpointsData.listAnswerpoints;
      if (!isEmptyValue(items)) {
        dialerInfo = {
          mobile: items[0].dialer_tracking_number,
        };
      }
    }

    if (dialerInfo.mobile) {
      const extracted = await extractPhoneNumber(dialerInfo.mobile);
      if (extracted.country_code && extracted.national_number) {
        setLoader(true);
        toggleState('callAttemptsAlertModal')();
        try {
          const res = await API.post(
            'LEAD',
            '/calls',
            {
              headers: {
                'x-company-id': user.company.id,
                'x-user-id': user.id,
              },
              body: {
                call_to: mobile,
                call_from: `+${extracted.country_code}-${extracted.national_number}`,
                record: '1',
              },
            },
          );
          if (res) {
            await handleCreateCallLog(pendingSystem.name, res.label);
            await handleLogActivity({ type: 'phone', phone: mobile });
          }
        } catch (error) {
          handleAPIError('trigger call via Avanser', error);
          setLoader(false);
        }
      } else {
        setOpenSnackbar({
          variant: 'error',
          message: `There/'s an error when retrieving your dialer number info: ${dialerInfo.mobile}`,
        });
      }
    }
  };

  const handleCallButton = async () => {
    let dialerInfo = {
      name: '',
      mobile: '',
    };

    if (!listAnswerpointsError
      && !listAnswerpointsLoading
      && listAnswerpointsData
      && listAnswerpointsData.listAnswerpoints) {
      const { items } = listAnswerpointsData.listAnswerpoints;
      if (!isEmptyValue(items)) {
        dialerInfo = {
          name: items[0].dialer_name,
          mobile: items[0].dialer_tracking_number,
        };
      }
    }

    const status = await callMethodStatus({
      apps,
      avanserAccount,
      user: {
        ...user,
        dialer_name: dialerInfo.name,
        dialer_tracking_number: dialerInfo.mobile,
      },
      record: {
        mobile_country_code: record.mobile_country_code,
        msisdn: record.msisdn,
        mobile: record.mobile,
        phone: record.phone,
      },
    });

    if (status) {
      if ([
        NO_CALL_ACCESS,
        NO_AVANSER_LOGIN,
        NO_CALLER_MOBILE_NUMBER,
        NO_ANSWERPOINT_DIALER,
        IS_CALLING_SELF,
      ].includes(status)) {
        setCallPermissionStatus(status);
        toggleState('callNotAllowedModal')();
      } else if (status === MOBILE_DIALER) {
        // Use native dialer if is mobile
        await handleLogActivity({ type: 'phone', phone: mobile });
        await handleCreateCallLog(pendingUserAction.name);
        window.location = `tel:${record.mobile_country_code ? `+${mobile}` : mobile}`;
      } else {
        toggleState('callAttemptsAlertModal')();
      }
    }
  };

  const handleClose = () => {
    if (!(isLoading || loader)) {
      onClose();
    }
  };

  const handleViewOpportunity = () => {
    // TODO: When support notification popup in other screens
    // will need to call history.replace when in opp listing but history.push if in other screens
    if (location.pathname.includes('/opportunities?page=1')) {
      handleClose();
    } else {
      history.push(`/opportunities/${record.opportunity_id}?modal=notification_new_assigned&page=1`);
    }
  };

  const renderInfoLine = () => {
    let firstValue = '';
    let secondValue = '';

    if (type === 'lead') {
      const campaignName = record
      && record.campaign_id
      && campaigns[record.campaign_id]
      && campaigns[record.campaign_id].name
        ? campaigns[record.campaign_id].name
        : '';
      const submissionSourceName = record && record.form_name ? record.form_name : '';

      firstValue = campaignName;
      secondValue = submissionSourceName;
    } else if (type === 'customer') {
      firstValue = record.unit_number;
      secondValue = record.unit_number ? `${record.unit_spa_currency || 'MYR'} ${getNumberWithCommas(record.unit_spa_value)}` : '';
    }

    if (firstValue || secondValue) {
      return (
        <Grid item className={classes.infoContainer}>
          {
            firstValue && (
              <Typography variant="body2" align="center" className={classes.infoLine}>
                { firstValue }
              </Typography>
            )
          }
          {
            firstValue && secondValue && (
              <Typography variant="body2" align="center" className={classes.infoLine}>
                &nbsp;•&nbsp;
              </Typography>
            )
          }
          {
            secondValue && (
              <Typography variant="body2" align="center" className={classes.infoLine}>
                { secondValue }
              </Typography>
            )
          }
        </Grid>
      );
    }

    return null;
  };

  const renderCTAButtons = () => {
    if (type === 'lead') {
      return (
        <Grid container justify="space-evenly" className={classes.ctaButtons}>
          <Button
            variant="text"
            startIcon={<img alt="call now" src={PhoneIcon} />}
            disabled={isLoading || loader || !mobile}
            onClick={handleCallButton}
          >
            Call now
          </Button>
          <Button
            variant="text"
            startIcon={<img alt="whatsapp" src={WhatsAppSmallIcon} />}
            disabled={isLoading || loader || !mobile}
            onClick={() => { window.open(`https://wa.me/${mobile}`, '_blank'); }}
          >
            Whatsapp
          </Button>
          <Button
            variant="text"
            startIcon={<img alt="email" src={MailSmallIcon} />}
            disabled={isLoading || loader || !(record && record.email)}
            onClick={() => { window.location = `mailto:${record.email}`; }}
          >
            Email
          </Button>
        </Grid>
      );
    }

    if (type === 'customer') {
      return (
        <Grid container justify="center" className={classes.ctaButtons}>
          <Button
            variant="text"
            startIcon={<img alt="call now" src={ViewIcon} />}
            disabled={isLoading || loader}
            onClick={handleViewOpportunity}
          >
            View opportunity
          </Button>
        </Grid>
      );
    }

    return null;
  };

  const getProjectIDS = () => {
    let projectIDs = [];
    if (type === 'lead' && record.project_ids && record.project_ids.length > 0) {
      projectIDs = record.project_ids;
    } else if (type === 'customer' && record.project_id) {
      projectIDs = [record.project_id];
    }
    return projectIDs;
  };

  const title = `New ${type === 'lead' ? 'lead' : 'opportunity'} assigned to you!`;
  const projectIDs = getProjectIDS();
  return (
    <>
      <SimpleModal
        title={title}
        open={open}
        onCancel={handleClose}
        content={(
          <Grid container direction="column" alignItems="center" className={classes.root}>
            <LoadingContent isLoading={isLoading} />
            {
              errorText ? (
                <Typography>
                  {`Oops, there's an error to retrieve the ${type} data, please refresh.`}
                </Typography>
              ) : (
                <>
                  <Box className={classes.imageContainer} mb={1} mt={1}>
                    <img
                      alt="New"
                      src={type === 'lead' ? LeadsIcon : OpportunityIcon}
                    />
                  </Box>
                  <Typography variant="subtitle1" align="center">
                    { record.name }
                  </Typography>
                  {
                    projectIDs && projectIDs.length > 0 ? (
                      <Box mb={1} mt={0.2}>
                        <ProjectTags
                          projects={projects}
                          projectIds={projectIDs}
                        />
                      </Box>
                    ) : <Typography variant="body2" className={classes.infoLine}>No project added</Typography>
                  }
                  { renderInfoLine() }
                  { renderCTAButtons() }
                </>
              )
            }
          </Grid>
        )}
      />
      <CallNotAllowedModal
        type={type}
        status={callPermissionStatus}
        open={activeStates.callNotAllowedModal}
        onClose={toggleState('callNotAllowedModal')}
      />
      <CallAttemptsAlertModal
        open={activeStates.callAttemptsAlertModal}
        onClose={toggleState('callAttemptsAlertModal')}
        onConfirm={handleTriggerCall}
      />
    </>
  );
};

NotificationNewAssignedModal.propTypes = {
  refetchCallLogs: PropTypes.instanceOf(Object),
  record: PropTypes.instanceOf(Object),
  mobile: PropTypes.string,
  type: PropTypes.oneOf(['lead', 'customer']),
  errorText: PropTypes.string,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

NotificationNewAssignedModal.defaultProps = {
  refetchCallLogs: {
    enabled: false,
    callback: () => {},
  },
  record: {
    // For lead and can also be for opp primary customer in the future
    id: '',
    name: '',
    mobile_country_code: '',
    msisdn: '',
    mobile: '',
    phone: '',
    campaign_id: '',
    form_name: '',
    // For opportunity
    opportunity_id: '',
    unit_number: '',
    unit_spa_currency: 'MYR',
    unit_spa_value: '',
  },
  // For lead and customer
  mobile: '',
  // will also be "customer" for opportunity as it ties to customer
  type: 'lead',
  errorText: '',
  open: false,
  isLoading: false,
  onClose: () => {},
};

export default NotificationNewAssignedModal;
