import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { API } from 'aws-amplify';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  CallIcon,
  CallInboundIcon,
  CallOutboundIcon,
  PlusIcon,
} from '../../../assets/icons';
import { formatValue, jsonParser, previewDateTime } from '../../../utils';
import { AuthContext, PageContext, SnackbarContext } from '../../../contexts';
import AddCallLog from './AddCallLog';
import CallLogInfo from './CallLogInfo';
import { callOutcomes, pendingUserAction } from '../constants/callLogData';
import CallTypeTag from '../CallTypeTag';
import AudioPlayer from '../../AudioPlayer';
import useStyles from './styles';

export default function ProfileCallLogs(props) {
  const {
    headerPaddingTop,
    type,
    lead,
    customer,
    refetchCallLogs: refetchCallLogsFromParent,
    onRefetchCallback,
    onUpdateLeadStage,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const { activeTab, openCallLog } = qs.parse(location.search);

  const authContext = useContext(AuthContext);
  const { apps, user, users } = authContext.state;
  const pageContext = useContext(PageContext);
  const { avanserAccount } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [callLogs, setCallLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState({});
  const [logLimits, setLogLimits] = useState(20);
  const [refetchCallLogs, setRefetchCallLogs] = useState(true || refetchCallLogsFromParent);
  const [activeStates, setActiveStates] = useState({
    showCallLogInfo: false,
    showAddCallLog: false,
  });
  const [loaders, setLoaders] = useState({
    initialLoad: true,
    loading: false,
  });
  const [currentPlayingURL, setCurrentPlayingURL] = useState('');

  const toggleLoadingState = useCallback((name, bool) => {
    setLoaders((s) => ({ ...s, [name]: bool }));
  }, []);

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const handleAPIError = useCallback((label = '', error) => {
    const errorMsgFromRes = error
      && error.response
      && error.response.data
      && jsonParser(error.response.data.error_message);
    const errorStatus = error
      && error.response
      && error.response.status;
    setOpenSnackbar({
      variant: 'error',
      message: `Failed to ${label} with error status ${errorStatus}: ${errorMsgFromRes}`,
    });
  }, [setOpenSnackbar]);

  useEffect(() => {
    setRefetchCallLogs(refetchCallLogsFromParent);
  }, [refetchCallLogsFromParent]);

  useEffect(() => {
    // Customer need to pass in lead_id in order to retrieve logs
    // before it converted to Customer as Lead
    const handleRetrieveCallLogs = async () => {
      toggleLoadingState('loading', true);
      try {
        const res = await API.get(
          'LEAD',
          '/calls/list',
          {
            headers: {
              'x-company-id': user.company.id,
            },
            queryStringParameters: {
              ...(type === 'lead' && lead.id ? { lead_id: lead.id } : {}),
              ...(type === 'customer' && customer.id ? { customer_id: customer.id } : {}),
              page: 1,
              limit: logLimits,
            },
          },
        );
        if (res) {
          setCallLogs(res);
        }
      } catch (error) {
        handleAPIError('retrieve call logs', error);
      }
      toggleLoadingState('initialLoad', false);
      toggleLoadingState('loading', false);
      setRefetchCallLogs(false);
      onRefetchCallback();
    };

    if (refetchCallLogs && (
      (type === 'lead' && lead.id)
      || (type === 'customer' && customer.id))) {
      handleRetrieveCallLogs();
    } else {
      toggleLoadingState('initialLoad', false);
    }
  }, [
    refetchCallLogs,
    type,
    lead.id,
    customer.id,
    user,
    logLimits,
    handleAPIError,
    toggleLoadingState,
    onRefetchCallback,
  ]);

  useEffect(() => {
    if (activeTab === 'calls'
      && openCallLog === '1'
      && callLogs
      && callLogs.length > 0) {
      setTimeout(() => {
        setSelectedLog(callLogs[0]);
        setActiveStates((prev) => ({
          ...prev,
          showCallLogInfo: true,
        }));
      }, [500]);
    }
  }, [
    activeTab,
    openCallLog,
    callLogs,
  ]);

  const handleScrollMore = (e) => {
    const listboxNode = e.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
      && callLogs.length >= logLimits) {
      setLogLimits((prev) => prev + 10);
      setRefetchCallLogs(true);
    }
  };

  const handleCreateCallLog = async (log) => {
    try {
      const res = await API.post(
        'LEAD',
        '/calls/log',
        {
          headers: {
            'x-company-id': user.company.id,
            'x-user-id': user.id,
          },
          body: log,
        },
      );
      if (res) {
        if (type === 'lead') {
          onUpdateLeadStage(res);
        }
        setRefetchCallLogs(true);
      }
    } catch (error) {
      handleAPIError('create call log', error);
    }
  };

  const handleUpdateCallLog = async (log, field) => {
    try {
      const res = await API.put(
        'LEAD',
        `/calls/${log.id}`,
        {
          headers: {
            'x-user-id': user.id,
          },
          body: log,
        },
      );
      if (res) {
        if (type === 'lead' && field.name === 'status') {
          onUpdateLeadStage(res);
          setSelectedLog({});
          toggleState('showCallLogInfo')();
          history.push(`${location.pathname}?activeTab=calls`);
        }
        setRefetchCallLogs(true);
        setSelectedLog(res);
      }
    } catch (error) {
      handleAPIError('update call log', error);
    }
  };

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const renderListContentBox = (icon, header, children, rightNode) => (
    <section
      className={`${classes.listContentBox} ${headerPaddingTop ? 'headerPaddingTop' : ''}`}
      onScroll={handleScrollMore}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item className={classes.listHeader}>
          {
            !isDesktop && (
              <img src={icon} alt={header} className="icon" />
            )
          }
          <Typography variant="h6">{header}</Typography>
        </Grid>
        { rightNode }
      </Grid>
      { children }
      {
        !loaders.initialLoad
        && loaders.loading
        && (
          <Typography className={classes.loadingText}>
            Loading...
          </Typography>
        )
      }
    </section>
  );

  const renderStatus = (status) => {
    if (status) {
      if (status === pendingUserAction.name) {
        return pendingUserAction.label;
      }
      const index = callOutcomes.findIndex((outcome) => outcome.value === status);
      if (index > -1) {
        return callOutcomes[index].label;
      }
      return formatValue(status);
    }
    return 'No outcome added';
  };

  const renderAttempts = (retryCount) => {
    if (retryCount) {
      return `(${retryCount} attempt${retryCount > 1 ? 's' : ''})`;
    }
    return '';
  };

  const renderDurations = (duration) => {
    if (duration) {
      const formatted = moment.duration(parseInt(duration, 10), 'seconds');
      const hours = formatted.hours() ? `${formatted.hours()} hr` : '';
      const minutes = formatted.minutes() ? `${formatted.minutes()} min` : '';
      const seconds = formatted.seconds() ? `${formatted.seconds()} sec` : '';
      return `• Lasted ${hours} ${minutes} ${seconds}`;
    }
    return '';
  };

  const renderCallLog = (log) => {
    let icon = '';
    let logTypeLabel = '';
    switch (log.direction) {
      case 'call_out':
        icon = CallOutboundIcon;
        logTypeLabel = `Outbound call by ${getUserName(log.call_by)}`;
        break;
      case 'call_in':
        icon = CallInboundIcon;
        logTypeLabel = `Inbound call to ${log.call_to}`;
        break;
      default:
        break;
    }

    return (
      <Box pb={2.5} className={classes.callLog} key={log.id}>
        <Grid container>
          <Grid item xs={1}>
            <img alt={formatValue(log.type)} src={icon} className={classes.callIcon} />
          </Grid>
          <Box
            pl={1}
            component={Grid}
            item
            xs={11}
          >
            <Typography
              variant="subtitle1"
              className={classes.label}
              component={Link}
              onClick={() => {
                history.push(`${location.pathname}?activeTab=calls`);
                setSelectedLog(log);
                toggleState('showCallLogInfo')();
              }}
            >
              { logTypeLabel }
            </Typography>
            <Typography variant="body2">
              { previewDateTime(log.call_at) }
            </Typography>
            <Grid container alignItems="center" className={classes.tagSection}>
              <CallTypeTag type={log.type} direction={log.direction} />
              {
                log.recording_url
                && (apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess)
                && (avanserAccount && !isEmpty(avanserAccount))
                && (
                  <AudioPlayer
                    url={log.recording_url}
                    currentPlayingURL={currentPlayingURL}
                    onClick={() => setCurrentPlayingURL(log.recording_url)}
                  />
                )
              }
            </Grid>
            <Typography variant="body2" className={classes.caption}>
              {`${renderStatus(log.status)} ${renderAttempts(log.avanser_retry_count)} ${renderDurations(log.duration)}`}
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  };

  const renderCallLogs = () => {
    if (loaders.initialLoad) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.logsContainer}>
          <CircularProgress />
        </Grid>
      );
    }
    if (!(callLogs && callLogs.length > 0)) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.logsContainer}>
          <Typography variant="body2">
            No call logs found
          </Typography>
        </Grid>
      );
    }
    return callLogs.map((data) => (renderCallLog(data)));
  };

  const renderContent = () => (
    renderListContentBox(
      CallIcon,
      'Calls',
      (
        <div>
          { renderCallLogs() }
        </div>
      ),
      (
        <IconButton size="small" onClick={toggleState('showAddCallLog')} className="addButton">
          <img alt="add call log" src={PlusIcon} className="icon" />
        </IconButton>
      ),
    )
  );

  return (
    <>
      { renderContent() }
      <AddCallLog
        type={type}
        lead={lead}
        customer={customer}
        open={activeStates.showAddCallLog}
        onSubmit={handleCreateCallLog}
        onClose={toggleState('showAddCallLog')}
      />
      <CallLogInfo
        type={type}
        lead={lead}
        customer={customer}
        data={selectedLog}
        open={activeStates.showCallLogInfo}
        loading={loaders.initialLoad || loaders.loading}
        onUpdate={handleUpdateCallLog}
        onClose={() => {
          setSelectedLog({});
          toggleState('showCallLogInfo')();
          history.push(`${location.pathname}?activeTab=calls`);
        }}
      />
    </>
  );
}

ProfileCallLogs.propTypes = {
  headerPaddingTop: PropTypes.bool,
  refetchCallLogs: PropTypes.bool,
  type: PropTypes.oneOf(['lead', 'customer']),
  lead: PropTypes.instanceOf(Object),
  customer: PropTypes.instanceOf(Object),
  onUpdateLeadStage: PropTypes.func,
  onRefetchCallback: PropTypes.func,
};

ProfileCallLogs.defaultProps = {
  headerPaddingTop: true,
  refetchCallLogs: false,
  type: 'lead',
  lead: {},
  customer: {},
  onUpdateLeadStage: () => {},
  onRefetchCallback: () => {},
};
