import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

export const useStyles = makeStyles(({
  wrapper: {
    '& .fieldLabel': {
      marginTop: '0.93rem',
    },
  },
}));

export const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 3,
    padding: '0.813rem 0',
    width: 'calc(100% - 1.5rem)',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '0.125rem solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
  markLabel: {
    marginTop: '0.36rem',
  },
}))(Slider);
