import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { SimpleModal } from '..';
import { DollarIcon } from '../../assets/icons';
import useStyles from './styles';

const BlastBudgetModal = (props) => {
  const {
    currency,
    budget: defaultBudget,
    open,
    onClose,
    onSubmit,
    isEmail,
  } = props;

  const classes = useStyles();

  const [budget, setBudget] = useState(defaultBudget);

  useEffect(() => {
    setBudget(defaultBudget);
  }, [defaultBudget]);

  useEffect(() => {
    if (!open) {
      setBudget(defaultBudget || 0);
    }
  }, [
    open,
    defaultBudget,
  ]);

  const handleChange = (e) => {
    const { value } = e.target;
    setBudget(value);
  };

  const handleSubmit = () => {
    onSubmit(budget);
  };

  return (
    <SimpleModal
      title="Budget"
      open={open}
      onCancel={onClose}
      content={(
        <div className={classes.container}>
          <Typography variant="body2">
            State your budget for more accurate reporting
          </Typography>
          <TextField
            required
            fullWidth
            label="Amount"
            name="budget"
            variant="outlined"
            type="number"
            defaultValue={budget}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Icon className={classes.icon}>
                    <img alt="dollar" src={DollarIcon} />
                  </Icon>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: false,
              variant: 'standard',
              disableAnimation: true,
            }}
          />
          <Typography variant="caption">
            {`${currency} ${isEmail ? '0.01 per 10 emails' : '0.10 per SMS'}`}
          </Typography>
        </div>
      )}
      buttons={(
        <Button
          variant="contained"
          disabled={!budget}
          onClick={handleSubmit}
        >
          Save
        </Button>
      )}
    />
  );
};

BlastBudgetModal.propTypes = {
  currency: PropTypes.string,
  budget: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEmail: PropTypes.bool,
};

BlastBudgetModal.defaultProps = {
  currency: 'MYR',
  budget: 0,
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  isEmail: false,
};

export default BlastBudgetModal;
