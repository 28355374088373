import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// DELETE delete campaign form submission from GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteSubmission',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...DeleteSubmissionResponseFields}',
    fragments: [Fragments.deleteSubmissionResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update campaign form submission from GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateSubmission',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'fields', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'fields', value: 'fields' },
    ],
    fields: '{...UpdateSubmissionResponseFields}',
    fragments: [Fragments.updateSubmissionResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
