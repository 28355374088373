export default {
  app: {
    region: process.env.REACT_APP_AWS_REGION,
    stage: process.env.REACT_APP_RELEASE_STAGE,
    version: process.env.REACT_APP_VERSION,
    // LMS-2843
    // chatURL: process.env.REACT_APP_MHUB_CHAT_URL,
    leadFormUrl: process.env.REACT_APP_MHUB_LEAD_FORM_URL,
    loanTrackerURL: process.env.REACT_APP_MHUB_LOAN_TRACKER_URL,
    mhubAccountURL: process.env.REACT_APP_MHUB_ACCOUNT_URL,
    mhubAnalyticsURL: process.env.REACT_APP_MHUB_ANALYTICS_URL,
    mhubCreditCheckURL: process.env.REACT_APP_MHUB_CREDITCHECK_URL,
    mhubCommissionTrackerURL: process.env.REACT_APP_MHUB_COMMISSION_TRACKER_URL,
    enableCreditCheck: true,
    mhubApiUrl: process.env.REACT_APP_MHUB_API_URL,
    secureUrl: process.env.REACT_APP_MHUB_SECURE_URL,
    shortUrl: process.env.REACT_APP_MHUB_SHORTURL,
    siteplanUrl: process.env.REACT_APP_MHUB_SITE_PLAN_URL,
    mhubWorkflowURL: process.env.REACT_APP_MHUB_WORKFLOW_URL,
    unlayerEditorProjectId: process.env.REACT_APP_MHUB_UNLAYER_EDITOR_PROJECT_ID,
    subscriptionPublicVapidKey: process.env.REACT_APP_SUBSCRIPTION_PUBLIC_VAPID_KEY,
    // Facebook App
    facebookGraphApiVersion: process.env.REACT_APP_MHUB_FACEBOOK_GRAPHAPI_VERSION,
    facebookPersonalLoginClientID: process.env.REACT_APP_FB_PERSONAL_CLIENT_ID,
    facebookPersonalLoginSecret: process.env.REACT_APP_FB_PERSONAL_SECRET,
    facebookBusinessLoginClientID: process.env.REACT_APP_FB_BUSINESS_CLIENT_ID,
    facebookBusinessLoginSecret: process.env.REACT_APP_FB_BUSINESS_SECRET,
    facebookBusinessLoginConfigID: process.env.REACT_APP_FB_BUSINESS_CONFIG_ID,
  },
  api: {
    companyBranchApiUrl: process.env.REACT_APP_MHUB_COMPANY_BRANCH_API_URL,
    leadApiUrl: process.env.REACT_APP_MHUB_LEAD_API_URL,
    creditCheckApiUrl: process.env.REACT_APP_MHUB_CREDITCHECK_API_URL,
    workflowApiUrl: process.env.REACT_APP_MHUB_WORKFLOW_API_URL,
    userApiUrl: process.env.REACT_APP_MHUB_USER_API_URL,
    // LMS-2843
    // chatApiUrl: process.env.REACT_APP_MHUB_CHAT_API_URL,
    shortUrlApiUrl: process.env.REACT_APP_MHUB_SHORTURL_API_URL,
    facebookGraphApiUrl: process.env.REACT_APP_MHUB_FACEBOOK_GRAPHAPI_URL,
  },
  s3: {
    bucket: process.env.REACT_APP_S3_BUCKET,
  },
  cognito: {
    appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    cookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
    cookieSecure: process.env.REACT_APP_RELEASE_STAGE === 'prod',
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  },
  appsync: {
    authGraphqlEndpoint: process.env.REACT_APP_APPSYNC_AUTH_API_ENDPOINT,
    activityGraphqlEndpoint: process.env.REACT_APP_APPSYNC_ACTIVITY_ENDPOINT,
    audienceGraphqlEndpoint: process.env.REACT_APP_APPSYNC_AUDIENCE_GROUPS_ENDPOINT,
    campaignGraphqlEndpoint: process.env.REACT_APP_APPSYNC_CAMPAIGN_ENDPOINT,
    callsGraphqlEndpoint: process.env.REACT_APP_APPSYNC_CALLS_ENDPOINT,
    contactGraphqlEndpoint: process.env.REACT_APP_APPSYNC_CONTACT_ENDPOINT,
    customerGraphqlEndpoint: process.env.REACT_APP_APPSYNC_CUSTOMER_ENDPOINT,
    formGraphqlEndpoint: process.env.REACT_APP_APPSYNC_FORM_ENDPOINT,
    leadFieldsGraphqlEndpoint: process.env.REACT_APP_APPSYNC_LEAD_ENDPOINT,
    opportunityGraphqlEndpoint: process.env.REACT_APP_APPSYNC_OPPORTUNITY_ENDPOINT,
    submissionGraphqlEndpoint: process.env.REACT_APP_APPSYNC_SUBMISSION_ENDPOINT,
    subscriptionGraphqlEndpoint: process.env.REACT_APP_APPSYNC_SUBSCRIPTION_ENDPOINT,
    userGraphqlEndpoint: process.env.REACT_APP_APPSYNC_USER_ENDPOINT,
    buyerAccessGraphqlEndpoint: process.env.REACT_APP_APPSYNC_BUYERACCESS_ENDPOINT,
    leadIntegrationGraphqlEndpoint: process.env.REACT_APP_APPSYNC_LEAD_INTEGRATION_ENDPOINT,
  },
};
