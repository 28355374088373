import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../../../contexts';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '410px',
    '& > img': {
      width: '100%',
    },
    '& > section': {
      padding: '1.8rem 2rem 2rem 2rem',
      '& > p': {
        margin: '1rem 0',
      },
    },
  },
  iconClose: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  },
  infoBanner: {
    marginBottom: '1.8rem',
    '& p': {
      color: theme.palette.font.header,
    },
  },
  statusChip: {
    maxWidth: '200px',
    height: '20px',
    marginBottom: '0.8rem',
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    fontWeight: fonts.semiBold,
  },
  level2CTA: {
    marginLeft: 'auto',
    display: 'block',
  },
}));
