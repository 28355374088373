import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listSubmissions',
    args: [
      { name: 'fields', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'per_query', type: 'Int' },
      { name: 'pit_id', type: 'String' },
      { name: 'search_after', type: 'String' },
      { name: 'list_back', type: 'Boolean' },
      { name: 'page', type: 'Int' },
    ],
    argsMap: [
      { name: 'fields', value: 'fields' },
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'per_query', value: 'per_query' },
      { name: 'pit_id', value: 'pit_id' },
      { name: 'search_after', value: 'search_after' },
      { name: 'list_back', value: 'list_back' },
      { name: 'page', value: 'page' },
    ],
    fields: '{...ListSubmissionResponseFields}',
    fragments: [Fragments.listSubmissionResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const SEARCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'searchSubmissions',
    args: [
      { name: 'query', type: 'String' },
      { name: 'submission_id', type: 'String' },
      { name: 'name', type: 'String' },
      { name: 'email', type: 'String' },
      { name: 'mobile', type: 'String' },
    ],
    argsMap: [
      { name: 'query', value: 'query' },
      { name: 'submission_id', value: 'submission_id' },
      { name: 'name', value: 'name' },
      { name: 'email', value: 'email' },
      { name: 'mobile', value: 'mobile' },
    ],
    fields: '{...SearchSubmissionsResponseFields}',
    fragments: [Fragments.searchSubmissionsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const DOWNLOAD = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'downloadSubmissions',
    args: [
      { name: 'ids', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
    ],
    argsMap: [
      { name: 'ids', value: 'ids' },
      { name: 'filters', value: 'filters' },
    ],
    fields: '{...DownloadSubmissionsResponseFields}',
    fragments: [Fragments.downloadSubmissionsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST_SUBMISSION_EXPORT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listSubmissionExports',
    args: [
      { name: 'prefix', type: 'String' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'prefix', value: 'prefix' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListSubmissionExportsResponseFields}',
    fragments: [Fragments.listSubmissionExportsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
