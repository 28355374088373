import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minWidth: '200px',
    maxWidth: '410px',
    '& > img': {
      width: '100%',
    },
    '& > section': {
      padding: '1.5rem 2rem',
      '& > p': {
        marginTop: '1rem',
      },
      '& > div': {
        marginTop: '1rem',
      },
      '& > section': {
        marginTop: '1.5rem',
        '& > div': {
          marginTop: '1rem',
        },
        '& > span': {
          color: theme.palette.font.subBody,
        },
      },
    },
  },
  iconClose: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  },
}));
