import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// READ get audience group data GraphQL schema
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAudienceGroup',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...AudienceGroupFields}',
    fragments: [Fragments.audienceGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_AUDIENCES get audience group data with customer listing GraphQL schema
export const LIST_AUDIENCES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'queryAudienceGroup',
    args: [
      { name: 'audience_group_id', type: 'String', required: true },
      { name: 'fields', type: '[String]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'audience_group_id', value: 'audience_group_id' },
      { name: 'fields', value: 'fields' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...QueryAudienceGroupResponseFields}',
    fragments: [Fragments.queryAudienceGroupResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list audience groups GraphQL schema
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listAudienceGroups',
    args: [
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
      { name: 'all', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
      { name: 'all', value: 'all' },
    ],
    fields: '{...ListAudienceGroupsResponseFields}',
    fragments: [Fragments.listAudienceGroupsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// RETRIEVE_INFO retrieve total unique count of the audience groups GraphQL schema
// Without channel_id gets unique audience count.
// With channel_id get and updates unique audience count for other APIs to retrieve.
export const RETRIEVE_INFO = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'retrieveAudienceInfo',
    args: [
      { name: 'channel_id', type: 'String' },
      { name: 'channel_type', type: 'String' },
      { name: 'audience_group_ids', type: '[String]' },
      { name: 'channel_type', type: 'String' },
    ],
    argsMap: [
      { name: 'channel_id', value: 'channel_id' },
      { name: 'channel_type', value: 'channel_type' },
      { name: 'audience_group_ids', value: 'audience_group_ids' },
      { name: 'channel_type', value: 'channel_type' },
    ],
    fields: '{...RetrieveAudienceInfoResponseFields}',
    fragments: [Fragments.retrieveAudienceInfoResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
