import React from 'react';
import PropTypes from 'prop-types';

import { ApolloClient } from 'apollo-client';

import ClientContext from './context';

const Provider = ({ children, clients }) => {
  const context = { clients };
  return (
    <ClientContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ClientContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  clients: PropTypes.shape({
    [PropTypes.string]: PropTypes.instanceOf(ApolloClient),
  }),
};

Provider.defaultProps = {
  children: null,
  clients: null,
};

Provider.Consumer = ClientContext.Consumer;

export default Provider;
