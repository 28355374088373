import { makeStyles } from '@material-ui/core/styles';

const classContainer = (theme) => ({
  flex: 1,
  overflow: 'hidden',
  // This will help apply consistent field label style across the drawers
  // Just pass in the fieldLabel and required into the className
  '& .fieldLabel': {
    '&.required': {
      '&:after': {
        content: '" *"',
        color: theme.palette.tertiary.red,
      },
    },
  },
});

export function useContainerStyles(props, isWide) {
  if (isWide) {
    return makeStyles((theme) => ({
      container: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          width: '700px',
        },
        ...classContainer(theme),
      },
    }))(props);
  }
  return makeStyles((theme) => ({
    container: {
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
      ...classContainer(theme),
    },
  }))(props);
}

const classContent = (haveLoadingOverLay) => ({
  flex: 'auto', // Need it when don't have side nav
  overflowY: 'scroll',
  padding: '1rem 1.5rem',
  position: 'relative',
  '& > .scrollableContent': {
    height: 0, // Workaround for Safari nested flex scrolling
  },
  ...(haveLoadingOverLay
    ? {
      '& > div:first-child': {
        top: 0,
        left: 0,
      },
    } : {}),
});

const creditCheckClassContent = () => ({
  flex: 'auto', // Need it when don't have side nav
  overflowY: 'scroll',
  padding: '1rem 0',
  '& > .creditCheckContent': {
    height: '100%',
  },
});

export function useContentStyles(props, haveSideNav, isCreditCheck, haveLoadingOverLay) {
  if (haveSideNav) {
    return makeStyles((theme) => ({
      content: {
        ...classContent(haveLoadingOverLay),
        [theme.breakpoints.up('sm')]: {
          maxWidth: '400px',
        },
        [theme.breakpoints.down('xs')]: {
          '& > .scrollableContent > div': {
            paddingBottom: '3rem',
          },
        },
      },
    }))(props);
  }
  if (isCreditCheck) {
    return makeStyles((theme) => ({
      content: {
        ...creditCheckClassContent(),
        [theme.breakpoints.up('sm')]: {
          maxWidth: '400px',
        },
        [theme.breakpoints.down('xs')]: {
          '& > .scrollableContent > div': {
            paddingBottom: '3rem',
          },
        },
      },
    }))(props);
  }
  return makeStyles(() => ({
    content: {
      ...classContent(haveLoadingOverLay),
    },
  }))(props);
}

export const useStyles = makeStyles((theme) => ({
  drawer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  sideNavigations: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.tertiary.darkGrey}`,
  },
  mobileSideNavigation: {
    height: '200px',
    width: '250px',
  },
  categoryButton: {
    display: 'none',
    position: 'fixed',
    bottom: '5rem',
    zIndex: 1,
    backgroundColor: 'white',
    boxShadow: `0px 5px 5px ${theme.palette.tertiary.grey}`,
    left: '50%',
    transform: 'translate(-50%, 0)',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  headerBar: {
    padding: '0.5rem',
    alignItems: 'center',
    border: `1px solid ${theme.palette.tertiary.grey}`,
    borderLeft: 0,
    borderRight: 0,
    minHeight: '4.3rem',
    '&.leftPadding': {
      padding: '0.5rem 0.5rem 0.5rem 1.5rem',
    },
  },
  backIcon: {
    marginRight: '1rem',
    '& img': {
      height: '1.5rem',
      filter: 'invert(0.4)',
    },
  },
  // Fix material warning: classes undefined when passed from parent
  paper: {},
  footer: {
    padding: '1rem',
    minHeight: '80px',
  },
  secondaryButtons: {
    '& > button': {
      padding: '0 1rem',
      color: theme.palette.font.subBody,
      textTransform: 'inherit',
    },
    '& > button:not(:first-child)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  },
  primaryButtons: {
    '& button + button': {
      marginLeft: '1rem',
    },
  },
}));
