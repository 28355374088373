import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '278px',
    maxHeight: '70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.857rem',
    },
    '&:hover, &:active , &:focus ': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.tertiary.lightGrey,
    },
    '&:active , &:focus ': {
      color: theme.palette.primary.main,
      '& img': {
        filter: filters.primary.main,
      },
    },
    '&:disabled': {
      color: theme.palette.tertiary.grey,
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
  },
  active: {
    '&:not(:disabled)': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '& img': {
        filter: filters.primary.main,
      },
    },
  },
  startIcon: {
    marginRight: '1rem',
    height: '28px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0.5rem',
      height: '24px',
    },
  },
}));
