import React from 'react';
import PropTypes from 'prop-types';
import {
  Button as MuiButton,
  CircularProgress,
} from '@material-ui/core';

const Button = (props) => {
  const {
    isLoading,
    children,
    ...rest
  } = props;

  return (
    isLoading ? (
      <MuiButton {...rest} disabled>
        <CircularProgress size={15} />
      </MuiButton>
    ) : (
      <MuiButton {...rest}>
        { children }
      </MuiButton>
    )
  );
};

Button.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  isLoading: false,
  children: null,
};

export default Button;
