import React from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const NetworkError = () => {
  const classes = useStyles();

  window.document.title = 'Network error';

  // Does not take material UI styling
  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography variant="h1" className={classes.header}>
        Oops! No connection
      </Typography>
      <Typography variant="body1" className={classes.caption}>
          Please check your network and refresh
      </Typography>
      <Grid item>
        <Button
          className={classes.button}
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};

export default NetworkError;
