import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Drawer from '../Drawer';
import useStyles from './styles';

export default function FilterColumns(props) {
  const classes = useStyles(props);
  const {
    open,
    columns,
    defaultColumns,
    fixedColumns,
    sections,
    onClose,
    onSubmit,
  } = props;

  const [values, setValues] = useState(
    columns.length > 0 ? columns : defaultColumns,
  );

  useEffect(() => {
    if (columns) {
      setValues(
        columns.length > 0 ? columns : defaultColumns,
      );
    }
  }, [
    columns,
    defaultColumns,
  ]);

  const handleOnClose = (e) => {
    setValues(columns);
    onClose(e);
  };

  const handleOnSubmit = (e) => {
    onSubmit(e, values);
    onClose(e);
  };

  const handleOnReset = () => {
    setValues(defaultColumns);
  };

  const handleOnClear = () => {
    setValues(fixedColumns);
  };

  const handleSelectAll = () => {
    let selectedColumns = [];
    sections.forEach((section) => {
      if (section.fields && section.fields.length > 0) {
        selectedColumns = [...selectedColumns, ...section.fields.map((field) => field.value)];
      }
    });
    setValues(selectedColumns);
  };

  const handleOnChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setValues((prev) => [...prev, name]);
    } else {
      setValues((prev) => _.pull([...prev], name));
    }
  };

  const isChecked = (name) => values.includes(name);
  const isFixedColumn = (name) => fixedColumns.includes(name);

  const renderCheckbox = (field) => (
    <FormControlLabel
      key={field.value}
      className={classes.checkbox}
      label={(
        <Typography variant="subtitle2">
          { field.label || '' }
        </Typography>
      )}
      value={field.value}
      control={(
        <Checkbox
          name={field.value}
          checked={isChecked(field.value)}
          onChange={handleOnChange}
          disabled={isFixedColumn(field.value)}
        />
      )}
    />
  );

  const renderSecondaryButtons = () => [
    {
      label: 'Reset',
      onClick: handleOnReset,
    },
    {
      label: 'Select all',
      onClick: handleSelectAll,
    },
    {
      label: 'Clear all',
      onClick: handleOnClear,
    },
  ];

  const renderSectionHeader = (section) => {
    if (section.header) {
      return (
        <Typography variant="h6" className={classes.header}>
          { section.header }
        </Typography>
      );
    }
    return null;
  };

  const renderSectionOptions = (section) => {
    if (section.fields && section.fields.length > 0) {
      return (
        <FormGroup aria-label="position">
          {
            React.Children.toArray(
              section.fields.map((field) => (
                renderCheckbox(field)
              )),
            )
          }
        </FormGroup>
      );
    }
    return null;
  };

  return (
    <Drawer
      header="Columns"
      open={open}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      submitLabel="Apply"
      isSubmitDisabled={values.length === 0}
      secondaryButtons={renderSecondaryButtons()}
    >
      <div className={classes.content}>
        {
          React.Children.toArray(sections.map((section) => {
            if (section.fields && section.fields.length <= 0) {
              return null;
            }
            return (
              <section className={classes.section}>
                { renderSectionHeader(section) }
                { renderSectionOptions(section) }
              </section>
            );
          }))
        }
      </div>
    </Drawer>
  );
}

FilterColumns.propTypes = {
  open: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  fixedColumns: PropTypes.arrayOf(PropTypes.string),
  sections: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

FilterColumns.defaultProps = {
  open: false,
  columns: [],
  defaultColumns: [],
  fixedColumns: [],
  sections: [],
  onClose: () => { },
  onSubmit: () => { },
};
