function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgShare = function SvgShare(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Assets",
    width: 32,
    height: 32,
    viewBox: "0 0 32 32",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("path", {
    d: "M22.946,20.744a4.557,4.557,0,0,0-3.134,1.247l-6.48-4.2a4.565,4.565,0,0,0,.032-3.147l6.753-4.367a4.566,4.566,0,1,0-1.284-1.591l-6.56,4.242a4.575,4.575,0,1,0-.066,6.556l6.47,4.2a4.574,4.574,0,1,0,4.269-2.934Zm0-16.6a2.541,2.541,0,1,1-2.541,2.541A2.544,2.544,0,0,1,22.946,4.141ZM9.054,18.711A2.542,2.542,0,1,1,11.6,16.169,2.544,2.544,0,0,1,9.054,18.711ZM22.946,27.86a2.542,2.542,0,1,1,2.542-2.542A2.544,2.544,0,0,1,22.946,27.86Z",
    style: {
      fill: "#596975"
    }
  }));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgShare, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/share.8eb25e4c.svg";
export { ForwardRef as ReactComponent };