import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { SnackbarContext } from '../contexts';

import {
  getSelectorValue,
  clientContextWarn,
  redirectLogin,
} from '../utils';
import config from '../config';

const useEnhancedQuery = (query, props = {
  options: {},
  defaultData: {},
  selector: () => {},
  disableDefaultError: false,
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;
  const history = useHistory();

  const handleOnError = (err) => {
    // UnauthorizedException
    if (err.message === 'Network error: Response not successful: Received status code 401') {
      window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
      return;
    }

    if (!props.disableDefaultError) {
      switch (err.message) {
        case 'GraphQL error: forbidden':
          history.push('/forbidden');
          return;
        case 'GraphQL error: item not found':
          history.push('/not_found');
          return;
        case 'GraphQL error: item has been deleted':
          history.push('/item_deleted');
          return;
        default:
      }
      setOpenSnackbar({
        variant: 'error',
        message: 'An error has occured. Please try again.',
      });
      clientContextWarn(err);
    }
    if (props.options && props.options.onError) {
      props.options.onError(err);
    }
  };

  const result = useQuery(query, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: handleOnError,
    ...props.options,
  });

  const newData = getSelectorValue({ ...props.defaultData, ...result.data }, props.selector);
  return {
    ...result,
    data: newData,
  };
};

export default useEnhancedQuery;
