import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import SimpleModal from '../SimpleModal';

const ExportModal = (props) => {
  const {
    type,
    open,
    onCancel,
    onConfirm,
  } = props;

  const history = useHistory();

  const handleConfirm = () => {
    let path = '';
    onConfirm();
    switch (type) {
      case 'leads':
      case 'customers':
        path = type;
        break;
      case 'submissions':
        path = `campaigns/${type}`;
        break;
      default:
        break;
    }

    if (path) {
      history.push(`/${path}/past_exports`);
    }
  };

  return (
    <SimpleModal
      title="Exporting..."
      open={open}
      onCancel={onCancel}
      content={(
        <>
          <Typography variant="body2">
            We&apos;ll update you via email as soon as the CSV
            file is ready to be downloaded.
            It should take less than 15 minutes
          </Typography>
        </>
    )}
      buttons={(
        <Button
          variant="contained"
          onClick={handleConfirm}
        >
        Okay
        </Button>
    )}
    />
  );
};

ExportModal.propTypes = {
  type: PropTypes.oneOf(['leads', 'customers', 'submissions']),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

ExportModal.defaultProps = {
  type: 'leads',
  open: false,
  onCancel: () => {},
  onConfirm: () => {},
};

export default ExportModal;
