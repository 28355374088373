import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { isEmpty, startCase } from 'lodash';

import {
  validate,
  required,
} from '@mhub/web-validations';
import {
  TextField,
  Typography,
  Link,
  Box,
  InputLabel,
} from '@material-ui/core';
import Drawer from '../Drawer';
import DatePicker from '../DatePicker';
import { Searchable, SearchableProject } from '../Dropdown';
import {
  ROLE_HEADQUARTERS,
  ROLE_SUPERVISOR,
  ROLE_SALES_ADMIN,
  ROLE_SALES_MANAGER,
  ROLE_AGENCY_LEADER,
  ROLE_AGENCY_ADMIN,
} from '../../data';

import { AuthContext } from '../../contexts';

import { useStyles } from './styles';

export default function CampaignForm(props) {
  const {
    open,
    isLoading,
    onClose,
    onSubmit,
  } = props;

  const authContext = useContext(AuthContext);
  const { user, listCompanyBranches } = authContext.state;

  const [values, setValues] = useState({
    name: '',
    starting_at: '',
    ending_at: '',
    project_ids: [],
    description: '',
    company_branch_id: '',
  });
  const [errors, setErrors] = useState({});
  const [click, setClick] = useState(false);
  const [companyBranchesOptions, setCompanyBranchesOption] = useState([]);

  const classes = useStyles(props);

  // Role Access Control
  const isHQ = user.role === ROLE_HEADQUARTERS;
  const isL2 = [ROLE_SUPERVISOR, ROLE_SALES_ADMIN, ROLE_SALES_MANAGER].includes(user.role);
  const isL2b = [ROLE_AGENCY_ADMIN, ROLE_AGENCY_LEADER].includes(user.role);


  useEffect(() => {
    if (listCompanyBranches) {
      const companyBranchArr = [];
      Object.keys(listCompanyBranches).forEach((branch) => {
        companyBranchArr.push({
          label: listCompanyBranches[branch].name,
          value: branch,
        });
      });
      setCompanyBranchesOption(companyBranchArr);
    }
  }, [listCompanyBranches]);

  const getValidations = useCallback((isRequired = false) => {
    const validations = [];
    if (isRequired) {
      validations.push(required);
    }
    return validations;
  }, []);

  const getRequiredValidation = (name, value) => {
    const validations = getValidations(true);
    let label = startCase(name);
    let newValue = value || '';
    if (name === 'company_branch_id') {
      label = 'Business unit';
    } else if (name === 'project_ids') {
      label = 'Project name';
      newValue = newValue.toString();
    }
    validate({
      name,
      value: newValue,
      label,
      validations,
    }).then((result) => {
      setErrors((err) => {
        const newErrors = { ...err };
        if (!result.message) {
          delete newErrors[result.key];
          return newErrors;
        }
        return ({
          ...newErrors,
          [result.key]: result.message,
        });
      });
    });
  };

  const handleChangeDropdown = (name, s, isRequired = false) => {
    if (isRequired) {
      getRequiredValidation('company_branch_id', s);
    }
    setValues({ ...values, [name]: s });
  };

  const handleOnChange = (e, isRequired = false) => {
    const { name, value } = e.target;
    if (isRequired) {
      getRequiredValidation(name, value);
    }
    setValues({ ...values, [name]: value });
  };

  const handleChangeMultipleDropdown = (e, name, value, isRequired = false) => {
    if (isRequired) {
      getRequiredValidation(name, value);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnChangeDate = (v) => {
    getRequiredValidation('starting_at', v.start);
    getRequiredValidation('ending_at', v.end);
    setValues({
      ...values,
      starting_at: v.start,
      ending_at: v.end,
    });
  };

  const handleOnClearDate = () => {
    const newValues = values;
    delete newValues.starting_at;
    delete newValues.ending_at;
    setValues({ ...newValues });
    getRequiredValidation('starting_at');
    getRequiredValidation('ending_at');
  };

  const handleOnClose = (e) => {
    setValues({});
    setErrors({});
    onClose(e);
  };

  const handleOnSubmit = (e) => {
    setClick(true);

    const emptyRequiredFields = [];
    const requiredFields = ['name', 'project_ids', 'starting_at', 'ending_at'];
    if (isHQ) {
      requiredFields.push('company_branch_id');
    }
    requiredFields.forEach((f) => {
      getRequiredValidation(f, values[f]);
      if (!(values[f] && !isEmpty(values[f]))) {
        emptyRequiredFields.push(f);
      }
    });

    if (isEmpty(errors) && emptyRequiredFields.length <= 0) {
      setClick(false);
      // TODO: Should remove timezone for start and end
      // once backend fixed default unmarshall
      const startDate = values.starting_at.toString().replace('T00:00:00Z', '');
      const endDate = values.ending_at.toString().replace('T00:00:00Z', '');
      onSubmit(e, {
        ...values,
        starting_at: `${startDate}T00:00:00Z`,
        ending_at: `${endDate}T00:00:00Z`,
      });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={(e) => { handleOnClose(e); }}
      header="New campaign"
      onSubmit={handleOnSubmit}
      submitLabel="Save"
      isLoading={isLoading}
    >
      <div className={classes.content}>
        <Box className={classes.buContainer}>
          <InputLabel required error={click ? !!errors.name : null}>
            Select business unit
          </InputLabel>
          <Typography variant="caption">
            Allow the users of this business unit to access the following campaign
          </Typography>
          <Searchable
            required
            filterSelectedOptions
            disableClearable
            disabled={isLoading || (isL2 || isL2b)}
            options={companyBranchesOptions}
            error={click ? errors.company_branch_id : ''}
            value={isHQ ? values.company_branch_id : user.companyBranch.id}
            onChange={(e, s) => handleChangeDropdown('company_branch_id', s, isHQ)}
          />
        </Box>
        <TextField
          required
          fullWidth
          label="Campaign name"
          name="name"
          variant="outlined"
          defaultValue={values.name}
          error={click ? !!errors.name : null}
          helperText={click ? errors.name : null}
          onChange={(e) => handleOnChange(e, true)}
          disabled={isLoading}
          InputLabelProps={{
            shrink: false,
            variant: 'standard',
            disableAnimation: true,
          }}
        />

        <DatePicker
          required
          label="Campaign period"
          value={{
            start: values.starting_at,
            end: values.ending_at,
          }}
          disabled={isLoading}
          error={click && (errors.starting_at || errors.ending_at) ? 'Campaign period is required' : null}
          onChange={handleOnChangeDate}
          onClear={handleOnClearDate}
        />

        <SearchableProject
          required
          multiple
          filterSelectedOptions
          label="Project name"
          value={values.project_ids || []}
          error={errors.project_ids}
          disabled={isLoading}
          onChange={(e, selected) => handleChangeMultipleDropdown(e, 'project_ids', selected, true)}
        />

        <TextField
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          name="description"
          label="Description"
          defaultValue={values.description}
          disabled={isLoading}
          onChange={(e) => handleOnChange(e)}
          className={classes.textarea}
          InputLabelProps={{
            shrink: false,
            variant: 'standard',
            disableAnimation: true,
          }}
        />
      </div>
      <Typography variant="body2">
        Learn more about campaigns&nbsp;
        <Link target="_blank" rel="noopener" href="https://support.mhub.my/hc/en-us/articles/360047473153-What-is-a-campaign-and-channel-">
          here
        </Link>
      </Typography>
    </Drawer>
  );
}

CampaignForm.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CampaignForm.defaultProps = {
  open: false,
  isLoading: false,
  onClose: () => { },
  onSubmit: () => { },
};
