import ReactGA from 'react-ga4';
import {
  autoAssignmentCreate,
  campaignCreate,
  channelCreate,
  creditCheckGenerated,
  creditCheckViewSuccess,
  enabledStages,
  formCreate,
  leadContacted,
  leadQualified,
  leadUnqualified,
  opportunityReservedBooked,
  viewProfile,
} from './const';
import config from '../config';

const ReactGAEvent = (props) => {
  const { name, ...rest } = props;

  if (enabledStages.includes(config.app.stage)
    && name) {
    if (name === 'login') {
      return ReactGA.event(name, {
        ...rest,
      });
    }

    let value = 0;
    switch (name) {
      case viewProfile:
        value = 0;
        break;
      case campaignCreate:
        value = 1;
        break;
      case leadContacted:
      case leadQualified:
      case leadUnqualified:
      case creditCheckViewSuccess:
        value = 2;
        break;
      case channelCreate:
        value = 5;
        break;
      case formCreate:
      case autoAssignmentCreate:
      case opportunityReservedBooked:
      case creditCheckGenerated:
        value = 10;
        break;
      default:
        break;
    }

    return ReactGA.event(name, {
      currency: 'MYR',
      value,
      ...rest,
    });
  }

  return null;
};

export default ReactGAEvent;
