import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const NotFound = ({ history }) => {
  const classes = useStyles();

  const handleClick = () => {
    history.push('/');
  };

  window.document.title = 'Not found';

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography variant="h1">Oops!</Typography>
      <Typography variant="body1" className={classes.bodyText}>
          The page that you requested was not found.
      </Typography>
      <Grid item>
        <Button
          variant="contained"
          className={classes.buttons}
          onClick={handleClick}
        >
          Go back
        </Button>
      </Grid>
    </Grid>
  );
};

NotFound.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default NotFound;
