export function oddOrEven(number) {
  // Make sure is a number
  const formattedNumber = Number.isInteger(number) ? number : Number(number);
  if (!Number.isNaN(formattedNumber)) {
    if (formattedNumber % 2 === 0) {
      return 'even';
    }
    return 'odd';
  }
  return '';
}

export function round(value, precision) {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function getPercentageNumber(dividend, divisor, precision) {
  if (dividend
    && !Number.isNaN(dividend)
    && dividend > 0
    && divisor
    && !Number.isNaN(divisor)
    && divisor > 0) {
    if (precision && precision > 0) {
      const percentage = (dividend / divisor) * 100;
      return round(percentage, precision);
    }
    return (Math.floor((dividend / divisor) * 100));
  }
  return 0;
}
