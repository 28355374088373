import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Icon } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { InfoFilledIcon } from '../../assets/icons';
import useStyles from './styles';

const InfoBanner = (props) => {
  const {
    className,
    type,
    content,
  } = props;

  const classes = useStyles();

  const renderIcon = () => {
    if (type === 'warning') {
      return <Warning />;
    }
    return (
      <Icon>
        <img alt="info" src={InfoFilledIcon} />
      </Icon>
    );
  };

  return (
    <Grid
      container
      wrap="nowrap"
      className={`${classes.banner} ${type === 'warning' ? 'warning' : 'info'} ${className}`}
    >
      { renderIcon() }
      <Box>
        { content }
      </Box>
    </Grid>
  );
};

InfoBanner.propTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  content: PropTypes.node,
  className: PropTypes.string,
};

InfoBanner.defaultProps = {
  type: 'info',
  content: null,
  className: '',
};

export default InfoBanner;
