import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// CREATE_USER_GROUP create user group GraphQL schema.
export const CREATE_USER_GROUP = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createUserGroup',
    args: [
      { name: 'name', type: 'String', required: true },
      { name: 'assignment_name', type: 'String', required: true },
      { name: 'description', type: 'String' },
      { name: 'user_ids', type: '[String]', required: true },
      { name: 'enabled', type: 'Boolean' },
      { name: 'reassignment', type: 'Boolean' },
      { name: 'type', type: 'String', required: true },
      { name: 'assignment_rules', type: 'String' },
      { name: 'reassignment_rules', type: 'String' },
    ],
    argsMap: [
      { name: 'name', value: 'name' },
      { name: 'assignment_name', value: 'assignment_name' },
      { name: 'description', value: 'description' },
      { name: 'user_ids', value: 'user_ids' },
      { name: 'enabled', value: 'enabled' },
      { name: 'reassignment', value: 'reassignment' },
      { name: 'type', value: 'type' },
      { name: 'assignment_rules', value: 'assignment_rules' },
      { name: 'reassignment_rules', value: 'reassignment_rules' },
    ],
    fields: '{...UserGroupFields}',
    fragments: [Fragments.userGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_USER_GROUP update user group GraphQL schema.
export const UPDATE_USER_GROUP = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateUserGroup',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'assignment_name', type: 'String', required: true },
      { name: 'description', type: 'String' },
      { name: 'user_ids', type: '[String]', required: true },
      { name: 'enabled', type: 'Boolean' },
      { name: 'reassignment', type: 'Boolean' },
      { name: 'type', type: 'String', required: true },
      { name: 'assignment_rules', type: 'String' },
      { name: 'reassignment_rules', type: 'String' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'name', value: 'name' },
      { name: 'assignment_name', value: 'assignment_name' },
      { name: 'description', value: 'description' },
      { name: 'user_ids', value: 'user_ids' },
      { name: 'enabled', value: 'enabled' },
      { name: 'reassignment', value: 'reassignment' },
      { name: 'type', value: 'type' },
      { name: 'assignment_rules', value: 'assignment_rules' },
      { name: 'reassignment_rules', value: 'reassignment_rules' },
    ],
    fields: '{...UserGroupFields}',
    fragments: [Fragments.userGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_USER_GROUP delete user group GraphQL schema.
export const DELETE_USER_GROUP = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteUserGroup',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...UserGroupFields}',
    fragments: [Fragments.userGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
