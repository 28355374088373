// Filter black img to respective colours
export const primary = {
  main: 'invert(62%) sepia(34%) saturate(581%) hue-rotate(130deg) brightness(93%) contrast(97%)',
};

export const secondary = {
  main: 'invert(34%) sepia(84%) saturate(3226%) hue-rotate(168deg) brightness(98%) contrast(106%)',
};

export const tertiary = {
  grey: 'invert(88%) sepia(4%) saturate(100%) hue-rotate(155deg) brightness(107%) contrast(80%)',
  lighterGrey: 'invert(42%) sepia(23%) saturate(316%) hue-rotate(163deg) brightness(89%) contrast(92%)',
  mediumGrey: 'invert(99%) sepia(1%) saturate(365%) hue-rotate(186deg) brightness(99%) contrast(96%)',

  space: 'invert(11%) sepia(28%) saturate(3587%) hue-rotate(177deg) brightness(89%) contrast(103%)',

  red: 'invert(13%) sepia(98%) saturate(6098%) hue-rotate(3deg) brightness(101%) contrast(123%)',
  peach: 'invert(64%) sepia(82%) saturate(1113%) hue-rotate(312deg) brightness(96%) contrast(112%)',
  darkRed: 'invert(19%) sepia(45%) saturate(5117%) hue-rotate(351deg) brightness(84%) contrast(94%)',
  darkGreen: 'invert(60%) sepia(53%) saturate(384%) hue-rotate(108deg) brightness(96%) contrast(85%)',
  invert: 'invert(41%) sepia(5%) saturate(1487%) hue-rotate(163deg) brightness(91%) contrast(83%)',
  lightOrange: 'invert(66%) sepia(18%) saturate(1152%) hue-rotate(330deg) brightness(97%) contrast(96%)',
  jade: 'invert(46%) sepia(89%) saturate(360%) hue-rotate(107deg) brightness(95%) contrast(88%)',
  brightBlue: 'invert(32%) sepia(91%) saturate(2094%) hue-rotate(206deg) brightness(97%) contrast(90%)',
  alertYellow: 'invert(82%) sepia(72%) saturate(1225%) hue-rotate(321deg) brightness(107%) contrast(98%)',
};

export const font = {
  body: 'invert(39%) sepia(11%) saturate(707%) hue-rotate(163deg) brightness(97%) contrast(87%)',
  subBody: 'invert(72%) sepia(7%) saturate(341%) hue-rotate(175deg) brightness(86%) contrast(84%)',
};
