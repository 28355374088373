import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    marginTop: 10,
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '& .MuiButton-label': {
      fontSize: '0.86rem',
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0.54rem',
    },
    '& .MuiButton-iconSizeSmall': {
      fontSize: '1.1rem',
    },
  },
}));
