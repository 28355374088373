import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import Drawer from '../../Drawer';
import {
  CloseIcon,
  EditIcon,
  TrashIcon,
} from '../../../assets/icons';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';
import { SnackbarContext } from '../../../contexts';
import useStyles from './styles';

const EditSavedFields = (props) => {
  const {
    dbStoreName,
    database,
    data,
    open,
    onClose,
    onUpdate,
  } = props;

  const classes = useStyles();

  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const [fields, setFields] = useState(data);
  const [savedName, setSavedName] = useState('');
  const [isEditField, setIsEditField] = useState('');

  useEffect(() => {
    if (data) {
      setFields(data);
    }
  }, [data]);

  const handleOpenEdit = (name) => {
    setSavedName(name);
    setFields(data);
    setIsEditField(name);
  };

  const handleClose = () => {
    setSavedName('');
    setIsEditField('');
    onClose();
  };

  const handleChange = (e, idx) => {
    const { value } = e.target;
    if (fields[idx]) {
      setSavedName(value);
    }
  };

  const handleCancel = () => {
    setSavedName('');
    setIsEditField('');
    setFields(data);
  };

  const handleDelete = async (key, idx) => {
    if (database && key) {
      try {
        await database.delete(dbStoreName, key);
      } catch {
        setOpenSnackbar({ variant: 'error', message: 'Failed to delete saved fields. Please try again.' });
      }
      fields.splice(idx, 1);
      setFields([...fields]);
      onUpdate();
    }
  };

  const handleUpdate = async (key, idx) => {
    const newFields = { ...fields };
    if (savedName) {
      newFields[idx] = {
        ...newFields[idx],
        name: savedName,
      };
    }
    setIsEditField('');
    if (database && key) {
      try {
        await database.put(
          dbStoreName,
          {
            name: newFields[idx].name,
            filters: newFields[idx].filters,
            columns: newFields[idx].columns || [],
          },
          key,
        );
      } catch {
        setOpenSnackbar({ variant: 'error', message: 'Failed to update name. Please try again.' });
      }
      setFields([...fields]);
      onUpdate();
    }
    setSavedName('');
  };

  const renderField = (name, key, idx) => {
    if (isEditField === name) {
      return (
        <>
          <Grid container>
            <TextField
              fullWidth
              variant="outlined"
              defaultValue={name}
              inputProps={{ maxLength: 25 }}
              onChange={(e) => handleChange(e, idx)}
            />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={1}
            className={classes.controls}
          >
            <Grid item>
              <Button
                size="small"
                variant="contained"
                disabled={!savedName}
                onClick={() => handleUpdate(key, idx)}
              >
                <SaveIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                onClick={handleCancel}
              >
                <img alt="edit" className={classes.icon} src={CloseIcon} />
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography>
            { name }
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleOpenEdit(name)}>
            <img alt="edit" className={classes.icon} src={EditIcon} />
          </IconButton>
          <IconButton onClick={() => handleDelete(key, idx)}>
            <img alt="edit" className={classes.icon} src={TrashIcon} />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Drawer
      header="Edit views"
      hideFooter
      open={open}
      onClose={handleClose}
    >
      {
        fields.length > 0 && fields.map((f, idx) => (
          <Fragment key={f.name}>
            { renderField(f.name, f.key, idx) }
          </Fragment>
        ))
      }
    </Drawer>
  );
};

EditSavedFields.propTypes = {
  dbStoreName: PropTypes.string,
  database: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Array),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

EditSavedFields.defaultProps = {
  dbStoreName: '',
  database: {},
  data: [],
  open: false,
  onClose: () => {},
  onUpdate: () => {},
};

export default EditSavedFields;
