// Check if an array contain value from another array
export function duplicateArrayValue(array1, array2) {
  return array1.some((v) => array2.includes(v));
}

// Convert string to array
export function splitString(string) {
  if (string) {
    return string.split(',');
  }
  return [];
}

// Join array to string
export function joinArray(array) {
  if (array && array.length > 0) {
    return array.join(',');
  }
  return '';
}
