// Use it in places that calls refetch but user can still unmount it while it's refetching
export default async (refetch, label) => {
  if (refetch) {
    try {
      await refetch();
    } catch (err) {
      // TODO: Just a workaround. Need to upgrade apollo client to fix this issue
      console.error(`[Work around handler] Failed to call ${label || ''} refetch due to component unmount.`);
    }
  }
};
