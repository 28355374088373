import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Link,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import SimpleModal from '../SimpleModal';
import LoadingButton from '../loader/LoadingButton';
import { useEnhancedMutation } from '../../hooks';
import { LEAD } from '../../graphql';
import { clientLead } from '../..';
import {
  formatValue,
  getCampaignName,
  getCountryLabel,
  getLabelFromList,
  getProjectName,
  isEmptyValue,
  previewDateTime,
  refetchPromise,
  toCapitalize,
  getInfoFromIC,
  getFieldLabel,
} from '../../utils';
import { AuthContext, SnackbarContext } from '../../contexts';
import { leadStage } from '../../data/settings';
import useStyles from './styles';

export default function MergePage(props) {
  const classes = useStyles();

  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;
  const authContext = useContext(AuthContext);
  const { listCompanyBranches } = authContext.state;

  const [loaders, setLoaders] = useState({
    mergeConfirmation: false,
  });
  const [masterLeadId, setMasterLeadId] = useState('');
  const [duplicateLeadIds, setDuplicateLeadIds] = useState([]);
  const [mergeValues, setMergeValues] = useState({});
  const [fieldsWithOptions, setFieldsWithOptions] = useState([]);

  const {
    users,
    campaigns,
    projects,
    fields,
    page,
    selectedLeads,
    activeStates,
    leadFieldSettings,
    refetchLead,
    toggleState,
    onClose,
  } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const currentId = match.params.id || '';

  const [mergeLeads] = useEnhancedMutation(LEAD.MERGE(), { client: clientLead });

  useEffect(() => {
    // Make the first selected leads as default
    if (page === 2 && selectedLeads.length && selectedLeads[0]) {
      const selectedLeadIds = selectedLeads.map((selected) => selected.id);
      const subDuplicateLeads = selectedLeadIds.filter((id) => id !== selectedLeads[0].id);
      setMasterLeadId(selectedLeads[0].id);
      setDuplicateLeadIds(subDuplicateLeads);
      const newValues = { ...selectedLeads[0] };
      Object.keys(newValues).forEach((key) => {
        if (typeof newValues[key] !== 'string' && typeof newValues[key] !== 'number') {
          newValues[key] = {
            id: selectedLeads[0].id,
            value: selectedLeads[0][key],
          };
        } else {
          newValues[key] = {
            id: selectedLeads[0].id,
            value: selectedLeads[0][key],
          };
        }
      });
      setMergeValues(newValues);
    }
  }, [
    page,
    selectedLeads,
  ]);

  useEffect(() => {
    if (page === 2 && selectedLeads.length && mergeValues.id) {
      const selectedLeadIds = selectedLeads.map((selected) => selected.id);
      const subDuplicateLeads = selectedLeadIds.filter((id) => id !== mergeValues.id.value);
      setMasterLeadId(mergeValues.id.value);
      setDuplicateLeadIds(subDuplicateLeads);
    }
  }, [
    page,
    selectedLeads,
    mergeValues,
  ]);

  useEffect(() => {
    if (leadFieldSettings) {
      const dropdowns = [];
      Object.keys(leadFieldSettings).forEach((category) => {
        if (leadFieldSettings[category].attributes) {
          Object.keys(leadFieldSettings[category].attributes).forEach((f) => {
            if (['dropdown', 'multiselections'].includes(leadFieldSettings[category].attributes[f].input_type)) {
              dropdowns.push({
                name: f,
                options: leadFieldSettings[category].attributes[f].options || [],
              });
            }
          });
        }
      });
      setFieldsWithOptions(dropdowns);
    }
  }, [leadFieldSettings]);

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const getBusinessUnitName = (id) => {
    let name = id || '';
    if (id && listCompanyBranches[id] && listCompanyBranches[id].name) {
      name = listCompanyBranches[id].name;
    }
    return name;
  };

  const formatStageLabel = (value) => {
    const currentStageInfo = leadStage.options.find((opt) => (opt.value === value));
    if (value === 'new') {
      return 'New';
    }
    return (currentStageInfo && currentStageInfo.label) || '';
  };

  const formatFieldValue = (value, name) => {
    // Get dropdown fields options label from settings
    const fieldIndex = fieldsWithOptions.findIndex((field) => field.name === name);
    if (fieldIndex && fieldIndex > -1) {
      const options = fieldsWithOptions[fieldIndex].options || [];
      // If is array, render as array of string
      if (Array.isArray(value)) {
        const formatted = value.map((val) => getLabelFromList(val, options)).toString();
        return formatted;
      }
      return getLabelFromList(value, options);
    }
    return !isEmptyValue(value) ? value : '';
  };

  const renderFieldValue = (name, item) => {
    switch (name) {
      case 'country_code':
      case 'nationality_country_code':
        return getCountryLabel(item[name]);
      case 'mobile':
        return item.msisdn || item.mobile || item.phone;
      case 'created_at':
      case 'updated_at':
        return previewDateTime(item[name]);
      case 'assigned_to':
        return item.assigned_to ? getUserName(item.assigned_to) : 'Unassigned';
      case 'created_by':
      case 'updated_by':
        return getUserName(item[name]);
      case 'submission_source':
        return item.form_name;
      case 'campaign_id':
        return getCampaignName(item, campaigns);
      case 'stage':
        return formatStageLabel(item[name]);
      case 'company_branch_id':
        return getBusinessUnitName(item[name]);
      default:
        return formatFieldValue(item[name], name);
    }
  };

  const toggleLoadingState = (name) => () => {
    setLoaders((s) => ({ ...s, [name]: !s[name] }));
  };

  const handleSelectMerge = (e, selected, value) => {
    const { checked, name } = e.target;
    if (checked) {
      if (name === 'mobile') {
        setMergeValues((prev) => ({
          ...prev,
          mobile_country_code: { id: selected.id, value: selected.mobile_country_code || '' },
          msisdn: { id: selected.id, value: selected.msisdn || '' },
          [name]: { id: selected.id, value },
        }));
      }
      if (name === 'form_name' && selected.form_id) {
        setMergeValues((prev) => ({
          ...prev,
          form_id: { id: selected.id, value: selected.form_id },
          [name]: { id: selected.id, value },
        }));
      } else {
        setMergeValues((prev) => ({
          ...prev,
          [name]: { id: selected.id, value },
        }));
      }
    }
  };

  const handleSelectMergeAll = (selected) => {
    const newValues = { ...selected };
    Object.keys(newValues).forEach((key) => {
      if (typeof newValues[key] !== 'string' && typeof newValues[key] !== 'number') {
        newValues[key] = {
          id: selected.id,
          value: selected[key],
        };
      } else {
        newValues[key] = {
          id: selected.id,
          value: selected[key],
        };
      }
    });
    setMergeValues(newValues);
  };

  const handleSelectMasterLead = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setMergeValues((prev) => ({
        ...prev,
        id: {
          id: value,
          value,
        },
      }));
    }
  };

  const getModuleName = () => {
    const arr = match.url.split('/');
    if (arr.length < 2) {
      return '';
    }
    const name = arr[1];
    return name;
  };

  const handleMerge = () => {
    toggleLoadingState('mergeConfirmation')();

    let newCampaignName = '';

    if (mergeValues.campaign_id
      && campaigns[mergeValues.campaign_id.value]
      && campaigns[mergeValues.campaign_id.value].name
    ) {
      newCampaignName = campaigns[mergeValues.campaign_id.value].name;
    } else {
      newCampaignName = '';
    }

    const newValues = {
      ...mergeValues,
      campaign_name: newCampaignName,
    };
    delete newValues.id;
    delete newValues.created_at;
    delete newValues.created_by;
    delete newValues.updated_at;
    delete newValues.updated_by;
    delete newValues.merged_by;
    delete newValues.merged_at;
    delete newValues.merged_to;
    delete newValues.merged_ids;

    // Remove id from each object and generate nric info
    Object.keys(newValues).forEach((key) => {
      newValues[key] = newValues[key].value || '';

      if (key === 'nric' && newValues[key]) {
        const nricInfo = getInfoFromIC(newValues[key]);
        newValues.date_of_birth = nricInfo.dateOfBirth;
        newValues.gender = nricInfo.gender;
      }
    });

    mergeLeads({
      variables: {
        lead_id: masterLeadId,
        duplicate_lead_ids: duplicateLeadIds,
        fields: JSON.stringify(newValues),
      },
    }).then(async (result) => {
      if (result && result.data) {
        setTimeout(async () => {
          setOpenSnackbar({
            variant: 'success',
            message: 'Leads merged successfully.',
          });
          if (getModuleName() === 'leads') {
            if (masterLeadId !== currentId) {
              history.replace(`/leads/${masterLeadId}`);
            } else if (refetchLead) {
              await refetchPromise(refetchLead, 'Merge lead');
            }
          }
          toggleLoadingState('mergeConfirmation')();
          toggleState('mergeConfirmation')();
          onClose();
        }, 2000);
      }
    });
  };

  const handleCloseModal = () => {
    if (!loaders.mergeConfirmation) {
      toggleState('mergeConfirmation')();
    }
  };

  const renderModalButtons = () => {
    if (loaders.mergeConfirmation) {
      return (
        <>
          <LoadingButton variant="outlined" />
          <LoadingButton variant="contained" />
        </>
      );
    }

    return (
      <>
        <Button
          variant="outlined"
          onClick={toggleState('mergeConfirmation')}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleMerge}
        >
          Confirm
        </Button>
      </>
    );
  };

  const renderValueType = (name, value) => {
    if (value) {
      if (typeof value === 'string') {
        let newValue = formatValue(value);
        if (name === 'project_ids') {
          newValue = getProjectName({ project_id: value }, projects);
        }
        return (
          <Typography variant="body2" className={classes.value}>
            { newValue }
          </Typography>
        );
      }

      if (typeof value === 'object') {
        const formatted = [];
        if (name === 'emergency_contacts') {
          const sortOrder = leadFieldSettings.emergency_contact
            && leadFieldSettings.emergency_contact.attributes
            ? Object.keys(leadFieldSettings.emergency_contact.attributes) : [];

          sortOrder.forEach((key) => {
            if (value[key]) {
              formatted.push(` ${formatValue(key)}: ${value[key]}`);
            }
          });
        } else {
          Object.entries(value).forEach(([key, v]) => {
            if (v) {
              formatted.push(` ${formatValue(key)}: ${v}`);
            }
          });
        }

        return (
          React.Children.toArray(formatted.map((v) => (
            <Typography variant="body2" className={classes.value}>
              { v }
            </Typography>
          )))
        );
      }
    }
    return null;
  };

  const renderValue = (field, selected) => {
    if (typeof selected[field.name] === 'string' || typeof selected[field.name] === 'number') {
      return (
        <Typography variant="body2" className={classes.value}>
          { renderFieldValue(field.name, selected) }
        </Typography>
      );
    }
    if (Array.isArray(selected[field.name])) {
      return (
        React.Children.toArray(selected[field.name].map((v) => (
          renderValueType(field.name, v)
        )))
      );
    }
    return (
      <Typography variant="body2" className={classes.value}>
        { renderFieldValue(field.name, selected) }
      </Typography>
    );
  };

  const renderCreateUpdateRow = (items, info) => {
    const { label, atKey, byKey } = info;
    return (
      <TableRow key={label}>
        <TableCell>
          <Typography variant="subtitle2">
            { label }
          </Typography>
        </TableCell>
        {
          React.Children.toArray(items.map((item) => (
            <TableCell>
              <Grid container direction="column" className={classes.createUpdateInfo}>
                <Grid item>
                  <Typography variant="body2" className={classes.value}>
                    { renderFieldValue(atKey, item) }
                  </Typography>
                </Grid>
                {
                  item[byKey] && (
                    <Grid item>
                      <Typography variant="body2">
                        {`by ${renderFieldValue(byKey, item)}`}
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>
            </TableCell>
          )))
        }
      </TableRow>
    );
  };

  const isAllSameValue = (items, key) => {
    // Remove those without the field value
    const list = items.filter((item) => item[key]).map((item) => item[key]);
    if (key === 'assigned_to' && list.length <= 0) {
      return true;
    }
    if (list.length === items.length) {
      // Consider same if all have values + same values
      const hasDuplicates = (new Set(list)).size === 1;
      return hasDuplicates;
    }
    return false;
  };

  const isCheck = (field, selected) => {
    if (
      mergeValues[field.name]
      && mergeValues[field.name].id
      && mergeValues[field.name].id === selected.id
    ) {
      // Special case for empty mobile to use msisdn
      if (field.name === 'mobile' && !selected[field.name] && selected.msisdn) {
        return mergeValues.msisdn.value === selected.msisdn;
      }
      if (mergeValues[field.name].value) {
        if (typeof selected[field.name] !== 'string' && typeof selected[field.name] !== 'number') {
          return (
            JSON.stringify(mergeValues[field.name].value) === JSON.stringify(selected[field.name])
          );
        }
        return mergeValues[field.name].value === selected[field.name];
      }
      // Speical case for assigned_to. To support empty string for Unassigned.
      if (field.name === 'assigned_to') {
        return !selected[field.name];
      }
    }
    return false;
  };

  const isDisabled = (field, selected) => {
    if (field.name === 'mobile') {
      return !(selected[field.name] || selected.msisdn);
    }
    return field.name !== 'assigned_to' && !selected[field.name];
  };

  const formatRadioValue = (value) => {
    if (value) {
      if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return value;
    }
    return '';
  };

  return (
    <>
      <Typography variant="body2" className={classes.topMessage}>
        Select the values that you want to retain.
        All related notes will be associated with the new record.
      </Typography>
      <section className={classes.section}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {
                selectedLeads.map((selected) => (
                  <TableCell key={selected.id} className={classes.cell}>
                    <Typography variant="subtitle1">
                      { toCapitalize(selected.name) || 'n/a' }
                    </Typography>
                    <Typography
                      variant="body2"
                      component={Link}
                      onClick={() => handleSelectMergeAll(selected)}
                    >
                      Select all
                    </Typography>
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Master record
                </Typography>
              </TableCell>
              {
                React.Children.toArray(selectedLeads.map((selected) => (
                  <TableCell>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item>
                        <Radio
                          classes={{ root: classes.radioRoot }}
                          name="master-record"
                          value={selected.id}
                          checked={masterLeadId === selected.id}
                          onChange={handleSelectMasterLead}
                          inputProps={{ 'aria-label': 'Master record' }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body2" className={classes.value}>
                          Use as master
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                )))
              }
            </TableRow>
            {
              fields.map((field) => (
                selectedLeads.some((selected) => field.name in selected)
                && !isAllSameValue(selectedLeads, field.name)
                && (
                  <TableRow key={field.name}>
                    <TableCell className={classes.cellLabel}>
                      <Typography variant="subtitle2">
                        { field.label }
                      </Typography>
                    </TableCell>
                    {
                      React.Children.toArray(selectedLeads.map((selected) => (
                        <TableCell className={classes.cell}>
                          <Grid container alignItems="center" wrap="nowrap">
                            <Grid item>
                              <Radio
                                classes={{ root: classes.radioRoot }}
                                name={field.name}
                                value={formatRadioValue(selected[field.name])}
                                disabled={isDisabled(field, selected)}
                                checked={isCheck(field, selected)}
                                onChange={(e) => handleSelectMerge(
                                  e,
                                  selected,
                                  selected[field.name],
                                )}
                                inputProps={{ 'aria-label': field.name }}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              { renderValue(field, selected) }
                            </Grid>
                          </Grid>
                        </TableCell>
                      )))
                    }
                  </TableRow>
                )
              ))
            }
            {
              [
                { label: getFieldLabel('created_at'), atKey: 'created_at', byKey: 'created_by' },
                { label: 'Updated on', atKey: 'updated_at', byKey: 'updated_by' },
              ].map((info) => renderCreateUpdateRow(selectedLeads, info))
            }
          </TableBody>
        </Table>
      </section>
      <SimpleModal
        open={activeStates.mergeConfirmation}
        title="Merge leads"
        content={(
          <>
            <Typography>
              { `You are about to merge ${selectedLeads.length} leads` }
            </Typography>
            <Typography variant="caption">
              Note: You cannot undo this
            </Typography>
          </>
        )}
        buttons={renderModalButtons()}
        onCancel={handleCloseModal}
      />
    </>
  );
}

MergePage.propTypes = {
  page: PropTypes.number,
  users: PropTypes.instanceOf(Object),
  projects: PropTypes.instanceOf(Object),
  campaigns: PropTypes.instanceOf(Object),
  activeStates: PropTypes.instanceOf(Object),
  leadFieldSettings: PropTypes.instanceOf(Object),
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  selectedLeads: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  refetchLead: PropTypes.func,
  toggleState: PropTypes.func,
  onClose: PropTypes.func,
};

MergePage.defaultProps = {
  page: 1,
  users: {},
  projects: {},
  campaigns: {},
  activeStates: {},
  leadFieldSettings: {},
  fields: [],
  selectedLeads: [],
  refetchLead: null,
  toggleState: () => {},
  onClose: () => {},
};
