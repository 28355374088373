import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {
  AddButton,
  LoadingButton,
  SimpleModal,
} from '..';
import { AddAudience } from '../../pages/Audience/components';
import {
  useEnhancedMutation,
  useEnhancedQuery,
} from '../../hooks';
import { AUDIENCE } from '../../graphql';
import { Searchable } from '../Dropdown';
import { clientAudience } from '../..';
import useStyles from './styles';

const AudienceGroupModal = (props) => {
  const {
    data,
    open,
    onConfirm,
    onClose,
  } = props;

  const classes = useStyles();

  const [loaders, setLoaders] = useState({
    modal: true,
    audienceGroupDrawer: false,
  });
  const [list, setList] = useState([]);
  const [selections, setSelections] = useState([]);
  const [activeStates, setActiveStates] = useState({
    audienceGroupDrawer: false,
  });

  const [createAudienceGroup] = useEnhancedMutation(AUDIENCE.CREATE(),
    { client: clientAudience });

  const queryListAudienceGroups = useEnhancedQuery(AUDIENCE.LIST(), {
    options: {
      client: clientAudience,
      variables: {
        all: true,
      },
    },
    defaultData: {
      listAudienceGroups: {
        items: [],
        count: 0,
      },
    },
    selector: (d) => d.listAudienceGroups,
  });

  useEffect(() => {
    if (!queryListAudienceGroups.loading
      && !queryListAudienceGroups.error
      && queryListAudienceGroups.data.items
    ) {
      setLoaders((prev) => ({
        ...prev,
        modal: false,
      }));
      // Return records with id/name/count only
      const audienceGroupsOptions = queryListAudienceGroups.data.items.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      const addNewAudience = { label: 'New audience', value: 'addAudience' };
      audienceGroupsOptions.push(addNewAudience);
      setList(audienceGroupsOptions);
    }
  }, [
    queryListAudienceGroups.loading,
    queryListAudienceGroups.error,
    queryListAudienceGroups.data.items,
  ]);

  useEffect(() => {
    if (!open && !data) {
      setSelections([]);
    }
    if ((!open || open) && data) {
      const ids = data.map((id) => id);
      setSelections(ids);
    }
  }, [open, data]);

  const toggleState = (name, state) => () => {
    setActiveStates((s) => ({ ...s, [name]: state || !s[name] }));
  };

  const toggleLoadingState = (name, bool) => () => {
    setLoaders((s) => ({ ...s, [name]: bool }));
  };

  const handleOnChange = (e, value) => {
    const selected = value.filter((id) => id !== 'addAudience');
    setSelections(selected);
  };

  const handleAddAudienceGroup = (e, values) => {
    toggleLoadingState('audienceGroupDrawer', true)();
    toggleLoadingState('modal', true)();
    createAudienceGroup({
      variables: {
        ...values,
      },
    }).then((result) => {
      setTimeout(() => {
        if (result) {
          queryListAudienceGroups.refetch();
          toggleState('audienceGroupDrawer')();
        }
        toggleLoadingState('audienceGroupDrawer', false)();
        toggleLoadingState('modal', false)();
      }, 2000);
    });
  };

  const handleConfirm = () => {
    onConfirm(selections);
  };

  const handleClose = () => {
    if (!loaders.modal && !loaders.audienceGroupDrawer) {
      onClose();
    }
  };

  const getSelectionObject = () => {
    const selected = [];
    selections.forEach((id) => {
      const audienceFound = list.find((val) => val.value === id);
      selected.push(audienceFound);
    });
    return selected || [];
  };

  const renderButton = () => {
    const disable = selections.length <= 0 || loaders.modal || loaders.audienceGroupDrawer;
    if (loaders.modal) {
      return (
        <LoadingButton variant="contained" />
      );
    }
    return (
      <Button
        variant="contained"
        disabled={disable}
        onClick={handleConfirm}
      >
        Add
      </Button>
    );
  };

  return (
    <>
      <SimpleModal
        title="Audience group"
        open={open}
        onCancel={handleClose}
        content={(
          <>
            <Typography
              variant="body2"
              className={classes.description}
            >
              Select the audiences you would like to reach.
            </Typography>
            <Searchable
              multiple
              label="New audience"
              options={list}
              value={selections}
              disabled={loaders.modal || loaders.audienceGroupDrawer}
              renderOption={(option) => {
                const audienceGroup = list.find((audience) => audience.value === option.value);
                const index = selections.findIndex((id) => id === audienceGroup.value);
                const checked = index > -1;
                return (
                  <>
                    {
                      option.value === 'addAudience'
                        ? (
                          <div
                            key="add-audience-group"
                            value="add-audience-group"
                            className={classes.menuItem}
                          >
                            <AddButton
                              className={classes.addButton}
                              label="New audience"
                              onClick={() => toggleState('audienceGroupDrawer')()}
                            />
                          </div>
                        )
                        : (
                          <div
                            key={option.value}
                            value={option.value}
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={checked}
                              className={classes.checkbox}
                            />
                            { option.label }
                          </div>

                        )
                    }
                  </>
                );
              }}
              onChange={handleOnChange}
              renderTags={() => null}
            />
            {
              getSelectionObject().length > 0 && (
                <section className={classes.chipContainer}>
                  {
                     getSelectionObject().map((audience) => (
                       <Chip
                         key={audience && audience.value}
                         label={audience && audience.label}
                         className={classes.chip}
                       />
                     ))
                  }
                </section>
              )
            }
          </>
        )}
        buttons={renderButton()}
      />
      <AddAudience
        isLoading={loaders.audienceGroupDrawer}
        open={activeStates.audienceGroupDrawer}
        onSubmit={handleAddAudienceGroup}
        onClose={!loaders.audienceGroupDrawer ? toggleState('audienceGroupDrawer') : null}
      />
    </>
  );
};

AudienceGroupModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

AudienceGroupModal.defaultProps = {
  data: {},
  open: false,
  onConfirm: () => {},
  onClose: () => {},
};

export default AudienceGroupModal;
