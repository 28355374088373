import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../../contexts';

export default makeStyles((theme) => ({
  callIcon: {
    filter: filters.font.body,
    height: '1.4rem',
    width: '1.4rem',
  },
  listContentBox: {
    overflow: 'auto',
    flex: 1,
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
    '& .icon': {
      filter: 'invert(0.4)',
      width: '1.5rem',
      height: '1.5rem',
    },
    '& .icon.noteIcon': {
      marginRight: '0.5rem',
    },
    '& .addButton': {
      padding: 0,
    },
    '& > div:not(:first-child)': {
      paddingTop: '1.5rem',
    },
    '&.headerPaddingTop': {
      [theme.breakpoints.down('sm')]: {
        '& > div': {
          paddingTop: '1.5rem',
          maxHeight: '300px',
        },
      },
    },
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      marginRight: '0.5rem',
    },
  },
  caption: {
    marginTop: '0.5rem',
    color: theme.palette.font.subBody,
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
  loadingText: {
    color: theme.palette.font.subBody,
  },
  logsContainer: {
    minHeight: '10rem',
    '& > p': {
      color: theme.palette.font.subBody,
    },
  },
  tagSection: {
    '& > button': {
      marginLeft: '0.5rem',
    },
  },
}));
