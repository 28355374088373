import * as CONSTRUCTOR from '../generate';

export const rescheduledInfo = CONSTRUCTOR.FRAGMENT({
  name: 'RescheduledInfo',
  fields: `{
    created_at
    created_by
    old_starting_at
    old_ending_at
    starting_at
    ending_at
  }`,
});

export const appointment = CONSTRUCTOR.FRAGMENT({
  name: 'Appointment',
  fields: `{
    id
    company_id
    company_branch_id
    opportunity_id
    opportunity_primary_customer_id
    name
    type
    note_id
    note_message
    cancellation_reason
    status
    starting_at
    ending_at
    location
    assigned_to
    customer_ids
    meeting_url
    rescheduled {...RescheduledInfoFields}
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
  fragments: [rescheduledInfo],
});

export const getAppointmentMetricsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetAppointmentMetricsResponse',
  fields: `{
    upcoming_appointment_counts
    pending_appointment_counts
    completed_appointment_counts
    cancelled_appointment_counts
    rescheduled_appointment_counts
  }`,
});

export const customerInfo = CONSTRUCTOR.FRAGMENT({
  name: 'CustomerInfo',
  fields: `{
    id
    company_id
    type
    name
    contact_ids
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
});

export const opportunity = CONSTRUCTOR.FRAGMENT({
  name: 'Opportunity',
  fields: `{
    id
    lead_id
    primary_customer_id
    customer_ids
    submission_id
    stage
    lost_reason
    cancellation_reason_id
    source
    form_id
    campaign_id
    campaign_name
    channel_id
    channel_name
    project_id
    project_name
    project_site_plan_id
    project_sales_chart_type
    unit_id
    unit_number
    unit_size
    unit_spa_value
    unit_spa_currency
    unit_status_id
    unit_status_name
    unit_status_description
    unit_layout_type
    unit_created_at
    unit_updated_at
    utm_campaign
    utm_source
    utm_medium
    utm_term
    utm_content
    assigned_to
    assigned_by
    assigned_at
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

export const opportunityInfo = CONSTRUCTOR.FRAGMENT({
  name: 'OpportunityInfo',
  fields: `{
    id
    lead_id
    primary_customer_id
    customer_ids
    removed_customer_ids
    submission_id
    stage
    lost_reason
    cancellation_reason_id
    source
    form_id
    campaign_id
    campaign_name
    channel_id
    channel_name
    project_id
    project_name
    project_site_plan_id
    project_sales_chart_type
    unit_id
    unit_number
    unit_size
    unit_spa_value
    unit_spa_currency
    unit_status_id
    unit_status_name
    unit_status_description
    unit_layout_type
    unit_created_at
    unit_updated_at
    utm_campaign
    utm_source
    utm_medium
    utm_term
    utm_content
    upcoming_appointment_counts
    pending_appointment_counts
    booking_id
    assigned_to
    assigned_by
    assigned_at
    created_at
    created_by
    updated_at
    updated_by
    primary_customer {...CustomerInfoFields}
    customers {...CustomerInfoFields}
  }`,
  fragments: [customerInfo],
});

// GetOpportunityResponse fields fragment.
export const getOpportunityResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetOpportunityResponse',
  fields: `{
    opportunity {...OpportunityInfoFields}
  }`,
  fragments: [opportunityInfo],
});

// ListOpportunitiesResponse fields fragment.
export const listOpportunitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListOpportunitiesResponse',
  fields: `{
    opportunities {...OpportunityInfoFields}
    count
    pit_id
    search_afters
  }`,
  fragments: [opportunityInfo],
});
