import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import {
  useRouteMatch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AuthContext, PageContext } from '../../contexts';
import Drawer from '../Drawer';
import ViewPage from './ViewPage';
import MergePage from './MergePage';
import { ROLE_HEADQUARTERS } from '../../data';

export default function DuplicateDrawer(props) {
  const authContext = useContext(AuthContext);
  const { user, users } = authContext.state;
  const pageContext = useContext(PageContext);
  const { leadFieldSettings, projects, campaigns } = pageContext.state;

  const [page, setPage] = useState(1);
  const [fields, setFields] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [activeStates, setActiveStates] = useState({
    mergeConfirmation: false,
  });

  const {
    open,
    duplicateCustomers,
    duplicateLeads,
    refetchLead,
    onClose,
  } = props;

  const match = useRouteMatch();

  useEffect(() => {
    const flattenFields = [{ name: 'stage', label: 'Stage' }];
    Object.keys(leadFieldSettings).forEach((type) => {
      if (type === 'emergency_contact') {
        flattenFields.push({ name: 'emergency_contacts', label: 'Emergency contacts' });
      } else if (leadFieldSettings[type].attributes) {
        Object.keys(leadFieldSettings[type].attributes).forEach((name) => (
          flattenFields.push({
            name,
            label: leadFieldSettings[type].attributes[name].label,
          })
        ));
      }
    });

    const systemFields = [
      { name: 'project_ids', label: 'Project name' },
      { name: 'campaign_id', label: 'Campaign name' },
      { name: 'form_name', label: 'Submission source' },
      { name: 'assigned_to', label: 'Assignee' },
    ];

    const isHQ = user.role === ROLE_HEADQUARTERS;
    if (isHQ) {
      systemFields.push({ name: 'company_branch_id', label: 'Business unit' });
    }

    flattenFields.push(...systemFields);

    setFields(flattenFields);
  }, [leadFieldSettings, user]);

  useEffect(() => {
    const getModuleName = () => {
      const arr = match.url.split('/');
      if (arr.length < 2) {
        return '';
      }
      const name = arr[1];
      return name;
    };

    if (
      getModuleName() === 'leads'
      && match.params.id
    ) {
      const haveCurrentLead = selectedLeads.find((selected) => selected.id === match.params.id);
      if (!haveCurrentLead) {
        const currentLead = duplicateLeads.find((lead) => lead.id === match.params.id);
        if (currentLead) {
          setSelectedLeads([{
            ...currentLead,
            msisdn: currentLead.msisdn || '',
            mobile_country_code: currentLead.mobile_country_code || '',
            mobile: currentLead.mobile || '',
            assigned_to: currentLead.assigned_to || '',
          }]);
        }
      }
    }
  }, [
    match,
    duplicateLeads,
    selectedLeads,
  ]);

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const handleNext = () => {
    setPage(2);
  };

  const handleBack = () => {
    setPage(1);
  };

  const handleClose = () => {
    setSelectedLeads([]);
    setPage(1);
    onClose();
  };

  const renderPageContent = () => {
    switch (page) {
      case 2:
        return (
          <MergePage
            page={page}
            fields={fields}
            users={users}
            projects={projects}
            campaigns={campaigns}
            selectedLeads={selectedLeads}
            activeStates={activeStates}
            leadFieldSettings={leadFieldSettings}
            refetchLead={refetchLead}
            toggleState={toggleState}
            onClose={handleClose}
          />
        );
      default:
        return (
          <ViewPage
            user={user}
            users={users}
            projects={projects}
            duplicateLeads={duplicateLeads}
            duplicateCustomers={duplicateCustomers}
            selectedLeads={selectedLeads}
            setSelectedLeads={setSelectedLeads}
          />
        );
    }
  };

  return (
    <Drawer
      isWideDrawer
      header="Duplicates"
      open={open}
      submitLabel={page === 1 ? 'Next' : 'Merge'}
      onSubmit={page === 1 ? handleNext : toggleState('mergeConfirmation')}
      isSubmitDisabled={selectedLeads.length < 2 || _.isEmpty(duplicateLeads)}
      cancelLabel="Back"
      onCancel={handleBack}
      showCancel={page === 2}
      onClose={handleClose}
    >
      { renderPageContent() }
    </Drawer>
  );
}

DuplicateDrawer.propTypes = {
  open: PropTypes.bool,
  duplicateCustomers: PropTypes.instanceOf(Object),
  duplicateLeads: PropTypes.instanceOf(Object),
  refetchLead: PropTypes.func,
  onClose: PropTypes.func,
};

DuplicateDrawer.defaultProps = {
  open: false,
  duplicateCustomers: {},
  duplicateLeads: {},
  refetchLead: null,
  onClose: () => {},
};
