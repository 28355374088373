import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Auth GraphQL Mutations.

// CREATE create lead GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createAPIKey',
    args: [
      { name: 'type', type: 'String', required: true },
      { name: 'key', type: 'String' },
      { name: 'developer_id', type: 'String' },
      { name: 'outbound_api_key', type: 'String' },
      { name: 'company_branch_id', type: 'String' },
      { name: 'description', type: 'String' },
    ],
    argsMap: [
      { name: 'type', value: 'type' },
      { name: 'key', value: 'key' },
      { name: 'developer_id', value: 'developer_id' },
      { name: 'outbound_api_key', value: 'outbound_api_key' },
      { name: 'company_branch_id', value: 'company_branch_id' },
      { name: 'description', value: 'description' },
    ],
    fields: '{...APIKeyFields}',
    fragments: [Fragments.apikey],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update api key GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateAPIKey',
    args: [
      { name: 'type', type: 'String', required: true },
      { name: 'key', type: 'String', required: true },
      { name: 'id', type: 'String' },
      { name: 'developer_id', type: 'String' },
      { name: 'outbound_api_key', type: 'String' },
      { name: 'company_branch_id', type: 'String' },
      { name: 'description', type: 'String' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'type', value: 'type' },
      { name: 'key', value: 'key' },
      { name: 'outbound_api_key', value: 'outbound_api_key' },
      { name: 'developer_id', value: 'developer_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
      { name: 'description', value: 'description' },
    ],
    fields: '{...APIKeyFields}',
    fragments: [Fragments.apikey],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE delete api key GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteAPIKey',
    args: [
      { name: 'type', type: 'String', required: true },
      { name: 'key', type: 'String', required: true },
      { name: 'id', type: 'String' },
    ],
    argsMap: [
      { name: 'type', value: 'type' },
      { name: 'key', value: 'key' },
      { name: 'id', value: 'id' },
    ],
    fields: '{...APIKeyFields}',
    fragments: [Fragments.apikey],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
