import React, {
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PageContext } from '../../../contexts';

import useStyles from './styles';

const SaveFields = (props) => {
  const {
    data,
    onCancel,
    onSubmit,
  } = props;

  const pageContext = useContext(PageContext);
  const {
    savedFields,
  } = pageContext.state;

  const classes = useStyles(props);

  const [name, setName] = useState(data);
  const [errors, setErrors] = useState({});

  const debounceCallBack = _.debounce((callBack) => {
    callBack();
  }, 200);

  const handleChange = (e) => {
    const { value } = e.target;
    // Don't allow duplicate filter name
    debounceCallBack(() => {
      if (savedFields.length > 0) {
        const isExists = savedFields.filter((filter) => filter.name === value).length > 0;
        if (isExists) {
          setErrors({
            name: 'Filter name already exists',
          });
        } else {
          setErrors({});
        }
      }
      setName(value);
    });
  };

  const handleCancel = (e) => {
    onCancel(e);
  };

  const handleSubmit = () => {
    onSubmit(name);
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.modalTopBar}
        container
        justify="space-between"
        alignItems="center"
      >
        <Typography className={classes.modalTitle}>Save view</Typography>
        <IconButton className={classes.iconClose} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <div>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          inputProps={{ maxLength: 25 }}
          defaultValue={data}
          error={!!errors.name}
          helperText={errors.name}
          onChange={handleChange}
        />
      </div>
      <div className={classes.controls}>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!name || !!errors.name} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

SaveFields.propTypes = {
  data: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

SaveFields.defaultProps = {
  data: '',
  onCancel: () => {},
  onSubmit: () => {},
};

export default SaveFields;
