import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Snackbar, { initialState } from './context';

export default function SnackbarProvider(props) {
  const { children } = props;
  const timeoutRef = useRef(null);
  const setTimeoutRef = (selected) => { timeoutRef.current = selected; };

  const [context, setContext] = useState(initialState);

  const setOpenSnackbar = useCallback((state) => {
    // Instead of using autoHideDuration prop,
    // We manually settimeout so that the timer will always reset
    // if there's new error pops up
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setTimeoutRef(null);
    }

    setTimeoutRef(setTimeout(() => {
      setContext((prev) => ({
        ...prev,
        snackbar: { ...prev.snackbar, open: false },
      }));
    }, 5000));

    setContext((prev) => ({
      ...prev,
      snackbar: { ...state, open: true },
    }));
  }, []);

  const setCloseSnackbar = useCallback(() => {
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setTimeoutRef(null);
    }

    setContext((prev) => ({
      ...prev,
      snackbar: { ...prev.snackbar, open: false },
    }));
  }, []);

  const value = {
    state: context,
    actions: {
      setOpenSnackbar,
      setCloseSnackbar,
    },
  };

  return (
    <Snackbar.Provider value={value}>
      {children}
    </Snackbar.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
