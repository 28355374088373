import { CONSTRUCTOR } from '..';

// APIKey fields fragment.
export const apikey = CONSTRUCTOR.FRAGMENT({
  name: 'APIKey',
  fields: `{
    key
    id
    name
    company_id
    developer_id
    outbound_api_key
    type
    status
    description
    company_branch_id
    created_at
    created_by
  }`,
});

// ListAPIKeysResponse fields fragment.
export const listAPIKeysResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListAPIKeysResponse',
  fields: `{
    items {...APIKeyFields}
    count
  }`,
  fragments: [apikey],
});
