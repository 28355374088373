/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../contexts/Auth';

import { getQuery, decodeRedirect } from '../utils/url';

const RouteUnauthenticated = ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthContext.Consumer>
        {({ state }) => {
          if (!state.isAuthenticated || !state.hasAccess) {
            return <C {...props} {...cProps} />;
          }
          const { redirect } = getQuery();
          if (redirect) {
            let url = decodeRedirect(redirect);
            if (url.startsWith(window.location.origin)) {
              url = url.replace(window.location.origin, '');
            }
            return <Redirect from="/" to={url} />;
          }
          return <Redirect to="/" />;
        }}
      </AuthContext.Consumer>
    )}
  />
);

RouteUnauthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default RouteUnauthenticated;
