import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    '& > p:first-child': {
      color: theme.palette.font.header,
    },
  },
  red: {
    color: theme.palette.error.main,
  },
}));
