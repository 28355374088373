import React from 'react';
import PropTypes from 'prop-types';

import { Icon, IconButton } from '@material-ui/core';
import { ArrowIcon, BurgerMenuIcon } from '../../assets/icons';

import { useStyles, toggleButtonStyles } from './styles';

const ToggleButton = (props) => {
  const { collapsed, onToggle } = props;

  const classes = useStyles();
  const wrapperClasses = toggleButtonStyles(props, 'wrapper');
  const buttonClasses = toggleButtonStyles(props, 'button');

  return (
    <div className={wrapperClasses.root}>
      <IconButton
        size="small"
        aria-label={collapsed ? 'Close' : 'Open'}
        classes={buttonClasses}
        onClick={onToggle}
      >
        { !collapsed
          ? (
            <Icon className={classes.icon}>
              <img alt="menu" src={ArrowIcon} />
            </Icon>
          )
          : (
            <Icon className={classes.icon}>
              <img alt="menu" src={BurgerMenuIcon} />
            </Icon>
          )}
      </IconButton>
    </div>
  );
};

ToggleButton.propTypes = {
  collapsed: PropTypes.bool,
  onToggle: PropTypes.func,
};

ToggleButton.defaultProps = {
  collapsed: true,
  onToggle: () => {},
};

export default ToggleButton;
