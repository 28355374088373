import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Fade,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';
import { ReactComponent as FileJPGIcon } from '../../../../assets/icons/file_jpg.svg';
import { ReactComponent as FilePDFIcon } from '../../../../assets/icons/file_pdf.svg';
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reload.svg';

export default function UploadError(props) {
  const classes = useStyles();
  const { f, retry } = props;
  const { name, type } = f;

  const theme = useTheme();

  const handleClick = () => {
    retry(name, f);
  };

  return (
    <Fade in timeout={600}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        classes={{
          container: classes.fileDetailContainer,
        }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Grid item xs={1} className={classes.fileTypeIconContainer}>
          {type === 'pdf' ? <FilePDFIcon /> : <FileJPGIcon />}
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
            className={classes.paddingLeft}
          >
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="caption">
              <span className={classes.redText}>Upload failed</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              className={classes.button}
              startIcon={<ReloadIcon />}
              onClick={handleClick}
            >
              Try again
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
}

UploadError.propTypes = {
  f: PropTypes.instanceOf(Object),
  retry: PropTypes.func,

};

UploadError.defaultProps = {
  f: {},
  retry: () => {},
};
