import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { ArrowIcon } from '../../assets/icons';
import Button from '../Button';
import useStyles from './styles';

const BackButton = (props) => {
  const {
    disabled,
    onClick,
  } = props;

  const classes = useStyles();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Box
      mb={1.25}
      className={`${classes.root} ${disabled ? 'disabled' : ''}`}
    >
      <Button
        size="small"
        onClick={handleClick}
        disabled={disabled}
        startIcon={<img alt="back" src={ArrowIcon} className={classes.icon} />}
        className={classes.button}
      >
        <Typography variant="subtitle1">
          Back
        </Typography>
      </Button>
    </Box>
  );
};

BackButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default BackButton;
