import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { openDB } from 'idb';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PrimaryNav from './PrimaryNav';
import SecondaryNav from './SecondaryNav';
import AppNavToggleButton from './ToggleButton';
import SimpleModal from '../SimpleModal';
import logo from '../../assets/logo/mhub_logo_desktop.svg';
import { EditIcon } from '../../assets/icons';
import EditSavedFields from '../Filter/EditSavedFields';
import {
  PageContext,
  AuthContext,
} from '../../contexts';
import config from '../../config';
import { useAppNavStyles } from './styles';

const AppNav = (props) => {
  const authContext = useContext(AuthContext);
  const {
    hasHigherAccess,
    user,
    apps,
  } = authContext.state;
  const pageContext = useContext(PageContext);
  const {
    lastReleaseNoteVersionSeen,
    navigations,
    indexedDB,
    indexedDBInfo,
    savedFields,
  } = pageContext.state;
  const {
    setWhatsNewModal,
    setIndexedDB,
    setIndexeDBInfo,
    getSavedFields,
    setCustomNavSection,
    setSavedFields,
  } = pageContext.actions;

  const classes = useAppNavStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { showMenu, toggleMenu } = props;

  const [collapse, setCollapse] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('leads');
  const [activeStates, setActiveStates] = useState({
    editSavedFields: false,
    oldFilterWarning: false,
  });

  const { pathname, search } = window.location;

  // TODO: Enabled me if zendesk widget chat support is enabled again in the future
  // const handleContactUs = () => {
  //   if (!isDesktop) {
  //     toggleMenu();
  //   }
  //   openSupportWidget();
  // };

  const path = `${pathname}${search}`;
  const newReleaseNoteAvailable = (
    !lastReleaseNoteVersionSeen
    || (lastReleaseNoteVersionSeen && lastReleaseNoteVersionSeen !== config.app.version)
  );

  const data = {
    leads: [
      {
        name: 'leads',
        header: 'Leads',
        items: [
          {
            text: 'All leads',
            path: '/leads?page=1',
          },
          {
            text: 'My leads',
            path: `/leads?assignee=current_user&assigned_to=${user.id}&page=1`,
          },
          {
            text: 'Unassigned leads',
            path: '/leads?assignee=unassigned&assigned_to=unassigned&page=1',
          },
          {
            text: 'Unqualified leads',
            path: '/leads?stage=unqualified&page=1',
          },
          {
            text: 'Deleted leads',
            path: '/leads?deleted=true&page=1',
          },
          {
            text: 'Past exports',
            path: '/leads/past_exports',
          },
        ],
      },
      ...navigations,
    ],
    customers: [
      {
        name: 'customers',
        header: 'Customers',
        items: [
          {
            text: 'All customers',
            path: '/customers?page=1',
          },
          {
            text: 'Past imports',
            path: '/customers/past_imports',
          },
          {
            text: 'Past exports',
            path: '/customers/past_exports',
          },
        ],
      },
      {
        hide: !(config.app.enableCreditCheck && user.role === 'Headquarters'),
        name: 'credit_check',
        header: 'Credit check',
        items: [
          {
            text: 'All credit check',
            path: '/customers/credit_check',
          },
        ],
      },
      ...navigations,
    ],
    opportunities: [
      {
        name: 'opportunities',
        header: 'Opportunities',
        items: [
          {
            text: 'All opportunities',
            path: '/opportunities?page=1',
          },
          {
            text: 'Potential opportunities',
            path: '/opportunities?nav_param=nav_potential&stage=new,follow_up,reserved,booked',
          },
          {
            text: 'Lost opportunities',
            path: '/opportunities?nav_param=nav_stage_lost&stage=lost&fields=project_name,unit_spa_value,customers,stage,lost_reason,assigned_to,created_at',
          },
          {
            text: 'Appointment calendar',
            path: '/opportunities/appointment',
          },
        ],
      },
      ...navigations,
    ],
    campaigns: [
      {
        name: 'campaigns',
        header: 'Campaigns',
        items: [
          {
            text: 'All campaigns',
            path: '/campaigns?page=1',
          },
          {
            hide: !hasHigherAccess,
            text: 'All submissions',
            path: '/campaigns/submissions?page=1',
          },
          {
            hide: !hasHigherAccess,
            text: 'Invalid submissions',
            path: '/campaigns/submissions?invalid=true&page=1',
          },
          {
            hide: !hasHigherAccess,
            text: 'Past exports',
            path: '/campaigns/submissions/past_exports',
          },
        ],
      },
      {
        name: 'audiences',
        header: 'Audiences',
        hide: !hasHigherAccess,
        items: [
          {
            hide: !hasHigherAccess,
            text: 'All audiences',
            path: '/audiences',
          },
        ],
      },
      ...navigations,
    ],
    // chats: [
    //   {
    //     header: 'Chat',
    //     items: [
    //       {
    //         text: 'All chats',
    //         path: '/chats',
    //       },
    //     ],
    //   },
    //   ...navigations,
    // ],
    settings: [
      {
        name: 'data',
        header: 'Data',
        items: [
          {
            text: 'All fields',
            path: '/settings/fields',
          },
        ],
      },
      {
        name: 'automation',
        header: 'Automation',
        items: [
          {
            text: 'Auto assignment',
            path: '/settings/auto_assignment',
          },
          {
            text: 'Workflow',
            path: '/settings/workflow',
          },
        ],
      },
      {
        name: 'campaign',
        header: 'Campaign settings',
        items: [
          {
            text: 'API integrations',
            path: '/settings/api_integrations',
          },
          {
            text: 'Call settings',
            path: '/settings/call',
            hide: !(apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess),
          },
          {
            text: 'Facebook settings',
            path: '/settings/facebook',
          },
          {
            text: 'Email verification',
            path: '/settings/email_verifications',
          },
        ],
      },
      {
        name: 'srb',
        header: 'Showroom for Buyers',
        items: [
          {
            text: 'Buyers access',
            path: '/settings/buyer_access',
          },
        ],
      },
      // LMS-2843
      // {
      //   name: 'chat',
      //   header: 'Chat',
      //   items: [
      //     {
      //       text: 'Chat settings',
      //       path: '/settings/chats',
      //     },
      //   ],
      // },
    ],
    help: [
      {
        name: 'help',
        header: 'Help',
        items: [
          {
            text: 'FAQ',
            path: '/faq',
          },
          {
            text: 'What\'s new',
            onClick: () => { setWhatsNewModal(true); },
            haveBadge: newReleaseNoteAvailable,
          },
          {
            text: 'Support',
            href: 'https://support.mhub.my/hc/en-us/categories/360001490114-Lead',
          },
          // Zendesk chat widget support has been removed since release/v1.113.0
          // {
          //   text: 'Contact us',
          //   onClick: handleContactUs,
          // },
          {
            text: 'Terms of use',
            href: 'https://mhub.my/policies/terms_of_use/',
          },
        ],
      },
    ],
  };

  if (config.app.enableCreditCheck && user.role === 'Headquarters') {
    data.settings.push({
      name: 'credit_check',
      header: 'Credit check',
      items: [
        {
          text: 'Credit check settings',
          path: '/settings/credit_check',
        },
      ],
    });
  }

  // TODO: uncomment after deployment is confirmed
  if (user.role === 'Headquarters') {
    data.settings.push({
      name: 'salescandy',
      header: 'SalesCandy settings',
      items: [
        {
          text: 'SalesCandy settings',
          path: '/settings/salescandy',
        },
        {
          text: 'Data mapping',
          path: '/settings/salescandy/data_mapping',
        },
      ],
    });
  }

  const toggleState = (name, state) => () => {
    setActiveStates((s) => ({ ...s, [name]: state || !s[name] }));
  };

  const getModuleName = useCallback(() => {
    const arr = pathname.split('/');
    if (arr.length < 2) {
      return '';
    }
    const name = arr[1];
    switch (name) {
      case 'audiences':
        return 'campaigns';
      default:
        return name;
    }
  }, [pathname]);

  // Will check if have old filters format
  const onSelectFilter = useCallback((e, filters) => {
    if (Array.isArray(filters)) {
      e.preventDefault();
      toggleState('oldFilterWarning')();
    } else if (!isDesktop) {
      toggleMenu();
    }
  }, [
    isDesktop,
    toggleMenu,
  ]);

  const getSavedFieldsPath = useCallback((fields, name) => {
    const encoded = qs.stringify({
      ...fields.filters,
      fields: fields.columns || [],
    }, {
      arrayFormat: 'comma',
      sort: false,
    });
    return `/${name}?${encoded}`;
  }, []);

  useEffect(() => {
    const name = isDesktop ? getModuleName() : selectedMenu;

    if (savedFields.length > 0) {
      const filterItems = savedFields.map((fields) => ({
        text: fields.name,
        path: getSavedFieldsPath(fields, name),
        onSelect: (e) => {
          onSelectFilter(e, fields.filters);
        },
      }));

      setCustomNavSection([
        {
          name: 'custom',
          header: 'Saved views',
          sideHeaderNode: (
            <IconButton
              size="small"
              onClick={() => {
                setActiveStates((s) => ({ ...s, editSavedFields: !s.editSavedFields }));
              }}
            >
              <img alt="edit saved fields" src={EditIcon} className={classes.editIcon} />
            </IconButton>
          ),
          items: filterItems,
        },
      ]);
    } else {
      setCustomNavSection([]);
    }
  }, [
    getSavedFieldsPath,
    onSelectFilter,
    classes,
    getModuleName,
    isDesktop,
    indexedDBInfo,
    selectedMenu,
    savedFields,
    setCustomNavSection,
    toggleMenu,
  ]);

  const updateSavedFieldsNav = useCallback(async (db, storeName) => {
    const fields = await getSavedFields(db, storeName);

    setSavedFields(fields);
  }, [
    getSavedFields,
    setSavedFields,
  ]);

  useEffect(() => {
    async function openDatabase() {
      let DB_NAME;
      let DB_STORE_NAME;
      const DB_VERSION = 2;

      const name = isDesktop ? getModuleName() : selectedMenu;

      switch (name) {
        case 'leads':
          DB_NAME = 'LeadFilters';
          DB_STORE_NAME = 'lead-filters';
          break;
        case 'customers':
          DB_NAME = 'CustomerFilters';
          DB_STORE_NAME = 'customer-filters';
          break;
        case 'opportunities':
          DB_NAME = 'OpportunityFilters';
          DB_STORE_NAME = 'opportunity-filters';
          break;
        case 'contacts':
          DB_NAME = 'ContactFilters';
          DB_STORE_NAME = 'contact-filters';
          break;
        default:
          DB_NAME = '';
          DB_STORE_NAME = '';
          break;
      }

      setIndexeDBInfo({
        name: DB_NAME,
        storeName: DB_STORE_NAME,
        version: DB_VERSION,
      });

      if (DB_NAME && DB_STORE_NAME) {
        const iDB = (
          window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB
        );
        if (!iDB) {
          console.log("Your browser doesn't support a stable version of IndexedDB.");
        }

        const db = await openDB(DB_NAME, DB_VERSION, {
          upgrade(request) {
            if (!request.objectStoreNames.contains(DB_STORE_NAME)) {
              const store = request.createObjectStore(DB_STORE_NAME, {
                autoIncrement: true,
              });
              store.createIndex('name', 'name');
            }
          },
        });

        await updateSavedFieldsNav(db, DB_STORE_NAME);

        setIndexedDB(db);
      } else {
        setIndexedDB(null);
      }
    }
    openDatabase();
  }, [
    getModuleName,
    getSavedFields,
    isDesktop,
    updateSavedFieldsNav,
    selectedMenu,
    setIndexeDBInfo,
    setIndexedDB,
  ]);

  const handleUpdateFilters = async () => {
    await updateSavedFieldsNav(indexedDB, indexedDBInfo.storeName);
  };

  const getSecondarySelection = () => {
    let index = -1;
    let sectionName = '';
    Object.keys(data).forEach((key) => {
      const menu = data[key];
      menu.forEach((v, i) => {
        const arr = menu[i];
        if (arr.items) {
          arr.items.forEach((vv, ii) => {
            const p = arr.items[ii].path;
            if (path.startsWith(p)) {
              sectionName = arr.name;
              index = ii;
            }
          });
        }
      });
    });
    return {
      index,
      sectionName,
    };
  };

  const primaryNavIndex = getModuleName();
  const secondaryNavSelection = getSecondarySelection();

  const hasSubmenu = (name) => {
    const submenu = data[name];
    return submenu && submenu.length;
  };

  const showSecondaryNav = (
    pathname !== '/not_found'
    && hasSubmenu(getModuleName())
  );

  const onSelectMenu = (e) => {
    const { name } = e.currentTarget;
    // Reset saved fields list when navigates
    if (selectedMenu !== name) {
      setSavedFields([]);
    }
    if (hasSubmenu(name)) {
      e.preventDefault();
      setSelectedMenu(name);
      setShowSubmenu(true);
    } else {
      toggleMenu();
    }
  };

  const back = () => {
    setShowSubmenu(false);
  };

  const renderDesktopNav = () => (
    <>
      <PrimaryNav
        selected={primaryNavIndex}
      />
      {
        showSecondaryNav ? (
          <>
            <SecondaryNav
              collapsed={collapse}
              data={data[getModuleName()]}
              selected={secondaryNavSelection}
            />
            <AppNavToggleButton
              collapsed={collapse}
              onToggle={() => setCollapse((a) => !a)}
            />
          </>
        ) : null
      }
    </>
  );

  const renderDrawerNav = () => (
    <Drawer
      open={showMenu}
      onClose={toggleMenu}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.drawerHeader}>
          {
            showSubmenu
              ? (
                <div className={classes.controls} onClick={back}>
                  <ChevronLeftIcon />
                  <Typography variant="subtitle2">Back</Typography>
                </div>
              ) : (
                <a href="/" className={classes.logo}>
                  <img alt="MHub CRM" src={logo} />
                </a>
              )
          }
        </div>
        {
          showSubmenu
            ? (
              <SecondaryNav
                collapsed={false}
                data={data[selectedMenu]}
                selected={secondaryNavSelection}
                onSelect={toggleMenu}
              />
            ) : (
              <PrimaryNav
                selected={primaryNavIndex}
                onSelect={onSelectMenu}
              />
            )
        }
      </div>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      {
        isDesktop
          ? renderDesktopNav()
          : renderDrawerNav()
      }
      <EditSavedFields
        dbStoreName={indexedDBInfo.storeName}
        open={activeStates.editSavedFields}
        database={indexedDB}
        data={savedFields}
        onClose={() => {
          setActiveStates((s) => ({ ...s, editSavedFields: !s.editSavedFields }));
        }}
        onUpdate={handleUpdateFilters}
      />
      <SimpleModal
        title="Changes required"
        open={activeStates.oldFilterWarning}
        onCancel={toggleState('oldFilterWarning')}
        content={(
          <>
            <Typography variant="body2">
              We detected an outdated version of saved filters.
              Kindly delete the selected saved filters and save again.
            </Typography>
          </>
        )}
        buttons={(
          <Button
            variant="contained"
            onClick={toggleState('oldFilterWarning')}
          >
            Okay
          </Button>
        )}
      />
    </div>
  );
};

AppNav.Global = PrimaryNav;
AppNav.Contextual = SecondaryNav;

AppNav.propTypes = {
  showMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

AppNav.defaultProps = {
  showMenu: false,
  toggleMenu: () => {},
};

export default AppNav;
