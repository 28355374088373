import { darken, lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '450px',
    padding: '1.5rem 2rem',
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  heading: {
    marginBottom: '1rem',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2.5),
    right: theme.spacing(4),
  },
  errorButton: {
    backgroundColor: '#ff796e',
    '&:hover': {
      backgroundColor: lighten('#ff796e', 0.2),
    },
    '&:active': {
      backgroundColor: darken('#ff796e', 0.2),
    },
    '&:focus': {
      backgroundColor: darken('#ff796e', 0.2),
    },
  },
}));
