import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// ContactField GraphQL Queries.

// READ read contact field GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getContactFieldSettings',
    args: [],
    argsMap: [],
    fields: '{...ContactFieldSettingsResponseFields}',
    fragments: [Fragments.contactFieldSettingsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export default READ;
