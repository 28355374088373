import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// UPDATE_SUBSCRIPTION_EMAIL_BLAST update total email blast GraphQL schema.
export const UPDATE_SUBSCRIPTION = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateSubscription',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'allocated_email_blast_count', type: 'Int' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'allocated_email_blast_count', value: 'allocated_email_blast_count' },
    ],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
