import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { RouteAuthenticated, LoadingPage } from '../../components';
import { componentLoader } from '../../utils';

const Dashboard = lazy(() => componentLoader(() => import('./screens/Dashboard'), 2));

const Home = ({ match }) => (
  <Suspense fallback={<LoadingPage isLoading />}>
    <Switch>
      <RouteAuthenticated path={match.path} exact component={Dashboard} />
    </Switch>
  </Suspense>
);

Home.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default Home;
