import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// READ read contact GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getContact',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...GetContactResponseFields}',
    fragments: [Fragments.getContactResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listContacts',
    args: [
      { name: 'fields', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'fields', value: 'fields' },
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListContactsResponseFields}',
    fragments: [Fragments.listContactsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SEARCH search contacts GraphQL schema.
export const SEARCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'searchContact',
    args: [
      { name: 'query', type: 'String' },
      { name: 'email', type: 'String' },
      { name: 'mobile', type: 'String' },
    ],
    argsMap: [
      { name: 'query', value: 'query' },
      { name: 'email', value: 'email' },
      { name: 'mobile', value: 'mobile' },
    ],
    fields: '{...SearchContactResponseFields}',
    fragments: [Fragments.searchContactResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
