import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Checkbox,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { AuthContext } from '../../../contexts';
import Searchable from '../Searchable';
import { allOption } from '../../../data/settings';
import { isEmptyValue } from '../../../utils';
import { AlertCircleIcon } from '../../../assets/icons';
import useStyles from './styles';

export default function SearchableAssignee(props) {
  const {
    value,
    showAvatar,
    showStatusInChip,
    restrictOptions,
    removeUnassignedOption,
    removeWithoutVerifiedPhoneNumber,
    options,
    disabledOptions,
    ...searchableProps
  } = props;

  const authContext = useContext(AuthContext);
  const { userOptions, hasHigherAccess } = authContext.state;
  const classes = useStyles();

  const isSelectAllCheckbox = (
    searchableProps.type === 'checkbox'
    && searchableProps.multiple
    && searchableProps.addSelectAllOption
    && !isEmptyValue(options)
    && options.length < 100 // API have 100 limit per field for filters
  );
  const getAllUserOptions = () => {
    let newOptions = [];
    if (!options) {
      if (removeUnassignedOption) {
        newOptions = userOptions;
      } else {
        newOptions = [
          { label: 'Unassigned', value: 'unassigned', disabled: restrictOptions && !hasHigherAccess },
          ...userOptions,
        ];
      }

      if (!isEmptyValue(newOptions)
      && isSelectAllCheckbox
      && newOptions.findIndex((opt) => opt.value === allOption.value) < 0) {
        newOptions = [allOption, ...newOptions];
      }
    } else {
      newOptions = options;
    }

    return newOptions;
  };
  const allUsers = getAllUserOptions();

  const getList = (vals, list = []) => {
    const clonedList = JSON.parse(JSON.stringify(list));
    // This is to solve autocomplete missing selected option when search for new value/non existing
    if (vals && !isEmpty(vals)) {
      if (!searchableProps.multiple) {
        const isExists = list.findIndex((obj) => obj.value === (vals)) > -1;
        if (!isExists) {
          clonedList.push({ label: vals, value: vals });
        }
      } else {
        vals
          .filter((s) => !list.find((obj) => obj.value === s))
          .forEach((v) => {
            clonedList.push({ label: v, value: v });
          });
      }
    }
    return clonedList;
  };

  const getValues = () => {
    if (allUsers && allUsers.length > 0) {
      if (Array.isArray(value)) {
        const unassignIndex = value.indexOf('');
        if (unassignIndex > -1) {
          const newValue = [...value];
          newValue[unassignIndex] = 'unassigned';
          return newValue;
        }
        return value;
      }
      return value;
    }
    return null;
  };

  const getFilterOptions = (opt, state) => {
    // Show options that are already filtered by the component itself
    // Such as when pass in filterSelectedOptions
    let filteredOption = opt;
    if (state && state.inputValue) {
      const { inputValue } = state;
      const formatted = inputValue.toLowerCase();
      filteredOption = opt.filter((user) => (
        (user.role && user.role.toLowerCase().includes(formatted))
        || (user.label && user.label.toLowerCase().includes(formatted))
        || (user.companyBranch
          && user.companyBranch.name
          && user.companyBranch.name.toLowerCase().includes(formatted))
      ));
    }
    if (removeWithoutVerifiedPhoneNumber) {
      filteredOption = filteredOption.filter((o) => o.phone_number_verified);
    }
    return filteredOption;
  };

  const renderDisabledUserStatus = (user) => {
    const isUnassigned = user && user.value === 'unassigned';
    if (isEmptyValue(user) || (!isEmptyValue(user) && !isUnassigned && !user.user_status)) {
      return 'Non-existent';
    }
    if (user && user.user_status && user.user_status === 'suspended') {
      return 'Suspended';
    }
    return '';
  };

  const preparedOptions = getList(value, allUsers).filter((option) => option.id !== undefined)
    .map((option) => ({
      ...option,
      disabled: disabledOptions.includes(option.id),
    }));

  return (
    <Searchable
      disableClearable={restrictOptions && !hasHigherAccess}
      options={preparedOptions}
      value={getValues()}
      filterOptions={getFilterOptions}
      renderOption={(option) => {
        const isLoadMore = option.label === 'Load more' && !option.role && !option.phone_number;
        const disabledStatus = renderDisabledUserStatus(option) !== '';

        if (isLoadMore) {
          return (
            <Grid container justify="center" alignItems="center">
              <Typography className={classes.loadMore} variant="body2">
                {option.label}
              </Typography>
            </Grid>
          );
        }

        return (
          <>
            {
              searchableProps.renderOption
                ? searchableProps.renderOption
                : (
                  <>
                    {
                      searchableProps.type === 'checkbox'
                      && searchableProps.multiple
                      && (
                        <Checkbox
                          className={classes.checkbox}
                          checked={
                            (!isEmptyValue(getValues()) && getValues().includes(option.value))
                            || (
                              isSelectAllCheckbox
                              && option.value === allOption.value
                              && !isEmptyValue(getValues())
                              && getValues().length === allUsers.length - 1
                            )
                          }
                        />
                      )
                    }
                    <Grid container>
                      <Grid container alignItems="baseline" justify="space-between">
                        <Grid item xs={8}>
                          <Typography className={classes.name}>
                            { option.label }
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" className={classes.role}>
                            { disabledStatus ? `(${renderDisabledUserStatus(option)})` : option.role }
                          </Typography>
                        </Grid>
                      </Grid>
                      {
                        !['unassigned', allOption.value].includes(option.value) && (
                        <Grid container alignItems="center" justify="space-between">
                          <Grid item>
                            <Typography variant="body2" className={classes.number}>
                              { option.phone_number }
                            </Typography>
                          </Grid>
                          {
                            !disabledStatus && (
                              <Grid item>
                                <Typography variant="caption" className={classes.branchName}>
                                  { option.company_branch_name }
                                </Typography>
                              </Grid>
                            )
                          }
                        </Grid>
                        )
                      }
                    </Grid>
                  </>
                )
            }
          </>
        );
      }}
      getOptionDisabled={(option) => option.disabled || (restrictOptions && renderDisabledUserStatus(option) !== '')}
      renderTags={(values, getTagProps) => values.map(
        (selected, index) => {
          const selectedInfo = allUsers.find((user) => user.value === selected) || {};
          const label = (selectedInfo && selectedInfo.label) || selected || '';
          const status = renderDisabledUserStatus(selectedInfo);
          const isNonExisting = isEmptyValue(selectedInfo) || status === 'Non-existent';
          const isSuspended = status === 'Suspended';
          return (
            <Chip
              value={selected}
              index={index}
              label={`${label} ${showStatusInChip && status ? `(${status.toLowerCase()})` : ''}`}
              classes={{
                label: status !== '' ? classes.lightChipLabel : '',
              }}
              icon={
                isNonExisting ? (
                  <img className={classes.alertIcon} alt="non-existing user" src={AlertCircleIcon} />
                ) : null
              }
              avatar={!isNonExisting && showAvatar ? (
                <Avatar className={`${classes.avatar} ${isSuspended ? 'suspended' : ''}`}>
                  { (label.charAt(0)).toUpperCase() }
                </Avatar>
              ) : null}
              {...getTagProps({ index })}
            />
          );
        },
      )}
      {...searchableProps}
    />
  );
}

SearchableAssignee.propTypes = {
  showAvatar: PropTypes.bool,
  showStatusInChip: PropTypes.bool,
  restrictOptions: PropTypes.bool,
  removeUnassignedOption: PropTypes.bool,
  removeWithoutVerifiedPhoneNumber: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};

SearchableAssignee.defaultProps = {
  showAvatar: false,
  showStatusInChip: false,
  restrictOptions: false,
  removeUnassignedOption: false,
  removeWithoutVerifiedPhoneNumber: false,
  value: null,
  options: null,
  disabledOptions: [],
};
