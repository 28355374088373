import React, {
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useEnhancedQuery } from '../../hooks';
import {
  CUSTOMER,
  OPPORTUNITY,
} from '../../graphql';
import {
  clientCustomer,
  clientOpportunity,
} from '../..';
import {
  LeadsIcon,
  CustomersIcon,
} from '../../assets/icons';
import {
  formatValue,
  getOpportunityName,
  toCapitalize,
} from '../../utils';
import { ROLE_HEADQUARTERS } from '../../data';
import useStyles from './styles';

export default function ViewPage(props) {
  const classes = useStyles();

  const [activeStates, setActiveStates] = useState({
    leads: true,
    customers: true,
  });

  const {
    user,
    users,
    projects,
    duplicateCustomers,
    duplicateLeads,
    selectedLeads,
    setSelectedLeads,
  } = props;

  const match = useRouteMatch();
  const currentId = match.params.id || '';

  const HaveAccessToCustomer = (id) => {
    let haveAccess = true;
    const test = useEnhancedQuery(CUSTOMER.READ(), {
      disableDefaultError: true,
      options: {
        client: clientCustomer,
        variables: {
          id,
        },
      },
    });
    if (test.error && test.error.message === 'GraphQL error: forbidden') {
      haveAccess = false;
    }
    return haveAccess;
  };


  const GetOpportunities = (id) => {
    const queryOpportunities = useEnhancedQuery(OPPORTUNITY.LIST(), {
      options: {
        client: clientOpportunity,
        variables: {
          customer_id: id,
          sorts: [{ field: 'created_at', order: 'dsc' }],
          limit: 50,
          per_query: 50,
          pit_id: '',
          search_after: '',
        },
      },
      defaultData: {
        listOpportunities: {
          opportunities: [],
          count: 0,
        },
      },
      selector: (d) => d.listOpportunities,
    });
    return (
      (queryOpportunities.data && queryOpportunities.data.opportunities) || []
    );
  };

  const customers = duplicateCustomers.map((customer) => (
    {
      ...customer.contact,
      id: customer.id,
      opportunities: GetOpportunities(customer.id),
      haveAccess: HaveAccessToCustomer(customer.id),
    }
  ));

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const handleSelectLeads = (e, lead) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedLeads((prev) => ([...prev, lead]));
    } else {
      setSelectedLeads((prev) => prev.filter((l) => l.id !== lead.id));
    }
  };

  const renderProject = (type, item) => {
    if (type === 'leads' && item.project_ids) {
      const list = [...item.project_ids];
      const firstProject = list.splice(0, 1);
      return (
        <>
          {
            firstProject.length > 0 && React.Children.toArray(firstProject.map((id) => (
              <div className={classes.projectWrapper}>
                <Typography variant="caption" className={`${classes.project} ${classes.ellipsis}`}>
                  { (projects[id] && projects[id].name) || id }
                </Typography>
              </div>
            )))
          }
          {
            list.length > 0 && (
              <div className={classes.projectWrapper}>
                <Typography variant="caption" className={`${classes.project} ${classes.ellipsis} subCounts`}>
                  {`+ ${list.length} `}
                </Typography>
              </div>
            )
          }
        </>
      );
    }

    if (type === 'customers' && item.opportunities && item.opportunities.length > 0) {
      const newestOpp = item.opportunities[0];
      if (newestOpp && newestOpp.project_id) {
        const name = getOpportunityName(newestOpp, projects);
        return (
          <div className={classes.projectWrapper}>
            <Typography variant="caption" className={`${classes.project} ${classes.ellipsis}`}>
              { name }
            </Typography>
          </div>
        );
      }
    }
    return null;
  };

  const renderAssignee = (type, item) => {
    if (type === 'leads') {
      return getUserName(item.assigned_to) || 'Unassigned';
    }

    if (type === 'customers' && item.opportunities && item.opportunities.length > 0) {
      return (
        item.opportunities[0] && item.opportunities[0].project_id
          ? (getUserName(item.opportunities[0].assigned_to) || 'Unassigned')
          : ''
      );
    }
    return '';
  };

  const renderTableRow = (items, item, type) => {
    if (_.isEmpty(item)) {
      return null;
    }
    if (item.deleted_at) {
      return null;
    }

    const isHQ = user.role === ROLE_HEADQUARTERS;

    return (
      <TableRow key={item.id}>
        <TableCell>
          <div className={classes.checkboxCell}>
            {
              type === 'leads' && items.length > 1 && (
                <Checkbox
                  disableRipple
                  className={classes.checkbox}
                  disabled={
                    item.id === currentId
                    || (
                      selectedLeads.length === 3
                      && !(selectedLeads.some((selected) => selected.id === item.id))
                    )
                    || (!isHQ && item.assigned_to !== user.id)
                  }
                  checked={(
                    selectedLeads.some((selected) => (
                      selected.id === item.id
                    ))
                  )}
                  onChange={(e) => handleSelectLeads(e, {
                    ...item,
                    msisdn: item.msisdn || '',
                    mobile_country_code: item.mobile_country_code || '',
                    mobile: item.mobile || '',
                    assigned_to: item.assigned_to || '',
                  })}
                />
              )
            }
          </div>
        </TableCell>
        <TableCell>
          <Link
            variant="subtitle2"
            className={`${classes.name} ${classes.ellipsis}`}
            href={`/${type}/${item.id}`}
          >
            { toCapitalize(item.name) || 'n/a' }
          </Link>
        </TableCell>
        <TableCell
          className={`${classes.projectCell} ${classes.value}`}
        >
          {
            type === 'customers' && !item.haveAccess
              ? ' '
              : renderProject(type, item)
          }
        </TableCell>
        <TableCell>
          <Typography variant="body2" className={`${classes.assignee} ${classes.ellipsis} ${classes.value}`}>
            { renderAssignee(type, item) }
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{ item.msisdn || item.mobile || item.phone }</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{ item.email }</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{ item.nric }</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderCategory = (name, items) => {
    let info = '';

    switch (name) {
      case 'customers':
        info = 'Customer duplicates cannot be merged with leads.';
        break;
      default:
        info = 'Select up to 3 leads to merge and click next to choose fields.';
        break;
    }

    return (
      <section className={classes.section}>
        <Grid container alignItems="center">
          <IconButton
            size="small"
            className={`${classes.expand} ${activeStates[name] ? classes.expandOpen : ''}`}
            onClick={toggleState(name)}
          >
            <ExpandMore />
          </IconButton>
          <img
            alt=""
            className={classes.icon}
            src={name === 'leads' ? LeadsIcon : CustomersIcon}
          />
          <Typography variant="h6">
            {`${formatValue(name)} (${items.length})`}
          </Typography>
          {
            name === 'leads' && selectedLeads.length > 0 && (
              <Typography variant="caption" className={classes.totalSelected}>
                {`${selectedLeads.length} selected`}
              </Typography>
            )
          }
        </Grid>
        <Collapse in={activeStates[name]} timeout="auto">
          <Typography variant="body2" className={classes.info}>
            { info }
          </Typography>
          <div className={classes.tableContainer}>
            {
              items.length > 0 && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {
                        [
                          { label: 'Name', width: '30%' },
                          { label: name === 'leads' ? 'Project name' : 'Newest opportunity', width: '35%' },
                          { label: 'Assignee', width: '20%' },
                          { label: 'Mobile', width: '10%' },
                          { label: 'Email', width: '10%' },
                          { label: 'MyKad (NRIC)', width: '10%' },
                        ].map((cell) => (
                          <TableCell style={{ width: cell.width }}>
                            <Typography className={classes.th}>
                              { cell.label }
                            </Typography>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tbody}>
                    {
                      items.map((item) => (
                        renderTableRow(items, item, name)
                      ))
                    }
                  </TableBody>
                </Table>
              )
            }
          </div>
        </Collapse>
      </section>
    );
  };

  return (
    <>
      <Typography variant="body2" className={classes.topMessage}>
        We found duplicates of the same mobile or email or MyKad (NRIC).
        To resolve, view to merge or qualify this lead.&nbsp;
        <Link target="_blank" rel="noopener" href="https://support.mhub.my/hc/en-us/articles/360055635834-How-do-I-merge-duplicate-leads-">
          Learn more
        </Link>
      </Typography>
      { renderCategory('leads', duplicateLeads) }
      { renderCategory('customers', customers) }
    </>
  );
}

ViewPage.propTypes = {
  user: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Object),
  projects: PropTypes.instanceOf(Object),
  duplicateCustomers: PropTypes.instanceOf(Object),
  duplicateLeads: PropTypes.instanceOf(Object),
  selectedLeads: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setSelectedLeads: PropTypes.func,
};

ViewPage.defaultProps = {
  user: {},
  users: {},
  projects: {},
  duplicateCustomers: {},
  duplicateLeads: {},
  selectedLeads: [],
  setSelectedLeads: () => {},
};
