import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Searchable from '../Searchable';
import { AuthContext, PageContext, SnackbarContext } from '../../../contexts';
import { getProjects } from '../../../data/mhub_api';
import { usePrevious } from '../../../hooks';

export default function SearchableProject(props) {
  const {
    // Allow parent to pass in own loading.
    // Useful for scenario where parent need do some logic for the options
    parentLoading,
    passProjectInfo,
    // Allow parent pass in own options
    isParentOptions,
    parentOptions,
    onChange,
    disabledOptions,
    ...searchableProps
  } = props;

  const authContext = useContext(AuthContext);
  const { apps } = authContext.state;
  const pageContext = useContext(PageContext);
  const { projects } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const onMountRef = useRef(true);
  const setOnMountRef = (state) => { onMountRef.current = state; };

  const [search] = useState('');
  const [limit, setLimit] = useState(50);
  const [options, setOptions] = useState([]);
  const [projectInfos, setProjectInfos] = useState([]);
  const [loading, setLoading] = useState(true);
  const prevSearch = usePrevious(search);
  const prevLimit = usePrevious(limit);

  useEffect(() => {
    let initialLoad = true;
    // Pass in deleted/no access but selected projects into the list
    // to fix autocomplete missing option warning
    function concatMissingSelectedProject(clonedOptions, id) {
      let newClonedOptions = clonedOptions || [];
      const haveValue = newClonedOptions.filter((item) => id === item.value).length > 0;
      if (!haveValue) {
        newClonedOptions = newClonedOptions.concat({
          label: (projects[id] && projects[id].name) || id,
          value: id,
        });
      }

      return newClonedOptions;
    }

    async function fetchProjectData() {
      let newOptions = [];
      let newProjectInfos = [];
      // Show loading at the end before fetch data
      if (!initialLoad) {
        setOptions((prev) => [
          ...prev,
          { label: 'Loading...', value: 'loading', disabled: true },
        ]);
      }

      let list = [];
      // Get project details
      try {
        list = await getProjects(apps.showroom.apiURL, search, limit);
      } catch (e) {
        list = [];
        setOpenSnackbar({ variant: 'error', message: 'Error occured when retrieving project listing' });
        if (initialLoad) {
          initialLoad = false;
          setLoading(false);
        }
        return;
      }

      if (list) {
        const projectOptions = list.map((project) => (
          { label: project.name, value: project.id }
        ));
        newOptions = projectOptions;
        newProjectInfos = list;
      } else {
        newOptions = [];
        newProjectInfos = [];
      }

      if (searchableProps.value) {
        if (searchableProps.multiple
          && Array.isArray(searchableProps.value)
          && searchableProps.value.length > 0) {
          let clonedOptions = JSON.parse(JSON.stringify(newOptions));
          searchableProps.value.forEach((id) => {
            clonedOptions = concatMissingSelectedProject(clonedOptions, id);
          });
          newOptions = clonedOptions;
        } else if (!searchableProps.multiple
          && typeof searchableProps.value === 'string'
          && searchableProps.value) {
          const clonedOptions = JSON.parse(JSON.stringify(newOptions));
          newOptions = concatMissingSelectedProject(clonedOptions, searchableProps.value);
        }
      }
      if (initialLoad) {
        initialLoad = false;
        setLoading(false);
      }

      setOptions(newOptions);
      setProjectInfos(newProjectInfos);
    }

    // Call API only when necessary to avoid spams whenever component rerenders
    if (onMountRef.current
      || prevSearch !== search
      || prevLimit !== limit) {
      fetchProjectData();
      setOnMountRef(false);
    }
  }, [
    apps.showroom.apiURL,
    search,
    limit,
    prevSearch,
    prevLimit,
    projects,
    searchableProps.value,
    searchableProps.multiple,
    setOpenSnackbar,
  ]);

  // Retrieve more when reach scroll till the bottom
  const handleScroll = (e) => {
    if (!isParentOptions) {
      const listboxNode = e.currentTarget;
      if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1) {
        setLimit((prev) => prev + 50);
      }
    }
  };

  const handleOnChange = (e, selected) => {
    if (passProjectInfo && !searchableProps.multiple && selected) {
      // TODO: Currently only single selection can have project infos pass out
      const index = projectInfos.findIndex((option) => option.id === selected);
      if (index > -1) {
        onChange(e, selected, { ...projectInfos[index] });
      }
    } else {
      onChange(e, selected);
    }
  };

  const getList = (value, list = []) => {
    let clonedList = [];
    if (!parentLoading) {
      clonedList = JSON.parse(JSON.stringify(list));
      // This is to solve autocomplete missing selected
      // option when search for new value/non existing
      if (value && !isEmpty(value)) {
        if (!searchableProps.multiple) {
          const isExists = list.findIndex((obj) => obj.value === value) > -1;
          if (!isExists) {
            clonedList.push({ label: (projects[value] && projects[value].name) || value, value });
          }
        } else {
          value
            .filter((s) => !list.find((obj) => obj.value === s))
            .forEach((v) => {
              clonedList.push({ label: (projects[v] && projects[v].name) || v, value: v });
            });
        }
      }
    }
    return clonedList;
  };

  const renderOption = (option) => (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs>
        <Typography variant="body2">{option.label || ''}</Typography>
      </Grid>
    </Grid>
  );
  const preparedOptions = getList(searchableProps.value, isParentOptions ? parentOptions : options)
    .map((option) => ({
      ...option,
      disabled: disabledOptions.includes(option.value),
    }));
  return (
    <Searchable
      options={preparedOptions}
      loading={loading || parentLoading}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      renderOption={(option) => renderOption(option)}
      onChange={handleOnChange}
      {...searchableProps}
    />
  );
}

SearchableProject.propTypes = {
  parentLoading: PropTypes.bool,
  passProjectInfo: PropTypes.bool,
  isParentOptions: PropTypes.bool,
  parentOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};

SearchableProject.defaultProps = {
  parentLoading: false,
  passProjectInfo: false,
  isParentOptions: false,
  parentOptions: [],
  onChange: () => {},
  disabledOptions: [],
};
