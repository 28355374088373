import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// READ read opportunity GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getOpportunity',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'customer_id', type: 'String' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'customer_id', value: 'customer_id' },
    ],
    fields: '{...GetOpportunityResponseFields}',
    fragments: [Fragments.getOpportunityResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list opportunity GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listOpportunities',
    args: [
      { name: 'customer_id', type: 'String' },
      { name: 'fields', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'per_query', type: 'Int' },
      { name: 'pit_id', type: 'String' },
      { name: 'search_after', type: 'String' },
      { name: 'list_back', type: 'Boolean' },
      { name: 'page', type: 'Int' },
    ],
    argsMap: [
      { name: 'customer_id', value: 'customer_id' },
      { name: 'fields', value: 'fields' },
      { name: 'fields', value: 'fields' },
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'per_query', value: 'per_query' },
      { name: 'pit_id', value: 'pit_id' },
      { name: 'search_after', value: 'search_after' },
      { name: 'list_back', value: 'list_back' },
      { name: 'page', value: 'page' },
    ],
    fields: '{...ListOpportunitiesResponseFields}',
    fragments: [Fragments.listOpportunitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_APPOINTMENT read appointment GraphQL schema.
export const READ_APPOINTMENT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAppointment',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_APPOINTMENTS list appointments GraphQL schema.
export const LIST_APPOINTMENTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listAppointments',
    args: [
      { name: 'filters', type: '[Filter!]' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_APPOINTMENT_METRICS get appointment metrics GraphQL schema.
export const GET_APPOINTMENT_METRICS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAppointmentMetrics',
    args: [
      { name: 'queryCancelledByMonth', type: 'Int' },
      { name: 'queryRescheduledByMonth', type: 'Int' },
    ],
    argsMap: [
      { name: 'queryCancelledByMonth', value: 'queryCancelledByMonth' },
      { name: 'queryRescheduledByMonth', value: 'queryRescheduledByMonth' },
    ],
    fields: '{...GetAppointmentMetricsResponseFields}',
    fragments: [Fragments.getAppointmentMetricsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
