import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  focus: {
    '& > div > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #49b3b1',
    },
  },
  flag: {
    marginRight: '0.5rem',
  },
  text: {
    '& > .MuiOutlinedInput-root.MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& > .MuiOutlinedInput-root.MuiOutlinedInput-adornedStart > input': {
      paddingLeft: '0.5rem',
    },
  },
});
