import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { Route } from 'react-router-dom';
import { AuthContext } from '../contexts';
import { enabledStages } from './const';
import config from '../config';

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { location: { pathname, search } } = this.props;
    const page = pathname + search;
    const { email } = this.props;
    if (!page.includes('/login') && !(email.toLowerCase().endsWith('mhub.my'))) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACK_ID, { send_page_view: false });
      const { location } = this.props;
      this.logPageChange(
        location.pathname,
        location.search,
      );
    }
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const page = pathname + search;
    const { email } = this.props;
    if (!page.includes('/login') && !(email.toLowerCase().endsWith('mhub.my'))) {
      const isDifferentPathname = pathname !== prevLocation.pathname;
      const isDifferentSearch = search !== prevLocation.search;
      if (isDifferentPathname || isDifferentSearch) {
        this.logPageChange(pathname, search);
      }
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    const { options } = this.props;
    const { email, company_code: companyCode } = this.props;
    if (!page.includes('/login') && !(email.toLowerCase().endsWith('mhub.my'))) {
      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
        userId: email,
        ...options,
        user_properties: {
          company_code: companyCode,
        },
      });
      ReactGA.send({ hitType: 'pageview', page });
    }
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.instanceOf(Object),
  email: PropTypes.string,
  company_code: PropTypes.string,
};

GoogleAnalytics.defaultProps = {
  options: null,
  email: '',
  company_code: '',
};

const RouteTracker = () => {
  const authContext = useContext(AuthContext);
  return (
    <>
      { enabledStages.includes(config.app.stage)
       && (
       <Route
         render={(routeProps) => (
           <GoogleAnalytics
             {...routeProps}
             email={authContext.state.user.email}
             company_code={authContext.state.user.company.code}
           />
         )}
       />
       ) }
    </>
  );
};
export default {
  GoogleAnalytics,
  RouteTracker,
};
