/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import { Auth, API } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';

import { hasUpperGroups, hasAllowedGroups } from '../../data/groups';
import { getUserRole } from '../../data';

import { USERS } from '../../graphql';
import { enhancedQuery } from '../../utils/graphql';

import { clientUser } from '../../index';

import AuthContext, { initialState } from './context';
import { SnackbarContext } from '../Snackbar';

import { redirectLogin } from '../../utils';
import {
  getAuthentication,
} from '../../data/mhub_api';
import config from '../../config';
import { LoadingPage } from '../../components';

class AuthProvider extends Component {
  static Consumer = AuthContext.Consumer;
  static contextType = SnackbarContext;

  static initialState = initialState;

  constructor(props) {
    super(props);
    this.state = {
      data: initialState,
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  componentWillUnmount() {
    const { actions } = this.context;
    const { setCloseSnackbar } = actions;
    setCloseSnackbar();
  }

  setStateAsync = (newState = {}) => new Promise((resolve) => {
    this.setState((prevState) => ({
      data: Immutable.merge(prevState.data, newState),
    }), () => resolve(newState));
  });

  isExternalCompanyBranch = (list, id) => (
    list[id]
    && list[id].is_external
    && list[id].is_external.toLowerCase() === 'true'
  );

  fetchAllUsers = async () => {
    const { actions } = this.context;
    const { setOpenSnackbar } = actions;
    return enhancedQuery(clientUser, USERS.LIST(), {
      options: {
        variables: {
          company_branch_id: '',
        },
        onError: () => {
          setOpenSnackbar({
            variant: 'error',
            message: 'An error has occured. Please try again.',
          });
        },
      },
      defaultData: {
        listUsers: [],
      },
      selector: (d) => d.listUsers,
    });
  }

  fetchUsers = async (user) => {
    const { actions } = this.context;
    const { setOpenSnackbar } = actions;
    return enhancedQuery(clientUser, USERS.LIST(), {
      options: {
        variables: {
          company_branch_id: ['Supervisor', 'Sales Manager', 'Sales Admin'].includes(user.role) ? user.companyBranch.id : '',
        },
        onError: () => {
          setOpenSnackbar({
            variant: 'error',
            message: 'An error has occured. Please try again.',
          });
        },
      },
      defaultData: {
        listUsers: [],
      },
      selector: (d) => d.listUsers,
    });
  }

  getUserData = async (resp) => {
    let user = {};
    const affiliate = resp.affiliate || {};
    const company = (affiliate && affiliate.company) || {};
    const companyBranch = (affiliate && affiliate.companyBranch) || {};
    const groups = resp.groups.map((group) => group.code) || [];
    if (!_.isEmpty(resp)) {
      user = {
        id: resp.awsUsername,
        name: resp.name,
        preferred_name: resp.preferredName,
        email: resp.email,
        mobile_prefix: resp.mobilePrefix,
        mobile_suffix: resp.mobile,
        phone_number: `${resp.mobilePrefix}${resp.mobile}`,
        groups,
        role: getUserRole(
          groups,
          companyBranch.isExternal,
        ),
        company: {
          id: company.service_company_id,
          name: company.name,
          shortName: company.shortName,
          apps: company.apps,
          code: company.code,
        },
        companyBranch: {
          id: companyBranch.service_company_branch_id,
          name: companyBranch.name,
          shortName: companyBranch.shortName,
          isExternal: companyBranch.isExternal,
        },
      };
    }

    Bugsnag.addMetadata('user', {
      user_id: user.id,
      name: user.name,
      email: user.email,
      company_id: user.company.id,
    });

    return user;
  }

  getAllUsers = async () => {
    const { users } = await this.fetchAllUsers();
    if (!users) {
      return [];
    }

    return users.map((u) => {
      if (u.phone_number) {
        return {
          ...u,
          phone_number: this.formatPhoneNumber(u.phone_number),
        };
      }
      return u;
    });
  }

  formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      return phoneNumber.replace(/\+/g, '');
    }
    return '';
  };

  getUsers = async (user) => {
    const { users } = await this.fetchUsers(user);
    if (!users) {
      return [];
    }

    return users.map((u) => {
      if (u.phone_number) {
        return {
          ...u,
          phone_number: this.formatPhoneNumber(u.phone_number),
        };
      }
      return u;
    });
  }

  fetchListCompanyBranch = async () => {
    const { actions } = this.context;
    const { setOpenSnackbar } = actions;
    let list = await enhancedQuery(clientUser, USERS.LIST_COMPANY_BRANCH(), {
      options: {
        onError: () => {
          setOpenSnackbar({
            variant: 'error',
            message: 'An error has occured. Please try again.',
          });
        },
      },
      defaultData: {
        listCompanyBranches: [],
      },
      selector: (d) => d.listCompanyBranches,
    });
    list = list.company_branches || [];
    const mapped = list.map((bu) => ({ [bu.id]: bu }));
    return Object.assign({}, ...mapped);
  }

  getUserList = async (users, listCompanyBranches, convertToObject) => {
    // Set users as object with userId as key
    // For accessing user info by user id
    if (convertToObject) {
      const mapped = users.map((u) => {
        const companyBranchId = u.company_branch_id;
        const companyBranchName = (listCompanyBranches[companyBranchId] && listCompanyBranches[companyBranchId].name) || '';
        const isExternal = this.isExternalCompanyBranch(listCompanyBranches, companyBranchId);
        return ({
          [u.id]: {
            ...u,
            role: getUserRole(u.groups, isExternal),
            company_branch_name: companyBranchName,
            company_branch_id: companyBranchId,
          },
        });
      });
      const objects = Object.assign({}, ...mapped);
      return objects;
    }

    const options = users.map((u) => {
      const companyBranchId = u.company_branch_id;
      const companyBranchName = (listCompanyBranches[companyBranchId] && listCompanyBranches[companyBranchId].name) || '';
      const isExternal = this.isExternalCompanyBranch(listCompanyBranches, companyBranchId);
      return ({
        label: u.name,
        value: u.id,
        role: getUserRole(u.groups, isExternal),
        company_branch_name: companyBranchName,
        company_branch_id: companyBranchId,
        ...u,
      });
    });
    return options;
  }

  getCurrentSession = async (firstTry = true) => {
    let resp;
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      resp = authUser.getSignInUserSession();
    } catch (error) {
      if (
        firstTry && (
          (typeof error === 'string' && !error.match(/not authenticate/i)) || (
            typeof error === 'object' && !Array.isArray(error) && error.code !== 'PasswordResetRequiredException'
            && (error.message.match(/not authenticate/i)
              || error.message.match(/token/i))
          )
        )
      ) {
        // delay retry to check new token
        await setTimeout(() => {
          this.getCurrentSession(false);
        }, 1000);
        return null;
      }

      await this.setStateAsync({
        hasAccess: false,
        isAuthenticated: false,
        isLoading: false,
      });
      return null;
    }
    return resp;
  }

  getAllCognitoCookies = (base) => document.cookie.split('; ')
    .filter((cookie) => cookie.startsWith(base))
    .reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      return { ...acc, [decodeURIComponent(key)]: decodeURIComponent(value) }; // Decode cookies
    }, {});

    setCurrentSession = async () => {
      const baseCookie = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`;
      const cognitoCookies = this.getAllCognitoCookies(baseCookie);

      const idTokenKey = Object.keys(cognitoCookies).find((key) => key.endsWith('.idToken'));

      if (!idTokenKey) {
        const customError = new Error('CognitoIdentityServiceProvider.idToken not found');
        Bugsnag.notify(customError, {
          metadata: {
            cookies: cognitoCookies,
          },
        });
        throw customError;
      }

      try {
        const response = await API.post(
          'USER',
          '/usersession/create',
          {
            body: {
              id_token: cognitoCookies[idTokenKey],
              redirect_url: process.env.REACT_APP_MHUB_LEAD_URL,
              request_headers: cognitoCookies,
            },
          },
        );
        return response;
      } catch (error) {
        Bugsnag.notify(error, {
          metadata: {
            cookies: cognitoCookies,
          },
        });
        console.error('Error creating session:', error);
        throw error;
      }
    };

  authenticate = async () => {
    await this.setStateAsync({
      isLoading: true,
      hasAccess: false,
      isAuthenticated: false,
    });

    const sessionResp = await this.getCurrentSession();

    let { hasAccess, hasHigherAccess } = this.state;

    let isAuthenticated = false;
    let user = {
      groups: [],
    };
    let data = {};

    if (sessionResp) {
      data = await getAuthentication();
      if (data && !_.isEmpty(data)) {
        user = await this.getUserData(data);
        if (user && !_.isEmpty(user)) {
          isAuthenticated = true;
        }
      }
    }

    // FIXME: Implement this in separate context provider
    let apps = null;
    let users = {};
    let userOptions = [];
    let activeUserOptions = [];
    let listCompanyBranches = [];

    const maxRetries = 2; // Number of retries after the first attempt
    let attempts = 0;
    let success = false;

    if (isAuthenticated && Array.isArray(user.groups) && user.groups.length > 0) {
      while (attempts <= maxRetries && !success) {
        try {
          await this.setCurrentSession();
          success = true;
        } catch (error) {
          attempts += 1;
          console.error(`Attempt ${attempts} failed:`, error);

          if (attempts > maxRetries) {
            // If all attempts fail, redirect to login
            window.location.href = `${config.app.secureUrl}/logout`;
            await this.setStateAsync({
              authenticated: false,
              hasAccess: false,
              hasHigherAccess: false,
              isLoading: false,
              isLoggedOut: true,
            });
          } else {
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }
        }
      }

      if (success) {
        listCompanyBranches = await this.fetchListCompanyBranch();

        hasAccess = hasAllowedGroups(user.groups);
        hasHigherAccess = hasUpperGroups(user.groups);

        // Retrieve user list
        const listUsers = await this.getUsers(user);
        const listAllUsers = await this.getAllUsers();

        // Set user options
        // Exclude users without name and sort users in ascending order
        const filtered = listUsers.filter((u) => u.name);
        filtered
          .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
          .sort((a, b) => {
            if (!(a && a.user_status) || a.user_status === 'suspended') {
              return 1;
            }
            if (!(b && b.user_status) || b.user_status === 'suspended') {
              return -1;
            }
            return 0;
          });
        userOptions = await this.getUserList(filtered, listCompanyBranches);
        users = await this.getUserList(listAllUsers, listCompanyBranches, true);

        const userIds = Object.values(users).map((u) => (u.id));
        if (userIds && userIds.length) {
          const userExist = userIds.filter((id) => id === user.id).length > 0;
          if (!userExist) {
            users[user.id] = user;
            Bugsnag.notify(`User ${user} not found in user listing`);
          }
        }

        // Retrieve only active users
        const filteredNonActive = filtered.filter((u) => u.user_status && u.user_status !== 'suspended');
        activeUserOptions = await this.getUserList(filteredNonActive, listCompanyBranches);

        // Get showroom app details
        if (data && data.affiliate && data.affiliate.company && data.affiliate.company.apps) {
          apps = data.affiliate.company.apps
            .reduce((obj, app) => Object.assign(obj, { [app.appID]: app }), {});
        }
      }
    } else {
      window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
    }

    await this.setStateAsync({
      apps,
      hasAccess,
      hasHigherAccess,
      isAuthenticated,
      user,
      users,
      userOptions,
      activeUserOptions,
      listCompanyBranches,
      isLoading: false,
    });
  }

  destroyCurrentSession = async () => {
    try {
      const sessionResp = await this.getCurrentSession();
      const baseCookie = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}.${sessionResp.idToken.payload.sub}`;
      const cognitoCookies = this.getAllCognitoCookies(baseCookie);
      const idToken = cognitoCookies[`${baseCookie}.idToken`];

      const userSessionResp = await API.post(
        'USER',
        '/usersession/delete',
        { body: { id_token: idToken } },
      );

      return userSessionResp;
    } catch (error) {
      console.error('Error destroying session:', error);
      throw error;
    }
  };

  // Sign out of cognito session.
  // Redirect to login app's signout page to let login app handle signout session flow.
  signOut = async () => {
    try {
      await this.destroyCurrentSession();
      window.location.href = `${config.app.secureUrl}/logout`;
      await this.setStateAsync({
        authenticated: false,
        hasAccess: false,
        hasHigherAccess: false,
        isLoading: false,
        isLoggedOut: true,
      });
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  render() {
    const { children } = this.props;
    const { data } = this.state;
    const value = {
      state: data,
      actions: {
        authenticate: this.authenticate,
        signOut: this.signOut,
      },
    };

    if (!data.isAuthenticated) {
      return <LoadingPage isLoading />;
    }

    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  }
}

// LMS-970: AWS Cognito ID and access tokens expire after 1 hour
// Here with make a request to auth every 30 minutes to keep the connection alive
// Refer: https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html#limits-hard
setInterval(async () => {
  try {
    await Auth.currentSession();
  } catch {
    window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
  }
}, 1800000);

AuthProvider.displayName = 'AuthProvider';
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
