import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Link,
  Select,
  IconButton,
  Typography,
  Box,
  InputLabel,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import {
  LEAD,
  CUSTOMER,
} from '../../graphql';
import { previewDateTime, TruncateString } from '../../utils';
import { Drawer, Tooltip, UploadFileContainer } from '..';
import {
  useStyles, SelectInput,
  CustomMenuItem,
  BorderLinearProgress,
} from './styles';
import { AuthContext } from '../../contexts';
import { useEnhancedMutation } from '../../hooks';
import { Searchable } from '../Dropdown';
import { ROLE_HEADQUARTERS } from '../../data';
import { CloseRoundIcon, FileTypeDisabledIcon, FileTypeIcon } from '../../assets/icons';

function BulkImport(props) {
  const {
    open,
    data,
    dataFields,
    onCancel,
    refetch,
    excelTemplateURL,
    csvTemplateURL,
    findOutMoreURL,
    importLabel,
    importDescription,
    importType,
  } = props;
  const { fields } = data;
  const authContext = useContext(AuthContext);
  const { user, listCompanyBranches } = authContext.state;
  const classes = useStyles(props);
  const wideSectionClass = useStyles(props, true);

  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState(data);
  const [file, setFile] = useState([]);
  const [uploadedFileResponse, setUploadedFileResponse] = useState({});
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState({});
  const [fileSelected, setFileSelected] = useState({});
  const [companyBranchesOptions, setCompanyBranchesOption] = useState([]);
  const [companyBranchID, setCompanyBranchID] = useState('');

  const [uploadLeads] = useEnhancedMutation(LEAD.UPLOAD());
  const [uploadCustomers] = useEnhancedMutation(CUSTOMER.UPLOAD());
  const totalSteps = 1;
  const isFirstStep = activeStep === 0;
  const isHQ = user.role === ROLE_HEADQUARTERS;

  useEffect(() => {
    if (listCompanyBranches) {
      const companyBranchArr = [];
      Object.keys(listCompanyBranches).forEach((branch) => {
        companyBranchArr.push({
          label: listCompanyBranches[branch].name,
          value: branch,
        });
      });
      setCompanyBranchesOption(companyBranchArr);
    }
  }, [listCompanyBranches]);

  const handleChangeDropdown = (e, s) => {
    setCompanyBranchID(s);
  };

  const getFieldsArray = useCallback((type) => {
    const fieldsArray = [];
    if (!_.isEmpty(dataFields[type].attributes)) {
      Object.entries(dataFields[type].attributes).forEach(([key, value]) => {
        fieldsArray.push({ name: key, ...value });
      });
      return fieldsArray;
    }
    return fieldsArray;
  }, [dataFields]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const total = `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    return total;
  };

  const useSectionStyle = () => {
    if (isFirstStep) return classes.sections;
    return wideSectionClass.sections;
  };

  // TODO: Enable back when in LMS-725
  // const isImportDisabled = () => {
  //   const fieldValue = fields.filter((f) => f.value !== 'unmapped');
  //   return fieldValue.length < 3;
  // };

  const uploadFile = (fileToUpload) => {
    setUploadPercentage(10);
    // TODO: Pass in `company_branch_id` into this two mutations
    if (importType === 'lead') {
      uploadLeads({
        variables: {
          company_branch_id: companyBranchID,
        },
      })
        .then((res) => {
          if (!_.isEmpty(res.data.uploadLeads.url)) {
            setUploadPercentage(100);
            setTimeout(() => {
              setUploadedFileResponse(res.data.uploadLeads);
              setLoading(false);
              setError({});
              setCompleted(true);
            }, 1000);
          }
        })
        .catch(() => {
          setLoading(false);
          setError({ errorType: 'upload', errorMessage: 'Upload failed.' });
        });
    } else {
      uploadCustomers({
        variables: {
          filename: fileToUpload.name,
          company_branch_id: companyBranchID,
          import_id: (!_.isEmpty(fileSelected) && fileSelected.import_id) || '',
        },
      })
        .then((res) => {
          if (!_.isEmpty(res.data.uploadCustomers.url)) {
            setUploadPercentage(100);
            setTimeout(() => {
              setUploadedFileResponse(res.data.uploadCustomers);
              setLoading(false);
              setError({});
              setCompleted(true);
            }, 1000);
          }
        })
        .catch(() => {
          setLoading(false);
          setError({ errorType: 'upload', errorMessage: 'Upload failed.' });
          setFile([]);
        });
    }
  };

  const handleOnChange = (e, name) => {
    const { value } = e.target;
    if (_.isArray(fields) && fields.length > 0) {
      fields.forEach((element, index) => {
        if (element.name === name) {
          setValues(fields[index].value = value);
        }
      });
    }
  };

  const handleOnUpload = (e) => {
    const f = Array.from(e.target.files);
    setLoading(true);
    setFile(f[0]);
    uploadFile(f[0]);
  };

  const handleOnClearUpload = () => {
    setUploadPercentage(0);
    setCompleted(false);
    setError({});
    setFile([]);
  };

  const handleClose = () => {
    setError({});
    setCompanyBranchID('');
    setUploadPercentage(0);
    setActiveStep(0);
    setFile([]);
    setFileSelected({});
    onCancel();
    setCompleted(false);
  };

  // TODO: Revisit when doing LMS-630, for now import will only reload the list with uploaded file
  const handleOnImport = () => {
    if (!(user
        && user.companyBranch
        && user.companyBranch.id)) {
      setLoading(false);
      setError({ errorType: 'upload', errorMessage: 'Upload failed.' });
      Bugsnag.notify(`Failed to bulk import: ${user.email} company_branch_id is null.`);
      return;
    }
    setLoading(true);
    const headers = {
      'x-amz-meta-user-id': user.id,
      'x-amz-meta-company-branch-id': !isHQ ? user.companyBranch.id : companyBranchID,
    };
    if (uploadedFileResponse.import_id) {
      headers['x-amz-meta-import-id'] = uploadedFileResponse.import_id;
    }

    fetch(uploadedFileResponse.url, {
      method: 'PUT',
      headers,
      body: file,
    })
      .then(async (r) => {
        if (r.ok) {
          await setTimeout(() => {
            setLoading(false);
            refetch();
            handleClose();
            // FIXME: Remove when implement import mapping
            console.log(values);
          }, 3000);
        } else {
          throw new Error('Response failed');
        }
      })
      .catch(() => {
        setLoading(false);
        setError({ errorType: 'upload', errorMessage: 'Upload failed.' });
      });
  };

  const renderOptions = (items) => {
    const fieldSelected = (value) => fields.findIndex((v) => v.value === value);
    if (items) {
      return items.map(
        (opt) => (
          <CustomMenuItem
            disabled={fieldSelected(opt.name) > -1}
            key={opt.name}
            value={opt.name}
          >
            {opt.label}
          </CustomMenuItem>
        ),
      );
    }
    return null;
  };

  const renderDropdown = (label, name, dropdownProps) => {
    const fieldIndexName = fields.findIndex((v) => v.name === name);
    return (
      <Select
        fullWidth
        displayEmpty
        value={fields[fieldIndexName].value}
        onChange={(e) => handleOnChange(e, name)}
        input={<SelectInput id={`select-checkbox-${label}`} />}
        MenuProps={{
          classes: { paper: classes.paper },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          MenuListProps: {
            disablePadding: true,
          },
        }}
        {...dropdownProps}
      >
        <CustomMenuItem key="unmapped" value="unmapped">Unmapped</CustomMenuItem>
        <Typography className={classes.optionTitle}>
          Basic fields
        </Typography>
        { renderOptions(getFieldsArray('basic')) }
        <Typography className={classes.optionTitle}>
          Other fields
        </Typography>
        { renderOptions(getFieldsArray('custom')) }
      </Select>
    );
  };

  const renderField = (field) => {
    const commonProps = {
      disabled: field.disabled,
      defaultValue: field.defaultValue,
      placeholder: field.placeholder,
      required: field.required,
      inputProps: { id: field.html_id },
    };
    return (
      <Grid key={field.name} className={classes.fieldSection} container alignItems="center">
        <Grid item xs={4}>
          <div className={classes.textIconWrapper}>
            <Typography className={classes.fieldLabel}>
              {TruncateString(field.label, 20)}
            </Typography>
            {/* TODO: Temporary show/hide circle logic */}
            {
              field.value !== 'unmapped' ? (
                <Icons.CheckCircle className={classes.checkIcon} />
              ) : null
            }
          </div>
        </Grid>
        <Grid item xs={8} className={classes.paddingLeft}>
          {renderDropdown(field.label, field.name, commonProps)}
        </Grid>
      </Grid>
    );
  };

  const renderGuideList = () => (
    <>
      <ul className={classes.list}>
        <li>{`The CSV file has all the required ${importType} fields`}</li>
        <li>
          The values of the fields are in the correct format
          <br />
          (eg. number fields only contain numbers)
        </li>
        <li>
          {'Make sure your filename does not contain invalid characters (eg. < > ? [ ] : | * ( ), except - or _)'}
        </li>
        <li>
            Download this
          <Link href={excelTemplateURL}> Sample XLS </Link>
          or
          <Link href={csvTemplateURL}> Sample CSV </Link>
            for reference
        </li>
      </ul>
      <Typography variant="body2">
        Click&nbsp;
        <Link target="_blank" rel="noopener" href={findOutMoreURL}>
          here
        </Link>
        &nbsp;to find out more
      </Typography>
    </>
  );

  const getUploadDescription = () => {
    let text = '';
    if (loading) {
      text = 'Loading...';
    }
    if (error && error.type === 'upload') {
      text = 'Upload failed';
    }
    if (completed) {
      text = `${previewDateTime(moment())} • ${formatBytes(file.size)}`;
    }
    return text;
  };

  const renderStepOne = () => (
    (
      <>
        <Box className={classes.description}>
          <Typography variant="body2">
            {`To ${importDescription}, upload a CSV file according to the guideline below.`}
          </Typography>

          { renderGuideList() }
        </Box>

        {
          isHQ && (
            <Box className={classes.buContainer}>
              <InputLabel required>
                Select business unit
              </InputLabel>
              <Typography variant="caption">
                Allow the users of this business unit to access the following imported leads
              </Typography>
              <Searchable
                required
                filterSelectedOptions
                disableClearable
                disabled={loading || uploadPercentage === 100}
                options={companyBranchesOptions}
                value={companyBranchID}
                onChange={handleChangeDropdown}
              />
            </Box>
          )
        }

        <Box>
          <InputLabel required>
            Attachment
          </InputLabel>
          {
            !(file && file.name) ? (
              <UploadFileContainer
                disabled={isHQ && !companyBranchID}
                accept=".csv"
                description=" CSV file types only"
                onChange={handleOnUpload}
              />
            ) : (
              <div className={classes.fileInputWrapper}>
                <Grid
                  className={classes.fileInput}
                  container
                  alignItems="center"
                  justify="space-between"
                  wrap="nowrap"
                  flex="1"
                >
                  {
                    !completed
                      ? <img src={FileTypeDisabledIcon} alt="Upload not complete yet" />
                      : <img src={FileTypeIcon} alt="Uploaded complete" />
                  }
                  <Box className={classes.fileInfo}>
                    <Tooltip title={file.name} placement="bottom-start">
                      <Typography variant="subtitle1" className={classes.ellipsis}>
                        {file.name}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption" className={error && error.type === 'upload' ? 'errorText' : ''}>
                      { getUploadDescription() }
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    disableRipple
                    disableFocusRipple
                    disabled={!completed || loading}
                    onClick={handleOnClearUpload}
                  >
                    <img
                      src={CloseRoundIcon}
                      className={`${classes.closeIcon} ${(!completed || loading) ? 'disabled' : ''}`}
                      alt="Remove uploaded file"
                    />
                  </IconButton>
                </Grid>
                {
                  !(uploadPercentage === 100 && completed) && (
                    <BorderLinearProgress
                      variant="determinate"
                      value={uploadPercentage}
                    />
                  )
                }
              </div>
            )
          }
        </Box>
      </>
    )
  );

  const getImportLabel = () => {
    if (!importLabel) return 'Import';
    return importLabel.charAt(0).toUpperCase() + importLabel.slice(1);
  };

  const renderStepTwo = () => (
    <>
      <Typography variant="body2" className={classes.information}>
        Match columns in your uploaded file to the lead fields to complete the import.
      </Typography>
      <Grid container className={classes.tableHeader}>
        <Grid item xs={4}>CSV file column header</Grid>
        <Grid item xs={8} className={classes.paddingLeft}>Lead fields</Grid>
      </Grid>
      {data && data.fields.length > 0 ? data.fields.map((v) => renderField(v)) : null}
    </>
  );

  return (
    <Drawer
      header={getImportLabel()}
      open={open}
      submitLabel="Import"
      isSubmitDisabled={loading || !(file && file.name) || !completed}
      onSubmit={handleOnImport}
      onClose={handleClose}
    >
      <div className={`${classes.content} ${useSectionStyle()}`}>
        <Grid
          container
          justify="space-between"
          className={classes.titleWrapper}
        >
          <Grid item>
            <Typography variant="h6">
              { isFirstStep ? 'Upload file' : 'Map columns to fields'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {`${activeStep + 1}/${totalSteps}`}
            </Typography>
          </Grid>
        </Grid>
        { isFirstStep ? renderStepOne() : renderStepTwo() }
      </div>
      {/* TODO: Enable back in LMS-725 when API ready */}
      {/* { isFirstStep ? <Button variant="contained" onClick={() =>
      setActiveStep((s) => s + 1)}>Next</Button> : (
            <div>
          <Button className={classes.marginRight} variant="outlined"
          onClick={() => setActiveStep((s) => s - 1)}>Back</Button>
          <Button variant="contained" disabled={isImportDisabled()}
          onClick={handleOnImport}>
            Import
          </Button>
        </div>
      )} */}
    </Drawer>
  );
}

BulkImport.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  dataFields: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func,
  refetch: PropTypes.func,
  excelTemplateURL: PropTypes.string,
  csvTemplateURL: PropTypes.string,
  findOutMoreURL: PropTypes.string,
  importLabel: PropTypes.string,
  importDescription: PropTypes.string,
  importType: PropTypes.string,
};

BulkImport.defaultProps = {
  open: false,
  data: {},
  dataFields: {},
  onCancel: () => {},
  refetch: () => {},
  excelTemplateURL: '',
  csvTemplateURL: '',
  findOutMoreURL: '',
  importLabel: '',
  importDescription: '',
  importType: '',
};

export default BulkImport;
