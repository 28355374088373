import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../../../contexts/Theme';

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    '& > div:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  value: {
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
    '&.italic': {
      fontStyle: 'italic',
    },
  },
  controls: {
    marginTop: '0.5rem',
    '& > div > button': {
      width: '36px',
      padding: 0,
    },
  },
  currency: {
    marginRight: '0.2rem',
  },
  icon: {
    height: '18px',
    width: '18px',
    filter: filters.tertiary.lighterGrey,
  },
  textarea: {
    '& textarea': {
      height: 'auto',
    },
  },
}));
