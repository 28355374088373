import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getSubscription',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
