import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  pages: {
    flex: '1',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    height: '40px',
    '& li': {
      display: 'flex',
      margin: '0 0.5rem',
      '& a': {
        outline: 'none',
        border: 'none',
      },
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  link: {
    transition: 'all 0.25s',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.86rem',
    minWidth: '2.15rem',
    height: '2.15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.font.header,
    [theme.breakpoints.down('xs')]: {
      minWidth: '1rem',
    },
  },
  loadMore: {
    color: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
  },
  breakLink: {
    padding: '0.75rem',
    cursor: 'pointer',
  },
  activeLink: {
    background: theme.palette.tertiary.mediumGrey,
  },
  disabledLink: {
    background: 'none',
    cursor: 'default',
  },
  pageAmountButton: {
    padding: '0.5rem 1rem',
    '&:hover': {
      background: theme.palette.tertiary.mediumGrey,
      color: theme.palette.font.header,
    },
    '& svg': {
      fill: theme.palette.font.body,
    },
  },
  hideNumbers: {
    '& > li:not(.previous):not(.next)': {
      display: 'none',
    },
  },
  hideAll: {
    '& > a': {
      cursor: 'default',
      color: theme.palette.tertiary.grey,
    },
  },
}));
