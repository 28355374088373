import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Users GraphQL Queries.

// LIST list users GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listUsers',
    args: [
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...ListUserResponseFields}',
    fragments: [Fragments.listUserResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_COMPANY_BRANCH list users GraphQL schema.
export const LIST_COMPANY_BRANCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCompanyBranches',
    args: [],
    argsMap: [],
    fields: '{...ListCompanyBranchesResponseFields}',
    fragments: [Fragments.listCompanyBranchesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_USER_GROUPS list users GraphQL schema.
export const LIST_USER_GROUPS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listUserGroups',
    args: [
      { name: 'filters', type: '[ConditionInput]' },
      { name: 'sort_latest_update', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
      { name: 'sort_latest_update', value: 'sort_latest_update' },
    ],
    fields: '{...ListUserGroupsResponseFields}',
    fragments: [Fragments.listUserGroupsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_USER_GROUP get user group GraphQL schema.
export const GET_USER_GROUP = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getUserGroup',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...UserGroupFields}',
    fragments: [Fragments.userGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_ACTIVITIES list leads' activities GraphQL schema.
export const LIST_ACTIVITIES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listActivities',
    args: [
      { name: 'user_group_id', type: 'String' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'user_group_id', value: 'user_group_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListActivitiesResponseFields}',
    fragments: [Fragments.listActivitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
