import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = (props) => {
  const { ...rest } = props;

  return (
    <Button {...rest} disabled>
      <CircularProgress size={15} />
    </Button>
  );
};

export default LoadingButton;
