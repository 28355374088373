import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import { toCapitalize } from '../../utils';
import userStyles from './styles';

const ListExistUsers = (props) => {
  const {
    id,
    leads,
    customers,
  } = props;

  const classes = userStyles();

  const listLeads = (id ? leads.filter((item) => item.id !== id) : leads) || [];
  const listCustomers = (id ? customers.filter((item) => item.id !== id) : customers) || [];

  const renderSingleResult = () => {
    // Only match single lead
    if (listLeads.length === 1 && listCustomers.length === 0) {
      const lead = listLeads[0];
      return (
        <Link
          component={RouterLink}
          to={`/leads/${lead.id}`}
          target="_blank"
        >
          { toCapitalize(lead.name) }
        </Link>
      );
    }
    // Only match single customer
    if (listCustomers.length === 1 && listLeads.length === 0) {
      const customer = listCustomers[0];
      return (
        <Link
          component={RouterLink}
          to={`/customers/${customer.id}`}
          target="_blank"
        >
          { toCapitalize(customer.name) }
        </Link>
      );
    }
    return '';
  };

  const haveSingleResult = (listLeads.length === 1 && listCustomers.length === 0)
    || (listCustomers.length === 1 && listLeads.length === 0);

  return (
    <div className={classes.container}>
      {
        haveSingleResult ? (
          <Typography>
            Are you looking for&nbsp;
            { renderSingleResult() }
            ?
          </Typography>
        ) : (
          <>
            <Typography>Are you looking for</Typography>
            <ul>
              {
                listLeads.map((lead) => (
                  <li key={lead.id}>
                    <Link
                      component={RouterLink}
                      to={`/leads/${lead.id}`}
                      target="_blank"
                    >
                      { toCapitalize(lead.name) }
                    </Link>
                  </li>
                ))
              }
              {
                listCustomers.map((customer) => (
                  <li key={customer.id}>
                    <Link
                      component={RouterLink}
                      to={`/customers/${customer.id}`}
                      target="_blank"
                    >
                      { toCapitalize(customer.name) }
                    </Link>
                  </li>
                ))
              }
            </ul>
          </>
        )
      }
    </div>
  );
};

ListExistUsers.propTypes = {
  id: PropTypes.string,
  leads: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
  customers: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ),
};

ListExistUsers.defaultProps = {
  id: '',
  leads: [],
  customers: [],
};

export default ListExistUsers;
