function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgCloseSmall = function SvgCloseSmall(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Assets",
    width: 16,
    height: 16,
    viewBox: "0 0 24 24",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("path", {
    d: "M13.289,12,19.2,6.087A.911.911,0,1,0,17.913,4.8L12,10.711,6.087,4.8a.9.9,0,0,0-.644-.266h0A.9.9,0,0,0,4.8,4.8a.912.912,0,0,0,0,1.288L10.714,12,4.8,17.913a.91.91,0,0,0,.644,1.554.9.9,0,0,0,.644-.266L12,13.286l5.912,5.91a.933.933,0,0,0,1.288,0,.912.912,0,0,0,0-1.288Z",
    style: {
      fill: "#596975"
    }
  }));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgCloseSmall, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/closeSmall.d98ff9b8.svg";
export { ForwardRef as ReactComponent };