import { CONSTRUCTOR } from '..';

// Filter fields fragment.
export const filter = CONSTRUCTOR.FRAGMENT({
  name: 'Filter',
  fields: `{
    field
    values
  }`,
});

// Search fields fragment.
export const search = CONSTRUCTOR.FRAGMENT({
  name: 'Search',
  fields: `{
    match
    sort_col
    sort_dir
    filters {...FilterFields}
    from
    size
  }`,
  fragments: [filter],
});
