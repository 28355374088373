import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export default makeStyles((theme) => ({
  banner: {
    padding: '1rem',
    borderRadius: '5px',
    '& > .MuiIcon-root, & > svg': {
      marginRight: '0.5rem',
      display: 'flex',
      alignItems: 'center',
    },
    '& > div': {
      alignSelf: 'center',
    },
    '&.info': {
      backgroundColor: theme.palette.tertiary.lightBlue,
      '& > .MuiIcon-root': {
        filter: filters.secondary.main,
      },
    },
    '&.warning': {
      background: theme.palette.tertiary.mustard,
      '& > svg': {
        color: theme.palette.tertiary.alertYellow,
      },
    },
  },
}));
