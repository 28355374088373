import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// LeadIntegration GraphQL Queries.

// READ fetch tenant data GraphQL schema.
export const FETCH_TENANT_DATA = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'fetchTenantData',
    args: [{ name: 'company_id', type: 'String', required: true }],
    argsMap: [{ name: 'company_id', value: 'company_id' }],
    fields: '{...FetchTenantDataResponseFields}',
    fragments: [Fragments.fetchTenantDataResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const FETCH_ALL_FIELD_DATA = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'fetchAllFieldData',
    args: [{ name: 'company_id', type: 'String', required: true },
      { name: 'field_name', type: 'String', required: true }],
    argsMap: [{ name: 'company_id', value: 'company_id' },
      { name: 'field_name', value: 'field_name' }],
    fields: '{...FetchAllFieldDataResponseFields}',
    fragments: [Fragments.fetchAllFieldDataResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ list data mappings GraphQL schema.
export const LIST_DATA_MAPPING = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listDataMappings',
    args: [{ name: 'company_id', type: 'String', required: true },
      { name: 'type', type: 'String' }],
    argsMap: [{ name: 'company_id', value: 'company_id' },
      { name: 'type', value: 'type' }],
    fields: '{...ListDataMappingsResponseFields}',
    fragments: [Fragments.listDataMappingsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
