import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../../../contexts';

export default makeStyles((theme) => ({
  container: {
    '& label': {
      marginTop: '1.5rem',
    },
  },
  removeFieldIcon: {
    marginLeft: '1rem',
    padding: 0,
    color: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.main,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0.5rem',
    },
  },
  dropdown: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      minWidth: '0px',
    },
    '& + $dropdown': {
      paddingLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0',
        marginTop: '0.5rem',
      },
    },
  },
  wrapper: {
    backgroundColor: theme.palette.tertiary.lightGrey,
    padding: '0.5rem 1.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem 1rem',
    },
    '& > div:first-child p': {
      marginRight: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginRight: '0.5rem',
      },
    },
  },
  rule: {
    '& > p:first-child': {
      marginRight: '0.5rem',
    },
    '& > p:not(:first-child)': {
      margin: '0 0.5rem',
    },
  },
  addButton: {
    padding: 0,
    '&:hover': {
      background: 'unset',
      color: theme.palette.primary.dark,
    },
  },
  separator: {
    padding: '0.5rem 0',
    backgroundColor: theme.palette.tertiary.lightGrey,
    '&:before, &:after': {
      content: '" "',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.tertiary.darkGrey}`,
    },
    '&:before': {
      marginRight: '0.5rem',
    },
    '&:after': {
      marginLeft: '0.5rem',
    },
  },
  andWrapper: {
    padding: '0.2rem 0.8rem',
    borderRadius: '15px',
    backgroundColor: theme.palette.tertiary.darkGrey,
    fontWeight: fonts.semiBold,
  },
  fields: {
    display: 'flex',
    flex: 1,
    minWidth: '0px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      flexDirection: 'column',
      width: '50%',
    },
  },
  datePickerRoot: {
    padding: 0,
  },
  calendarIcon: {
    opacity: 0.5,
  },
  terms: {
    marginTop: '1.5rem',
  },
}));
