import { PhoneNumberUtil } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import { isMobile as isMobileDevice } from 'react-device-detect';
import {
  AVANSER_DIALER,
  IS_CALLING_SELF,
  MOBILE_DIALER,
  NO_AVANSER_LOGIN,
  NO_CALL_ACCESS,
  NO_CALLER_MOBILE_NUMBER,
  NO_ANSWERPOINT_DIALER,
} from '../data/calls';
import { isEmptyValue } from './types';

// Pass in phone number with +{countrycode}{mobile}
export async function extractPhoneNumber(number) {
  if (number) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const extracted = await phoneUtil.parse(number, '');
    if (phoneUtil.isValidNumber(extracted)) {
      return {
        country_code: extracted.getCountryCode(),
        national_number: extracted.getNationalNumber(),
      };
    }
  }
  return {};
}

export function normalizeAvanserPhoneNumber(number) {
  if (number) {
    return number.replace(/\+|-/g, '');
  }
  return '';
}

export async function callMethodStatus({
  apps = {
    lead_management_v2: { hasCallAccess: false },
  },
  avanserAccount = {},
  user = {
    company: { id: '' },
    mobile_prefix: '',
    mobile_suffix: '',
    mobile: '',
    dialer_name: '',
    dialer_tracking_number: '',
  },
  record = {
    mobile_country_code: '',
    msisdn: '',
    mobile: '',
    phone: '',
  },
}) {
  const recordMobile = record.msisdn || record.mobile || record.phone;
  const noCallAccess = !(apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess);
  const noAvanserAccount = !(avanserAccount && !isEmpty(avanserAccount));
  const noDialer = !(
    user
    && !isEmptyValue(user.dialer_name)
    && !isEmptyValue(user.dialer_tracking_number)
  );
  const noCallerNumber = !(user && user.mobile_prefix && user.mobile_suffix);
  let status = '';

  if (isMobileDevice && (noCallAccess || noAvanserAccount || noDialer)) {
    status = MOBILE_DIALER;
  } else if (noCallAccess) {
    status = NO_CALL_ACCESS;
  } else if (noAvanserAccount) {
    status = NO_AVANSER_LOGIN;
  } else if (noCallerNumber) {
    status = NO_CALLER_MOBILE_NUMBER;
  } else if (user && user.mobile === recordMobile) {
    status = IS_CALLING_SELF;
  } else if (noDialer) {
    status = NO_ANSWERPOINT_DIALER;
  } else {
    status = AVANSER_DIALER;
  }
  return status;
}
