import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../../contexts';

export default makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  iconClose: { padding: 0 },
  root: {
    width: 'auto',
    minWidth: '32.14rem',
    padding: '1.8rem 2.1rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  modalTopBar: { marginBottom: theme.spacing(2) },
  modalTitle: { ...fonts.modalTitleFont },
}));
