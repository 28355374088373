import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default class Logo extends PureComponent {
  classNames = () => {
    const { loading, className } = this.props;
    return `MHub-Logo ${loading} ${className}`;
  }

  render() {
    const { loading, color, ...rest } = this.props;
    const classNames = this.classNames();
    return (
      <div
        {...rest}
        className={classNames}
      >
        <svg height="100%" width="100%" viewBox="1074.04 1190.785 678.971 333.732">
          <g>
            <polygon className="brick-1" points="1235.153,1363.405 1074.04,1524.517 1074.04,1363.405" />
            <polygon className="brick-2" points="1235.152,1190.785 1074.04,1351.896 1235.153,1351.896" />
            <polygon className="brick-3" points="1246.661,1190.785 1407.773,1351.896 1246.661,1351.896" />
            <polygon className="brick-4" points="1580.392,1190.785 1419.28,1351.896 1580.392,1351" />
            <polygon className="brick-5" points="1591.899,1190.785 1753.011,1351.896 1591.899,1351.896" />
            <polygon className="brick-6" points="1591.899,1363.405 1753.011,1524.517 1753.011,1363.405" />
            <polygon className="brick-7" points="1580.392,1363.405 1419.28,1524.517 1419.28,1363.405" />
            <polygon className="brick-8" points="1246.66,1363.405 1407.771,1524.517 1407.771,1363.405" />
          </g>
        </svg>
      </div>
    );
  }
}

Logo.displayName = 'Logo';

Logo.propTypes = {
  color: PropTypes.oneOf(['blue', 'red', 'white', 'grey', 'green']),
  loading: PropTypes.bool,
  className: PropTypes.string,
};

Logo.defaultProps = {
  color: null,
  loading: false,
  className: null,
};
