import { makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from '../../contexts';

export default makeStyles({
  root: {
    height: '60%',
  },
  button: {
    marginTop: '1rem',
    ...fonts.buttonFont,
    textTransform: 'none',
    minWidth: '70px',
    minHeight: '35px',
    padding: '0 1rem',
    backgroundColor: colors.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: colors.secondary.light,
    },
  },
  header: {
    ...fonts.h1,
  },
  caption: {
    textAlign: 'center',
    ...fonts.body1,
  },
});
