import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// GET_ACCOUNT get avanser account GraphQL schema
export const GET_ACCOUNT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAccount',
    args: [],
    argsMap: [],
    fields: '{ ...AccountFields }',
    fragments: [Fragments.account],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_ADSOURCES get adsources list GraphQL schema
export const LIST_ADSOURCES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listAdsources',
    args: [],
    argsMap: [],
    fields: '{ ...ListAdsourcesResponseFields }',
    fragments: [Fragments.listAdsourcesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_ANSWERPOINTS get answerpoints list GraphQL schema
export const LIST_ANSWERPOINTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listAnswerpoints',
    args: [
      { name: 'filters', type: '[Filter!]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListAnswerpointsResponseFields}',
    fragments: [Fragments.listAnswerpointsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_ANSWERPOINT get answerpoint info GraphQL schema
export const READ_ANSWERPOINT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getAnswerpoint',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...AnswerpointFields}',
    fragments: [Fragments.answerPoint],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
