import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import Drawer from '../Drawer';
import { getFieldLabel } from '../../utils';

import useStyles from './styles';

export default function FilterSort(props) {
  const {
    open,
    sort,
    options,
    onClose,
    onSort,
  } = props;

  const classes = useStyles();

  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (sort) {
      let pos = options.findIndex((c) => c.field === sort.field && c.order === sort.order);
      pos = pos === -1 ? 0 : pos;
      setPosition(pos);
    }
  }, [
    sort,
    options,
  ]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setPosition(parseInt(value, 10));
  };

  const handleOnClear = () => {
    setPosition(0);
  };

  const handleOnClose = (e) => {
    let pos = options.findIndex((c) => c.field === sort.field && c.order === sort.order);
    pos = pos === -1 ? 0 : pos;
    setPosition(pos);
    onClose(e);
  };

  const handleOnSort = (e) => {
    onSort(e, {
      sort: {
        field: options[position].field,
        order: options[position].order,
      },
    });
    onClose(e);
  };

  const renderSecondaryButtons = () => [
    {
      label: 'Reset',
      onClick: handleOnClear,
    },
  ];

  return (
    <Drawer
      header="Sort"
      open={open}
      onClose={handleOnClose}
      onSubmit={handleOnSort}
      submitLabel="Apply"
      secondaryButtons={renderSecondaryButtons()}
    >
      <div className={classes.content}>
        <RadioGroup name="sorts" onChange={handleOnChange} value={position}>
          {
            options.map((opt, idx) => (
              <FormControlLabel
                key={opt.label}
                control={<Radio />}
                label={<Typography variant="subtitle2">{opt.label}</Typography>}
                value={idx}
              />
            ))
          }
        </RadioGroup>
      </div>
    </Drawer>
  );
}

FilterSort.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSort: PropTypes.func,
  sort: PropTypes.instanceOf(Object),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

FilterSort.defaultProps = {
  open: false,
  onClose: () => {},
  onSort: () => {},
  sort: {
    field: 'created_at',
    order: 'desc',
  },
  options: [
    { label: `${getFieldLabel('created_at')} newest first`, field: 'created_at', order: 'desc' },
    { label: `${getFieldLabel('created_at')} oldest first`, field: 'created_at', order: 'asc' },
    { label: 'Last updated newest first', field: 'updated_at', order: 'desc' },
    { label: 'Last updated oldest first', field: 'updated_at', order: 'asc' },
    { label: 'Name A - Z', field: 'name', order: 'asc' },
    { label: 'Name Z - A', field: 'name', order: 'desc' },
  ],
};
