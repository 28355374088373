import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../contexts';
import { bold, bodyFont } from '../../../contexts/Theme/font';

export default makeStyles((theme) => ({
  type: {
    fontWeight: bold,
    padding: '0.5rem 1rem',
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
  },
  label: {
    fontWeight: 400,
    color: colors.font.header,
    fontFamily: bodyFont,
  },
}));
