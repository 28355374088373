import { makeStyles } from '@material-ui/core/styles';
import { filters, fonts } from '../../contexts';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  imageContainer: {
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: theme.palette.tertiary.mediumGrey,
    padding: '0.75rem',
    '& > img': {
      filter: filters.font.body,
      height: '30px',
    },
  },
  infoContainer: {
    width: '100%',
    textAlign: 'center',
  },
  infoLine: {
    display: 'inline-block',
    color: theme.palette.font.subBody,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
  },
  ctaButtons: {
    marginTop: '1.5rem',
    '& > button': {
      padding: 0,
      minHeight: '25px',
      fontSize: fonts.body2.fontSize,
      color: theme.palette.font.body,
      '& > span > span > img': {
        filter: filters.font.body,
        height: '22px',
        width: '22px',
      },
      '& + button': {
        borderRadius: 'unset',
        borderLeft: `1px solid ${theme.palette.tertiary.grey}`,
        marginLeft: '0.5rem',
        padding: '0 0 0 1rem',
      },
      '&:hover': {
        color: theme.palette.primary.main,
        '& > span > span > img': {
          filter: filters.primary.main,
        },
      },
    },
  },
}));
