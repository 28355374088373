import { CONSTRUCTOR } from '..';

// Lead Note fields fragment.
export const note = CONSTRUCTOR.FRAGMENT({
  name: 'Note',
  fields: `{
    id
    message
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

// Lead ListNoteResponse fields fragment.
export const listNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListNoteResponse',
  fields: `{
    items {...NoteFields}
    count
  }`,
  fragments: [note],
});

// Customer Note fields fragment.
export const customerNote = CONSTRUCTOR.FRAGMENT({
  name: 'Note',
  fields: `{
    id
    message
    appointment_id
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

// ListNoteResponse fields fragment.
export const listCustomerNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListNoteResponse',
  fields: `{
    items {...NoteFields}
    count
  }`,
  fragments: [customerNote],
});

// CreateNoteResponse fields fragment.
export const createNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'CreateNoteResponse',
  fields: `{
    id
  }`,
});

// UpdateNoteResponse fields fragment.
export const updateNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'UpdateNoteResponse',
  fields: `{
    id
    lead_id
    customer_id
    message
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

// GetNoteResponse fields fragment.
export const getNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetNoteResponse',
  fields: `{
    body
  }`,
});

// DeleteNoteResponse fields fragment.
export const deleteNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'DeleteNoteResponse',
  fields: `{
    id
  }`,
});

// AssignNoteResponse fields fragment.
export const assignNoteResponse = CONSTRUCTOR.FRAGMENT({
  name: 'AssignNoteResponse',
  fields: `{
    body
  }`,
});
