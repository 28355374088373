import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
  section: {
    padding: '1rem 0',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.tertiary.mediumGrey}`,
    },
  },
  header: {
    marginBottom: '1rem',
  },
  checkbox: {
    display: 'flex',
    marginRight: 0,
    marginBottom: '1.14rem',
    '& > span': {
      padding: 0,
      marginLeft: '0.71rem',
    },
  },
  buttons: {
    padding: '1.5rem 0',
  },
  resetButton: {
    padding: '0 1rem',
    color: theme.palette.font.subBody,
    textTransform: 'inherit',
  },
  clearButton: {
    marginLeft: '1rem',
  },
}));
