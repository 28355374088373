import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import _ from 'lodash';

import moment from 'moment';
import {
  AppBar as MuiAppBar,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
  ListSubheader,
  Link,
  Badge,
  Chip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowDropDown } from '@material-ui/icons';
import {
  HelpIcon,
  BurgerMenuIcon,
  ProfileIcon,
  SignoutIcon,
  SettingsIcon,
  SearchIcon,
  LeadsIcon,
  CustomersIcon,
  ArrowIcon,
  StarIcon,
  SupportIcon,
  DocumentIcon,
  MhubAccountIcon,
} from '../../assets/icons';
import logo from '../../assets/logo/mhub_logo_desktop.svg';
import { AuthContext, PageContext } from '../../contexts';
import { LEAD, CUSTOMER } from '../../graphql';
import { clientLead, clientCustomer } from '../..';
import {
  DropdownMenu,
  IconRoundButton,
} from '..';
// LMS-2843
// import ChatNotification from '../ChatNotification/ChatNotification';
import GlobalSearch from '../Dropdown/GlobalSearch/GlobalSearch';
import { toCapitalize } from '../../utils';
import config from '../../config';
import useStyles from './styles';

const AppBar = (props) => {
  const authContext = useContext(AuthContext);
  const { signOut } = authContext.actions;
  const pageContext = useContext(PageContext);
  const { lastReleaseNoteVersionSeen } = pageContext.state;
  const { setWhatsNewModal } = pageContext.actions;

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const { toggleMenu } = props;

  const [trialDaysLeft, setTrialDaysLeft] = useState(0);
  const [activeStates, setActiveStates] = useState({
    signOut: false,
  });
  const [expandSearch, setExpandSearch] = useState(false);
  const [searchType, setSearchType] = useState('all');
  const [searchResults, setSearchResults] = useState([]);

  const [searchLeadQuery, {
    data: searchLeadData,
    loading: searchLeadLoading,
  }] = useLazyQuery(LEAD.SEARCH(), {
    client: clientLead,
    fetchPolicy: 'no-cache',
  });

  const [searchCustomerQuery, {
    data: searchCustomerData,
    loading: searchCustomerLoading,
  }] = useLazyQuery(CUSTOMER.SEARCH(), {
    client: clientCustomer,
    fetchPolicy: 'no-cache',
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { apps, user } = authContext.state;
  const profileURL = apps.showroom && apps.showroom.webURL ? apps.showroom.webURL : '';
  const accountURL = config.app.mhubAccountURL;
  const newReleaseNoteAvailable = (
    !lastReleaseNoteVersionSeen
    || (lastReleaseNoteVersionSeen && lastReleaseNoteVersionSeen !== config.app.version)
  );

  useEffect(() => {
    async function fetchLeadDetails() {
      const lms = authContext.state.apps.lead_management_v2;
      if (lms) {
        const today = moment();
        const endDate = moment(lms.trialEndsAt);
        const daysLeft = endDate.diff(today, 'days');
        setTrialDaysLeft(daysLeft);
      }
    }
    fetchLeadDetails();
  }, [authContext.state.apps]);

  useEffect(() => {
    if (isDesktop && expandSearch) {
      setExpandSearch(false);
    }
  }, [isDesktop, expandSearch]);

  useEffect(() => {
    const searchLeadResults = searchLeadData
      && searchLeadData.searchLead && searchLeadData.searchLead.body
      ? JSON.parse(searchLeadData.searchLead.body).map((lead) => (
        {
          type: 'lead',
          label: lead.name,
          value: lead.id,
          mobile: lead.msisdn || lead.mobile,
        }
      )) : [];

    const searchCustomerResults = searchCustomerData
      && searchCustomerData.searchCustomers && searchCustomerData.searchCustomers.body
      ? JSON.parse(searchCustomerData.searchCustomers.body).map((customer) => (
        {
          type: 'customer',
          label: customer.contact && customer.contact.name ? customer.contact.name : 'Could not retrieve data',
          isOldContact: (customer.contact && customer.contact.deleted_at),
          value: customer.id,
          mobile: customer.contact ? (customer.contact.msisdn || customer.contact.mobile || 'n/a') : 'Could not retrieve data',
        }
      )) : [];

    switch (searchType) {
      case 'lead':
        setSearchResults([...searchLeadResults]);
        break;
      case 'customer':
        setSearchResults([...searchCustomerResults]);
        break;
      default:
        setSearchResults([
          ...searchLeadResults,
          ...searchCustomerResults,
        ]);
        break;
    }
  }, [
    searchType,
    searchLeadData,
    searchCustomerData,
  ]);

  const handleOpenState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: true }));
  };

  const handleCloseState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: false }));
  };

  const handleOpenURL = (url, newTab) => {
    const URL = url;
    if (newTab) {
      window.open(URL, '_blank');
      return;
    }
    window.location.href = URL;
  };

  const handleSignOut = () => {
    signOut();
  };

  const renderUserInformation = () => (
    <Grid container className={classes.userInfo} direction="column">
      <Grid item>
        <Typography variant="subtitle1" className={classes.value}>
          {user.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" display="inline">
          Role:&nbsp;
        </Typography>
        <Typography variant="body2" display="inline" className={classes.value}>
          {user.role}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" display="inline">
          Company:&nbsp;
        </Typography>
        <Typography variant="body2" display="inline" className={classes.value}>
          {(user.company && user.company.name) || ''}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" display="inline">
          Business unit:&nbsp;
        </Typography>
        <Typography variant="body2" display="inline" className={classes.value}>
          {(user.companyBranch && user.companyBranch.name) || ''}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderMenuHeader = (text) => (
    <Typography variant="subtitle1" className={classes.header}>
      {text}
    </Typography>
  );

  const renderMenuItem = (text, icon, haveBadge) => (
    <>
      {
        icon && (
          <ListItemIcon className={classes.menuIcon}>
            <Badge
              color="error"
              variant="dot"
              invisible={!haveBadge}
              className={classes.badge}
            >
              <Icon><img alt="" src={icon} /></Icon>
            </Badge>
          </ListItemIcon>
        )
      }
      {text}
    </>
  );

  const renderTrialText = () => {
    switch (true) {
      case trialDaysLeft === 1:
        return <div className={classes.trialText}>last day free trial</div>;
      case trialDaysLeft >= 2:
        return (
          <div className={classes.trialText}>
            {`${trialDaysLeft} days free trial`}
          </div>
        );
      case trialDaysLeft <= -1:
      default:
        return null;
    }
  };

  const products = apps ? [
    {
      label: 'Showroom',
      name: 'showroom',
      hasAccess: (apps.showroom && apps.showroom.hasAccess) || false,
      url: (apps.showroom && apps.showroom.webURL)
        || config.app.secureUrl,
    },
    {
      label: 'Loan Tracker',
      name: 'loan_tracker',
      hasAccess: (apps.loan_tracker && apps.loan_tracker.hasAccess) || false,
      url: (apps.loan_tracker && apps.loan_tracker.webURL)
        || config.app.loanTrackerURL,
    },
    {
      label: 'CRM',
      name: 'crm',
      hasAccess: true,
      url: '/',
      tag: (
        <div className={classes.trailTextContainer}>
          {renderTrialText()}
        </div>
      ),
    },
    {
      label: 'Credit Control',
      name: 'credit_control',
      hasAccess: (apps.credit_control && apps.credit_control.hasAccess) || false,
      url: (apps.credit_control && apps.credit_control.webURL),
    },
    {
      label: 'Analytics',
      name: 'analytics',
      hasAccess: (apps.analytics && apps.analytics.hasAccess) || false,
      url: (apps.analytics && apps.analytics.webURL)
        || config.app.mhubAnalyticsURL,
    },
    {
      label: 'Commission',
      name: 'commission_tracker',
      hasAccess: (apps.commission_tracker && apps.commission_tracker.hasAccess) || false,
      url: (apps.commission_tracker && apps.commission_tracker.webURL)
        || config.app.mhubCommissionTrackerURL,
      tag: (
        <div className={classes.betaTextContainer}>
          Beta
        </div>
      ),
    },
  ] : [];

  const handleOnChangeApp = (e) => {
    const { value } = e.target;
    if (value) {
      window.location.href = value;
    }
  };

  // Apps navigation for mobile view
  const renderAppsDrowdown = () => {
    if (!(apps && !_.isEmpty(apps))) {
      return null;
    }

    return (
      <Grid container justify="center">
        <FormControl className={classes.appsDropdownContainer}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value="/"
            onChange={handleOnChangeApp}
            className={classes.appsDropdown}
          >
            {
              products.map((product) => (
                product.hasAccess && (
                  <MenuItem key={product.name} value={product.url}>
                    <Typography>{product.label}</Typography>
                  </MenuItem>
                )
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const currentLocation = useLocation().pathname.substring(1);
  const renderTitle = () => {
    let title = '';
    const secondaryLocationPath = currentLocation.indexOf('/');
    if (secondaryLocationPath >= 0) {
      title = currentLocation.substr(0, secondaryLocationPath);
    } else {
      title = currentLocation;
    }
    switch (title) {
      case '':
        title = 'Home';
        break;
      case 'login':
        title = '';
        break;
      case 'access_denied':
        title = 'Access denied';
        break;
      case 'not_found':
        title = 'Not found';
        break;
      default:
    }
    return (
      <Typography variant="h6" className={classes.documentTitle}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </Typography>
    );
  };

  const renderHeading = () => {
    // to only show apps drowdown when on the homepage
    if (currentLocation === '') {
      return renderAppsDrowdown();
    }
    return renderTitle();
  };

  const minDesktopScreenSizeForAllNavs = useMediaQuery(theme.breakpoints.down('1300'));
  const renderMHubProductNavigations = () => {
    if (!(apps && !_.isEmpty(apps))) {
      return null;
    }

    let productsDesktopNav = [];
    let navsUnderMore = [];
    if (productsDesktopNav && products && products.length > 0) {
      productsDesktopNav = products;
      if (minDesktopScreenSizeForAllNavs && productsDesktopNav.length > 3) {
        navsUnderMore = productsDesktopNav.splice(3, productsDesktopNav.length - 3);
      }
    }

    return (
      <ul className={classes.ul}>
        <li>
          <a href="/" className={classes.logo}>
            <img alt="MHub CRM" src={logo} />
          </a>
        </li>
        {
          productsDesktopNav.map((product) => (
            product.hasAccess && (
              <li key={product.name}>
                <a href={product.url} className={product.name === 'crm' ? 'focus underline' : ''}>
                  {product.label}
                </a>
                {product.tag}
              </li>
            )
          ))
        }
        {
          navsUnderMore.length > 0
          && (
            <li className={classes.moreApps}>
              <DropdownMenu
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                data={navsUnderMore.map((nav) => ({
                  children: <Typography>{nav.label}</Typography>,
                  onClick: () => handleOpenURL(nav.url),
                  hide: !nav.hasAccess,
                }))}
              >
                {({ open, isOpen }) => (
                  <>
                    <Typography
                      className={`${classes.navigationFont} ${isOpen ? 'focus' : ''}`}
                      component={Link}
                      onClick={open}
                    >
                      More
                      <ArrowDropDown />
                    </Typography>
                  </>
                )}
              </DropdownMenu>
            </li>
          )
        }
      </ul>
    );
  };

  const debounceCallBack = _.debounce((callBack) => {
    callBack();
  }, 200);

  const callSearchQueries = (value, type) => {
    if (value) {
      const searchVariables = {
        variables: {
          query: value.toLowerCase(),
          search_all: true,
        },
      };
      switch (type) {
        case 'lead':
          debounceCallBack(() => {
            searchLeadQuery(searchVariables);
          });
          break;
        case 'customer':
          debounceCallBack(() => {
            searchCustomerQuery(searchVariables);
          });
          break;
        default:
          debounceCallBack(() => {
            searchLeadQuery(searchVariables);
            searchCustomerQuery(searchVariables);
          });
          break;
      }
    }
  };

  const handleOnSearchTypeChange = (type, searchValue) => {
    setSearchType(type);
    callSearchQueries(searchValue, type);
  };

  const handleOnSearchChange = (value) => {
    callSearchQueries(value, searchType);
  };

  // TODO: Enabled me if zendesk widget chat support is enabled again in the future
  // const handleContactUs = () => {
  //   openSupportWidget();
  // };

  const renderListTitleIcon = (type) => {
    switch (type) {
      case 'lead':
        return LeadsIcon;
      case 'customer':
        return CustomersIcon;
      default:
        return null;
    }
  };

  const renderGlobalSearch = () => {
    const searchOptions = [
      { icon: SearchIcon, label: 'All', value: 'all' },
      { icon: LeadsIcon, label: 'Lead', value: 'lead' },
      { icon: CustomersIcon, label: 'Customer', value: 'customer' },
    ];

    return (
      <GlobalSearch
        className={`${expandSearch ? classes.mobileGlobalSearch : ''}`}
        loading={searchLeadLoading || searchCustomerLoading}
        searchType={searchType}
        searchTypeOptions={searchOptions}
        onSearchTypeChange={handleOnSearchTypeChange}
        inputOptions={searchResults}
        onInputChange={(e, v) => handleOnSearchChange(v)}
        onChange={(e, v) => v && v.value && history.push(`/${v.type}s/${v.value}`)}
        onClearInput={() => setSearchResults([])}
        groupBy={(option) => option.type}
        filterOptions={(options) => options}
        renderOption={(option) => (
          <Grid container direction="column" className={classes.option}>
            <Grid container className={classes.contactLabel}>
              <Typography variant="subtitle2">
                { toCapitalize(option.label) }
              </Typography>
              { option.isOldContact && <Chip label="Old" className={classes.oldChat} /> }
            </Grid>

            <Typography variant="caption">
              { option.mobile }
            </Typography>
          </Grid>
        )}
        renderGroup={(params) => [
          <ListSubheader key={params.key} className={classes.searchListTitle}>
            <img
              alt={params.group}
              src={renderListTitleIcon(params.group)}
              className={classes.icon}
            />
            <Typography variant="caption" align="center">{params.group}</Typography>
          </ListSubheader>,
          params.children,
        ]}
      />
    );
  };

  const renderNavigationButton = (title, icon, open) => (
    <IconRoundButton
      imgIconSrc={icon}
      tooltipLabel={title}
      onClick={open}
      showBadge={newReleaseNoteAvailable && title === 'Help'}
    />
  );

  const renderDesktopView = () => (
    <>
      <Grid item>
        {renderMHubProductNavigations()}
      </Grid>
      <Grid item xs={4} className={classes.navigationsContainer}>
        {renderGlobalSearch()}
        {/*
        // LMS-2843
        <ChatNotification />
        */}
        <DropdownMenu
          className={classes.menu}
          headerNode={renderMenuHeader('Help')}
          data={[
            {
              children: renderMenuItem('FAQ', HelpIcon),
              onClick: () => history.push('/faq'),
            },
            {
              children: renderMenuItem('What\'s new', StarIcon, newReleaseNoteAvailable),
              onClick: () => { setWhatsNewModal(true); },
            },
            {
              children: renderMenuItem('Support', SupportIcon),
              onClick: () => handleOpenURL(
                'https://support.mhub.my/hc/en-us/categories/360001490114-Lead',
                true,
              ),
            },
            // Zendesk chat widget support has been removed since release/v1.113.0
            // {
            //   children: renderMenuItem('Contact us', SpeechBubbleIcon),
            //   onClick: handleContactUs,
            // },
            {
              children: renderMenuItem('Terms of use', DocumentIcon),
              onClick: () => handleOpenURL(
                'https://mhub.my/policies/terms_of_use/',
                true,
              ),
            },
          ]}
        >
          {({ open }) => renderNavigationButton('Help', HelpIcon, open)}
        </DropdownMenu>
        <DropdownMenu
          className={classes.menu}
          headerNode={renderUserInformation()}
          data={[
            {
              children: renderMenuItem('Profile', ProfileIcon),
              onClick: () => handleOpenURL(`${profileURL}/profile/`),
            },
            {
              children: renderMenuItem('MHub account', MhubAccountIcon),
              onClick: () => handleOpenURL(
                `${accountURL}/`,
                true,
              ),
              hide: !(user.role && user.role === 'Headquarters'),
            },
            {
              children: renderMenuItem('Settings', SettingsIcon),
              onClick: () => history.push('/settings/fields'),
              hide: !(user.role && user.role === 'Headquarters'),
            },
            {
              children: renderMenuItem('Sign out', SignoutIcon),
              onClick: handleOpenState('signOut'),
            },
          ]}
        >
          {({ open }) => renderNavigationButton('Profile', ProfileIcon, open)}
        </DropdownMenu>
      </Grid>
    </>
  );

  const renderMobileView = () => (
    <>
      <Grid item>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMenu}
        >
          <Icon>
            <img alt="menu" src={BurgerMenuIcon} />
          </Icon>
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        {renderHeading()}
      </Grid>
      <Grid item className={classes.mobileNavs}>
        <IconButton
          className={classes.searchButton}
          onClick={() => setExpandSearch(true)}
        >
          <img
            alt="search"
            src={SearchIcon}
            className={`${classes.icon} ${classes.searchListIcon}`}
          />
        </IconButton>
        {/*
          // LMS-2843
          <ChatNotification />
        */}
      </Grid>
    </>
  );

  const renderAppBar = () => {
    if (expandSearch) {
      return (
        <>
          <Grid item>
            <IconButton
              onClick={() => setExpandSearch(false)}
              className={classes.backButton}
            >
              <img alt="menu" src={ArrowIcon} className={classes.backIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            {renderGlobalSearch()}
          </Grid>
        </>
      );
    }

    return (
      <>
        {
          isDesktop
            ? renderDesktopView()
            : renderMobileView()
        }
      </>
    );
  };

  return (
    <MuiAppBar
      className={`${classes.root} ${expandSearch && 'expand'}`}
      color="inherit"
      position="static"
    >
      <Grid
        container
        alignItems="center"
        justify={`${!expandSearch ? 'space-between' : 'flex-start'}`}
      >
        {renderAppBar()}
      </Grid>
      <Dialog open={activeStates.signOut} onClose={handleCloseState('signOut')}>
        <DialogTitle>Confirm to sign out?</DialogTitle>
        <Grid container justify="center" className={classes.controls}>
          <Button variant="outlined" onClick={handleCloseState('signOut')}>
            No
          </Button>
          <Button variant="contained" onClick={handleSignOut}>
            Yes
          </Button>
        </Grid>
      </Dialog>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  toggleMenu: PropTypes.func,
};

AppBar.defaultProps = {
  toggleMenu: () => { },
};

export default AppBar;
