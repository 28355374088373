import { CONSTRUCTOR } from '..';

// LeadIntegration GraphQL Mutations.

// UPSERT upsert data mappings GraphQL schema.
export const UPSERT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createDataMappings',
    args: [
      { name: 'dataMappings', type: '[DataMappingInput!]', required: true },
    ],
    argsMap: [
      { name: 'dataMappings', value: 'dataMappings' },
    ],
    fields: '{ body }',
    fragments: [],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }

  return CONSTRUCTOR.GRAPHQL(options);
};

export default UPSERT;
