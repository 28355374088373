import { CONSTRUCTOR } from '..';

// LeadFieldSettingsResponse fields fragment.
export const leadFieldSettingsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'LeadFieldSettingsResponse',
  fields: `{
    body
    updated_at
  }`,
});
