import React, {
  useRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLazyQuery } from 'react-apollo';
import Bugsnag from '@bugsnag/js';
import { MoreVert } from '@material-ui/icons';
import {
  Button,
  Dialog,
  Typography,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { AuthContext, SnackbarContext } from '../../contexts';
import {
  DeleteConfirmation,
  DropdownMenu,
  Pagination,
} from '..';
import LoadingButton from '../loader/LoadingButton';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeSmall.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';
import Drawer from '../Drawer';
import { isEmptyValue, refetchPromise } from '../../utils';
import { CUSTOMER, LEAD, OPPORTUNITY } from '../../graphql';
import { clientLead, clientCustomer, clientOpportunity } from '../..';
import useStyles from './styles';
import { useEnhancedMutation } from '../../hooks';

const Note = (props) => {
  const {
    id,
    type,
    onClose,
    ...drawerProps
  } = props;

  const authContext = useContext(AuthContext);
  const { users } = authContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState({ id: '', appointment_id: '' });
  const [page, setPage] = useState({ limit: 10, offset: 0 });
  const [counts, setCounts] = useState(0);
  const [value, setValue] = useState('');
  const [editBox, setEditBox] = useState(false);
  const [updateMsg, setUpdateMsg] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [haveNoteChanges, setHaveNoteChanges] = useState(false);

  const timeoutRef = useRef(null);
  const setTimeoutRef = (s) => { timeoutRef.current = s; };

  const [listLeadNotesQuery, {
    data: listLeadNotes,
    loading: listLeadNotesLoading,
    error: listLeadNotesError,
    called: listLeadNotesCalled,
    refetch: listLeadNotesRefetch,
  }] = useLazyQuery(LEAD.LIST_NOTE(), {
    fetchPolicy: 'no-cache',
    client: clientLead,
  });

  const [listCustomerNotesQuery, {
    data: listCustomerNotes,
    loading: listCustomerNotesLoading,
    error: listCustomerNotesError,
    called: listCustomerNotesCalled,
    refetch: listCustomerNotesRefetch,
  }] = useLazyQuery(CUSTOMER.LIST_NOTE(), {
    fetchPolicy: 'no-cache',
    client: clientCustomer,
  });

  const [getAppointmentQuery, {
    data: { getAppointment } = { getAppointment: {} },
    loading: getAppointmentLoading,
  }] = useLazyQuery(OPPORTUNITY.READ_APPOINTMENT(), {
    fetchPolicy: 'no-cache',
    client: clientOpportunity,
  });

  const [createLeadNote] = useEnhancedMutation(LEAD.CREATE_NOTE(), {
    client: clientLead,
  });
  const [updateLeadNote] = useEnhancedMutation(LEAD.UPDATE_NOTE(), {
    client: clientLead,
  });
  const [deleteLeadNote] = useEnhancedMutation(LEAD.DELETE_NOTE(), {
    client: clientLead,
  });
  const [createCustomerNote] = useEnhancedMutation(CUSTOMER.CREATE_NOTE(), {
    client: clientCustomer,
  });
  const [updateCustomerNote] = useEnhancedMutation(CUSTOMER.UPDATE_NOTE(), {
    client: clientCustomer,
  });
  const [deleteCustomerNote] = useEnhancedMutation(CUSTOMER.DELETE_NOTE(), {
    client: clientCustomer,
  });
  const [updateAppointment] = useEnhancedMutation(OPPORTUNITY.UPDATE_APPOINTMENT(), {
    client: clientOpportunity,
  });

  const refetchList = type === 'lead' ? listLeadNotesRefetch : listCustomerNotesRefetch;

  useEffect(() => {
    if (drawerProps.open && type && id) {
      switch (type) {
        case 'lead':
          listLeadNotesQuery({
            variables: {
              lead_id: id,
              ...page,
            },
          });
          break;
        case 'customer':
          listCustomerNotesQuery({
            variables: {
              customer_id: id,
              ...page,
            },
          });
          break;
        default:
          break;
      }
    }
  }, [
    id,
    type,
    page,
    drawerProps.open,
    listLeadNotesQuery,
    listCustomerNotesQuery,
  ]);

  useEffect(() => {
    if (drawerProps.open && type && (listLeadNotesCalled || listCustomerNotesCalled)) {
      let loading = true;
      let error = false;
      let data = {};
      switch (type) {
        case 'lead':
          loading = listLeadNotesLoading;
          error = listLeadNotesError;
          data = listLeadNotes;
          break;
        case 'customer':
          loading = listCustomerNotesLoading;
          error = listCustomerNotesError;
          data = listCustomerNotes;
          break;
        default:
          break;
      }
      if (!loading) {
        setIsLoading(false);
        if (!error && data && !isEmptyValue(data.listNotes)) {
          const { items, count } = data.listNotes;
          setNotes(items || []);
          setCounts(count);
        }
      }
    }
  }, [
    type,
    drawerProps.open,
    listLeadNotesLoading,
    listLeadNotesError,
    listLeadNotes,
    listLeadNotesCalled,
    listCustomerNotesLoading,
    listCustomerNotesError,
    listCustomerNotes,
    listCustomerNotesCalled,
  ]);

  useEffect(() => {
    if (selected.appointment_id) {
      getAppointmentQuery({
        variables: {
          id: selected.appointment_id,
        },
      });
    }
  }, [getAppointmentQuery, selected.appointment_id]);


  const getUserName = (userID) => {
    const u = users[userID];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return userID;
  };

  const handleClearTimeout = () => {
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setTimeoutRef(null);
    }
  };

  const createNote = () => {
    const createAPI = type === 'customer' ? createCustomerNote : createLeadNote;
    return createAPI({
      variables: {
        [type === 'customer' ? 'customer_id' : 'lead_id']: id,
        message: value,
      },
    });
  };

  const updateNote = () => {
    const updateAPI = type === 'customer' ? updateCustomerNote : updateLeadNote;
    return updateAPI({
      variables: {
        id: selected.id,
        message: updateMsg,
      },
    });
  };

  const deleteNote = () => {
    const deleteAPI = type === 'customer' ? deleteCustomerNote : deleteLeadNote;
    return deleteAPI({
      variables: {
        id: selected.id,
      },
    });
  };

  const handleOnAddNote = () => {
    if (value) {
      setIsLoading(true);
      handleClearTimeout();
      createNote().then((item) => {
        setTimeoutRef(setTimeout(async () => {
          await refetchPromise(refetchList, 'Create note');
          setHaveNoteChanges(true);
          notes.unshift(item.data.createNote);
          setNotes([].concat(notes));
          setValue('');
          setIsLoading(false);
        }, 2000));
      }).catch(() => {
        setOpenSnackbar({
          variant: 'error',
          message: 'An error has occured. Please try again.',
        });
        setIsLoading(false);
      });
    }
  };

  const handleOnUpdateNote = () => {
    setIsLoading(true);
    handleClearTimeout();
    if (selected.id) {
      updateNote().then((item) => {
        let updateNoteList = [...notes];
        setTimeoutRef(setTimeout(async () => {
          await refetchPromise(refetchList, 'Update note');
          setHaveNoteChanges(true);
          updateNoteList = updateNoteList.map((note) => {
            if (note.id === item.data.updateNote.id) {
              return {
                id: note.id,
                message: updateMsg,
                created_by: note.created_by,
                created_at: note.created_at,
                account_id: note.account_id,
              };
            }
            return note;
          });
          setNotes(updateNoteList);
          setEditBox(false);
          if (!selected.appointment_id) {
            setIsLoading(false);
          }
        }, 2000));
      }).catch(() => {
        setOpenSnackbar({
          variant: 'error',
          message: 'An error has occured. Please try again.',
        });
        setIsLoading(false);
      });
    }
  };

  const handleOnDeleteNote = () => {
    setIsLoading(true);
    setShowDeleteDialog(false);
    handleClearTimeout();
    if (selected.id) {
      deleteNote().then(() => {
        const deleteNoteCallback = () => {
          setTimeoutRef(setTimeout(async () => {
            await refetchPromise(refetchList, 'Delete note');
            setHaveNoteChanges(true);
            const newArray = notes.filter((item) => (item.id !== selected.id));
            setNotes([].concat(newArray));
            setIsLoading(false);
          }, 2000));
        };
        // Remove note info from appointment if is ties to one
        if (selected.appointment_id) {
          if (getAppointment) {
            updateAppointment({
              variables: {
                ...getAppointment,
                id: selected.appointment_id,
                note_id: '',
                note_message: '',
              },
            }).then(() => {
              deleteNoteCallback();
            }).catch((err) => {
              Bugsnag.notify(`Delete note (note id: ${selected.id}) failed to delete note details that tie to an appointment (appointment id: ${selected.appointment_id}).`, err);
              setOpenSnackbar({
                variant: 'error',
                message: 'This note is tie to an appointment and an error has occured when delete the note inside appointment. Please try again.',
              });
              setIsLoading(false);
            });
          }
        } else {
          deleteNoteCallback();
        }
      }).catch(() => {
        setOpenSnackbar({
          variant: 'error',
          message: 'An error has occured. Please try again.',
        });
        setIsLoading(false);
      });
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleOnChange = (e) => {
    setUpdateMsg(e.target.value);
  };

  const handlePageChange = (pagination) => {
    setPage(pagination);
  };

  const handleClose = async () => {
    handleClearTimeout();
    setIsLoading(false);
    setNotes((prev) => prev || []);
    setSelected({
      id: '',
      appointment_id: '',
    });
    setPage({ limit: 10, offset: 0 });
    setValue('');
    setEditBox(false);
    setUpdateMsg('');
    onClose(haveNoteChanges);
    setHaveNoteChanges(false);
  };

  const dropMenuList = [
    { children: 'Edit', onClick: () => { setEditBox(true); } },
    { children: 'Delete', onClick: () => { setShowDeleteDialog(true); } },
  ];

  const renderSubmitButton = () => {
    if (isLoading) {
      return <LoadingButton variant="contained" size="small" />;
    }
    return (value ? (
      <Button variant="contained" size="small" onClick={handleOnAddNote}>
        Add
      </Button>
    ) : '');
  };

  const renderTableHeader = () => (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Note</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={12} className={classes.tableLoading}>
              <CircularProgress size={20} />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (!notes.length > 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={12}>
              <Typography>No notes added</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        {
          notes.map((note) => (
            <TableRow key={note.id} style={{ verticalAlign: 'center' }}>
              <TableCell className={classes.tableCell} style={{ verticalAlign: 'baseline' }}>
                <Typography variant="subtitle2" className={classes.name}>
                  { getUserName(note.created_by) }
                </Typography>
                <Typography variant="caption">{moment(note.created_at).fromNow()}</Typography>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ verticalAlign: 'baseline' }}
              >
                {
                  editBox && (selected.id && note.id === selected.id) ? (
                    <Grid container>
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                        name="editBox"
                        value={updateMsg}
                        onChange={handleOnChange}
                        className={classes.textarea}
                      />
                      <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                          <Button
                            className={classes.smallButton}
                            onClick={handleOnUpdateNote}
                            size="small"
                            variant="contained"
                            disabled={!updateMsg}
                          >
                            <SaveIcon />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.smallButton}
                            onClick={() => setEditBox(false)}
                            size="small"
                            variant="outlined"
                          >
                            <CloseIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container justify="space-between" alignItems="flex-start">
                      <Grid item xs={10}>
                        <Typography variant="body2" className={classes.content}>
                          { note.message }
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justify="flex-end">
                        <DropdownMenu data={dropMenuList}>
                          {({ open }) => (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setEditBox(false);
                                open(e);
                                setSelected({
                                  id: note.id,
                                  appointment_id: note.appointment_id || '',
                                });
                                setUpdateMsg(note.message);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          )}
                        </DropdownMenu>
                      </Grid>
                    </Grid>
                  )
                }
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    );
  };

  return (
    <Drawer
      header="Notes"
      hideFooter
      onClose={handleClose}
      isWideDrawer
      {...drawerProps}
    >
      <Grid container className={classes.paper}>
        <Typography variant="subtitle1">Add new note</Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          name="note"
          placeholder="Type something…"
          className={classes.textarea}
          disabled={isLoading}
          value={value}
          onChange={handleChange}
        />
        <Grid container justify="flex-end">
          { renderSubmitButton() }
        </Grid>
        <Table stickyHeader className={classes.container}>
          { renderTableHeader() }
          { renderTableBody() }
        </Table>
        <Pagination
          variant="simple"
          total={counts}
          onChangePage={handlePageChange}
          onChangeCount={handlePageChange}
        />
        <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
          <DeleteConfirmation
            isLoading={!!selected.appointment_id && getAppointmentLoading}
            onConfirm={handleOnDeleteNote}
            onCancel={() => setShowDeleteDialog(false)}
          />
        </Dialog>
      </Grid>
    </Drawer>
  );
};

Note.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['lead', 'customer']).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Note;
