import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useLazyQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import moment from 'moment';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  CallIcon,
  CallInboundIcon,
  CallOutboundIcon,
} from '../../../assets/icons';
import {
  formatValue,
  jsonParser,
  normalizeAvanserPhoneNumber,
  previewDateTime,
  toCapitalize,
  TruncateString,
} from '../../../utils';
import { callOutcomes } from '../constants/callLogData';
import { AuthContext, PageContext, SnackbarContext } from '../../../contexts';
import { CUSTOMER, LEAD } from '../../../graphql';
import { clientCustomer, clientLead } from '../../..';
import AudioPlayer from '../../AudioPlayer';
import useStyles from './styles';

export default function ListCallHistory(props) {
  const {
    adsourceID,
    answerpoints,
  } = props;

  const authContext = useContext(AuthContext);
  const { apps, user, users } = authContext.state;
  const pageContext = useContext(PageContext);
  const { avanserAccount } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;
  const history = useHistory();
  const classes = useStyles();

  const [currentPlayingURL, setCurrentPlayingURL] = useState('');
  const [callLogs, setCallLogs] = useState([]);
  const [logLimits, setLogLimits] = useState(20);
  const [refetchCallLogs, setRefetchCallLogs] = useState(true);
  const [loaders, setLoaders] = useState({
    initialLoad: true,
    loading: false,
  });
  const [relatedLeadNames, setRelatedLeadNames] = useState({});
  const [relatedCustomerNames, setRelatedCustomerNames] = useState({});

  const [queryListLead, {
    data: queryListLeadData,
    loading: queryListLeadLoading,
    error: queryListLeadError,
  }] = useLazyQuery(LEAD.LIST(), {
    client: clientLead,
    fetchPolicy: 'no-cache',
  });

  const [queryListCustomer, {
    data: queryListCustomerData,
    loading: queryListCustomerLoading,
    error: queryListCustomerError,
  }] = useLazyQuery(CUSTOMER.LIST(), {
    client: clientCustomer,
    fetchPolicy: 'no-cache',
  });

  const toggleLoadingState = useCallback((name, bool) => {
    setLoaders((s) => ({ ...s, [name]: bool }));
  }, []);

  const handleAPIError = useCallback((label = '', error) => {
    const errorMsgFromRes = error
      && error.response
      && error.response.data
      && jsonParser(error.response.data.error_message);
    const errorStatus = error
      && error.response
      && error.response.status;
    setOpenSnackbar({
      variant: 'error',
      message: `Failed to ${label} with error status ${errorStatus}: ${errorMsgFromRes}`,
    });
  }, [setOpenSnackbar]);

  useEffect(() => {
    const handleRetrieveCallLogs = async () => {
      toggleLoadingState('loading', true);
      try {
        const res = await API.get(
          'LEAD',
          '/calls/list',
          {
            headers: {
              'x-company-id': user.company.id,
            },
            queryStringParameters: {
              page: 1,
              limit: logLimits,
              adsource_id: adsourceID,
              type: 'printed_media',
            },
          },
        );
        if (res) {
          setCallLogs(res.filter((log) => log.direction !== 'call_out'));
        }
      } catch (error) {
        handleAPIError('retrieve call logs', error);
      }
      toggleLoadingState('initialLoad', false);
      toggleLoadingState('loading', false);
      setRefetchCallLogs(false);
    };

    if (refetchCallLogs && adsourceID) {
      handleRetrieveCallLogs();
    } else {
      toggleLoadingState('initialLoad', false);
    }
  }, [
    adsourceID,
    refetchCallLogs,
    user,
    logLimits,
    handleAPIError,
    toggleLoadingState,
  ]);

  useEffect(() => {
    // Get lead/customer name for call logs
    if (callLogs && callLogs.length > 0) {
      const profileIDs = callLogs.reduce((obj, log) => {
        const newObj = { ...obj };
        if (log.customer_id) {
          if (!newObj.customerIDs) {
            newObj.customerIDs = [log.customer_id];
          } else if (!newObj.customerIDs.includes(log.customer_id)) {
            newObj.customerIDs.push(log.customer_id);
          }
        } else if (log.lead_id) {
          if (!newObj.leadIDs) {
            newObj.leadIDs = [log.lead_id];
          } else if (!newObj.leadIDs.includes(log.lead_id)) {
            newObj.leadIDs.push(log.lead_id);
          }
        }
        return { ...newObj };
      }, {});

      if (profileIDs.leadIDs && profileIDs.leadIDs.length > 0) {
        queryListLead({
          variables: {
            fields: [
              'id',
              'name',
            ],
            filters: profileIDs.leadIDs.map((id) => ({
              field: 'id',
              operator: '==',
              value: id,
            })),
            limit: 200,
          },
        });
      }
      if (profileIDs.customerIDs && profileIDs.customerIDs.length > 0) {
        queryListCustomer({
          variables: {
            filters: profileIDs.customerIDs.map((id) => ({
              field: 'id',
              operator: '==',
              value: id,
            })),
            limit: 200,
          },
        });
      }
    }
  }, [
    callLogs,
    queryListLead,
    queryListCustomer,
  ]);

  useEffect(() => {
    if (!queryListLeadLoading
      && !queryListLeadError
      && queryListLeadData
      && queryListLeadData.listLeads
      && queryListLeadData.listLeads.body) {
      const listLeads = JSON.parse(queryListLeadData.listLeads.body);
      let objNames = {};
      if (listLeads.length > 0) {
        objNames = listLeads
          .reduce((obj, lead) => Object.assign(obj, { [lead.id]: toCapitalize(lead.name) }), {});
      }
      setRelatedLeadNames(objNames);
    }

    if (!queryListCustomerLoading
      && !queryListCustomerError
      && queryListCustomerData
      && queryListCustomerData.listCustomers
      && queryListCustomerData.listCustomers.customers) {
      const listCustomers = JSON.parse(queryListCustomerData.listCustomers.customers);
      let objNames = {};
      if (listCustomers.length > 0) {
        objNames = listCustomers
          .reduce((obj, customer) => {
            if (customer.contacts && customer.contacts[0] && customer.contacts[0].name) {
              return Object.assign(obj, { [customer.id]: toCapitalize(customer.contacts[0].name) });
            }
            return obj;
          }, {});
      }
      setRelatedCustomerNames(objNames);
    }
  }, [
    queryListLeadData,
    queryListLeadLoading,
    queryListLeadError,
    queryListCustomerData,
    queryListCustomerLoading,
    queryListCustomerError,
  ]);

  const handleScrollMore = (e) => {
    const listboxNode = e.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
      && callLogs.length >= logLimits) {
      setLogLimits((prev) => prev + 10);
      setRefetchCallLogs(true);
    }
  };

  const handleNavigateProfile = (log) => {
    if (log.customer_id) {
      history.push(`/customers/${log.customer_id}`);
    } else if (log.lead_id) {
      history.push(`/leads/${log.lead_id}`);
    } else {
      setOpenSnackbar({ variant: 'error', message: 'This call log is not tied to any lead or customer. Kindly contact support@mhub.my for further assistance' });
    }
  };

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const renderAnswerpointName = (number) => {
    if (answerpoints && answerpoints.length > 0) {
      const matched = answerpoints.find((answerpoint) => (
        normalizeAvanserPhoneNumber(answerpoint.mobile) === normalizeAvanserPhoneNumber(number)
      ));
      if (matched && matched.name) {
        return matched.name;
      }
    }
    return normalizeAvanserPhoneNumber(number);
  };

  const renderListContentBox = (icon, header, children) => (
    <section
      className={classes.listContentBox}
      onScroll={handleScrollMore}
    >
      { children }
      {
        !loaders.initialLoad
        && loaders.loading
        && (
          <Typography className={classes.loadingText}>
            Loading...
          </Typography>
        )
      }
    </section>
  );

  const renderStatus = (status) => {
    if (status) {
      const index = callOutcomes.findIndex((outcome) => outcome.value === status);
      if (index > -1) {
        return callOutcomes[index].label;
      }
      return formatValue(status);
    }
    return 'No outcome added';
  };

  const renderDurations = (duration) => {
    if (duration) {
      const formatted = moment.duration(parseInt(duration, 10), 'seconds');
      const hours = formatted.hours() ? `${formatted.hours()} hr` : '';
      const minutes = formatted.minutes() ? `${formatted.minutes()} min` : '';
      const seconds = formatted.seconds() ? `${formatted.seconds()} sec` : '';
      return `• Lasted ${hours} ${minutes} ${seconds}`;
    }
    return '';
  };

  const renderCallLog = (log) => {
    let icon = '';
    let logTitle = '';
    let profileName = '';
    if (log.customer_id) {
      profileName = relatedCustomerNames[log.customer_id] || log.direction === 'call_in' ? log.call_from : '(Deleted Customer)';
    } else if (log.lead_id) {
      profileName = relatedLeadNames[log.lead_id] || log.direction === 'call_in' ? log.call_from : '(Deleted Lead)';
    } else {
      profileName = 'n/a';
    }

    switch (log.direction) {
      case 'call_out':
        icon = CallOutboundIcon;
        logTitle = `Outbound call by ${TruncateString(getUserName(log.call_by), 15)} to ${profileName}`;
        break;
      case 'call_in':
        icon = CallInboundIcon;
        logTitle = `Inbound call from ${profileName}`;
        break;
      default:
        break;
    }

    return (
      <Box pb={2.5} className={classes.callLog} key={log.id}>
        <Grid container>
          <Grid item xs={1}>
            <img alt={formatValue(log.type)} src={icon} className={classes.callIcon} />
          </Grid>
          <Box
            pl={1}
            component={Grid}
            item
            xs={11}
          >
            <Typography
              variant="subtitle1"
              className={classes.label}
              component={Link}
              onClick={() => handleNavigateProfile(log)}
            >
              {logTitle}
            </Typography>
            <Typography variant="body2">
              { previewDateTime(log.call_at) }
            </Typography>
            {
              log.recording_url
              && (apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess)
              && (avanserAccount && !isEmpty(avanserAccount))
              && (
                <Box mt={1}>
                  <AudioPlayer
                    url={log.recording_url}
                    currentPlayingURL={currentPlayingURL}
                    onClick={() => setCurrentPlayingURL(log.recording_url)}
                  />
                </Box>
              )
            }
            <Typography variant="body2" className={classes.caption}>
              {`${renderStatus(log.status)} (${renderAnswerpointName(log.answerpoint)}) ${renderDurations(log.duration)}`}
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  };

  const renderCallLogs = () => {
    if (loaders.initialLoad) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.logsContainer}>
          <CircularProgress />
        </Grid>
      );
    }
    if (!(callLogs && callLogs.length > 0)) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.logsContainer}>
          <Typography variant="body2">
            No call logs found
          </Typography>
        </Grid>
      );
    }
    return callLogs.map((data) => (renderCallLog(data)));
  };

  const renderContent = () => (
    renderListContentBox(
      CallIcon,
      'Calls',
      (
        <div>
          { renderCallLogs() }
        </div>
      ),
    )
  );

  return renderContent();
}

ListCallHistory.propTypes = {
  adsourceID: PropTypes.string.isRequired,
  answerpoints: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

ListCallHistory.defaultProps = {
  answerpoints: [],
};
