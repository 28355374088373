import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useHistory, withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { CssBaseline, Dialog } from '@material-ui/core';
import Routes from '../../Routes';
import {
  AppBar,
  AppNav,
  LoadingOverlay,
  NewsModal,
  Snackbar,
} from '../../components';
import ReauthoriseFacebookModal from '../Settings/components/ReauthoriseFacebookModal';
import { AuthContext, PageContext, SnackbarContext } from '../../contexts';
import GA from '../../google_analytics/initialize';
import { storageAvailable } from '../../utils/storage';
import ReactGAEvent from '../../google_analytics/events';
import { login } from '../../google_analytics/const';
import config from '../../config';
import useStyles from './styles';

const App = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  const { isAuthenticated, user, hasHigherAccess } = authContext.state;
  const {
    appLoading,
    showWhatsNewModal,
    facebookSubscription,
  } = pageContext.state;
  const {
    setWhatsNewModal,
    setIsWindowActive,
  } = pageContext.actions;
  const snackbarContext = useContext(SnackbarContext);
  const { snackbar } = snackbarContext.state;
  const { setCloseSnackbar } = snackbarContext.actions;

  const [activeStates, setActiveStates] = useState({
    facebookReauthorise: false,
  });
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = useCallback(() => {
    setShowMenu((prev) => !prev);
  }, []);
  const toggleModal = () => setWhatsNewModal(!showWhatsNewModal);
  const toggleSnackbar = (e, reason) => {
    if (reason !== 'clickaway') {
      setCloseSnackbar();
    }
  };

  const classes = useStyles();

  useEffect(() => {
    // The only way to see if user just "login" is to see if they landed
    // in CRM from MHub login page
    if (isAuthenticated && user && user.company && user.company.code) {
      const lastPage = document.referrer;
      if (lastPage && lastPage.includes(config.app.secureUrl)) {
        ReactGAEvent({
          name: login,
          company_code: user.company.code,
        });
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    // Prompt fb reauthorisation for user with higher access only
    if (storageAvailable('localStorage') && hasHigherAccess) {
      const promptedAt = localStorage.getItem('facebookReauthorisePromptAt') || null;
      const haveStoredKey = localStorage.getItem('facebookReauthorisePromptAt') !== null;

      if (!_.isEmpty(facebookSubscription)) {
        const {
          data_access_expires_at: dataAccessExpiresAt,
          is_valid: isValid,
        } = facebookSubscription;
        const expiresAt = moment(dataAccessExpiresAt);
        const now = moment();
        const daysLeftToExpire = expiresAt.diff(now, 'days');
        const expired = expiresAt.isBefore(now);
        const expiring7Days = expiresAt.isAfter(now) && daysLeftToExpire <= 7;
        const nextPromptAt = promptedAt ? moment(promptedAt).add(1, 'days').format() : 0;
        const hasPassed24Hours = nextPromptAt && now.isSameOrAfter(nextPromptAt);
        const isLoginForBusiness = expiresAt.isSame('1970-01-01T00:00:00Z');

        let promptReauthorisation = false;

        if (
          (isLoginForBusiness && !isValid && !haveStoredKey)
          || (isLoginForBusiness && !isValid && haveStoredKey && hasPassed24Hours)
        ) {
          promptReauthorisation = true;
        } else if (
          (!isLoginForBusiness && !haveStoredKey && (expiring7Days || expired || !isValid))
          || (!isLoginForBusiness && haveStoredKey && hasPassed24Hours)) {
          promptReauthorisation = true;
        }

        if (
          (haveStoredKey && isValid && !expiring7Days && !expired)
          || (haveStoredKey && isValid && isLoginForBusiness)
        ) {
          localStorage.removeItem('facebookReauthorisePromptAt');
        } else if (promptReauthorisation) {
          localStorage.setItem('facebookReauthorisePromptAt', moment().format());
          setActiveStates((prev) => ({
            ...prev,
            facebookReauthorise: true,
          }));
        }
      }
    }
  }, [
    hasHigherAccess,
    facebookSubscription,
  ]);

  useEffect(() => {
    let hidden;
    let visibilityChange;

    function handleVisibilityChange() {
      if (document[hidden]) {
        setIsWindowActive(false);
      } else {
        setIsWindowActive(true);
      }
    }

    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (!(typeof document.addEventListener === 'undefined' || hidden === undefined)) {
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
  }, [setIsWindowActive]);

  // Hide navigations if is form /sms preview
  const hideNavigations = () => {
    const { pathname } = window.location;
    const arr = pathname.split('/');
    const isFormPreview = arr.length >= 4 && arr[2] === 'form' && arr[4] === 'preview';
    const isSMSPreview = arr.length >= 3 && arr[3] === 'sms' && arr[4] === 'preview';
    const isEmailPreview = arr.length >= 3 && arr[3] === 'email' && arr[4] === 'preview';
    return isFormPreview || isSMSPreview || isEmailPreview;
  };

  const handleClose = async () => {
    setActiveStates((prev) => ({ ...prev, facebookReauthorise: false }));
  };

  const handleRedirectCampaignSettings = async () => {
    setActiveStates((prev) => ({ ...prev, facebookReauthorise: false }));
    history.push('/settings/facebook');
  };

  return (
    <>
      <GA.RouteTracker />
      <CssBaseline />
      { !hideNavigations() && <AppBar toggleMenu={toggleMenu} /> }
      <LoadingOverlay isLoading={appLoading} />
      <div className={`${classes.content} ${hideNavigations() ? 'noNav' : ''}`}>
        {
          !hideNavigations() && (
            <AppNav
              showMenu={showMenu}
              toggleMenu={toggleMenu}
            />
          )
        }
        <Dialog open={showWhatsNewModal} onClose={toggleModal}>
          <NewsModal onClose={toggleModal} />
        </Dialog>
        <Routes />
      </div>
      <ReauthoriseFacebookModal
        open={activeStates.facebookReauthorise}
        onClick={handleRedirectCampaignSettings}
        onClose={handleClose}
      />
      <Snackbar
        variant={snackbar.variant}
        open={snackbar.open}
        key={snackbar.message}
        message={snackbar.message}
        onClose={toggleSnackbar}
      />
    </>
  );
};

export default withRouter(App);
