import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ApolloProvider } from '@apollo/react-hooks';

import ClientContext from './context';

import { getSelectorValue } from '../../utils';

const Consumer = ({ name, selector, children }) => {
  const { clients } = useContext(ClientContext);
  const client = getSelectorValue(clients, name || selector);
  if (!client) {
    console.warn(`${name || selector} not found in context provided: ${clients}`);
  }
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

Consumer.propTypes = {
  name: PropTypes.string,
  selector: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
};

Consumer.defaultProps = {
  name: null,
  selector: null,
  children: null,
};

export default Consumer;
