import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '30rem',
    maxWidth: '30rem',
    padding: '1.5rem 2rem',
    '&.wider': {
      width: '33rem',
      maxWidth: '33rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      '&.wider': {
        width: 'unset',
      },
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  heading: {
    marginBottom: '1rem',
    '& > h4': {
      maxWidth: '90%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '80%',
      },
    },
  },
}));
