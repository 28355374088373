import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { fonts } from '../../../contexts';
import { filters } from '../../../contexts/Theme';

export const useStyles = makeStyles((theme) => ({
  disabledText: { color: theme.palette.font.subBody },
  selected: {
    color: theme.palette.font.header,
  },
  root: {
    '&:not(.Mui-disabled)': {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    maxHeight: '10rem',
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subHeader: {
    ...fonts.overline,
    color: theme.palette.font.subBody,
    backgroundColor: theme.palette.common.white,
    lineHeight: '1.5',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  infoButton: {
    marginLeft: '0.5rem',
    '& img': {
      width: '1rem',
      filter: filters.tertiary.lighterGrey,
    },
  },
}));

export const CustomMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
  },
  selected: {},
}))(MenuItem);
