import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from '@material-ui/icons';

import { Button } from '@material-ui/core';
import useStyles from './styles';

export default function AddButton(props) {
  const {
    className,
    disabled,
    label,
    onClick,
    ...buttonProps
  } = props;

  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      startIcon={<Icons.AddCircle />}
      className={`${className} ${classes.button}`}
      disabled={disabled}
      disableRipple
      {...buttonProps}
    >
      {label}
    </Button>
  );
}

AddButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

AddButton.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  onClick: () => {},
};
