import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';
import * as NoteFragments from '../Note/fragments';

// CREATE create customer GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createCustomer',
    args: [
      { name: 'type', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'contact_ids', type: '[String]' },
    ],
    argsMap: [
      { name: 'type', value: 'type' },
      { name: 'name', value: 'name' },
      { name: 'contact_ids', value: 'contact_ids' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update customer GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateCustomer',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'type', type: 'String' },
      { name: 'name', type: 'String' },
      { name: 'contact_ids', type: '[String]' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'type', value: 'type' },
      { name: 'name', value: 'name' },
      { name: 'contact_ids', value: 'contact_ids' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE delete customer GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteCustomer',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPLOAD upload customer GraphQL schema.
export const UPLOAD = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'uploadCustomers',
    args: [
      { name: 'filename', type: 'String', required: true },
      { name: 'import_id', type: 'String' },
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'filename', value: 'filename' },
      { name: 'import_id', value: 'import_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...UploadCustomersResponseFields}',
    fragments: [Fragments.uploadCustomersResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LINK_CONTACTS link contacts from customer GraphQL schema.
export const LINK_CONTACTS = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'linkContacts',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'contact_ids', type: '[String]', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'contact_ids', value: 'contact_ids' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UNLINK_CONTACTS unlink contacts from customer GraphQL schema.
export const UNLINK_CONTACTS = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'unlinkContacts',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'contact_ids', type: '[String]', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'contact_ids', value: 'contact_ids' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE_NOTE create note GraphQL schema.
export const CREATE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createNote',
    args: [
      { name: 'customer_id', type: 'String', required: true },
      { name: 'message', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'customer_id', value: 'customer_id' },
      { name: 'message', value: 'message' },
    ],
    fields: '{...NoteFields, customer_id}',
    fragments: [NoteFragments.note],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_NOTE update note GraphQL schema.
export const UPDATE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateNote',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'message', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'message', value: 'message' },
    ],
    fields: '{...NoteFields, customer_id}',
    fragments: [NoteFragments.customerNote],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_NOTE delete note GraphQL schema.
export const DELETE_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteNote',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...NoteFields, customer_id}',
    fragments: [NoteFragments.customerNote],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
