import React, {
  Children,
  cloneElement,
  useCallback,
  useState,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import { MenuList, MenuItem, Typography } from '@material-ui/core';
import { Tooltip } from '..';
import useStyles from './styles';

const Menu = forwardRef((props, ref) => {
  const {
    children,
    className,
    customStyle,
    emptyNode,
    data,
    itemNode,
    value: propsValue,
    defaultValue,
    onChange,
    disabled,
    activeCondition,
    ...rest
  } = props;

  const [value, setValue] = useState(propsValue || defaultValue);
  const classes = useStyles();
  const handleOnChange = useCallback((e, { value: v }) => {
    setValue(v);
    onChange(e, { value: v });
  }, [onChange]);

  const renderContent = useCallback(() => {
    if (children) {
      return Children.map(children, (child, idx) => {
        if (Menu.isMenuItem(child)) {
          // only allow MenuItem
          const childProps = {
            selected: child.props.active || activeCondition(child.props, value, idx, children),
            disabled: child.props.disabled || child.props.isHeader || disabled,
            onClick: child.props.value
              ? (e) => handleOnChange(e, { value: child.props.value }) : child.props.onClick,
            classes: { ...child.props.classes },
            className: `${child.props.isHeader ? classes.header : ''}`,
          };
          return cloneElement(child, childProps);
        }
        return child;
      });
    }
    if (Array.isArray(data) && data.length > 0) {
      return data.map(({
        value: itemValue,
        isHeader,
        hide,
        title,
        ...itemRest
      }, idx, arr) => {
        if (hide) {
          return null;
        }
        if (isHeader) {
          return <Typography key={idx} variant="h6" className={classes.header}>{itemRest.children}</Typography>;
        }
        return (
          <Tooltip key={idx} title={title} position="bottom">
            <div>
              {
                itemNode({
                  ...itemRest,
                  selected: itemRest.active
                    || activeCondition({ value: itemValue, ...itemRest }, value, idx, arr),
                  disabled: itemRest.disabled || disabled,
                  onClick: itemValue ? (e) => handleOnChange(e, { value: itemValue }) : itemRest.onClick,
                  classes: { ...itemRest.classes },
                }, idx, arr)
              }
            </div>
          </Tooltip>
        );
      });
    }
    if (emptyNode) {
      return emptyNode;
    }
    return null;
  }, [
    classes,
    children,
    emptyNode,
    data,
    disabled,
    handleOnChange,
    itemNode,
    value,
    activeCondition,
  ]);

  return (
    <MenuList
      disablePadding
      ref={ref}
      className={`${className} ${!customStyle ? classes.list : ''}`}
      {...rest}
    >
      {renderContent()}
    </MenuList>
  );
});

Menu.isMenuItem = (child) => typeof child.type === 'function' && child.type.name === MenuItem.name;

Menu.List = MenuList;
Menu.Item = MenuItem;

Menu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  customStyle: PropTypes.bool,
  emptyNode: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.node,
    ...MenuItem.propTypes,
  })),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  activeCondition: PropTypes.func,
  itemNode: PropTypes.func,
};

Menu.defaultProps = {
  children: null,
  className: '',
  customStyle: false,
  emptyNode: null,
  data: [],
  disabled: false,
  value: '',
  defaultValue: '',
  onChange: () => {},
  activeCondition: () => false,
  itemNode: (item, idx) => <MenuItem {...item} key={idx} />,
};

export default Menu;
