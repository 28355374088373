export { default as annoucement } from './annoucement.jpg';
export { default as avanserLogo } from './avanser_logo.png';
export { default as avanserLogoWithoutText } from './avanser_logo_without_text.png';
export { default as convertQualifyLead } from './qualified_lead_banner.svg';
export { default as dashboardAnnouncement } from './dashboardAnnouncement.jpg';
export { default as dashboardIllustration } from './dashboardIllustration.svg';
export { default as emptyState } from './emptyState.svg';
export { default as facebookReauthorise } from './facebook_reauthorise.jpg';
export { default as facebookFormImage } from './facebook_form_image_placeholder.jpg';
export { default as mobileTemplate } from './mobile_template.png';
export { default as reserveProject } from './showroom_reserve_project.svg';
export { default as schedule } from './schedule.jpg';
