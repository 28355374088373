import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import qs from 'query-string';
import _ from 'lodash';
import IconRoundButton from '../IconRoundButton';
import useStyles from './styles';

export default function RightNav(props) {
  const { items } = props;
  const history = useHistory();
  const location = useLocation();
  const { activeTab } = qs.parse(location.search);

  const classes = useStyles();

  const [expand, setExpand] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (items && activeTab) {
      const activeTabIndex = _.findIndex(items, (d) => d.name === activeTab);
      const newIndex = activeTabIndex > -1 ? activeTabIndex : 0;
      if (selectedIndex !== newIndex) {
        setSelectedIndex(newIndex);
      }
    }
  }, [
    items,
    activeTab,
    selectedIndex,
  ]);

  const toggleSelected = (idx, item) => {
    if (selectedIndex !== idx) {
      history.push(`${location.pathname}?activeTab=${item.name}`);
      return;
    }
    setExpand((prev) => !prev);
  };

  const renderSecondaryContent = () => {
    if (!_.isEmpty(items[selectedIndex]) && items[selectedIndex].node) {
      return items[selectedIndex].node;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <div className={`${classes.secondaryContainer} ${expand ? 'expand' : ''}`}>
        { renderSecondaryContent() }
      </div>
      <div className={classes.primaryContainer}>
        {
          items.map((item, idx) => (
            <IconRoundButton
              key={item.name}
              tooltipLabel={item.tooltipLabel}
              activeType={selectedIndex === idx ? 'active' : 'inactive'}
              imgIconSrc={item.icon}
              onClick={() => toggleSelected(idx, item)}
            />
          ))
        }
      </div>

    </div>
  );
}

RightNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

RightNav.defaultProps = {
  items: [],
};
