import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';

export default makeStyles((theme) => ({
  topMessage: {
    color: theme.palette.font.header,
  },
  section: {
    marginTop: '1.5rem',
  },
  info: {
    marginTop: '0.5rem',
  },
  tableContainer: {
    overflow: 'auto',
    flex: 1,
  },
  table: {
    marginTop: '1rem',
    '& th:last-child': {
      paddingRight: '1rem',
    },
  },
  th: {
    ...fonts.subtitle2,
    color: theme.palette.font.header,
  },
  tbody: {
    '& td': {
      border: 0,
      paddingTop: '0.5rem',
      '&:first-child': {
        paddingRight: '0',
      },
    },
    '& tr:last-child td': {
      paddingBottom: '0.5rem',
    },
    '& tr > td:last-child': {
      paddingRight: '1rem',
    },
  },
  value: {
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
    '&$projectCell:empty::after': {
      fontSize: fonts.body2.fontSize,
    },
  },
  totalSelected: {
    marginLeft: '1rem',
  },
  name: {
    maxWidth: '25ch',
  },
  icon: {
    height: '20px',
    filter: 'invert(42%)',
    marginRight: '0.5rem',
  },
  projectCell: {
    display: 'flex',
    alignItems: 'center',
  },
  projectWrapper: {
    display: 'inline-block',
  },
  project: {
    maxWidth: '150px',
    borderRadius: '4px',
    background: theme.palette.tertiary.dimGreen,
    padding: '4px 8px 4px 8px',
    marginRight: '8px',
    fontWeight: fonts.semiBold,
    '&.subCounts': {
      background: theme.palette.tertiary.mediumGrey,
    },
  },
  assignee: {
    maxWidth: '150px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  email: {
    maxWidth: '15ch',
  },
  ellipsis: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expand: {
    padding: 0,
    marginRight: '0.3rem',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  emptyResult: {
    marginTop: '0.5rem',
  },
  checkbox: {
    padding: 0,
  },
  checkboxCell: {
    width: '25px',
  },

  // MergePage
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  radioRoot: {
    padding: 0,
    marginRight: '0.5rem',
  },
  createUpdateInfo: {
    paddingLeft: '25px',
  },
  cellLabel: {
    minWidth: '150px',
    maxWidth: '150px',
    '& h6': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
    },
  },
  cell: {
    minWidth: '185px',
    maxWidth: '185px',
    '& h6, p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
    },
  },
}));
