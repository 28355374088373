import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as MailBigIcon } from '../../../../assets/icons/mail_big.svg';
import { ReactComponent as WhatsAppIcon } from '../../../../assets/icons/whatsapp.svg';
import useStyles from './styles';

export default function ShareOptions(props) {
  const {
    contactInfo, fileURL, handleClose, index,
  } = props;
  const classes = useStyles();

  const [copySuccess, setCopySuccess] = useState('');

  const handleOnCancel = () => {
    handleClose(index, false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fileURL)
      .then(() => {
        setCopySuccess('Copied!');
      })
      .catch(() => {
        setCopySuccess('Failed!');
      });
  };
  useEffect(() => {
    if (copySuccess !== '') {
      setTimeout(() => setCopySuccess(''), 2000);
    }
  }, [copySuccess]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleShareAction = (action) => {
    let url;
    const encodedName = encodeURIComponent(fileURL);
    switch (action.toLowerCase()) {
      case 'whatsapp':
        if (isDesktop) {
          url = `https://web.whatsapp.com/send?phone=${contactInfo.mobile}&text=${encodedName}`;
        } else {
          url = `whatsapp://send?phone=${contactInfo.mobile}&text=${encodedName}`;
        }
        window.open(url, '_blank');
        break;
      case 'mail':
        window.location = `mailto:${contactInfo.email}?body=${encodedName}`;
        break;
      default: break;
    }
  };

  return (
    <Fade in timeout={600}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        classes={{
          container: classes.shareContainer,
        }}
      >
        <Grid item xs={11}>
          <Box spacing={1} display="flex" alignItems="center" justifyContent="flex-start">
            <Typography variant="subtitle1" className={`${classes.sideMargin}`}>
              Share via
            </Typography>
            <IconButton
              className={classes.actionIcon}
              onClick={() => handleShareAction('mail')}
            >
              <MailBigIcon />
            </IconButton>
            <IconButton
              className={`${classes.actionIcon} ${classes.marginLeft}`}
              onClick={() => handleShareAction('whatsapp')}
            >
              <WhatsAppIcon />
            </IconButton>
            <IconButton
              className={`${classes.actionIcon} ${classes.marginLeft}`}
              onClick={copyToClipboard}
            >
              <LinkIcon />
            </IconButton>
            <span className={copySuccess && classes.copyStatus}>
              {copySuccess}
            </span>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              classes={{
                label: classes.closeIcon,
              }}
              onClick={handleOnCancel}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
}

ShareOptions.propTypes = {
  contactInfo: PropTypes.instanceOf(Object),
  fileURL: PropTypes.string,
  handleClose: PropTypes.func,
  index: PropTypes.number,
};

ShareOptions.defaultProps = {
  contactInfo: {
    email: '',
    mobile: '',
  },
  fileURL: '',
  handleClose: () => {},
  index: 0,
};
