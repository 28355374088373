function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgFileJpg = function SvgFileJpg(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Capa_1",
    "data-name": "Capa 1",
    width: 32,
    height: 32,
    viewBox: "0 0 40 40",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("g", null, React.createElement("g", null, React.createElement("path", {
    d: "M25.627.067H7.775a1.6,1.6,0,0,0-1.6,1.6V38.338a1.6,1.6,0,0,0,1.6,1.595h24.45a1.6,1.6,0,0,0,1.595-1.595V8.26Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("polygon", {
    points: "25.627 8.26 33.82 8.26 25.627 0.067 25.627 8.26",
    style: {
      fill: "#dbddde"
    }
  })), React.createElement("g", null, React.createElement("path", {
    d: "M34.647,29.848H5.353A1.063,1.063,0,0,1,4.29,28.785V19.426a1.063,1.063,0,0,1,1.063-1.063H34.647a1.063,1.063,0,0,1,1.063,1.063v9.359A1.063,1.063,0,0,1,34.647,29.848Z",
    style: {
      fill: "#49b3b1"
    }
  }), React.createElement("g", null, React.createElement("path", {
    d: "M12.7,26.8a1.942,1.942,0,0,1-.748-.6l.6-.72a1.362,1.362,0,0,0,1.124.648q.882,0,.882-1.043V22.157H12.544v-.882h3.064v3.753a2.06,2.06,0,0,1-.484,1.492,1.906,1.906,0,0,1-1.424.5A2.309,2.309,0,0,1,12.7,26.8Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("path", {
    d: "M20.772,21.518a1.858,1.858,0,0,1,.837.7,2.126,2.126,0,0,1,0,2.147,1.849,1.849,0,0,1-.837.7,3.146,3.146,0,0,1-1.282.242H18.213v1.634H17.161V21.275H19.49A3.13,3.13,0,0,1,20.772,21.518Zm-.287,2.6a1.153,1.153,0,0,0,0-1.666,1.609,1.609,0,0,0-1.043-.291H18.213v2.248h1.229A1.615,1.615,0,0,0,20.485,24.122Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("path", {
    d: "M26.979,24.041h1V26.3a3.15,3.15,0,0,1-1.019.534,3.908,3.908,0,0,1-1.2.186,3.24,3.24,0,0,1-1.561-.376A2.8,2.8,0,0,1,23.105,25.6a3.024,3.024,0,0,1,0-2.992,2.8,2.8,0,0,1,1.1-1.04,3.279,3.279,0,0,1,1.573-.376,3.439,3.439,0,0,1,1.294.234,2.566,2.566,0,0,1,.979.688l-.663.647a2.13,2.13,0,0,0-1.561-.647,2.2,2.2,0,0,0-1.063.251,1.823,1.823,0,0,0-.729.7,2.024,2.024,0,0,0-.262,1.035,2,2,0,0,0,.262,1.019,1.9,1.9,0,0,0,.729.712,2.132,2.132,0,0,0,1.054.258,2.277,2.277,0,0,0,1.165-.291Z",
    style: {
      fill: "#f5f6f7"
    }
  })))));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgFileJpg, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/file_jpg.91b0d679.svg";
export { ForwardRef as ReactComponent };