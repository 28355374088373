function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgReload = function SvgReload(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Assets",
    width: 32,
    height: 32,
    viewBox: "0 0 32 32",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("path", {
    d: "M24.354,7.639A11.841,11.841,0,1,0,27.432,18.96H24.354A8.875,8.875,0,1,1,15.993,7.121a8.756,8.756,0,0,1,6.245,2.634L17.472,14.52h10.36V4.161Z",
    style: {
      fill: "#596975"
    }
  }));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgReload, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/reload.7753965a.svg";
export { ForwardRef as ReactComponent };