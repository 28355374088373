import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  annoucement,
  schedule,
} from '../../assets/images';
import { DatePicker, TimePicker } from '..';
import { useEnhancedMutation } from '../../hooks';
import { CAMPAIGN } from '../../graphql';
import { clientCampaign } from '../..';
import { stripTypename } from '../../utils';
import {
  SnackbarContext,
  AuthContext,
} from '../../contexts';
import useStyles from './styles';

const BlastScheduleModal = (props) => {
  const {
    sms,
    email,
    open,
    refetchChannel,
    refetchSubscription,
    onClose,
    isEmail,
  } = props;

  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const snackbarContext = useContext(SnackbarContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext.state;
  const { setOpenSnackbar, setCloseSnackbar } = snackbarContext.actions;

  const [page, setPage] = useState(1);
  const [scheduleAt, setScheduleAt] = useState({
    date: null,
    time: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [scheduleBlast] = useEnhancedMutation(CAMPAIGN.UPDATE_CHANNEL(),
    { client: clientCampaign });

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
      setCloseSnackbar();
      setPage(1);
      setScheduleAt({
        date: null,
        time: null,
      });
    }
  }, [open, setCloseSnackbar]);

  useEffect(() => {
    if (scheduleAt.date && scheduleAt.time) {
      const formatTime = moment(scheduleAt.time).format('HH:mm');
      const combineDateTime = moment(`${scheduleAt.date} ${formatTime}`).format('YYYY-MM-DD HH:mm');
      const currentTime = moment().format('YYYY-MM-DD HH:mm');
      if (moment(currentTime).isSame(combineDateTime)
        || moment(currentTime).isAfter(combineDateTime)
      ) {
        setError('Schedule time must be greater than current time');
        return;
      }
    }
    setError('');
  }, [scheduleAt]);

  const handleChangeDate = (v) => {
    setScheduleAt((prev) => ({
      ...prev,
      date: v,
    }));
  };

  const handleChangeTime = (v) => {
    setScheduleAt((prev) => ({
      ...prev,
      time: v,
    }));
  };

  const handleSchedule = () => {
    const blastData = isEmail ? email : sms;
    if (scheduleAt.date && scheduleAt.time) {
      setIsLoading(true);
      const formatTime = moment(scheduleAt.time).format('HH:mm');
      let combineDateTime = moment(`${scheduleAt.date} ${formatTime}`);
      combineDateTime = moment(combineDateTime).format();
      scheduleBlast({
        variables: {
          ...stripTypename(blastData),
          scheduled_at: combineDateTime,
          paused_at: null,
          company_id: user.company.id,
        },
      }).then((result) => {
        setTimeout(() => {
          if (result && result.data) {
            setPage(2);
            refetchChannel();
            refetchSubscription();
          }
          setIsLoading(false);
        }, 2000);
      }).catch(() => {
        setOpenSnackbar({
          variant: 'error',
          message: 'An error has occured. Please try again.',
        });
        setIsLoading(false);
      });
    }
  };

  const handleRedirectCampaign = () => {
    if (match.params.id) {
      const { id } = match.params;
      history.push(`/campaigns/${id}`);
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const getImageSrc = () => {
    if (page === 1) {
      return schedule;
    }
    return annoucement;
  };

  const renderSchedulePage = () => (
    <>
      <Typography variant="h4">
        Schedule&nbsp;
        { isEmail ? 'email' : 'sms' }
        &nbsp;blast
      </Typography>
      <Typography variant="body2">
        When do you want to send it? No changes are allowed within 5 minutes of the scheduled time.
      </Typography>
      <section>
        <DatePicker
          simple
          required
          disableClearable
          disablePast
          disabled={isLoading}
          label="Delivery date"
          value={scheduleAt.date || null}
          onChange={handleChangeDate}
        />
        <TimePicker
          fullWidth
          required
          disableClearable
          disabled={isLoading}
          label="Delivery time"
          error={error}
          value={scheduleAt.time || null}
          onChange={handleChangeTime}
        />
        <Typography variant="caption">
          Malaysia GMT/UTC +8
        </Typography>
      </section>
      <Grid container justify={isMobile ? 'center' : 'flex-end'}>
        <Button
          variant="contained"
          onClick={handleSchedule}
          disabled={!(scheduleAt.date && scheduleAt.time) || isLoading || !!error}
        >
          Schedule
        </Button>
      </Grid>
    </>
  );

  const renderCompletePage = () => (
    <>
      <Typography variant="h4">Woohoo!</Typography>
      <Typography variant="body2">
        You have successfully scheduled this blast.
        No changes are allowed within 5 minutes of the scheduled time.
      </Typography>
      <Grid container justify={isMobile ? 'center' : 'flex-end'}>
        <Button
          variant="contained"
          onClick={handleRedirectCampaign}
        >
          View campaign
        </Button>
      </Grid>
    </>
  );

  const renderContent = () => {
    if (page === 1) {
      return renderSchedulePage();
    }
    return renderCompletePage();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.container}>
        <IconButton className={classes.iconClose} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <img alt="" src={getImageSrc()} />
        <section>
          { renderContent() }
        </section>
      </div>
    </Dialog>
  );
};

BlastScheduleModal.propTypes = {
  sms: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  refetchChannel: PropTypes.func,
  refetchSubscription: PropTypes.func,
  onClose: PropTypes.func,
  isEmail: PropTypes.bool,
  email: PropTypes.instanceOf(Object),
};

BlastScheduleModal.defaultProps = {
  sms: {},
  open: false,
  refetchChannel: () => {},
  refetchSubscription: () => {},
  onClose: () => {},
  isEmail: false,
  email: {},
};

export default BlastScheduleModal;
