import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import { LockIcon } from '../../assets/icons';
import useStyles from './styles';

const Forbidden = () => {
  const history = useHistory();

  const classes = useStyles();

  const handleClickHomepage = () => {
    history.push('/');
  };

  document.title = 'Forbidden';

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.forbidden}
    >
      <Icon>
        <img alt="lock" src={LockIcon} />
      </Icon>
      <Typography variant="h1">Request Access</Typography>
      <Typography variant="body1" className={classes.bodyText}>
          You do not have permission to access this page,
        <br />
          please refer to your system administrator
      </Typography>
      <Grid
        container
        className={classes.buttonGroup}
        justify="center"
      >
        <Button variant="contained" onClick={handleClickHomepage}>Go to homepage</Button>
      </Grid>
    </Grid>
  );
};

export default Forbidden;
