// Omit not user friendly activity logs
export const omittedLogItems = [
  'aging_days',
  'assigned_at',
  'assigned_by',
  'campaign_name',
  'channel_name',
  'customer_id',
  'import_id',
  'imported_at',
  'merged_at',
  'merged_ids',
  'merged_by',
  'mobile_country_code',
  'manual_reassignment_id',
  'project_id',
  'submission_count',
  'submission_id',
  'send_reminder_count',
  'user_group_id',
  'user_group_assignment_name',
  'form_id',
  'form_name',
  'deleted_at',
  'deleted_by',
  'rescheduled',
  'note_id',
];

export const omittedLogItemsWithoutMerged = omittedLogItems.filter((name) => name !== 'merged_at');

export const omitQualifiedItems = (newValues, keys) => {
  const qualifiedItems = ['qualified_at', 'qualified_by'];
  if (newValues && newValues.stage && newValues.stage === 'qualified') {
    // If new value stage is qualified, return newValues without qualified info
    return keys.filter((key) => !qualifiedItems.includes(key));
  }
  return keys;
};
