import { createContext } from 'react';

export const initialState = ({
  snackbar: {
    open: false,
    variant: 'error',
    message: 'Error occured',
  },
});

export default createContext({
  state: initialState,
  actions: {
    setOpenSnackbar: () => {},
    setCloseSnackbar: () => {},
  },
});
