import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  container: {
    '& > div': {
      margin: '1rem 0 0.5rem 0',
    },
    '& > span': {
      color: theme.palette.font.subBody,
    },
  },
  icon: {
    width: '12px',
    filter: filters.tertiary.lighterGrey,
  },
}));
