export const callOutcomes = [
  { label: 'Connected', value: 'answered' },
  { label: 'Line busy', value: 'busy' },
  { label: 'No answer', value: 'unanswered' },
  { label: 'Wrong number', value: 'wrong_phone' },
];

export const pendingUserAction = {
  name: 'user_action_pending',
  label: 'Pending user action',
};

export const pendingSystem = {
  name: 'system_pending',
  label: 'System pending',
};
