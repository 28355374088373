import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    padding: '0.5rem 2rem',
  },
  content: {
    margin: '2rem 0 1rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
  infoSection: {
    minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '365px',
    },
    '& > h6': {
      marginBottom: '1rem',
    },
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
  },
  tabsRoot: {
    minHeight: '35px',
  },
  tabRoot: {
    minWidth: '100px',
    minHeight: '35px',
    textTransform: 'capitalize',
    '& h6': {
      color: theme.palette.font.subBody,
    },
    '&.Mui-selected': {
      borderRadius: '4px',
      background: theme.palette.tertiary.mediumGrey,
      '& h6': {
        color: theme.palette.font.header,
      },
    },
  },
  tabIndicator: {
    display: 'none',
  },
  button: {
    '& + $button': {
      marginLeft: '0.57rem',
    },
    '& img': {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '40px',
      padding: '0 0.5rem',
    },
  },
  moreButton: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 1rem',
    },
  },
  moreIcon: {
    marginRight: '0.3rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  editButton: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 1rem',
      width: '85px',
    },
    '& .MuiButton-startIcon': {
      marginRight: '0.5rem',
    },
  },
  header: {
    padding: '0.5rem 0 1rem 0',
    borderBottom: `1px solid ${theme.palette.tertiary.darkGrey}`,
    '& h1': {
      marginBottom: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
    },
  },
  nameWrapper: {
    '& > h1': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '50%',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '70%',
      },
    },
    '& > div': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '50%',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '30%',
      },
    },
  },
  grid: {
    marginTop: '1rem',
    '& > div': {
      display: 'flex',
      margin: '4px 0',
    },
    [theme.breakpoints.up('sm')]: {
      '& > div:nth-child(odd)': {
        paddingRight: '1rem',
      },
    },
  },
  label: {
    flexShrink: 0,
    width: '22ch',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      width: '18ch',
    },
  },
  value: {
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.red': {
      color: theme.palette.error.main,
    },
    '&:empty::after': {
      content: '"n/a"',
      color: theme.palette.tertiary.grey,
    },
  },
  duplicateWarn: {
    background: theme.palette.tertiary.mustard,
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    padding: '1rem',
    borderRadius: '4px',
    marginTop: '1.5rem',
    '& > h6': {
      padding: '0 0.5rem',
    },
  },
  subDuplicateWarn: {
    background: '#FEFBED',
    padding: '1rem',
  },
  warningIcon: {
    color: theme.palette.tertiary.alertYellow,
  },
  leftWrapper: {
    paddingRight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
    '& > section:not(:first-child)': {
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
  },
  rightWrapper: {
    '& > $listContentBox:not(:first-child)': {
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
  },
  listContentBox: {
    '& .icon': {
      filter: 'invert(0.4)',
      width: '1.5rem',
      height: '1.5rem',
    },
    '& .icon.noteIcon': {
      marginRight: '0.5rem',
    },
    '& .addButton': {
      padding: 0,
    },
  },
  contents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '14rem',
    margin: '1rem 0',
    boxShadow: `0px 2px 6px ${theme.palette.tertiary.grey}`,
    '&.empty': {
      backgroundColor: theme.palette.tertiary.lightGrey,
    },
    '& > div:first-child': {
      flexGrow: 1,
      justifyContent: 'center',
      padding: '1rem',
    },
    '& .viewMore': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
      padding: '0.5rem 1rem',
      cursor: 'pointer',
      '& > span': {
        color: theme.palette.font.header,
      },
      '&:hover': {
        backgroundColor: theme.palette.tertiary.darkGrey,
      },
    },
    '& .MuiTypography-body2:not(.MuiLink-root)': {
      color: theme.palette.font.body,
    },
  },
  listContent: {
    display: 'block',
    '& > div + div': {
      marginTop: '1rem',
    },
    '& .ellipsis': {
      maxWidth: '80%',
      '& p, h6': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  fieldWrapper: {
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem',
    '& > div:first-child': {
      flex: 1,
      overflow: 'hidden',
    },
    '&.active': {
      padding: '0',
    },
    '& > p': {
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& img.edit': {
      display: 'none',
      filter: 'invert(42%)',
    },
    '&:hover:not(.active):not(.uneditable)': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
      '& img': {
        display: 'block',
      },
    },
  },
  calendarIcon: {
    opacity: '0.5',
  },

  statusIcon: {
    display: 'flex',
    cursor: 'pointer',
  },
  alert: {
    filter: filters.tertiary.peach,
  },
  verified: {
    filter: filters.tertiary.darkGreen,
  },

  buttonWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      '& > .editIcon': {
        display: 'none',
      },
    },
  },
}));
