import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SnackbarContext } from '../contexts';

import { clientContextWarn } from '../utils';

const useEnhancedMutation = (query, options = {}) => {
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const handleOnError = (err) => {
    let msg = err.message || 'An error occurred. Please try again.';
    if (err.message.startsWith('GraphQL error:')) {
      const formatted = msg.replace('GraphQL error:', '');
      msg = formatted.charAt(1).toUpperCase() + formatted.slice(2);
    }
    if (options.customErrorMessage) {
      msg = options.customErrorMessage(err, msg);
    }
    if (!options.disableDefaultError) {
      setOpenSnackbar({
        variant: 'error',
        message: msg,
      });
    }
    clientContextWarn(err);
    if (options && options.onError) {
      options.onError(err);
    }
  };

  const result = useMutation(query, {
    ...options,
    onError: handleOnError,
  });

  return result;
};

export default useEnhancedMutation;
