import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';

export default makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  helperText: {
    marginTop: theme.spacing(1),
  },
  iconClose: { padding: 0 },
  root: {
    width: 'auto',
    minWidth: '32.14rem',
    padding: '1.8rem 2.1rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  listWrapper: {
    marginTop: theme.spacing(2),
  },
  loading: {
    padding: theme.spacing(3),
  },
  modalTopBar: { marginBottom: theme.spacing(2) },
  modalTitle: { ...fonts.modalTitleFont },
  searchField: {
    paddingRight: '0.71em',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  info: {
    marginTop: '0.5rem',
    maxWidth: '28.5rem',
    backgroundColor: theme.palette.tertiary.lightBlue,
    padding: '1rem',
    marginBottom: '1rem',
    '& > .MuiIcon-root': {
      marginRight: '0.5rem',
      color: theme.palette.secondary.main,
    },
  },
}));
