import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  shareContainer: {
    height: '70px',
    backgroundColor: theme.palette.tertiary.mediumGrey,
    padding: '0.5rem',
  },
  actionIcon: {
    border: `1px solid ${theme.palette.tertiary.grey}`,
    backgroundColor: theme.palette.tertiary.mediumGrey,
    '& > span > svg': {
      width: '1em',
      height: '1em',
      '& > path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& > span > svg': {
        '& > path': {
          fill: 'white',
        },
      },
    },
  },
  copyStatus: {
    margin: '1rem',
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    padding: '4px 8px',
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.tertiary.cyprus,
    fontSize: '0.75rem',
    color: theme.palette.common.white,
  },
  closeIcon: {
    '& > svg': {
      width: '24px',
      height: '24px',
      '& > path': {
        fill: theme.palette.font.body,
      },
    },
  },
  sideMargin: {
    marginLeft: '1.5rem',
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
}));
