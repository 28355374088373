import { makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from '../../contexts';

export default makeStyles((theme) => ({
  date: {
    '& > div': {
      backgroundColor: theme.palette.tertiary.lightGrey,
      fontFamily: fonts.bodyFont,
      fontWeight: fonts.semiBold,
    },
    margin: '0.5rem 0',
  },
  events: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px',
    },
  },
  event: {
    padding: '0.5rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1rem',
    },
  },
  summary: {
    display: 'flex',
    alignItems: 'baseline',
    '& > h6': {
      display: 'inline',
      whiteSpace: 'nowrap',
    },
    '& > #subject::after': {
      content: '"\\00a0"',
    },
    [theme.breakpoints.up('sm')]: {
      '& > #action::after': {
        content: '" –\\00a0"',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  changes: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      wordBreak: 'break-word',
      '& > span:first-child::first-letter': {
        textTransform: 'uppercase',
      },
    },
    '& > p': {
      display: 'inline',
    },
  },
  content: {
    '& > div': {
      margin: '0.5rem 0',
      padding: '1rem',
      background: '#f5f5f5',
      border: '0',
      '& > p': {
        whiteSpace: 'pre-line',
      },
    },
  },
  info: {
    '& > span': {
      color: colors.font.subBody,
    },
    '& > *': {
      marginRight: '0.5rem',
      '&:not(:first-child)::before': {
        content: '"\\2022"',
        color: '#596975',
        paddingRight: '0.5rem',
      },
    },
  },
  fieldName: {
    '&::after': {
      content: '"\\00a0"',
    },
  },
  list: {
    paddingLeft: '1.5rem',
  },
  log: {
    padding: '1.5rem 0',
    borderBottom: '1px solid #ddd',
  },
}));
