import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import {
  IconButton,
  Typography,
  Icon,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import format from 'date-fns/format';
import moment from 'moment';
import { CalendarIcon } from '../../assets/icons';
import useStyles from './styles';

export default function DatePicker(props) {
  const {
    value,
    name, label,
    simple, disableClearable,
    required, error,
    onChange, onClear,
    ...rest
  } = props;

  const classes = useStyles();
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setAnchorEl(containerRef.current);
    }
  }, []);

  const handleOnChangeDate = (v) => {
    const formatted = moment(v).format('YYYY-MM-DD');
    if (simple) {
      onChange(formatted);
      return;
    }
    if (
      !value.start
      || (value.start && value.end)
      || (value.start && (moment(formatted).isBefore(moment(value.start))))
    ) {
      onChange({ start: formatted, end: null });
      return;
    }
    onChange({ start: value.start, end: formatted });
  };

  const handleOnClear = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClear(e);
  };

  const renderLabel = () => {
    if (simple && value) {
      return moment(value).format('DD MMM YYYY');
    }
    if (!simple && value.start && value.end) {
      return `${moment(value.start).format('DD MMM YYYY')} - ${moment(value.end).format('DD MMM YYYY')}`;
    }
    return 'Select';
  };

  const renderSelectedDay = (day, selectedDay, dayInCurrentMonth, dayComponent) => {
    if (simple) {
      return dayComponent;
    }

    const dateClone = day.toDate();
    const date = moment(dateClone).format('YYYY-MM-DD');
    const start = moment(value.start).format('YYYY-MM-DD');
    const end = moment(value.end).format('YYYY-MM-DD');

    const dayIsBetween = moment(dateClone).isBetween(moment(value.start), moment(value.end));
    const isFirstDay = (date === start);
    const isLastDay = (date === end);
    const lastDayOfWeek = moment(moment(dateClone).endOf('week')).format('YYYY-MM-DD');
    const isLastDayOfWeek = moment(moment(dateClone).format('YYYY-MM-DD')).isSame(lastDayOfWeek);

    const wrapperClassName = `
      ${dayInCurrentMonth && value.start && isLastDay ? classes.endDateWrapper : classes.dayWrapper}
      ${isLastDayOfWeek ? classes.endDateWeekWrapper : ''}
      ${dayInCurrentMonth && dayIsBetween ? classes.highlight : ''}
      ${dayInCurrentMonth && isFirstDay ? classes.firstHighlight : ''}
      ${dayInCurrentMonth && isLastDay ? classes.endHighlight : ''}
    `;
    const dayClassName = `
      ${classes.day}
      ${(dayInCurrentMonth && isFirstDay) || (dayInCurrentMonth && isLastDay) ? classes.highlightDay : ''}
      ${!dayInCurrentMonth ? classes.nonCurrentMonthDay : ''}
    `;

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <Typography variant="body2">
            {format(dateClone, 'd')}
          </Typography>
        </IconButton>
      </div>
    );
  };

  const haveValue = (simple && value) || (!simple && value.start && value.end);
  return (
    <div key={name} ref={containerRef}>
      <MuiDatePicker
        fullWidth
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        label={label}
        labelFunc={() => renderLabel()}
        required={required}
        error={!!error}
        helperText={error}
        value={value}
        renderDay={(
          day, selectedDate, dayInCurrentMonth, dayComponent,
        ) => renderSelectedDay(day, selectedDate, dayInCurrentMonth, dayComponent)}
        onChange={handleOnChangeDate}
        InputProps={{
          className: `${classes.input} ${haveValue ? '' : classes.placeholder}`,
          endAdornment: (
            <>
              {
                (!(disableClearable || rest.disabled)) && (
                  <IconButton
                    className={`${classes.clearButton} ${haveValue ? classes.visibleButton : ''}`}
                    onClick={handleOnClear}
                  >
                    <Icons.CloseOutlined fontSize="small" />
                  </IconButton>
                )
              }
              <Icon className={classes.icon}>
                <img alt="date" src={CalendarIcon} />
              </Icon>
            </>
          ),
        }}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
        PopoverProps={{
          anchorEl,
        }}
        {...rest}
      />
    </div>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  simple: PropTypes.bool,
  disableClearable: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
};

DatePicker.defaultProps = {
  name: '',
  label: '',
  error: '',
  required: false,
  simple: false,
  disableClearable: false,
  onChange: () => {},
  onClear: () => {},
  value: {
    start: null,
    end: null,
  },
};
