import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Contact GraphQL Mutations.

// CREATE create contact GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createContact',
    args: [
      { name: 'fields', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'fields', value: 'fields' },
    ],
    fields: '{...CreateContactResponseFields}',
    fragments: [Fragments.createContactResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update contact GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateContact',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'fields', type: 'String', required: true },
      { name: 'customer_id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'fields', value: 'fields' },
      { name: 'customer_id', value: 'customer_id' },
    ],
    fields: '{...UpdateContactResponseFields}',
    fragments: [Fragments.updateContactResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE delete contact GraphQL schema
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteContact',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...ContactFields}',
    fragments: [Fragments.contact],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
