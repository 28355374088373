import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';
import { filters } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  header: {
    padding: '0.7rem 1rem',
    borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
    ...fonts.overline,
    color: theme.palette.font.subBody,
  },
  button: {
    marginLeft: '0.5rem',
    '& svg': {
      marginRight: '0.3rem',
    },
    [theme.breakpoints.between(0, 1025)]: {
      minWidth: '35px',
      padding: '0 0.5rem',
    },
  },
  menuIcon: {
    lineHeight: '0',
    filter: filters.font.body,
    '&.small': {
      width: '16px',
      height: '16px',
      marginRight: '0.5rem',
    },
    '&.big': {
      height: '21px',
      marginRight: '0.3rem',
    },
  },
  callIcon: {
    width: '18px',
    height: '18px',
  },
  buttonGroup: {
    '& button + button': {
      marginLeft: '0.5rem',
    },
  },
}));
