import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fileDetailContainer: {
    height: '70px',
    padding: '0.5rem',
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
    [theme.breakpoints.down('xs')]: { padding: 'unset' },

  },
  fileTypeIconContainer: {
    [theme.breakpoints.down('xs')]: { display: 'none' },
  },
  redText: {
    color: theme.palette.tertiary.red,
  },
  paddingLeft: {
    paddingLeft: '1rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 'unset',
    },
  },
  iconButtonLabelClose: {
    '& > svg': {
      width: '24px',
      height: '24px',
      '& > path': {
        fill: theme.palette.font.body,
      },
      '& > g': {
        fill: theme.palette.font.body,
      },
    },
  },
  info: {
    '& > *': {
      marginRight: '0.5rem',
      '&:not(:first-child)::before': {
        content: '"\\2022"',
        color: theme.palette.font.body,
        paddingRight: '0.5rem',
      },
    },
  },
}));
