export const states = [
  { label: 'Johor', value: 'Johor' },
  { label: 'Kedah', value: 'Kedah' },
  { label: 'Kelantan', value: 'Kelantan' },
  { label: 'Melaka', value: 'Malacca' },
  { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { label: 'Pahang', value: 'Pahang' },
  { label: 'Perlis', value: 'Perlis' },
  { label: 'Perak', value: 'Perak' },
  { label: 'Pulau Pinang', value: 'Penang' },
  { label: 'Sabah', value: 'Sabah' },
  { label: 'Sarawak', value: 'Sarawak' },
  { label: 'Selangor', value: 'Selangor' },
  { label: 'Terengganu', value: 'Terengganu' },
  { label: 'Wilayah Persekutuan Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Wilayah Persekutuan Labuan', value: 'Labuan' },
  { label: 'Wilayah Persekutuan Putrajaya', value: 'Putrajaya' },
];

export const cities = [
  { label: 'Alor Gajah', value: 'Alor Gajah' },
  { label: 'Alor Setar', value: 'Alor Setar' },
  { label: 'Ampang Jaya', value: 'Ampang Jaya' },
  { label: 'Batu Pahat', value: 'Batu Pahat' },
  { label: 'Bintulu', value: 'Bintulu' },
  { label: 'George Town', value: 'George Town' },
  { label: 'Hulu Selangor', value: 'Hulu Selangor' },
  { label: 'Ipoh', value: 'Ipoh' },
  { label: 'Iskandar Puteri', value: 'Iskandar Puteri' },
  { label: 'Johor Bahru', value: 'Johor Bahru' },
  { label: 'Kajang', value: 'Kajang' },
  { label: 'Kangar', value: 'Kangar' },
  { label: 'Kemaman', value: 'Kemaman' },
  { label: 'Keningau', value: 'Keningau' },
  { label: 'Ketereh', value: 'Ketereh' },
  { label: 'Kinabatangan', value: 'Kinabatangan' },
  { label: 'Klang', value: 'Klang' },
  { label: 'Kluang', value: 'Kluang' },
  { label: 'Kota Bharu', value: 'Kota Bharu' },
  { label: 'Kota Kinabalu', value: 'Kota Kinabalu' },
  { label: 'Kuala Terengganu', value: 'Kuala Terengganu' },
  { label: 'Kuala Selangor', value: 'Kuala Selangor' },
  { label: 'Kuala Langat', value: 'Kuala Langat' },
  { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Kuantan', value: 'Kuantan' },
  { label: 'Kubang Pasu', value: 'Kubang Pasu' },
  { label: 'Kuching', value: 'Kuching' },
  { label: 'Kulai', value: 'Kulai' },
  { label: 'Kulim', value: 'Kulim' },
  { label: 'Lahad Datu', value: 'Lahad Datu' },
  { label: 'Manjung', value: 'Manjung' },
  { label: 'Melaka City', value: 'Melaka City' },
  { label: 'Miri', value: 'Miri' },
  { label: 'Muar', value: 'Muar' },
  { label: 'Padawan', value: 'Padawan' },
  { label: 'Pasir Mas', value: 'Pasir Mas' },
  { label: 'Penampang', value: 'Penampang' },
  { label: 'Petaling Jaya', value: 'Petaling Jaya' },
  { label: 'Putrajaya', value: 'Putrajaya' },
  { label: 'Sandakan', value: 'Sandakan' },
  { label: 'Seberang Perai', value: 'Seberang Perai' },
  { label: 'Selayang', value: 'Selayang' },
  { label: 'Sepang', value: 'Sepang' },
  { label: 'Seremban', value: 'Seremban' },
  { label: 'Shah Alam', value: 'Shah Alam' },
  { label: 'Sibu', value: 'Sibu' },
  { label: 'Subang Jaya', value: 'Subang Jaya' },
  { label: 'Sungai Petani', value: 'Sungai Petani' },
  { label: 'Taiping', value: 'Taiping' },
  { label: 'Tawau', value: 'Tawau' },
  { label: 'Temerloh', value: 'Temerloh' },
];

export const statesAndCities = {
  'Federal Territories': {
    cities: ['Kuala Lumpur', 'Putrajaya'],
  },
  Johor: {
    cities: ['Batu Pahat', 'Iskandar Puteri', 'Johor Bahru', 'Kluang', 'Kulai', 'Muar'],
  },
  Kedah: {
    cities: ['Alor Setar', 'Kubang Pasu', 'Kulim', 'Sungai Petani'],
  },
  Kelantan: {
    cities: ['Ketereh', 'Kota Bharu', 'Pasir Mas'],
  },
  Melaka: {
    cities: ['Alor Gajah', 'Melaka City'],
  },
  'Negeri Sembilan': {
    cities: ['Seremban'],
  },
  Pahang: {
    cities: ['Kuantan', 'Temerloh'],
  },
  Penang: {
    cities: ['George Town', 'Seberang Perai'],
  },
  Perak: {
    cities: ['Ipoh', 'Manjung', 'Taiping'],
  },
  Perlis: {
    cities: ['Kangar'],
  },
  Sabah: {
    cities: ['Lahad Datu', 'Keningau', 'Kinabatangan', 'Kota Kinabalu', 'Penampang', 'Sandakan', 'Tawau'],
  },
  Sarawak: {
    cities: ['Bintulu', 'Kuching', 'Miri', 'Padawan', 'Sibu'],
  },
  Selangor: {
    cities: ['Ampang Jaya', 'Hulu Selangor', 'Kajang', 'Klang', 'Kuala Langat', 'Kuala Selangor', 'Kuala Selangor', 'Petaling Jaya', 'Selayang', 'Sepang', 'Shah Alam', 'Subang Jaya'],
  },
  Terengganu: {
    cities: ['Kemaman', 'Kuala Terengganu'],
  },

};
