import { CONSTRUCTOR } from '..';

export const audience = CONSTRUCTOR.FRAGMENT({
  name: 'Audience',
  fields: `{
    id
    name
    preferred_name
    email
    msisdn
    email_is_reachable
    contact_id
    created_at
    created_by
    updated_at
    updated_by
    unsubscribed_at
  }`,
});

export const condition = CONSTRUCTOR.FRAGMENT({
  name: 'Condition',
  fields: `{
    field
    operator
    value
  }`,
});

export const audienceGroup = CONSTRUCTOR.FRAGMENT({
  name: 'AudienceGroup',
  fields: `{
    id
    name
    company_id
    contact_id
    category
    conditions {...ConditionFields}
    count
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
  fragments: [condition],
});

export const listAudienceGroupsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListAudienceGroupsResponse',
  fields: `{
    items {...AudienceGroupFields}
    count
  }`,
  fragments: [audienceGroup],
});

export const queryAudienceGroupResponse = CONSTRUCTOR.FRAGMENT({
  name: 'QueryAudienceGroupResponse',
  fields: `{
    items {...AudienceFields}
    count
    unsubscribed_count
    subscribed_count
    unreachable_email_count
  }`,
  fragments: [audience],
});

export const retrieveAudienceInfoResponse = CONSTRUCTOR.FRAGMENT({
  name: 'RetrieveAudienceInfoResponse',
  fields: `{
    audience_groups {...AudienceGroupFields}
    unique_count
    non_gsm_count
    s3_key
  }`,
  fragments: [audienceGroup],
});
