import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';
// Buyer Access Control GraphQL Mutations.

// CREATE creates buyer access control GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createBuyerAccess',
    args: [
      { name: 'booking_limit', type: 'String' },
      { name: 'customer_id', type: 'String', required: true },
      { name: 'project_id', type: 'String' },
      { name: 'project_units', type: '[UnitReference]' },
      { name: 'start_date', type: 'String' },
      { name: 'end_date', type: 'String' },
      { name: 'is_all_day', type: 'Boolean' },
      { name: 'is_allow_unit_selection', type: 'Boolean' },
      { name: 'is_all_units', type: 'Boolean' },
      { name: 'project_name', type: 'String' },
    ],
    argsMap: [
      { name: 'booking_limit', value: 'booking_limit' },
      { name: 'customer_id', value: 'customer_id' },
      { name: 'project_id', value: 'project_id' },
      { name: 'project_units', value: 'project_units' },
      { name: 'start_date', value: 'start_date' },
      { name: 'end_date', value: 'end_date' },
      { name: 'is_all_day', value: 'is_all_day' },
      { name: 'is_allow_unit_selection', value: 'is_allow_unit_selection' },
      { name: 'is_all_units', value: 'is_all_units' },
      { name: 'project_name', value: 'project_name' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UDPATE updated buyer access control GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateBuyerAccess',
    args: [
      { name: 'booking_limit', type: 'String' },
      { name: 'customer_id', type: 'String', required: true },
      { name: 'id', type: 'String', required: true },
      { name: 'project_id', type: 'String' },
      { name: 'project_units', type: '[UnitReference]' },
      { name: 'start_date', type: 'String' },
      { name: 'end_date', type: 'String' },
      { name: 'is_all_day', type: 'Boolean' },
      { name: 'is_allow_unit_selection', type: 'Boolean' },
      { name: 'is_all_units', type: 'Boolean' },
      { name: 'project_name', type: 'String' },
    ],
    argsMap: [
      { name: 'booking_limit', value: 'booking_limit' },
      { name: 'customer_id', value: 'customer_id' },
      { name: 'id', value: 'id' },
      { name: 'project_id', value: 'project_id' },
      { name: 'project_units', value: 'project_units' },
      { name: 'start_date', value: 'start_date' },
      { name: 'end_date', value: 'end_date' },
      { name: 'is_all_day', value: 'is_all_day' },
      { name: 'is_allow_unit_selection', value: 'is_allow_unit_selection' },
      { name: 'is_all_units', value: 'is_all_units' },
      { name: 'project_name', value: 'project_name' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE deletes buyer access control GraphQL schema.
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteBuyerAccess',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE creates buyer access control GraphQL schema.
export const CREATE_LIST = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createBuyerAccessList',
    args: [
      { name: 'unique_customers', type: 'Int' },
      { name: 'buyer_access', type: 'BuyerAccess' },
      { name: 'audience_groups', type: '[AudienceGroup]' },
      { name: 's3_key', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'unique_customers', value: 'unique_customers' },
      { name: 'buyer_access', value: 'buyer_access' },
      { name: 'audience_groups', value: 'audience_groups' },
      { name: 's3_key', value: 's3_key' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE updates buyer access control GraphQL schema.
export const UPDATE_LIST = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateBuyerAccessList',
    args: [
      { name: 'buyer_access', type: 'BuyerAccess' },
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'buyer_access', value: 'buyer_access' },
      { name: 'id', value: 'id' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE deletes buyer access control GraphQL schema.
export const DELETE_LIST = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteBuyerAccessList',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{ ...BuyerAccessResponseFields }',
    fragments: [Fragments.buyerAccessResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
