import gql from 'graphql-tag';

// parseArgs parse arguments and converts them to string representation.
// args: [
//   { name: 'string', type: 'String', defaultValue: 'Hello', required: true },
//   { name: 'int', type: 'Int', defaultValue: 10 },
//   { name: 'bool', type: 'Boolean' },
// ],
// output:
// `
//   string: String! = "Hello",
//   int: Int = 10
//   bool: Boolean
// `
const parseArgs = (args) => args.reduce((result, item, idx) => {
  let output = result;
  const {
    name, type, defaultValue: dv, required: r,
  } = item;
  const required = r ? '!' : '';
  let defaultValue = dv ? ` = ${dv}` : '';
  if (type === 'String' && defaultValue) {
    defaultValue = ` = "${dv}"`;
  }
  const template = `$${name}: ${type}${required}${defaultValue},`;
  if (idx === 0) {
    output = template;
    return output;
  }
  return `${output}\n\t${template}`;
}, '');

// parseArgsMap parse arguments map and converts them to string representation.
// argsMap: [
//   { name: 'string', value: 'string' },
//   { name: 'int', value: 'int' },
//   {
//     name: 'nested',
//     value: [
//       {
//         name: 'string',
//         value: 'string',
//       },
//       {
//         name: 'int',
//         value: 'int',
//       },
//     ],
//   },
// ],
// output:
// `
//   string: $string,
//   int: $int,
//   nested: {
//     string: $string,
//     int: $int,
//   },
// `
const parseArgsMap = (argsMap) => argsMap.reduce((result, item, idx) => {
  let output = result;
  const { name, value } = item;
  let template = `${name}: $${value},`;
  if (Array.isArray(value) && value.length > 0) {
    const innerValue = parseArgsMap(value);
    template = `${name}: {\n\t${innerValue}\n},`;
  }
  if (idx === 0) {
    output = template;
    return output;
  }
  return `${output}\n\t${template}`;
}, '');

// pivotFragments combine fragments template string into a single string.
const pivotFragments = (fragments) => {
  if (Array.isArray(fragments) && fragments.length > 0) {
    return fragments.reduce((result, fragment, idx) => {
      let output = result;
      const { loc: { source: { body } } } = fragment;
      if (idx === 0) {
        output = body;
        return output;
      }
      return `${output}${body}`;
    }, '');
  }
  return '';
};

export const GRAPHQL = (options = {
  type: '',
  args: '',
  argsMap: '',
  name: '',
  operationName: '',
  fields: '',
  fragments: [],
}) => {
  const {
    type, name, fields, fragments,
  } = options;
  let { operationName, args, argsMap } = options;
  const haveArgs = Array.isArray(args) && args.length > 0 && Array.isArray(argsMap)
  && argsMap.length > 0;
  if (Array.isArray(args) && args.length > 0) args = parseArgs(args);
  if (Array.isArray(argsMap) && argsMap.length > 0) argsMap = parseArgsMap(argsMap);
  if (!operationName) {
    operationName = name.charAt(0).toUpperCase() + name.slice(1);
  }
  const fs = pivotFragments(fragments);
  if (haveArgs) {
    return gql(String.raw`
      ${type} ${operationName}(
        ${args}
      ) {
        ${name}(
          ${argsMap}
        ) ${fields}
      }
      ${fs}
    `);
  }
  return gql(String.raw`
      ${type} ${operationName} {
        ${name} ${fields}
      }
      ${fs}
    `);
};

export const FRAGMENT = (options = {
  name: '',
  fragmentName: '',
  fields: '',
  fragments: [],
}) => {
  const { name, fields, fragments } = options;
  let { fragmentName } = options;
  const fs = pivotFragments(fragments);
  if (!fragmentName) {
    fragmentName = `${name}Fields`;
  }
  return gql(String.raw`
    fragment ${fragmentName} on ${name} ${fields}
    ${fs}
  `);
};
