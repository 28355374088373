import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  IconButton,
  Typography,
  Button,
  Icon,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { SearchableAssignee } from '../Dropdown';

import useStyles from './styles';

const SearchListUsers = (props) => {
  const {
    disabled,
    helper,
    onCancel,
    onSave,
  } = props;

  const classes = useStyles(props);

  const [selected, setSelected] = useState(null);

  const handleOnSelect = (e, value) => {
    if (!disabled) {
      setSelected(value);
    }
  };

  const handleOnCancel = (e) => {
    setSelected(null);
    onCancel(e);
  };

  const handleOnSubmit = (e) => {
    if (!disabled && selected) {
      onSave(e, selected);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.modalTopBar}
        container
        justify="space-between"
        alignItems="center"
      >
        <Typography className={classes.modalTitle}>Assign to</Typography>
        <IconButton className={classes.iconClose} onClick={handleOnCancel}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <div className={classes.searchField}>
        <SearchableAssignee
          restrictOptions
          value={selected}
          onChange={handleOnSelect}
          disabled={disabled}
        />
      </div>
      <Typography variant="caption" component="p" className={classes.helperText}>
        Note: Clicking save will overwrite all current assignees
      </Typography>
      {
        helper && helper.type && helper.message && (
          <Grid container className={classes[helper.type]} wrap="nowrap">
            <Icon>
              <Info />
            </Icon>
            <div>
              { helper.message }
            </div>
          </Grid>
        )
      }
      <div className={classes.controls}>
        <Button variant="outlined" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleOnSubmit} disabled={!selected || disabled}>
          Save
        </Button>
      </div>
    </div>
  );
};

SearchListUsers.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  helper: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

SearchListUsers.defaultProps = {
  disabled: false,
  helper: {
    type: '',
    message: '',
  },
  onCancel: () => {},
  onSave: () => {},
};

export default withRouter(SearchListUsers);
