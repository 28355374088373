import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  },
  header: {
    '& h1': {
      marginBottom: '0.5rem',
    },
  },
  chip: {
    fontWeight: fonts.semiBold,
    margin: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
    '&.new, &.annoucement': {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.improvement': {
      color: theme.palette.tertiary.amber,
      border: `2px solid ${theme.palette.tertiary.amber}`,
    },
  },
  list: {
    margin: '0',
    paddingLeft: '1.5rem',
    color: theme.palette.font.body,
    '& li + li': {
      marginTop: '1rem',
    },
  },
  versionContainer: {
    '& > h5': {
      color: theme.palette.font.body,
      marginRight: '1rem',
    },
  },
  releaseText: {
    color: theme.palette.font.subBody,
  },
  section: {
    marginTop: '2rem',
    paddingTop: '2rem',
    borderTop: `solid 1px ${theme.palette.tertiary.grey}`,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      paddingTop: '1rem',
    },
  },
}));
