function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgFilePdf = function SvgFilePdf(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    id: "Capa_1",
    "data-name": "Capa 1",
    width: 32,
    height: 32,
    viewBox: "0 0 40 40",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("g", null, React.createElement("g", null, React.createElement("path", {
    d: "M25.627.067H7.775a1.6,1.6,0,0,0-1.6,1.6V38.338a1.6,1.6,0,0,0,1.6,1.595h24.45a1.6,1.6,0,0,0,1.595-1.595V8.26Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("polygon", {
    points: "25.627 8.26 33.82 8.26 25.627 0.067 25.627 8.26",
    style: {
      fill: "#dbddde"
    }
  })), React.createElement("g", null, React.createElement("path", {
    d: "M34.647,29.839H5.353A1.063,1.063,0,0,1,4.29,28.776V19.417a1.063,1.063,0,0,1,1.063-1.063H34.647a1.063,1.063,0,0,1,1.063,1.063v9.359A1.063,1.063,0,0,1,34.647,29.839Z",
    style: {
      fill: "#49b3b1"
    }
  }), React.createElement("g", null, React.createElement("path", {
    d: "M16.035,21.781a1.856,1.856,0,0,1,.586,1.509,1.79,1.79,0,0,1-.6,1.493,2.928,2.928,0,0,1-1.833.473h-1V26.9h-.947V21.291h1.926A2.881,2.881,0,0,1,16.035,21.781Zm-.67,2.316a1.241,1.241,0,0,0,.284-.884.913.913,0,0,0-.361-.822,2.078,2.078,0,0,0-1.132-.241h-.963V24.4h1.1A1.472,1.472,0,0,0,15.365,24.1Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("path", {
    d: "M21.983,22.025a2.615,2.615,0,0,1,.81,2.043,2.75,2.75,0,0,1-.786,2.071A3.343,3.343,0,0,1,19.6,26.9H17.736V21.291h1.927A3.343,3.343,0,0,1,21.983,22.025Zm-.145,2.067q0-1.926-2.207-1.926h-.947v3.845h1.052a2.265,2.265,0,0,0,1.561-.486A1.824,1.824,0,0,0,21.838,24.092Z",
    style: {
      fill: "#f5f6f7"
    }
  }), React.createElement("path", {
    d: "M24.961,22.174v1.541h2.488v.875H24.961V26.9h-.947V21.291h3.74l-.008.883Z",
    style: {
      fill: "#f5f6f7"
    }
  })))));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgFilePdf, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/file_pdf.9a987d85.svg";
export { ForwardRef as ReactComponent };