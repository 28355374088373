import React, {
  useState,
  useEffect,
} from 'react';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { SearchIcon } from '../../../assets/icons';

const SearchInput = (props) => {
  const {
    adornment,
    searchIcon,
    ...classes
  } = useStyles();

  const {
    onChange,
    iconPlacement,
    data,
    clear,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState(data);

  useEffect(() => {
    if (clear) {
      setInputValue('');
    }
  }, [clear]);

  const handleOnChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const renderAdornment = (
    <InputAdornment
      className={adornment}
      position={
        iconPlacement === 'left'
          ? 'start'
          : 'end'
      }
    >
      <img src={SearchIcon} alt="search" className={searchIcon} />
    </InputAdornment>
  );

  const adornmentPosition = iconPlacement === 'left' ? 'startAdornment' : 'endAdornment';

  return (
    <TextField
      classes={{ ...classes }}
      InputProps={{
        [adornmentPosition]: renderAdornment,
      }}
      type="text"
      variant="outlined"
      size="small"
      value={inputValue}
      onChange={(e) => handleOnChange(e)}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  iconPlacement: PropTypes.string,
  data: PropTypes.string,
  clear: PropTypes.bool,
};

SearchInput.defaultProps = {
  onChange: () => {},
  iconPlacement: 'left',
  data: '',
  clear: false,
};

export default SearchInput;
