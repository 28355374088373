import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Contact GraphQL Mutations.

// UPDATE update contact field GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateContactFieldSettings',
    args: [
      { name: 'content', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'content', value: 'content' },
    ],
    fields: '{...ContactFieldSettingsResponseFields}',
    fragments: [Fragments.contactFieldSettingsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export default UPDATE;
