import Bugsnag from '@bugsnag/js';
import { parseParams } from '../utils';
import config from '../config';

const API_URL = config.app.mhubApiUrl;

// TODO: Temporary changes for LMS-2457
// When merge with sign-in consolidate branch, use the changes in there
export async function getAuthentication(query = {}) {
  const res = await fetch(parseParams(`${API_URL}/authenticated/`, query), {
    credentials: 'include',
    mode: 'cors',
  });
  if (!res.ok) {
    const message = await res.text();
    Bugsnag.notify(`Failed to GET ${API_URL}/authenticated/: ${message}`);
    return {};
  }

  const data = await res.json();
  if (!(data.affiliate && data.affiliate.company && data.affiliate.company.apps)) {
    return {};
  }

  return data || {};
}

export async function getProjects(bookApiUrl, search = null, limit = 1500) {
  const queries = [];
  if (limit > 0) {
    queries.push(`limit=${limit}`);
  }
  if (search) {
    queries.push(`search=${search}`);
  }
  const res = await fetch(`${bookApiUrl}/projects/?${queries.join('&')}`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d;
}

export async function getProjectInfo(
  bookApiUrl,
  projectID,
  getRawResponse,
) {
  const res = await fetch(`${bookApiUrl}/projects/${projectID}/`, {
    credentials: 'include',
    mode: 'cors',
  });

  if (getRawResponse) {
    return res;
  }
  const d = await res.json();
  return d || {};
}

export async function getProjectUnits(
  bookApiUrl, projectID, unitStatuses = null, search = null, limit = 2000,
) {
  const queries = [];
  if (limit > 0) {
    queries.push(`limit=${limit}`);
  }
  if (search) {
    queries.push(`search=${search}`);
  }
  if (unitStatuses) {
    unitStatuses.forEach((s, i) => {
      queries.push(`unitStatus[${i}]=${s}`);
    });
  }
  const res = await fetch(`${bookApiUrl}/projects/${projectID}/units/?${queries.join('&')}`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d || [];
}

export async function getUnitInfo(
  bookApiUrl,
  projectID,
  unitID,
  getRawResponse,
) {
  const res = await fetch(`${bookApiUrl}/projects/${projectID}/units/${unitID}/`, {
    credentials: 'include',
    mode: 'cors',
  });

  if (getRawResponse) {
    return res;
  }
  const d = await res.json();
  return d || {};
}

export async function getUnitBooking(bookApiUrl, unitID) {
  const res = await fetch(`${bookApiUrl}/bookings/?page=1&projectUnitID=${unitID}`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d;
}

export async function getBookingInfo(bookApiUrl, bookingID) {
  const res = await fetch(`${bookApiUrl}/bookings/${bookingID}/`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d || {};
}

export async function getBookingList(bookApiUrl) {
  const res = await fetch(`${bookApiUrl}/bookings/`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d || [];
}

export async function getBookingHistory(bookApiUrl, unitID) {
  const res = await fetch(`${bookApiUrl}/bookings/?section=bookings&projectUnitID=${unitID}`, {
    credentials: 'include',
    mode: 'cors',
  });
  const d = await res.json();
  return d || [];
}

export async function getCancellationReasons(bookApiUrl) {
  if (bookApiUrl) {
    const res = await fetch(`${bookApiUrl}/cancellation_reasons/`, {
      credentials: 'include',
      mode: 'cors',
    });
    const d = await res.json();
    return d || [];
  }
  return [];
}
