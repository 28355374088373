import { CONSTRUCTOR } from '..';

export const subscription = CONSTRUCTOR.FRAGMENT({
  name: 'Subscription',
  fields: `{
    id
    company_id
    company_branch_id
    amount
    currency_code
    allocated_email_blast_count
    created_at
    created_by
    updated_at
    updated_by
  }`,
});
