import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts/Theme';

export default makeStyles((theme) => ({
  container: {
    marginTop: '2rem',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  smallButton: {
    padding: '0 8px',
    width: '32px',
  },
  textarea: {
    margin: '1rem 0',
    '& > div': {
      padding: '1rem',
    },
  },
  tableHeader: {
    '& > tr > th': {
      backgroundColor: theme.palette.common.white,
      '&:first-child': {
        width: '30%',
      },
    },
  },
  tableCell: {
    '&:first-child': {
      width: '30%',
    },
    paddingTop: '1rem',
    borderBottom: 'none',
  },
  tableLoading: {
    textAlign: 'center',
  },
  name: {
    fontWeight: fonts.semiBold,
    color: theme.palette.font.header,
  },
  content: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));
