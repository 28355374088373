import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DeleteConfirmation } from '../../..';
import useStyles from './styles';
import { ReactComponent as FileJPGIcon } from '../../../../assets/icons/file_jpg.svg';
import { ReactComponent as FilePDFIcon } from '../../../../assets/icons/file_pdf.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/share.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';


export default function FileInfo(props) {
  const classes = useStyles();
  const {
    f, handleDelete, handleShare, index, uploaded, progress,
  } = props;
  const {
    date, name, overSize, size, type,
  } = f;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleClickShare = () => {
    handleShare(index, true);
  };

  const handleClickDelete = () => {
    setShowDeleteDialog(true);
  };

  const theme = useTheme();

  const handleConfirmDelete = () => {
    setShowDeleteDialog(false);
    handleDelete(index);
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      classes={{
        container: classes.fileDetailContainer,
      }}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Grid item xs={1} className={classes.fileTypeIconContainer}>
        {type === 'pdf' ? <FilePDFIcon /> : <FileJPGIcon />}
      </Grid>
      <Grid item xs={8}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          flexDirection="column"
          className={classes.paddingLeft}
        >
          <Typography
            variant="subtitle1"
          >
            {name.substr(name.lastIndexOf('/') + 1, name.length)}
          </Typography>
          {uploaded ? (
            <div className={classes.info}>
              <Typography variant="caption">{ date }</Typography>
              <Typography variant="caption">
                { size }
                {' '}
                   MB
              </Typography>
            </div>
          ) : (
            <Typography variant="caption">
              { overSize
                ? (<span className={classes.redText}>Your file is larger than 5 MB</span>)
                : (
                  <span>
                      Uploading&nbsp;
                    {progress}
                      %
                  </span>
                ) }
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
          {uploaded
             && (
               <>
                 <IconButton
                   onClick={handleClickShare}
                   classes={{
                     label: classes.iconButtonLabelClose,
                   }}
                 >
                   <ShareIcon />
                 </IconButton>
                 <IconButton
                   onClick={handleClickDelete}
                   classes={{
                     label: classes.iconButtonLabelClose,
                   }}
                 >
                   <TrashIcon />
                 </IconButton>
               </>
             )}
        </Box>
      </Grid>
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DeleteConfirmation
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteDialog(false)}
        />
      </Dialog>
    </Grid>
  );
}

FileInfo.propTypes = {
  handleDelete: PropTypes.func,
  handleShare: PropTypes.func,
  f: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  uploaded: PropTypes.bool,
  progress: PropTypes.number,

};

FileInfo.defaultProps = {
  handleDelete: () => {},
  handleShare: () => {},
  f: {},
  index: 0,
  uploaded: true,
  progress: 0,
};
