import * as CONSTRUCTOR from '../generate';

export const buyerAccessResponse = CONSTRUCTOR.FRAGMENT({
  name: 'BuyerAccessResponse',
  fields: `{
    body
  }`,
});

export const listUnitsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListUnitsResponse',
  fields: `{
    items
    count
  }`,
});
