import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Searchable from '../Searchable';
import { AuthContext, PageContext, SnackbarContext } from '../../../contexts';
import { getProjectUnits } from '../../../data/mhub_api';
import { usePrevious } from '../../../hooks';

export default function SearchableProjectUnit(props) {
  const {
    passUnitInfo,
    projectID,
    unitStatus,
    prevUnitInfo,
    onChange,
    disabledErrorPrompt,
    ...searchableProps
  } = props;

  const authContext = useContext(AuthContext);
  const { apps } = authContext.state;
  const pageContext = useContext(PageContext);
  const { projects } = pageContext.state;
  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;

  const prevProjectID = usePrevious(projectID);

  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [options, setOptions] = useState([]);
  const [unitInfos, setUnitInfos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Clear error when select new project
    if (error && prevProjectID !== projectID) {
      setError('');
    }
  }, [
    error,
    prevProjectID,
    projectID,
  ]);

  // NOTE:
  // Only supports single selection for now until can retrieve full unit list without restriction.
  // Issue is due to when search, selected unit will lost it's info cause is no longer in the list
  useEffect(() => {
    const unitListingAccessError = 'Unit listing can only be retrieved if you have access to the project';
    let initialLoad = true;
    // Pass in deleted/no access but selected projects into the list
    // to fix autocomplete missing option warning
    function concatMissingSelectedUnit(clonedOptions, id) {
      let newClonedOptions = clonedOptions || [];
      const haveValue = newClonedOptions.filter((item) => id === item.value).length > 0;
      if (!haveValue) {
        newClonedOptions = newClonedOptions.concat({
          label: prevUnitInfo && prevUnitInfo.unit_number && prevUnitInfo.unit_id === id
            ? prevUnitInfo.unit_number
            : id,
          value: id,
        });
      }

      return newClonedOptions;
    }

    async function fetchProjectUnitData() {
      let newOptions = [];
      let newListInfos = [];
      // Show loading at the end before fetch data
      if (!initialLoad) {
        setOptions((prev) => [
          ...prev,
          { label: 'Loading...', value: 'loading', disabled: true },
        ]);
      }

      let list = [];
      // Get unit details
      try {
        list = await getProjectUnits(apps.showroom.apiURL, projectID, unitStatus, search, limit);
      } catch (e) {
        list = [];
        setError(unitListingAccessError);
        if (!disabledErrorPrompt) {
          setOpenSnackbar({ variant: 'error', message: 'Error occured when retrieving unit listing' });
        }
        if (initialLoad) {
          initialLoad = false;
          setLoading(false);
        }
        return;
      }

      setError('');

      if (list) {
        const unitOptions = list.map((unit) => (
          { label: unit.reference, value: unit.id }
        ));
        newOptions = unitOptions;
        newListInfos = list;
      } else {
        newOptions = [];
        newListInfos = [];
      }

      if (searchableProps.value
        && !searchableProps.multiple
        && typeof searchableProps.value === 'string'
        && searchableProps.value) {
        const clonedOptions = JSON.parse(JSON.stringify(newOptions));
        newOptions = concatMissingSelectedUnit(clonedOptions, searchableProps.value);
      }

      if (initialLoad) {
        initialLoad = false;
        setLoading(false);
      }

      setOptions(newOptions);
      setUnitInfos(newListInfos);
    }

    if (projectID && !error) {
      fetchProjectUnitData();
    }
  }, [
    disabledErrorPrompt,
    apps.showroom.apiURL,
    error,
    search,
    limit,
    projects,
    searchableProps.value,
    searchableProps.multiple,
    projectID,
    unitStatus,
    prevUnitInfo,
    setOpenSnackbar,
  ]);

  const debounceCallBack = debounce((e, callBack) => {
    callBack();
  }, 200);

  const handleSearch = (e, value) => {
    debounceCallBack(e, () => {
      setSearch(value);
    });
  };

  // Retrieve more when reach scroll till the bottom
  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
      setLimit((prev) => prev + 10);
    }
  };

  const handleOnChange = (e, selected) => {
    // NOTE: Only supports single selection
    if (passUnitInfo && !searchableProps.multiple && selected) {
      const index = unitInfos.findIndex((option) => option.id === selected);
      if (index > -1) {
        onChange(e, selected, { ...unitInfos[index] });
      }
    } else {
      onChange(e, selected);
    }
  };

  const renderSelectPlaceholder = () => {
    if (error) {
      return 'No access to the selected project';
    }
    if (!projectID) {
      return 'No project selected';
    }
    if (options.length) {
      return 'Search';
    }
    return 'No unit available';
  };

  return (
    <Searchable
      options={options}
      loading={loading}
      placeholder={renderSelectPlaceholder()}
      onInputChange={handleSearch}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onChange={handleOnChange}
      {...searchableProps}
      disabled={
        !!error
        || !projectID
        || searchableProps.disabled
      }
    />
  );
}

SearchableProjectUnit.propTypes = {
  disabledErrorPrompt: PropTypes.bool,
  passUnitInfo: PropTypes.bool,
  projectID: PropTypes.string,
  unitStatus: PropTypes.arrayOf(
    PropTypes.string,
  ),
  prevUnitInfo: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

SearchableProjectUnit.defaultProps = {
  disabledErrorPrompt: false,
  passUnitInfo: false,
  projectID: '',
  unitStatus: null,
  prevUnitInfo: {
    unit_id: '',
    unit_number: '',
  },
  onChange: () => {},
};
