import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputBase, MenuItem } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginBottom: '1rem',
    '& > div:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  controls: {
    padding: '1rem 0',
  },
  disabledText: {
    color: theme.palette.font.subBody,
  },
  errorText: {
    margin: '0.57rem 1rem 0',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
  hidden: {
    display: 'none',
  },
  helperText: {
    color: theme.palette.font.subBody,
    marginLeft: '2.22rem',
  },
  optionIcon: {
    '& > svg': {
      marginRight: '0.72rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  sectionWrapper: {
    width: 400,
    padding: '1.43rem',
    minHeight: '46.5rem',
    '& > .MuiTypography-root:first-child': {
      marginTop: 0,
    },
  },
  textarea: {
    '& textarea': {
      height: 'auto',
    },
  },
  buContainer: {
    '& > label': {
      marginBottom: 0,
    },
    '& > span': {
      marginBottom: '0.5rem',
      display: 'block',
    },
  },
}));

export const SelectInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.tertiary.grey}`,
    padding: '0.6rem 1.6rem 0.6rem 1rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:placeholder': {
      color: `${theme.palette.font.subBody}`,
    },
    '&:hover:not($disabled)': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  disabled: {
    '& $input': {
      backgroundColor: `${theme.palette.tertiary.lightGrey}`,
    },
  },
  focused: {
    '& $input': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  error: {
    '& $input': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.tertiary.alertRed}`,
    },
  },
}))(InputBase);

export const CustomMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
  },
  selected: {},
}))(MenuItem);
