// callMethodStatus function results
export const MOBILE_DIALER = 'mobile_default_dialer';
// Not subscribed to call feature
export const NO_CALL_ACCESS = 'no_call_access';
// Subscribed to call feature but not login
export const NO_AVANSER_LOGIN = 'no_avanser_login';
export const NO_CALLER_MOBILE_NUMBER = 'no_caller_mobile_number';
// No dialer ties to the answerpoint
export const NO_ANSWERPOINT_DIALER = 'no_answerpoint_dialer';
// Detect that user is calling own number
export const IS_CALLING_SELF = 'calling_self';
// User avanser automation call
export const AVANSER_DIALER = 'avanser_dialer';
