import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts/Theme';

export default makeStyles({
  empty: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& > .MuiIcon-root': {
      height: '5rem',
      width: '5rem',
      '& img': {
        filter: filters.font.body,
      },
    },
    '& > h6': {
      marginTop: '0.5rem',
    },
  },
});
