import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Grid,
  Button,
  TableContainer,
} from '@material-ui/core';
import {
  SimpleModal,
  Tooltip,
} from '..';
import useStyles from './styles';

const ProjectTags = (props) => {
  const { projects, projectIds } = props;

  const classes = useStyles();

  const [activeStates, setActiveStates] = useState({
    viewProjects: false,
  });

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  const getProjectNames = () => {
    if (Array.isArray(projectIds) && projectIds.length > 0) {
      const projectNames = projectIds.map((id) => (
        (projects[id] && projects[id].name) || id
      ));
      return projectNames;
    }
    return [];
  };

  const handleToggleViewProjects = (e) => {
    e.stopPropagation();
    toggleState('viewProjects')();
  };

  const renderFirstProject = () => {
    const projectNames = getProjectNames();
    return (
      <Tooltip title={projectNames[0]}>
        <div className={classes.projectWrapper}>
          <Typography
            variant="caption"
            className={`${classes.project} ${classes.ellipsis}`}
          >
            { projectNames[0] }
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const renderRemainingProjects = () => {
    if (projectIds && projectIds.length > 1) {
      return (
        <div
          aria-hidden="true"
          onClick={handleToggleViewProjects}
          className={`${classes.projectWrapper} secondary`}
        >
          <Typography
            variant="caption"
            className={`${classes.project} ${classes.ellipsis}`}
          >
            {`+${projectIds.length - 1} `}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const renderProjectList = () => {
    const list = getProjectNames();
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">
                  Project name
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              list.length && list.map((name) => (
                <TableRow key={name}>
                  <TableCell>
                    <Typography variant="body2">
                      { name }
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderModalButton = () => (
    <Grid container justify="flex-end">
      <Button variant="contained" onClick={handleToggleViewProjects}>
        Close
      </Button>
    </Grid>
  );

  return (
    <>
      {
        projectIds && Array.isArray(projectIds) && projectIds.length > 0 && (
          <div className={classes.container}>
            { renderFirstProject() }
            { renderRemainingProjects() }
            <SimpleModal
              title="Project list"
              content={renderProjectList()}
              buttons={renderModalButton()}
              open={activeStates.viewProjects}
              onCancel={handleToggleViewProjects}
            />
          </div>
        )
      }
    </>
  );
};

ProjectTags.propTypes = {
  projects: PropTypes.instanceOf(Object),
  projectIds: PropTypes.arrayOf(PropTypes.string),
};

ProjectTags.defaultProps = {
  projects: {},
  projectIds: [],
};

export default ProjectTags;
