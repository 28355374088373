import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../../contexts';

export default makeStyles((theme) => ({
  chip: {
    height: '25px',
    ...fonts.caption,
    fontWeight: fonts.semiBold,
    margin: '0.5rem 0',
    '&.workflow, &.manual_call': {
      color: theme.palette.tertiary.lavender,
      backgroundColor: theme.palette.tertiary.pastelPurple,
    },
    '&.inbound': {
      color: theme.palette.tertiary.darkGreen,
      backgroundColor: theme.palette.tertiary.dimGreen,
    },
  },
}));
