import {
  DEVELOPER_EXECUTIVE,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_AGENCY_ADMIN,
  DEVELOPER_AGENT_LEADER,
} from './groups';

// Level 1
export const ROLE_HEADQUARTERS = 'Headquarters';
// Level 2
export const ROLE_SUPERVISOR = 'Supervisor';
export const ROLE_SALES_MANAGER = 'Sales Manager';
export const ROLE_SALES_ADMIN = 'Sales Admin';
// Level 2.5
export const ROLE_AGENCY_LEADER = 'Agency Leader';
export const ROLE_AGENCY_ADMIN = 'Agency Admin';
// Level 3
export const ROLE_STAFF = 'Staff';
export const ROLE_AGENT = 'Agent';

// From backend, 1 = Headquarters ...
export const ROLE_MAP = [
  undefined,
  ROLE_HEADQUARTERS,
  ROLE_SUPERVISOR,
  ROLE_SALES_MANAGER,
  ROLE_SALES_ADMIN,
  ROLE_STAFF,
];

export const ROLE_HEADQUARTERS_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_HEADQUARTERS,
];

export const ROLE_SUPERVISOR_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
];

export const ROLE_SALES_MANAGER_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
];

export const ROLE_SALES_ADMIN_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_BRANCH_LEADER,
];

export const ROLE_AGENCY_ADMIN_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_AGENCY_ADMIN,
];

export const ROLE_AGENCY_LEADERS_GROUPS = [
  DEVELOPER_EXECUTIVE,
  DEVELOPER_AGENT_LEADER,
];

export const STAFF_GROUPS = [DEVELOPER_EXECUTIVE];

export function isHeadquartersRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_HEADQUARTERS_GROUPS.every((hg) => groups.includes(hg));
}

export function isSupervisorRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_SUPERVISOR_GROUPS.every((sg) => groups.includes(sg));
}

export function isSalesManagerRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_SALES_MANAGER_GROUPS.every((smg) => groups.includes(smg));
}

export function isSalesAdminRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_SALES_ADMIN_GROUPS.every((sag) => groups.includes(sag));
}

export function isAgencyAdminRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_AGENCY_ADMIN_GROUPS.every((aag) => groups.includes(aag));
}

export function isAgencyLeaderRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && ROLE_AGENCY_LEADERS_GROUPS.every((alr) => groups.includes(alr));
}

export function isStaffRole(groups = []) {
  return Array.isArray(groups) && groups.length > 0 && STAFF_GROUPS.every((sg) => groups.includes(sg));
}

export function getUserRole(groups, isExternalCompanyBranch) {
  if (groups) {
    switch (true) {
      case isHeadquartersRole(groups):
        return ROLE_HEADQUARTERS;
      case isSupervisorRole(groups):
        return ROLE_SUPERVISOR;
      case isSalesManagerRole(groups):
        return ROLE_SALES_MANAGER;
      case isSalesAdminRole(groups):
        return ROLE_SALES_ADMIN;
      case isAgencyAdminRole(groups) && isExternalCompanyBranch:
        return ROLE_AGENCY_ADMIN;
      case isAgencyLeaderRole(groups) && isExternalCompanyBranch:
        return ROLE_AGENCY_LEADER;
      case isStaffRole(groups) && isExternalCompanyBranch:
        return ROLE_AGENT;
      default:
        return ROLE_STAFF;
    }
  }
  return ROLE_STAFF;
}
