import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../contexts';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&.disabled': {
      opacity: 0.2,
    },
  },
  icon: {
    width: '1rem',
    filter: filters.font.body,
  },
  button: {
    padding: 0,
    width: 'unset',
    '& > span > h6': {
      color: theme.palette.font.body,
    },
    '&:hover': {
      '& > span > span > img': {
        filter: filters.primary.main,
      },
      '& > span > h6': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
