import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  ListSubheader,
  IconButton,
} from '@material-ui/core';
import { Tooltip } from '../..';
import { InfoIcon } from '../../../assets/icons';
import { TruncateString } from '../../../utils';
import { useStyles, CustomMenuItem } from './styles';

export default function SelectInput(props) {
  const {
    variant,
    label,
    name,
    value,
    placeholder,
    caption,
    loading,
    error,
    readOnly,
    required,
    anchorRef,
    options,
    optionNode,
    onChange,
    menuProps,
    ...selectProps
  } = props;

  const classes = useStyles(props);

  const handleOnChange = (e) => {
    if (!readOnly) {
      onChange(e, e.target.value);
    }
  };

  return (
    <div>
      <FormControl fullWidth error={!!error}>
        {
          label && (
            <InputLabel
              id={name}
              required={required}
              shrink={false}
              variant="standard"
              disableAnimation
            >
              { label }
            </InputLabel>
          )
        }
        <Select
          ref={anchorRef}
          fullWidth
          displayEmpty
          classes={{
            root: classes.root,
          }}
          variant={variant}
          labelId={name}
          name={name}
          value={value || ''}
          onChange={(e) => handleOnChange(e)}
          renderValue={(selected) => {
            if (loading) {
              return <span className={classes.disabledText}>Loading...</span>;
            }
            if (!selected && !value) {
              return <span className={classes.disabledText}>{placeholder}</span>;
            }
            const selectedValue = options.find((obj) => (obj.value === selected));
            return (
              <>
                <Typography className={`${classes.selected} ${classes.value}`}>
                  { (selectedValue && selectedValue.label) || '' }
                </Typography>
                {
                  selectedValue
                  && selectedValue.helperText
                  && (
                    <Tooltip title={selectedValue.helperText}>
                      {
                        ({ toggle }) => (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              toggle();
                            }}
                            className={classes.infoButton}
                          >
                            <img alt="menu" src={InfoIcon} />
                          </IconButton>
                        )
                      }
                    </Tooltip>
                  )
                }
              </>
            );
          }}
          MenuProps={{
            classes: { paper: classes.paper },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            MenuListProps: {
              disablePadding: true,
            },
            ...menuProps,
          }}
          {...selectProps}
        >
          {
            options && options.length > 0
              ? React.Children.toArray(options.map(
                (opt) => {
                  if (!optionNode) {
                    if (opt.subHeader) {
                      return (
                        <ListSubheader className={classes.subHeader}>
                          { opt.label }
                        </ListSubheader>
                      );
                    }
                    return (
                      <CustomMenuItem value={opt.value} disabled={opt.disabled}>
                        {TruncateString(opt.label, 40)}
                      </CustomMenuItem>
                    );
                  }
                  return optionNode(opt);
                },
              ))
              : <CustomMenuItem key="null" value="null" disabled>No result</CustomMenuItem>
          }
        </Select>
        {caption && <FormHelperText>{caption}</FormHelperText>}
      </FormControl>
    </div>
  );
}

SelectInput.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  menuProps: PropTypes.instanceOf(Object),
  optionNode: PropTypes.func,
  onChange: PropTypes.func,
};

SelectInput.defaultProps = {
  variant: 'outlined',
  label: '',
  name: '',
  value: '',
  placeholder: 'Select',
  caption: '',
  loading: false,
  error: false,
  readOnly: false,
  required: false,
  anchorRef: null,
  options: [],
  menuProps: {},
  optionNode: null,
  onChange: () => {},
};
