import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Amplify, { Auth } from 'aws-amplify';
import { createAppSyncLink } from 'aws-appsync';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'flag-icon-css/css/flag-icon.min.css';
import config from './config';

import { redirectLogin } from './utils';
import { App, NetworkError } from './pages';
import {
  ClientProvider,
  SnackbarProvider,
  ThemeProvider,
  PageProvider,
  AuthProvider,
} from './contexts';

import './index.css';

global.fetch = require('node-fetch');

const ignoreErrors = [
  'No current user',
  'Refresh Token has expired',
];

Bugsnag.start({
  apiKey: '8e33173812161b8180ba2b14c6b7a3f4',
  releaseStage: config.app.stage,
  appVersion: config.app.version,
  enabledReleaseStages: ['prod', 'demo'],
  onError: (event) => {
    if (ignoreErrors.includes(event.originalError.message)) {
      window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
      return false;
    }
    return true;
  },
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.app.region,
    userPoolId: config.cognito.userPoolId,
    identityPoolId: config.cognito.identityPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    cookieStorage: {
      domain: config.cognito.cookieDomain,
      secure: config.cognito.cookieSecure,
    },
  },
  Storage: {
    // NOTE: Public bucket is not configured as Amplify only supports one Bucket at a time.
    // Still able to call public bucket when using Storage module.
    region: config.app.region,
    bucket: config.s3.bucket,
    identityPoolId: config.cognito.identityPoolId,
  },
  API: {
    endpoints: [
      // LMS-2843
      // {
      //   name: 'CHAT',
      //   endpoint: config.api.chatApiUrl,
      // },
      {
        name: 'SHORTURL',
        endpoint: config.api.shortUrlApiUrl,
      },
      {
        name: 'CREDITCHECK',
        endpoint: config.api.creditCheckApiUrl,
        region: config.app.region,
      },
      {
        name: 'COMPANY_BRANCH',
        endpoint: config.api.companyBranchApiUrl,
        region: config.app.region,
      },
      {
        name: 'LEAD',
        endpoint: config.api.leadApiUrl,
        region: config.app.region,
      },
      {
        name: 'WORKFLOW',
        endpoint: config.api.workflowApiUrl,
        region: config.app.region,
      },
      {
        name: 'USER',
        endpoint: config.api.userApiUrl,
        region: config.app.region,
      },
    ],
  },
});

// generateLink generates appsync and apollo links
// ( appsync is using older version of react-apollo hence will not work properly )
const generateLink = (endpoint) => createAppSyncLink({
  url: endpoint,
  region: config.app.region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => Auth.currentSession()
      .then((data) => (data).getIdToken().getJwtToken())
      .catch(() => {
        window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
      }),
  },
  disableOffline: true,
}).concat(createHttpLink({ uri: endpoint }));

// generateClient generate ApolloClient with link ( appsync and apollo links )
const generateClient = (link) => new ApolloClient({ link, cache: new InMemoryCache() });

export const clientAuth = generateClient(generateLink(config.appsync.authGraphqlEndpoint));
export const clientActivity = generateClient(generateLink(config.appsync.activityGraphqlEndpoint));
export const clientAudience = generateClient(generateLink(config.appsync.audienceGraphqlEndpoint));
export const clientLead = generateClient(generateLink(config.appsync.leadFieldsGraphqlEndpoint));
export const clientLeadIntegration = generateClient(generateLink(config.appsync.leadIntegrationGraphqlEndpoint));
export const clientCampaign = generateClient(generateLink(config.appsync.campaignGraphqlEndpoint));
export const clientCalls = generateClient(generateLink(config.appsync.callsGraphqlEndpoint));
export const clientContact = generateClient(generateLink(config.appsync.contactGraphqlEndpoint));
export const clientCustomer = generateClient(generateLink(config.appsync.customerGraphqlEndpoint));
export const clientForm = generateClient(generateLink(config.appsync.formGraphqlEndpoint));
export const clientOpportunity = generateClient(generateLink(config.appsync.opportunityGraphqlEndpoint));
export const clientSubmission = generateClient(generateLink(config.appsync.submissionGraphqlEndpoint));
export const clientSubscription = generateClient(generateLink(config.appsync.subscriptionGraphqlEndpoint));
export const clientUser = generateClient(generateLink(config.appsync.userGraphqlEndpoint));
export const clientBuyerAccess = generateClient(generateLink(config.appsync.buyerAccessGraphqlEndpoint));


class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return [0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => this.moment().weekday(dayOfWeek).format('dd')[0]);
  }
}

ReactDOM.render(
  <ClientProvider
    clients={{
      clientAuth,
      clientActivity,
      clientAudience,
      clientLead,
      clientLeadIntegration,
      clientCampaign,
      clientCalls,
      clientContact,
      clientCustomer,
      clientForm,
      clientOpportunity,
      clientSubmission,
      clientSubscription,
      clientUser,
      clientBuyerAccess,
    }}
  >
    <ErrorBoundary FallbackComponent={NetworkError}>
      <ThemeProvider>
        <SnackbarProvider>
          <AuthProvider>
            <PageProvider>
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <Router>
                  <LastLocationProvider>
                    <App />
                  </LastLocationProvider>
                </Router>
              </MuiPickersUtilsProvider>
            </PageProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </ClientProvider>,
  document.getElementById('root'),
);
