import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';

export default makeStyles((theme) => ({
  clearButton: {
    padding: '0.29rem',
    visibility: 'hidden',
  },
  visibleButton: {
    visibility: 'visible',
  },
  icon: {
    color: theme.palette.font.body,
    opacity: 0.5,
  },
  input: {
    cursor: 'pointer',
    '& input': {
      cursor: 'pointer',
    },
  },
  placeholder: {
    color: theme.palette.font.subBody,
  },
  dayWrapper: {
    paddingRight: '0.72rem',
    marginTop: '0.36rem',
  },
  endDateWrapper: {
    marginRight: '0.72rem',
    marginTop: '0.36rem',
  },
  endDateWeekWrapper: {
    paddingRight: 0,
    marginRight: 0,
  },
  day: {
    width: 30,
    height: 30,
    margin: 0,
    padding: 0,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.font.subBody,
    '&:hover': {
      fontWeight: fonts.bold,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      color: theme.palette.font.header,
    },
  },
  nonCurrentMonthDay: {
    visibility: 'hidden',
  },
  highlight: {
    background: theme.palette.tertiary.mediumGrey,
  },
  highlightDay: {
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: 'white',
  },
  firstHighlight: {
    background: theme.palette.tertiary.mediumGrey,
    borderRadius: '50% 0 0 50%',
  },
  endHighlight: {
    background: theme.palette.tertiary.mediumGrey,
    borderRadius: '0 50% 50% 0',
  },
  timePickerPopover: {
    '& > .MuiPickersBasePicker-container .MuiToolbar-root .MuiPickersTimePickerToolbar-hourMinuteLabel .MuiTypography-h2': {
      fontSize: '3rem',
      lineHeight: '1',
    },
  },
}));
