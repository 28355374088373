import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// CREATE create activity GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createActivity',
    args: [
      { name: 'type', type: 'String', required: true },
      { name: 'object', type: 'String', required: true },
      { name: 'target_id', type: 'String', required: true },
      { name: 'target_type', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'type', value: 'type' },
      { name: 'object', value: 'object' },
      { name: 'target_id', value: 'target_id' },
      { name: 'target_type', value: 'target_type' },
    ],
    fields: '{...CreateActivityResponseFields}',
    fragments: [Fragments.createActivityResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
