import * as CONSTRUCTOR from '../generate';

// FetchTenantDataResponse fields fragment.
export const fetchTenantDataResponse = CONSTRUCTOR.FRAGMENT({
  name: 'FetchTenantDataResponse',
  fields: `{
    body
  }`,
});

// LeadIntegrationsResponse fields fragment.
export const leadIntegrationsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'LeadIntegrationsResponse',
  fields: `{
    body
  }`,
});

export const fetchAllFieldDataResponse = CONSTRUCTOR.FRAGMENT({
  name: 'FetchAllFieldDataResponse',
  fields: `{
    body
  }`,
});

// ListDataMappingsResponse fields fragment.
export const listDataMappingsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListDataMappingsResponse',
  fields: `{
    items {
      id
      company_id
      type
      mappings {
        sc_user_id
        sc_user_name
        mhub_user_id
        mhub_user_name
        sc_project_id
        sc_project_name
        mhub_project_ids
        mhub_project_names
        sc_status_id
        sc_action_statuses {
          sc_status_action
          sc_status_action_id
        }
        sc_status_lead
        mhub_status
        mhub_status_id
        mhub_lead_status
        mhub_customer_status
        mhub_opportunity_status
        mhub_lead_unqualified_reason_status
        mhub_opportunity_lost_reason_status
        status_category
        sc_field_id
        sc_field_name
        sc_field_label
        sc_type
        mhub_field_id
        mhub_field_name
        mhub_field_label
        mhub_type
        required
        field_category
      }
    }
    count
  }`,
});
