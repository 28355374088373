import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import { FileInfo, ShareOptions, UploadError } from './components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import useStyles from './styles';

export default function ShareSalesKit(props) {
  const {
    contactInfo,
    open,
    onCancel,
    projectId,
  } = props;
  const classes = useStyles();
  const [file, setFile] = useState({});
  const [fileList, setFileList] = useState([{}]);
  const [showShareOptions, setShowShareOptions] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [uploadPcnt, setUploadPcnt] = useState(0);
  const [callFileList, setCallFileList] = useState(false);

  Storage.configure({
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
    },
  });

  const getSubString = (str, delimiter, strLength, extractFrom) => {
    if (extractFrom === 'end') {
      return str.substr(str.lastIndexOf(delimiter) + 1, strLength);
    }
    return str.substr(str.indexOf(delimiter) + 1, strLength);
  };

  useEffect(() => {
    if (callFileList) {
      Storage.list(`projects/${projectId}/`)
        .then((result) => {
          const arr = [];
          result.map((r) => {
            const fileName = getSubString(r.key, '/', r.key.length, 'end');
            const type = getSubString(fileName, '.', fileName.length, 'end');
            const size = (r.size / 1024 / 1024).toFixed(1);
            const lastModified = r.lastModified.toString();
            const dateWtTimezone = getSubString(lastModified, '.', lastModified.length, 'start');
            const date = dateWtTimezone.substr(0, dateWtTimezone.lastIndexOf(':'));
            arr.push({
              name: fileName,
              type,
              size,
              date,
              projectId,
              path: r.key,
            });
            return null;
          });
          setFileList(arr);
        });
      setCallFileList(false);
    }
  }, [projectId, callFileList]);

  useEffect(() => {
    const arr = [];
    if (!_.isEqual(fileList, [{}])) {
      fileList.forEach(() => arr.push(false));
      setShowShareOptions(arr);
    }
  }, [fileList]);

  useEffect(() => {
    setCallFileList(true);
  }, []);

  const handleOnCancel = () => {
    setFile({});
    onCancel();
  };

  const handleShowShareOptions = (index, value) => {
    const newIds = showShareOptions.slice();
    newIds[index] = value;
    setShowShareOptions(newIds);
  };

  const uploadFile = (fileName, f) => {
    Storage.put(`projects/${projectId}/${fileName}`, f, {
      progressCallback(progress) {
        setUploadPcnt(Math.ceil((progress.loaded / progress.total) * 100));
      },
    })
      .then(() => {
        setFile({});
        setCallFileList(true);
      })
      .catch(() => {
        setUploadError(true);
      });
  };

  const handleOnUpload = (e) => {
    const f = Array.from(e.target.files);
    const type = f[0].type.slice(f[0].type.indexOf('/') + 1, f[0].type.length);
    const size = f[0].size / 1024 / 1024; // in MB
    const { name } = f[0];
    setFile({
      name,
      type,
      overSize: size > 5,
    });
    if (size > 5) {
      return;
    }
    uploadFile(name, f[0]);
  };


  const fileIsUploading = () => {
    if (_.isEmpty(file)) {
      return false;
    }
    return true;
  };

  const renderFileIsUploading = () => {
    if (fileIsUploading()) {
      switch (uploadError) {
        case false: return (
          <FileInfo
            key={file.key}
            f={file}
            uploaded={false}
            progress={uploadPcnt}
          />
        );
        case true:
          return (
            <UploadError
              key={file.key}
              f={file}
              retry={uploadFile}
            />
          );
        default: return null;
      }
    }
    return null;
  };

  const getFileURL = (name) => {
    // replace '+' & white space to enable correct rendering of URL
    const formattedFileName = name.replace(/\+/g, '%2B').replace(/\s+/g, '+');
    const fileURL = `${process.env.REACT_APP_MHUB_FILES_URL}/public/projects/${projectId}/${formattedFileName}`;
    return fileURL;
  };

  const handleConfirmDeleteFile = (index) => {
    Storage.remove(`projects/${projectId}/${fileList[index].name}`)
      .then(() => {
        setCallFileList(true);
      });
  };

  return (
    <Dialog open={open} onClose={handleOnCancel}>
      <div className={classes.container}>
        <Grid
          className={classes.topBar}
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid
            container
            alignItems="center"
            direction="row"
            justify="space-between"
          >
            <Typography variant="h4">Share sales kit</Typography>
            <IconButton
              classes={{
                label: classes.iconClose,
              }}
              onClick={handleOnCancel}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="row"
            classes={{
              container: classes.solidContainer,
            }}
          >
            <Grid item>
              <UploadIcon className={classes.marginRight} />
            </Grid>
            <Grid item>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
              >
                <label htmlFor="icon-button-file">
                  <input
                    accept=".pdf, .jpg, .jpeg"
                    className={classes.hide}
                    id="icon-button-file"
                    type="file"
                    onChange={handleOnUpload}
                  />
                  <Typography variant="subtitle1" className={classes.link}>
                    Browse to upload your file
                  </Typography>
                </label>
                <Typography variant="body2">
                  Maximum file size: 5 MB
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {renderFileIsUploading()}
          { !_.isEqual(fileList, [{}]) && fileList.map((f, index) => {
            if (showShareOptions[index] === false) {
              return (
                <FileInfo
                  key={f.name}
                  f={f}
                  handleDelete={handleConfirmDeleteFile}
                  index={index}
                  uploaded
                  handleShare={handleShowShareOptions}
                />
              );
            }
            return (
              <ShareOptions
                key={f.name}
                contactInfo={contactInfo}
                fileURL={getFileURL(f.name)}
                index={index}
                handleClose={handleShowShareOptions}
              />
            );
          })}
        </Grid>
      </div>
    </Dialog>
  );
}

ShareSalesKit.propTypes = {
  contactInfo: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  projectId: PropTypes.string,
};

ShareSalesKit.defaultProps = {
  contactInfo: {},
  open: false,
  onCancel: () => {},
  projectId: '',
};
