import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../contexts';
import { bodyFont } from '../../../contexts/Theme/font';

export default makeStyles((theme) => ({
  label: {
    fontWeight: 400,
    color: colors.font.header,
    fontFamily: bodyFont,
  },
}));
