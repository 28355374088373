import moment from 'moment';

export function previewDateTime(datetime) {
  return datetime ? moment(datetime).format('MMM DD, YYYY, hh:mm A') : '';
}

export function previewDate(date) {
  return date ? moment(date).format('MMM DD, YYYY') : '';
}

export function storedDateFormat(date) {
  return date ? moment(date).format('YYYY-MM-DD') : '';
}

export function previewTime(time) {
  return time ? moment(time).format('hh:mmA') : '';
}

export function previewDateTimePresetGTM(datetime) {
  return datetime ? moment(datetime, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD, YYYY, hh:mm A') : '';
}

export function checkAccessDatetime(inStartDate, inEndDate) {
  let isBefore = true;
  let isAfter = true;
  let isFutureDate = false;

  const startDate = moment(inStartDate || '', 'YYYY-MM-DDTHH:mm:ss');
  const endDate = moment(inEndDate || '', 'YYYY-MM-DDTHH:mm:ss');
  const now = moment();

  if (startDate && startDate.isValid()) {
    isAfter = now.isSameOrAfter(startDate);
    isFutureDate = now.isBefore(startDate);
  }

  if (endDate && endDate.isValid()) {
    isBefore = now.isSameOrBefore(endDate);
  }

  return { isActive: (isBefore && isAfter), isFutureDate };
}

export function getSecondsFromHours(hours) {
  return (hours * 3600).toString();
}
