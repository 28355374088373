import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/save.svg';
import {
  DatePicker,
  Drawer,
  SelectInput,
  TimePicker,
  SimpleModal,
} from '../../../index';
import {
  CloseSmallIcon,
  EditIcon,
} from '../../../../assets/icons';
import { AuthContext, PageContext } from '../../../../contexts';
import {
  callOutcomes,
  pendingSystem,
  pendingUserAction,
} from '../../constants/callLogData';
import {
  formatValue,
  getFieldLabel,
  previewDate,
  previewDateTime,
  previewTime,
  toCapitalize,
} from '../../../../utils';
import CallTypeTag from '../../CallTypeTag';
import AudioPlayer from '../../../AudioPlayer';
import useStyles from './styles';

export default function CallLogInfo(props) {
  const {
    type,
    open,
    lead,
    customer,
    data,
    loading,
    onUpdate,
    onClose,
  } = props;
  const authContext = useContext(AuthContext);
  const { users, apps } = authContext.state;
  const pageContext = useContext(PageContext);
  const { avanserAccount } = pageContext.state;

  const classes = useStyles();

  const [log, setLog] = useState(data);
  const [selected, setSelected] = useState({});
  const [currentPlayingURL, setCurrentPlayingURL] = useState('');
  const [activeStates, setActiveStates] = useState({
    uneditableWarning: false,
  });

  const uneditabled = ['workflow', 'manual_call'].includes(log.type) || data.direction === 'call_in';
  const fromNativeDialer = log.type === 'manual_call' && log.status === pendingUserAction.name;

  const fields = [
    {
      name: 'lead_id',
      label: 'Lead contacted',
      type: 'text',
      fixed: true,
      hide: data.direction === 'call_in' || type !== 'lead',
    },
    {
      name: 'customer_id',
      label: 'Customer contacted',
      type: 'text',
      fixed: true,
      hide: data.direction === 'call_in' || type !== 'customer',
    },
    {
      name: 'call_by',
      label: getFieldLabel('call_by'),
      type: 'text',
      fixed: true,
      hide: data.direction === 'call_in',
    },
    {
      name: 'call_from',
      label: getFieldLabel('call_from'),
      type: 'text',
      fixed: true,
      hide: data.direction !== 'call_in',
    },
    {
      name: 'answerpoint',
      label: 'Answered by',
      type: 'text',
      fixed: true,
      hide: data.direction !== 'call_in',
    },
    {
      name: 'type',
      label: 'Call type',
      type: 'chip',
      fixed: true,
    },
    {
      name: 'call_created_date',
      label: 'Call date',
      type: 'date',
      fixed: uneditabled,
      required: true,
    },
    {
      name: 'call_created_time',
      label: 'Call time',
      type: 'time',
      fixed: uneditabled,
      required: true,
    },
    {
      name: 'duration',
      label: 'Call duration (minutes)',
      type: 'number',
      fixed: !fromNativeDialer && uneditabled,
    },
    {
      name: 'status',
      label: 'Call outcome',
      type: 'dropdown',
      fixed: !fromNativeDialer && uneditabled,
      options: fromNativeDialer
        ? [
          ...callOutcomes,
          { label: pendingUserAction.label, value: pendingUserAction.name },
        ] : callOutcomes,
    },
    {
      name: 'remark',
      label: 'Remarks',
      type: 'longtext',
    },
    {
      name: 'recording_url',
      label: 'Call recording link',
      fixed: true,
      type: 'audio_player',
      hide: !(apps.lead_management_v2 && apps.lead_management_v2.hasCallAccess)
        || !(avanserAccount && !isEmpty(avanserAccount)),
    },
    {
      name: 'created_at',
      label: getFieldLabel('created_at'),
      fixed: true,
    },
    {
      name: 'updated_at',
      label: getFieldLabel('updated_at'),
      fixed: true,
    },
  ];

  const toggleState = (name) => () => {
    setActiveStates((s) => ({ ...s, [name]: !s[name] }));
  };

  useEffect(() => {
    if (open && data) {
      let formattedData = data;
      if (data.call_at) {
        const callDate = data.call_at;
        const callTime = data.call_at;
        formattedData = {
          ...formattedData,
          call_created_date: callDate,
          call_created_time: callTime,
        };
      }
      if (data.duration) {
        formattedData = {
          ...formattedData,
          duration: formattedData.duration / 60,
        };
      }
      setLog(formattedData);
    }

    if (!open) {
      setSelected({});
      setLog(data);
    }
  }, [open, data]);

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelected({ [name]: value });
  };

  const handleChangeDateTime = (name, v) => {
    setSelected({ [name]: v });
  };

  const handleUpdateLog = (field) => {
    let newLog = JSON.parse(JSON.stringify(log));

    if (['call_created_date', 'call_created_time'].includes(field.name)) {
      let combineDateTime = log.call_at;
      let formatDate = moment(log.call_created_date).format('YYYY-MM-DD');
      let formatTime = moment(log.call_created_time).format('HH:mm');
      if (log.call_created_time && field.name === 'call_created_date') {
        formatDate = moment(selected[field.name]).format('YYYY-MM-DD');
        combineDateTime = moment(`${formatDate} ${formatTime}`).format();
        newLog = {
          ...log,
          call_at: combineDateTime,
          [field.name]: selected[field.name],
        };
      }
      if (log.call_created_date && field.name === 'call_created_time') {
        formatTime = moment(selected[field.name]).format('HH:mm');
        combineDateTime = moment(`${formatDate} ${formatTime}`).format();
        newLog = {
          ...log,
          call_at: combineDateTime,
          [field.name]: selected[field.name],
        };
      }
    } else {
      newLog = { ...log, [field.name]: selected[field.name] };
    }

    // To render updated value in the drawer
    setLog({ ...newLog });

    // To pass to API
    const updatedLog = JSON.parse(JSON.stringify(newLog));

    // format duration from minutes to seconds
    const durationValue = (field.name === 'duration' && selected[field.name]) || updatedLog.duration || 0;
    if (durationValue) {
      const formatDuration = durationValue * 60;
      updatedLog.duration = formatDuration.toString();
    } else {
      delete updatedLog.duration;
    }
    delete updatedLog.call_created_date;
    delete updatedLog.call_created_time;

    onUpdate({ ...updatedLog }, field);
    setSelected({});
  };

  const renderEditButtons = (f) => (
    <Grid container spacing={1} justify="flex-end" className={classes.controls}>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          disabled={(f.required && selected[f.name] === '')}
          onClick={() => {
            if (log[f.name] !== selected[f.name]) {
              if (f.name === 'status' && fromNativeDialer) {
                toggleState('uneditableWarning')();
              } else {
                handleUpdateLog(f);
              }
            } else {
              setSelected({});
            }
          }}
        >
          <SaveIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setSelected({});
          }}
        >
          <Box component="img" src={CloseSmallIcon} />
        </Button>
      </Grid>
    </Grid>
  );

  const renderEditToggle = (f, clickFunc) => {
    if (!f.fixed) {
      if (loading) {
        return (
          <CircularProgress size={15} />
        );
      }

      return (
        <IconButton
          style={{ padding: 0 }}
          onClick={clickFunc}
        >
          <Box component="img" src={EditIcon} className={classes.icon} />
        </IconButton>
      );
    }
    return null;
  };

  const renderCreateUpdateInfo = (datetime, actor) => {
    const newDateTime = previewDateTime(datetime);
    let newActor = getUserName(actor);
    if (!newActor) {
      switch (type) {
        case 'workflow':
          newActor = 'Workflow';
          break;
        default:
          newActor = 'System';
          break;
      }
    }

    return `${newDateTime} by ${newActor}`;
  };

  const renderCallFrom = (f) => {
    if (log.direction === 'call_in') {
      if (log.lead_id) {
        return toCapitalize((lead && lead.name) || log[f.name]);
      }
      if (log.customer_id) {
        return toCapitalize((customer && customer.name) || log[f.name]);
      }
    }
    return '';
  };

  const renderValue = (f) => {
    let dispValue = '';

    if (log[f.name]) {
      if (f.type === 'chip') {
        return <CallTypeTag type={log[f.name]} direction={log.direction} />;
      }
      if (f.type === 'audio_player') {
        return (
          <AudioPlayer
            url={log[f.name]}
            currentPlayingURL={currentPlayingURL}
            onClick={() => setCurrentPlayingURL(log[f.name])}
          />
        );
      }

      switch (f.type) {
        case 'date':
          if (log[f.name]) {
            dispValue = previewDate(log[f.name]);
          }
          break;
        case 'time':
          if (log[f.name]) {
            dispValue = previewTime(log[f.name]);
          }
          break;
        case 'dropdown':
          if (f.options && f.options.length > 0) {
            const optionIndex = f.options.findIndex((option) => option.value === log[f.name]);
            if (optionIndex > -1) {
              dispValue = f.options[optionIndex].label || formatValue(log[f.name]);
            } else {
              dispValue = formatValue(log[f.name]);
            }
          }
          break;
        default:
          dispValue = log[f.name];
      }

      switch (f.name) {
        case 'call_by':
          dispValue = getUserName(log[f.name]);
          break;
        case 'call_from':
          dispValue = renderCallFrom(f);
          break;
        case 'created_at':
          dispValue = renderCreateUpdateInfo(dispValue, log.created_by);
          break;
        case 'updated_at':
          dispValue = renderCreateUpdateInfo(dispValue, log.updated_by);
          break;
        case 'lead_id':
          dispValue = toCapitalize(type === 'customer' ? (customer && customer.name) : (lead && lead.name));
          break;
        case 'customer_id':
          dispValue = toCapitalize((type === 'customer' && log.lead_id) ? (lead && lead.name) : (customer && customer.name));
          break;
        default:
          break;
      }
    }

    return (
      <Typography
        component="span"
        variant="body1"
        className={`${classes.value} ${(f.name === 'status' && log[f.name] && log[f.name] === pendingSystem.name) ? 'italic' : ''}`}
      >
        { dispValue }
      </Typography>
    );
  };

  const renderFixedField = (f) => (
    <div key={f.name}>
      <InputLabel>
        { f.label }
      </InputLabel>
      { renderValue(f) }
    </div>
  );

  const renderTextField = (f) => (
    <div key={f.name}>
      <InputLabel>
        { f.label }
      </InputLabel>
      {
        f.name in selected ? (
          <>
            <TextField
              fullWidth
              name={f.name}
              variant="outlined"
              type={f.type === 'currency' ? 'number' : f.type}
              defaultValue={log[f.name] || ''}
              onChange={(e) => handleChange(e)}
              InputProps={{
                inputProps: f.type !== 'text' ? {
                  min: 1,
                } : {},
              }}
            />
            { renderEditButtons(f) }
          </>
        ) : (
          <Grid container justify="space-between">
            { renderValue(f) }
            {
              Object.keys(selected).length === 0
                ? renderEditToggle(f, () => {
                  setSelected({ [f.name]: log[f.name] || '' });
                })
                : null
            }
          </Grid>
        )
      }
    </div>
  );

  const renderField = (f) => {
    if (f.hide) return null;

    let funcComponent;

    if (f.fixed) {
      funcComponent = renderFixedField(f);
    } else {
      switch (f.type) {
        case 'date':
          funcComponent = (
            <DatePicker
              simple
              disableClearable
              label={f.label}
              name={f.name}
              value={selected[f.name] || ''}
              onChange={(v) => handleChangeDateTime(f.name, v)}
            />
          );
          break;
        case 'time':
          funcComponent = (
            <TimePicker
              fullWidth
              disableClearable
              label={f.label}
              value={selected[f.name] || null}
              onChange={(v) => handleChangeDateTime(f.name, v)}
            />
          );
          break;
        case 'dropdown':
          funcComponent = (
            <SelectInput
              name={f.name}
              label={f.label}
              options={f.options}
              value={selected[f.name] || ''}
              onChange={handleChange}
            />
          );
          break;
        case 'longtext':
          funcComponent = (
            <>
              <InputLabel>
                {f.label}
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                name={f.name}
                value={selected[f.name] || ''}
                onChange={(e) => handleChange(e)}
                className={classes.textarea}
              />
            </>
          );
          break;
        default:
          return renderTextField(f);
      }
    }


    return (
      <div key={f.name}>
        { f.name in selected ? (
          <>
            { funcComponent }
            { renderEditButtons(f) }
          </>
        ) : (
          <>
            <InputLabel>
              { f.label }
            </InputLabel>
            <Grid container justify="space-between">
              <Grid item xs={11}>
                { renderValue(f) }
              </Grid>
              <Grid item>
                {
                  Object.keys(selected).length === 0
                    ? renderEditToggle(f, () => {
                      setSelected({ [f.name]: log[f.name] });
                    })
                    : null
                }
              </Grid>
            </Grid>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Drawer
        header="Call information"
        open={open}
        hideFooter
        isLoading={loading}
        onClose={handleClose}
      >
        <div className={classes.content}>
          { fields.map((field) => renderField(field)) }
        </div>
      </Drawer>
      <SimpleModal
        title="For your information"
        open={activeStates.uneditableWarning}
        onCancel={() => {
          setSelected({});
          toggleState('uneditableWarning')();
        }}
        content={(
          <Typography variant="body2">
            Once confirmed, you are not allowed to edit the call outcome and call duration anymore.
          </Typography>
        )}
        buttons={(
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setSelected({});
                toggleState('uneditableWarning')();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleUpdateLog({ name: 'status' });
                toggleState('uneditableWarning')();
              }}
            >
              Save
            </Button>
          </>
        )}
      />
    </>
  );
}

CallLogInfo.propTypes = {
  type: PropTypes.oneOf(['lead', 'customer']),
  lead: PropTypes.instanceOf(Object),
  customer: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

CallLogInfo.defaultProps = {
  type: 'lead',
  lead: {},
  customer: {},
  data: {},
  open: false,
  loading: false,
  onUpdate: () => {},
  onClose: () => {},
};
