import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../contexts';

export default makeStyles((theme) => ({
  container: {
    fontFamily: fonts.bodyFont,
    padding: '0 !important',
    '& > div > div button': {
      fontFamily: fonts.bodyFont,
    },
  },
  headerBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem',
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > button': {
        width: '1.5rem',
        height: '1.2rem',
        padding: '0.2rem',
      },
    },
  },
  contentWrapper: {
    '& .vanilla-calendar-week__day': {
      ...fonts.subtitle2,
    },
    '& .vanilla-calendar-day__btn': {
      ...fonts.body2,
      color: `${theme.palette.font.body} !important`,
      width: '2.15rem',
      height: '2.15rem',
      '&:hover, &.vanilla-calendar-day__btn_today, &.vanilla-calendar-day__btn_selected': {
        borderRadius: '50%',
        border: 'none',
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: '#49b3b13b !important',
      },
      '&.vanilla-calendar-day__btn_next, &.vanilla-calendar-day__btn_prev': {
        color: `${theme.palette.font.subBody} !important`,
      },
    },
  },
}));
