import { isEmpty } from 'lodash';

export function isObj(value) {
  return typeof value === 'object' && !Array.isArray(value);
}

export function isNumber(value) {
  return typeof value === 'number';
}

export function numberHasLength(value) {
  return isNumber(value) && value > 0;
}

export function arrayHasItems(arr) {
  return Array.isArray(arr) && numberHasLength(arr.length);
}

export function objHasOwnProperty(obj, key) {
  return obj && isObj(obj) && Object.prototype.hasOwnProperty.call(obj, key);
}

export function objHasKeys(obj) {
  if (obj && isObj(obj)) {
    const keys = Object.keys(obj);
    return arrayHasItems(keys);
  }
  return false;
}

// lodash isEmpty will always return true for boolean and number
// so need to double check if want to support bollean and number checkings
export function isEmptyValue(value) {
  if (!['boolean', 'number'].includes(typeof value)) {
    let newValue = value;
    if (typeof value === 'string') {
      newValue = newValue.trim();
    }
    return isEmpty(newValue);
  }
  return false;
}
