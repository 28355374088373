import { CONSTRUCTOR } from '..';

// ContactFieldSettingsResponse fields fragment.
export const contactFieldSettingsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ContactFieldSettingsResponse',
  fields: `{
    body
    updated_at
  }`,
});
