import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Icon,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext, PageContext } from '../../contexts';
import { usePrimaryNavStyles } from './styles';
import { Tooltip } from '..';
import {
  CustomersIcon,
  HomeIcon,
  HelpIcon,
  LeadsIcon,
  ProfileIcon,
  SettingsIcon,
  SignoutIcon,
  OpportunityIcon,
  CampaignsIcon,
} from '../../assets/icons';
import config from '../../config';
import { ROLE_HEADQUARTERS } from '../../data';

const PrimaryNav = (props) => {
  const classes = usePrimaryNavStyles();

  const authContext = useContext(AuthContext);
  const { user, apps } = authContext.state;
  const { signOut } = authContext.actions;
  const pageContext = useContext(PageContext);
  const { lastReleaseNoteVersionSeen } = pageContext.state;

  const { selected, onSelect } = props;

  const handleSignOut = () => {
    signOut();
  };

  function ListItemLink(properties) {
    return <ListItem button component="a" {...properties} />;
  }

  const items = [
    {
      name: '',
      title: 'Home',
      to: '/',
      icon: HomeIcon,
    },
    {
      name: 'leads',
      title: 'Leads',
      to: '/leads?page=1',
      icon: LeadsIcon,
    },
    {
      name: 'customers',
      title: 'Customers',
      to: '/customers?page=1',
      icon: CustomersIcon,
    },
    {
      name: 'opportunities',
      title: 'Opportunities',
      to: '/opportunities?page=1',
      icon: OpportunityIcon,
    },
    {
      name: 'campaigns',
      title: 'Campaigns',
      to: '/campaigns?page=1',
      icon: CampaignsIcon,
    },
    // LMS-2843
    // {
    //   name: 'chats',
    //   title: 'Chats',
    //   to: '/chats',
    //   icon: CommentIcon,
    // },
  ];

  // LMS-878: Hide settings from Staff
  if (user.role && user.role === ROLE_HEADQUARTERS) {
    items.push({
      name: 'settings',
      title: 'Settings',
      to: '/settings/fields',
      icon: SettingsIcon,
    });
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  if (isMobile) {
    const newReleaseNoteAvailable = (
      !lastReleaseNoteVersionSeen
      || (lastReleaseNoteVersionSeen && lastReleaseNoteVersionSeen !== config.app.version)
    );
    items.push({
      name: 'help',
      title: 'Help',
      to: '/faq',
      icon: HelpIcon,
      haveBadge: newReleaseNoteAvailable,
    });
  }

  const profileURL = apps.showroom && apps.showroom.webURL ? apps.showroom.webURL : '';
  return (
    <nav className={classes.root}>
      <List component="nav" className={classes.list}>
        {
          items.map((item) => (
            <ListItemLink
              button
              key={item.name}
              name={item.name}
              className={classes.item}
              component={RouterLink}
              to={item.to}
              selected={selected === item.name}
              onClick={onSelect}
            >
              <Badge
                color="error"
                variant="dot"
                className={classes.badge}
                invisible={!item.haveBadge}
              >
                <ListItemIcon className={classes.icon}>
                  <Tooltip title={item.title} placement="right">
                    <Icon>
                      <img alt={item.title} src={item.icon} />
                    </Icon>
                  </Tooltip>
                </ListItemIcon>
              </Badge>
              <Typography variant="h6">{ item.title }</Typography>
            </ListItemLink>
          ))
        }
      </List>
      <div className={classes.profile}>
        <Icon className={classes.profileIcon}>
          <img alt="User" src={ProfileIcon} />
        </Icon>
        <div className={classes.profileName}>
          <Typography variant="subtitle2">{user.name}</Typography>
          <Link variant="caption" href={`${profileURL}/profile/`}>
            View Profile
          </Link>
        </div>
        <Icon className={classes.profileIcon} onClick={handleSignOut}>
          <img alt="Signout" src={SignoutIcon} />
        </Icon>
      </div>
    </nav>
  );
};

PrimaryNav.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

PrimaryNav.defaultProps = {
  selected: '',
  onSelect: () => {},
};

export default PrimaryNav;
