import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  IconButton,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const ConfirmationModal = (props) => {
  const {
    open,
    header,
    content,
    onCancelText,
    onConfirmText,
    onConfirm,
    onCancel,
    hideCancel,
    type = 'save',
  } = props;
  const classes = useStyles();

  const getDefaultHeader = () => {
    if (type === 'delete') {
      return 'Delete';
    }
    if (type === 'save') {
      return 'Save changes?';
    }
    if (type === 'leave') {
      return 'Are you sure you want to leave?';
    }

    return '';
  };

  const getDefaultConfirmText = () => {
    if (type === 'delete') {
      return 'Delete';
    }
    if (type === 'save') {
      return 'Save';
    }
    if (type === 'leave') {
      return 'Leave';
    }

    return '';
  };

  const getDefaultCancelText = () => {
    if (type === 'leave') {
      return 'Stay';
    }

    return 'Cancel';
  };

  const getDefaultContent = () => {
    if (type === 'delete') {
      return (
        <>
          <Typography>Are you sure you want to do this?</Typography>
          <Typography variant="caption">Note: You cannot undo this</Typography>
        </>
      );
    }
    if (type === 'leave') {
      return (
        <Typography color="textSecondary">
          You have unsaved changes. If you leave the page, these changes will be
          lost.
        </Typography>
      );
    }

    return '';
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <div className={classes.root}>
        <Grid
          container
          className={classes.heading}
          justifycontent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{header || getDefaultHeader()}</Typography>
          <IconButton size="small" onClick={onCancel} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {content || getDefaultContent()}
        <div className={classes.controls}>
          {
            !hideCancel && (
              <Button variant="outlined" onClick={onCancel}>
                {onCancelText || getDefaultCancelText()}
              </Button>
            )
          }
          <Button
            variant="contained"
            color="secondary"
            className={clsx(
              ['delete', 'leave'].includes(type) && classes.errorButton,
            )}
            onClick={onConfirm}
          >
            {onConfirmText || getDefaultConfirmText()}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};


ConfirmationModal.propTypes = {
  type: PropTypes.string,
  header: PropTypes.string,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  content: PropTypes.node,
  onConfirmText: PropTypes.string,
  onCancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  type: '',
  onConfirmText: '',
  onCancelText: '',
  hideCancel: false,
  header: '',
  open: false,
  onConfirm: () => {},
  onCancel: () => {},
  content: null,
};

export default ConfirmationModal;
