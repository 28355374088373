import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

// Campaign GraphQL Mutations.

// CREATE create campaign GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createCampaign',
    args: [
      { name: 'name', type: 'String', required: true },
      { name: 'project_ids', type: '[String]' },
      { name: 'description', type: 'String' },
      { name: 'starting_at', type: 'String', required: true },
      { name: 'ending_at', type: 'String', required: true },
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'name', value: 'name' },
      { name: 'project_ids', value: 'project_ids' },
      { name: 'description', value: 'description' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...CampaignFields}',
    fragments: [Fragments.campaign],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update campaign GraphQL schema
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateCampaign',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'project_ids', type: '[String]' },
      { name: 'description', type: 'String' },
      { name: 'starting_at', type: 'String', required: true },
      { name: 'ending_at', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'name', value: 'name' },
      { name: 'project_ids', value: 'project_ids' },
      { name: 'description', value: 'description' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
    ],
    fields: '{...CampaignFields}',
    fragments: [Fragments.campaign],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE delete campaign GraphQL schema
export const DELETE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteCampaign',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...CampaignFields}',
    fragments: [Fragments.campaign],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE_CHANNEL create channel for campaign GraphQL schema.
export const CREATE_CHANNEL = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createChannel',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'campaign_id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'channel_type', type: 'String', required: true },
      { name: 'page_id', type: 'String' },
      { name: 'url', type: 'String' },
      { name: 'starting_at', type: 'String' },
      { name: 'ending_at', type: 'String' },
      { name: 'status', type: 'String' },
      { name: 'description', type: 'String' },
      { name: 'location', type: 'String' },
      { name: 'attendees', type: 'Int' },
      { name: 'total_budget', type: 'Float' },
      { name: 'total_spent', type: 'Float' },
      { name: 'budget_amount', type: 'String' },
      { name: 'budget_currency_code', type: 'String' },
      { name: 'cost_currency_code', type: 'String' },
      { name: 'message', type: 'String' },
      { name: 'audience_groups', type: '[AudienceGroupInput]' },
      { name: 'count', type: 'Int' },
      { name: 's3_key', type: 'String' },
      { name: 'paused_at', type: 'String' },
      { name: 'scheduled_at', type: 'String' },
      { name: 'sender_name', type: 'String' },
      { name: 'sender_email', type: 'String' },
      { name: 'subject', type: 'String' },
      { name: 'email_preview', type: 'String' },
      { name: 'email_design', type: 'String' },
      { name: 'email_content', type: 'String' },
      { name: 'used_email_blast_count', type: 'Int' },
      { name: 'development_id', type: 'Int' },
      { name: 'listing_portal_type', type: 'String' },
      { name: 'project_name', type: 'String' },
      { name: 'printed_media_type', type: 'String' },
      { name: 'allow_tracking', type: 'Boolean' },
      { name: 'adsource_id', type: 'String' },
      { name: 'iproperty_api_key', type: 'String' },
      { name: 'propertyguru_api_key', type: 'String' },
      { name: 'listing_id', type: 'String' },
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'name', value: 'name' },
      { name: 'channel_type', value: 'channel_type' },
      { name: 'page_id', value: 'page_id' },
      { name: 'url', value: 'url' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
      { name: 'status', value: 'status' },
      { name: 'description', value: 'description' },
      { name: 'location', value: 'location' },
      { name: 'attendees', value: 'attendees' },
      { name: 'total_budget', value: 'total_budget' },
      { name: 'total_spent', value: 'total_spent' },
      { name: 'budget_amount', value: 'budget_amount' },
      { name: 'budget_currency_code', value: 'budget_currency_code' },
      { name: 'cost_currency_code', value: 'cost_currency_code' },
      { name: 'message', value: 'message' },
      { name: 'audience_groups', value: 'audience_groups' },
      { name: 'count', value: 'count' },
      { name: 's3_key', value: 's3_key' },
      { name: 'paused_at', value: 'paused_at' },
      { name: 'scheduled_at', value: 'scheduled_at' },
      { name: 'sender_name', value: 'sender_name' },
      { name: 'sender_email', value: 'sender_email' },
      { name: 'subject', value: 'subject' },
      { name: 'email_preview', value: 'email_preview' },
      { name: 'email_design', value: 'email_design' },
      { name: 'email_content', value: 'email_content' },
      { name: 'used_email_blast_count', value: 'used_email_blast_count' },
      { name: 'development_id', value: 'development_id' },
      { name: 'listing_portal_type', value: 'listing_portal_type' },
      { name: 'project_name', value: 'project_name' },
      { name: 'printed_media_type', value: 'printed_media_type' },
      { name: 'allow_tracking', value: 'allow_tracking' },
      { name: 'adsource_id', value: 'adsource_id' },
      { name: 'iproperty_api_key', value: 'iproperty_api_key' },
      { name: 'propertyguru_api_key', value: 'propertyguru_api_key' },
      { name: 'listing_id', value: 'listing_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...ChannelFields}',
    fragments: [Fragments.channel],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_CHANNEL update channel for campaign GraphQL schema.
export const UPDATE_CHANNEL = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateChannel',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'id', type: 'String', required: true },
      { name: 'name', type: 'String' },
      { name: 'channel_type', type: 'String' },
      { name: 'page_id', type: 'String' },
      { name: 'url', type: 'String' },
      { name: 'starting_at', type: 'String' },
      { name: 'ending_at', type: 'String' },
      { name: 'status', type: 'String' },
      { name: 'description', type: 'String' },
      { name: 'location', type: 'String' },
      { name: 'attendees', type: 'Int' },
      { name: 'total_budget', type: 'Float' },
      { name: 'total_spent', type: 'Float' },
      { name: 'budget_amount', type: 'String' },
      { name: 'budget_currency_code', type: 'String' },
      { name: 'cost_amount', type: 'String' },
      { name: 'cost_currency_code', type: 'String' },
      { name: 'message', type: 'String' },
      { name: 'audience_groups', type: '[AudienceGroupInput]' },
      { name: 'count', type: 'Int' },
      { name: 's3_key', type: 'String' },
      { name: 'paused_at', type: 'String' },
      { name: 'scheduled_at', type: 'String' },
      { name: 'sender_name', type: 'String' },
      { name: 'sender_email', type: 'String' },
      { name: 'subject', type: 'String' },
      { name: 'email_preview', type: 'String' },
      { name: 'email_design', type: 'String' },
      { name: 'email_content', type: 'String' },
      { name: 'used_email_blast_count', type: 'Int' },
      { name: 'development_id', type: 'Int' },
      { name: 'listing_portal_type', type: 'String' },
      { name: 'project_name', type: 'String' },
      { name: 'form_id', type: 'String' },
      { name: 'printed_media_type', type: 'String' },
      { name: 'allow_tracking', type: 'Boolean' },
      { name: 'adsource_id', type: 'String' },
      { name: 'iproperty_api_key', type: 'String' },
      { name: 'propertyguru_api_key', type: 'String' },
      { name: 'listing_id', type: 'String' },
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'id', value: 'id' },
      { name: 'name', value: 'name' },
      { name: 'channel_type', value: 'channel_type' },
      { name: 'page_id', value: 'page_id' },
      { name: 'url', value: 'url' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
      { name: 'status', value: 'status' },
      { name: 'description', value: 'description' },
      { name: 'location', value: 'location' },
      { name: 'attendees', value: 'attendees' },
      { name: 'total_budget', value: 'total_budget' },
      { name: 'total_spent', value: 'total_spent' },
      { name: 'budget_amount', value: 'budget_amount' },
      { name: 'budget_currency_code', value: 'budget_currency_code' },
      { name: 'cost_amount', value: 'cost_amount' },
      { name: 'cost_currency_code', value: 'cost_currency_code' },
      { name: 'message', value: 'message' },
      { name: 'audience_groups', value: 'audience_groups' },
      { name: 'count', value: 'count' },
      { name: 's3_key', value: 's3_key' },
      { name: 'paused_at', value: 'paused_at' },
      { name: 'scheduled_at', value: 'scheduled_at' },
      { name: 'sender_name', value: 'sender_name' },
      { name: 'sender_email', value: 'sender_email' },
      { name: 'subject', value: 'subject' },
      { name: 'email_preview', value: 'email_preview' },
      { name: 'email_design', value: 'email_design' },
      { name: 'email_content', value: 'email_content' },
      { name: 'used_email_blast_count', value: 'used_email_blast_count' },
      { name: 'development_id', value: 'development_id' },
      { name: 'listing_portal_type', value: 'listing_portal_type' },
      { name: 'project_name', value: 'project_name' },
      { name: 'form_id', value: 'form_id' },
      { name: 'printed_media_type', value: 'printed_media_type' },
      { name: 'allow_tracking', value: 'allow_tracking' },
      { name: 'adsource_id', value: 'adsource_id' },
      { name: 'iproperty_api_key', value: 'iproperty_api_key' },
      { name: 'propertyguru_api_key', value: 'propertyguru_api_key' },
      { name: 'listing_id', value: 'listing_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...ChannelFields}',
    fragments: [Fragments.channel],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_CHANNEL delete channel for campaign GraphQL schema
export const DELETE_CHANNEL = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteChannel',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...ChannelFields}',
    fragments: [Fragments.channel],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SUBSCRIBE_FACEBOOK create facebook user GraphQL schema.
export const SUBSCRIBE_FACEBOOK = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'subscribeFacebook',
    args: [
      { name: 'fb_user_id', type: 'String', required: true },
      { name: 'username', type: 'String', required: true },
      { name: 'ad_account_ids', type: '[String]', required: true },
      { name: 'user_token', type: 'String', required: true },
      { name: 'page_ids', type: '[String]', required: true },
      { name: 'page_tokens', type: 'String', required: true },
      { name: 'login_type', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'fb_user_id', value: 'fb_user_id' },
      { name: 'username', value: 'username' },
      { name: 'ad_account_ids', value: 'ad_account_ids' },
      { name: 'user_token', value: 'user_token' },
      { name: 'page_ids', value: 'page_ids' },
      { name: 'page_tokens', value: 'page_tokens' },
      { name: 'login_type', value: 'login_type' },
    ],
    fields: '{...FacebookSubscriptionFields}',
    fragments: [Fragments.facebookSubscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UNSUBSCRIBE_FACEBOOK delete facebook user GraphQL schema.
export const UNSUBSCRIBE_FACEBOOK = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'unsubscribeFacebook',
    args: [],
    argsMap: [],
    fields: '{...FacebookSubscriptionFields}',
    fragments: [Fragments.facebookSubscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_FACEBOOK_SUBSCRIPTION get facebook user of the campaign GraphQL schema
export const RESYNC_FACEBOOK_ADSET = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'resyncFacebookAdset',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'company_id', type: 'String', required: true },
      { name: 'company_branch_id', type: 'String', required: true },
      { name: 'ad_account_ids', type: '[String]', required: true },
      { name: 'page_ids', type: '[String]', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'company_id', value: 'company_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
      { name: 'ad_account_ids', value: 'ad_account_ids' },
      { name: 'page_ids', value: 'page_ids' },
    ],
    fields: '{...resyncFacebookAdsetResponseFields}',
    fragments: [Fragments.resyncFacebookAdset],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};


// LINK_FACEBOOK_CAMPAIGN link facebook campaign GraphQL schema.
export const LINK_FACEBOOK_CAMPAIGN = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'linkFacebookCampaign',
    args: [
      { name: 'fb_campaign_id', type: 'String', required: true },
      { name: 'campaign_id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'fb_campaign_id', value: 'fb_campaign_id' },
      { name: 'campaign_id', value: 'campaign_id' },
    ],
    fields: '{...ChannelFields}',
    fragments: [Fragments.channel],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SEND_EMAIL_VERIFICATION send email OPT GraphQL schema.
export const SEND_EMAIL_VERIFICATION = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'sendEmailVerification',
    args: [
      { name: 'email', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'email', value: 'email' },
    ],
    fields: '{...EmailVerificationFields}',
    fragments: [Fragments.emailVerification],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CONFIRM_EMAIL_VERIFICATION confirm recieved OTP GraphQL schema.
export const CONFIRM_EMAIL_VERIFICATION = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'confirmEmailVerification',
    args: [
      { name: 'otp', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'otp', value: 'otp' },
    ],
    fields: '{...EmailVerificationFields}',
    fragments: [Fragments.emailVerification],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_EMAIL_VERIFICATION deletes verified email GraphQL schema.
export const DELETE_EMAIL_VERIFICATION = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteEmailVerification',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...EmailVerificationFields}',
    fragments: [Fragments.emailVerification],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
