import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// CREATE create opportunity GraphQL schema.
export const CREATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createOpportunity',
    args: [
      { name: 'lead_id', type: 'String' },
      { name: 'primary_customer_id', type: 'String', required: true },
      { name: 'customer_ids', type: '[String]' },
      { name: 'project_id', type: 'String', required: true },
      { name: 'unit_id', type: 'String' },
      { name: 'stage', type: 'String' },
      { name: 'lost_reason', type: 'String' },
      { name: 'assigned_to', type: 'String' },
      { name: 'source', type: 'String' },
      { name: 'campaign_id', type: 'String' },
      { name: 'campaign_name', type: 'String' },
      { name: 'channel_id', type: 'String' },
      { name: 'channel_name', type: 'String' },
      { name: 'form_id', type: 'String' },
    ],
    argsMap: [
      { name: 'lead_id', value: 'lead_id' },
      { name: 'primary_customer_id', value: 'primary_customer_id' },
      { name: 'customer_ids', value: 'customer_ids' },
      { name: 'project_id', value: 'project_id' },
      { name: 'unit_id', value: 'unit_id' },
      { name: 'stage', value: 'stage' },
      { name: 'lost_reason', value: 'lost_reason' },
      { name: 'assigned_to', value: 'assigned_to' },
      { name: 'source', value: 'source' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'campaign_name', value: 'campaign_name' },
      { name: 'channel_id', value: 'channel_id' },
      { name: 'channel_name', value: 'channel_name' },
      { name: 'form_id', value: 'form_id' },
    ],
    fields: '{...OpportunityFields}',
    fragments: [Fragments.opportunity],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE update opportunity GraphQL schema.
export const UPDATE = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateOpportunity',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'primary_customer_id', type: 'String' },
      { name: 'customer_ids', type: '[String]' },
      { name: 'project_id', type: 'String' },
      { name: 'unit_id', type: 'String' },
      { name: 'stage', type: 'String' },
      { name: 'lost_reason', type: 'String' },
      { name: 'assigned_to', type: 'String' },
      { name: 'source', type: 'String' },
      { name: 'campaign_id', type: 'String' },
      { name: 'campaign_name', type: 'String' },
      { name: 'channel_id', type: 'String' },
      { name: 'channel_name', type: 'String' },
      { name: 'form_id', type: 'String' },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'primary_customer_id', value: 'primary_customer_id' },
      { name: 'customer_ids', value: 'customer_ids' },
      { name: 'project_id', value: 'project_id' },
      { name: 'unit_id', value: 'unit_id' },
      { name: 'stage', value: 'stage' },
      { name: 'lost_reason', value: 'lost_reason' },
      { name: 'assigned_to', value: 'assigned_to' },
      { name: 'source', value: 'source' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'campaign_name', value: 'campaign_name' },
      { name: 'channel_id', value: 'channel_id' },
      { name: 'channel_name', value: 'channel_name' },
      { name: 'form_id', value: 'form_id' },
    ],
    fields: '{...OpportunityFields}',
    fragments: [Fragments.opportunity],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// ASSIGN assign assignee to opportunity GraphQL schema.
export const ASSIGN = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'assignOpportunity',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'assigned_to', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'assigned_to', value: 'assigned_to' },
    ],
    fields: '{...OpportunityFields}',
    fragments: [Fragments.opportunity],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UNASSIGN unassign assignee to opportunity GraphQL schema.
export const UNASSIGN = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'unassignOpportunity',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'assigned_to', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'assigned_to', value: 'assigned_to' },
    ],
    fields: '{...OpportunityFields}',
    fragments: [Fragments.opportunity],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// CREATE_APPOINTMENT create appointment for opportunity GraphQL schema.
export const CREATE_APPOINTMENT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'createAppointment',
    args: [
      { name: 'company_branch_id', type: 'String', required: true },
      { name: 'opportunity_id', type: 'String', required: true },
      { name: 'opportunity_primary_customer_id', type: 'String', required: true },
      { name: 'name', type: 'String', required: true },
      { name: 'type', type: 'String', required: true },
      { name: 'status', type: 'String', required: true },
      { name: 'note_message', type: 'String' },
      { name: 'cancellation_reason', type: 'String' },
      { name: 'starting_at', type: 'String', required: true },
      { name: 'ending_at', type: 'String', required: true },
      { name: 'location', type: 'String' },
      { name: 'assigned_to', type: 'String', required: true },
      { name: 'customer_ids', type: '[String]' },
      { name: 'meeting_url', type: 'String' },
    ],
    argsMap: [
      { name: 'company_branch_id', value: 'company_branch_id' },
      { name: 'opportunity_id', value: 'opportunity_id' },
      { name: 'opportunity_primary_customer_id', value: 'opportunity_primary_customer_id' },
      { name: 'name', value: 'name' },
      { name: 'type', value: 'type' },
      { name: 'status', value: 'status' },
      { name: 'note_message', value: 'note_message' },
      { name: 'cancellation_reason', value: 'cancellation_reason' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
      { name: 'location', value: 'location' },
      { name: 'assigned_to', value: 'assigned_to' },
      { name: 'customer_ids', value: 'customer_ids' },
      { name: 'meeting_url', value: 'meeting_url' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// UPDATE_APPOINTMENT update appointment for opportunity GraphQL schema.
export const UPDATE_APPOINTMENT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'updateAppointment',
    args: [
      { name: 'company_branch_id', type: 'String', required: true },
      { name: 'opportunity_primary_customer_id', type: 'String', required: true },
      { name: 'id', type: 'String', required: true },
      { name: 'name', type: 'String' },
      { name: 'type', type: 'String', required: true },
      { name: 'status', type: 'String', required: true },
      { name: 'note_message', type: 'String' },
      { name: 'note_id', type: 'String' },
      { name: 'cancellation_reason', type: 'String' },
      { name: 'starting_at', type: 'String' },
      { name: 'ending_at', type: 'String' },
      { name: 'location', type: 'String' },
      { name: 'assigned_to', type: 'String', required: true },
      { name: 'customer_ids', type: '[String]' },
      { name: 'meeting_url', type: 'String' },
    ],
    argsMap: [
      { name: 'company_branch_id', value: 'company_branch_id' },
      { name: 'opportunity_primary_customer_id', value: 'opportunity_primary_customer_id' },
      { name: 'id', value: 'id' },
      { name: 'name', value: 'name' },
      { name: 'type', value: 'type' },
      { name: 'status', value: 'status' },
      { name: 'note_message', value: 'note_message' },
      { name: 'note_id', value: 'note_id' },
      { name: 'cancellation_reason', value: 'cancellation_reason' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
      { name: 'location', value: 'location' },
      { name: 'assigned_to', value: 'assigned_to' },
      { name: 'customer_ids', value: 'customer_ids' },
      { name: 'meeting_url', value: 'meeting_url' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DELETE_APPOINTMENT delete appointment for opportunity GraphQL schema.
export const DELETE_APPOINTMENT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'deleteAppointment',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// RESCHEDULE_APPOINTMENT reschedule existing appointment GraphQL schema.
export const RESCHEDULE_APPOINTMENT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'rescheduleAppointment',
    args: [
      { name: 'id', type: 'String', required: true },
      { name: 'old_starting_at', type: 'String', required: true },
      { name: 'old_ending_at', type: 'String', required: true },
      { name: 'starting_at', type: 'String', required: true },
      { name: 'ending_at', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
      { name: 'old_starting_at', value: 'old_starting_at' },
      { name: 'old_ending_at', value: 'old_ending_at' },
      { name: 'starting_at', value: 'starting_at' },
      { name: 'ending_at', value: 'ending_at' },
    ],
    fields: '{...AppointmentFields}',
    fragments: [Fragments.appointment],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
