/* eslint react/forbid-prop-types: 0 */

import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
} from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';

import {
  RouteAuthenticated,
  RouteUnauthenticated,
  LoadingPage,
  RouteApplied,
} from './components';
import { PageContext } from './contexts';
import { componentLoader } from './utils';

const Audience = lazy(() => componentLoader(() => import('./pages/Audience'), 2));
const Customer = lazy(() => componentLoader(() => import('./pages/Customer'), 2));
const Campaign = lazy(() => componentLoader(() => import('./pages/Campaign'), 2));
// LMS-2843
// const Chat = lazy(() => componentLoader(() => import('./pages/Chat'), 2));
const FAQ = lazy(() => componentLoader(() => import('./pages/FAQ'), 2));
const Forbidden = lazy(() => componentLoader(() => import('./pages/Forbidden'), 2));
const Home = lazy(() => componentLoader(() => import('./pages/Home'), 2));
const Leads = lazy(() => componentLoader(() => import('./pages/Leads'), 2));
const Login = lazy(() => componentLoader(() => import('./pages/Login'), 2));
const Opportunity = lazy(() => componentLoader(() => import('./pages/Opportunity'), 2));
const Settings = lazy(() => componentLoader(() => import('./pages/Settings'), 2));
const NotFound = lazy(() => componentLoader(() => import('./pages/NotFound'), 2));
const ItemDeleted = lazy(() => componentLoader(() => import('./pages/ItemDeleted'), 2));
const ItemQualified = lazy(() => componentLoader(() => import('./pages/ItemQualified'), 2));
const AccessDenied = lazy(() => componentLoader(() => import('./pages/AccessDenied'), 2));

const Routes = () => {
  const pageContext = useContext(PageContext);
  const { appLoading } = pageContext.state;
  const { setAppLoading } = pageContext.actions;

  const history = useHistory();

  // If router change and got webLoad, switch it to false
  useEffect(() => {
    if (appLoading) {
      history.listen(() => {
        setAppLoading(false);
      });
    }
  }, [history, appLoading, setAppLoading]);

  return (
    <Suspense fallback={<LoadingPage isLoading />}>
      <Switch>
        <RouteAuthenticated path="/" exact component={Home} />
        <RouteAuthenticated path="/audiences" component={Audience} />
        <RouteAuthenticated path="/customers" component={Customer} />
        <RouteAuthenticated path="/campaigns" component={Campaign} />
        {/*
        // LMS-2843
        <RouteAuthenticated path="/chats" component={Chat} />
        */}
        <RouteAuthenticated path="/customers" component={Customer} />
        <RouteAuthenticated path="/opportunities" component={Opportunity} />
        <RouteAuthenticated path="/leads" component={Leads} />
        <RouteAuthenticated path="/settings" component={Settings} />
        <RouteAuthenticated path="/faq" component={FAQ} />
        <RouteAuthenticated path="/forbidden" component={Forbidden} />
        <RouteAuthenticated path="/item_qualified/:id" component={ItemQualified} />
        <RouteUnauthenticated path="/login" exact component={Login} />
        <RouteApplied path="/access_denied" component={AccessDenied} />
        <RouteApplied path="/item_deleted" component={ItemDeleted} />
        <RouteApplied path="/not_found" component={NotFound} />
        <Redirect to="/not_found" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
