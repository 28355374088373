import { makeStyles } from '@material-ui/core/styles';
import { filters } from '../../../contexts';

export default makeStyles(() => ({
  root: {
    maxWidth: '300px',
    '& > div:first-child': {
      padding: '1rem',
    },
    '& $list + $list': {
      marginTop: '1.5rem',
    },
  },
  list: {
    maxHeight: '250px',
    width: '300px',
    overflow: 'auto',
    paddingBottom: '1rem',
    '& > span': {
      padding: '0 1rem',
    },
  },
  listItem: {
    padding: '0rem 1rem',
  },
  label: {
    maxWidth: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    filter: filters.font.body,
  },
}));
